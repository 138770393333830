import React, { useState, useEffect } from 'react'
import { Box, Button, useMediaQuery } from "@mui/material";
import { motion } from 'framer-motion';
import './modalPopUpSignUp.css'
import { RETO_defaultSettings } from "../../../hooks/Others/RETO_data";
import LoginForm from './Forms/LoginForm';
import SignUpForm from './Forms/SignUpForm';
import ScrollToTop from '../../../ScrollToTop';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Page_Helmet from "../../../hooks/SEO/react_Helmet";
import { useAuth } from '../../../xFireBase/AuthContext';

const ModaPopUpSignUp = () => {

  const { CurrentLanguage } = useAuth();

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [showLogin, setShowLogin] = useState(true);


  const handleToggleForm = () => {
    setShowLogin(!showLogin);
  };


  return (
    <div id='login' className="SearchScreenSection" style={{ minHeight: '1000px', height: '800px' }}>
      <Page_Helmet
        title="Arab2Games | Login"
        description="احصل على افضل الالعاب و البرامج مجانية و كاملة بدون اي فيروسات"
        image={RETO_defaultSettings[0].Website_Main_Logo}
        url="https://Arab2Games.com/"
        keywords="A2G, Arab2Games, Arab2Game, arab2games, arab2game, عرب تو قيمز, عرب تو قيمنق, عرب 2 قيمز, apps, games, discord bots, arab2games, Arab2Games, Arab2Games.com, arab2games.com, arab2games.org, Arab2Games.org, arab2games.net, Arab2Games.net, arab2games.io, Arab2Games.io, arab2games.dev, Arab2Games.dev, crack, cracked, crack game, cracked game, crack games, cracked games, crack app, cracked app, crack apps, cracked apps, مكركة, كراك, العاب مكركة, مكركه, العاب مكركه, برامج مكركه, برامج مكركة, لعبة مكركة, لعبه مكركه, برنامج مكرك, العاب, لعبة, لعبه, برنامج, برامح, ريتو, لايك, لايك ريتو, arab2games, Arab2Games, ARAB2GAMES, Arab2Games, Arab2Games"
      />
      <ScrollToTop />

      <div style={{
        marginTop: isNonMobile ? '350px' : '270px', display: 'flex', justifyContent: 'center',
        transform: 'scale(0.9)', alignItems: 'center'
      }}>
        <motion.div
          layout
          id='fadeIn'
          transition={{ layout: { duration: 1, type: 'spring' } }}
          className='ModalContainer-card'
          onClick={(event) => event.stopPropagation()}
          style={{ borderRadius: '2rem', boxShadow: '0px 10px 30px rgba(0,0,0,0.5)', }}
        >

          <Box
            style={{ direction: 'rtl', textAlign: "center", justifyContent: "center", alignItems: "center" }}
            display="block"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <motion.div className='ModalContainer-Content'>
              <LazyLoadImage
                effect="blur"
                draggable={false}
                src={RETO_defaultSettings[0].Website_Main_Logo}
                alt='Arab2Games_Logo'
                className='ModalContainer-Logo'
                style={{
                  width: '130px',
                  height: '130px',
                }}
                onContextMenu={(e) => e.preventDefault()}
              />
              <motion.div className='ModalContainer-Form'
              style={{
                width: '100%',
                height: '100%',
                minWidth: '500px',
              }}
              >
                <motion.div className='ModalContainer-Form-Group'>
                  {showLogin ? (
                    <>
                      <LoginForm />
                      <Box display="flex" justifyContent="center" mt={2} >
                        <Button
                          color="info"
                          style={{ transform: CurrentLanguage === 'EN' ? 'scale(1.2)' : "scale(1.5)", marginTop: '1.5rem', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl' }}
                          onClick={handleToggleForm}>
                          {CurrentLanguage === 'EN' ? 'Create new account ?' : 'إنشاء حساب جديد ؟'}
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      <SignUpForm />
                      <Box display="flex" justifyContent="center" mt={2} >
                        <Button
                          color="info"
                          style={{ transform: CurrentLanguage === 'EN' ? 'scale(1.2)' : "scale(1.5)", marginTop: '1.5rem', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl' }}
                          onClick={handleToggleForm}>
                          {CurrentLanguage === 'EN' ? 'Already have an account ?' : 'عندك حساب ؟'}
                        </Button>
                      </Box>
                    </>
                  )}
                </motion.div>
              </motion.div>
            </motion.div>
          </Box>
        </motion.div>
      </div>
    </div>
  )
}

export default ModaPopUpSignUp;

