import React, { useState, useEffect } from 'react';
import BackToTopIcon from '../../assets/icons/angles-up-solid.svg';
import useMediaQuery from "@mui/material/useMediaQuery";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const BackToTopButton = () => {
    const [showButton, setShowButton] = useState(false);
    const isNonMobile = useMediaQuery("(min-width:1200px)");

    useEffect(() => {
      const handleScroll = () => {
        if (window.pageYOffset > 2700) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [showButton]);
  
    const handleClick = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };
  
    return (
      <button
        onClick={handleClick}
        style={{
          position: 'fixed',
          bottom: isNonMobile ? '30px' : '0',
          right: isNonMobile ? '20px' : '0',
          margin: '1rem',
          animation: 'zoomIn 0.3s ease-in-out',
          padding: '5px',
          backgroundColor: '#105EA7',
          borderRadius: '50%',
          boxShadow: '0 0 10px rgba(0, 0, 0, 1)',
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
          transition: 'all 0.3s ease-in-out',
          opacity: showButton ? 0.7 : 0,
          zIndex: 9999,
        }}
      >
          <LazyLoadImage
          effect="blur"
          draggable={false} 
          src={BackToTopIcon} 
          alt="BackToTop" 
          onContextMenu={(e) => e.preventDefault()}
          style={{width: '30px', height: '30px'}}  />
      </button>
    );
  }

export default BackToTopButton
