import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import DiscordIcon from '../../assets/img/icons/discord.svg';
import InstagramIcon from '../../assets/img/icons/instagram.svg';
import { useMediaQuery } from '@mui/material';
import { useAuth } from '../../xFireBase/AuthContext';
import InstallPrompt from '../../hooks/install_PWA/install_PWA_Prompt';
import { Link } from 'react-router-dom';
import { RETO_defaultSettings } from "../../hooks/Others/RETO_data";

const Footer = () => {
  const { CurrentLanguage } = useAuth();
  const TodayDate = new Date().getFullYear();
  const isNonMobile = useMediaQuery("(min-width:992px)");


  const FooterLinksArray = [
    {
      id: 1,
      text: 'الرئيسية',
      en_text: 'Home',
      link: `/`
    },
    {
      id: 2,
      text: 'الأسئلة الشائعة',
      en_text: 'FAQ',
      link: `/faq`
    },
    {
      id: 3,
      text: 'DMCA',
      en_text: 'DMCA',
      link: `/DMCA`
    },
    {
      id: 5,
      text: 'الشروط والأحكام',
      en_text: 'Terms of Service',
      link: `/terms_of_service`
    },
    {
      id: 6,
      text: 'سياسة الخصوصية',
      en_text: 'Privacy Policy',
      link: `/privacy_policy`
    },
  ];



  return (
    <footer className="footer" style={{ marginTop: '50px', direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr',  }} >
      <Container>
        <div style={{
          width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop: '-40px',
          direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', 
          flexDirection: isNonMobile ? 'row' : 'column', textAlign: 'center',
        }}>
          <div style={{ justifyContent: 'center', alignItems: 'center', color: '#aaa' }}>
            <header>
              <h5>
                {CurrentLanguage === 'EN'
                ? 'Download the website as an app on your device'
                :'بإمكانك الان تحميل الموقع كتطبيق على جهازك'}
              </h5>
            </header>

            <InstallPrompt />

          </div>

          <div style={{
            justifyContent: 'space-around', alignItems: 'center', width: '100%', maxWidth: '800px', display: 'flex',
            flexDirection: 'row', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', marginTop: isNonMobile ? null : '30px',
          }}>
            <ul
              style={{
                listStyle: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center',
                flexDirection: 'row', textAlign: 'center', width: '100%', flexWrap: 'wrap',
              }}
            >
              { 
              FooterLinksArray.map((item, index) => {
                return (
                  <li
                    style={{
                      margin: '0 10px', fontSize: '18px', fontWeight: 'bold', borderRadius: '10px', padding: '5px 10px'
                    }}
                  >
                    <Link
                      to={item.link}
                      target="_self"
                      rel="noreferrer"
                      style={{ textDecoration: 'none', color: '#aaa' }}
                    >
                      <span>
                        {CurrentLanguage === 'EN' ? item.en_text : item.text}
                      </span>
                    </Link>
                  </li>
                )
              })}


            </ul>
          </div>
        </div>
        
        <Row className="align-items-center">
          <Col size={12} sm={6} style={{ margin: '40px 0' }}>
            <LazyLoadImage
              effect="blur"
              draggable={false}
              className="LOGO"
              src={RETO_defaultSettings[0].Website_Main_Logo}
              alt="Logo"
              onContextMenu={(e) => e.preventDefault()}
              style={{
                width: '100px',
                height: '100px',
                borderRadius: '40%',
              }}
            />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end" style={{ 
           justifyContent: CurrentLanguage === 'EN' ? 'flex-start' : null, 
           alignItems: CurrentLanguage === 'EN' ? 'flex-start' : null, 
           display: CurrentLanguage === 'EN' ? 'flex' : null,
           flexDirection: CurrentLanguage === 'EN' ? 'column' : null, 
           textAlign: 'center',
           direction: CurrentLanguage === 'EN' ? 'ltr' : null,
           }}>
            <div className="social-icon" >
              <a
                href='https://discord.gg/jwx23GMAXm'
                target='_blank'
                rel='noreferrer'
                className='discord'
              >
                <img
                  loading="lazy"
                  draggable={false}
                  src={DiscordIcon}
                  alt="DisocrdIcon"
                  style={{ width: '100%', height: '100%', maxWidth: '14px', maxHeight: '14px' }}
                  onContextMenu={(e) => e.preventDefault()}
                />
              </a>
              <a
                href='https://www.instagram.com/arab2games/'
                target='_blank'
                rel='noreferrer'
                className='instagram'
              >
                <img
                  loading="lazy"
                  draggable={false}
                  src={InstagramIcon}
                  alt="InstagramIcon"
                  style={{ width: '100%', height: '100%', maxWidth: '14px', maxHeight: '14px' }}
                  onContextMenu={(e) => e.preventDefault()}
                />
              </a>
            </div>
            <p
            style={{ 
            display: CurrentLanguage === 'EN' ? 'flex' : null, alignItems: 'center', color: '#aaa', marginTop: '25px', 
            marginLeft: CurrentLanguage === 'EN' ? '20px' : null, marginRight: CurrentLanguage === 'EN' ? null : '20px'}}
            >
              Copyright {TodayDate}. @Arab2Games
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer