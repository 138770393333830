import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

import { NavBar } from "../components/HomeTop/NavBar";
import { NavBarMobile } from "../components/HomeTop/NavBar-Mobile";
import CurrentGamePage from './Games/CurrentGamePage';
import ALLGames from './Games/ALLGames';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Home from "./HomeScreen";
import ModaPopUpSignUp from '../hooks/Modals/Login_Modal/ModaPopUpSignUp';
import Footer from "../components/HomeFooter/Footer";
import SearchScreen from "./search/SearchScreen";
import ALLApps from "./Apps/ALLApps";
import CurrentAppPage from "./Apps/CurrentAppPage";
import PolicyScreen from "./policy_Agreement/PolicyScreen";
import AgreementScreen from "./policy_Agreement/AgreementScreen";
import PageNotFound from "./PageNotFound";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CommonQuestions from "./CommonQuestions";
import DMCAScreen from "./policy_Agreement/DMCA";
import DonateScreen from "./DonateScreen";

import RETO_servers from './RETO_servers/RETO_servers_Screen';
import { useAuth } from "../xFireBase/AuthContext";

function UserScreen2() {
  const { darkMode } = useAuth();

  const isNonMobile = useMediaQuery("(min-width:992px)");
  const isMobile = useMediaQuery("(max-width:530px)");


  return (
    <div
      style={{
        transition: 'all 0.5s ease-in-out',
        background: darkMode === 'light'
          ? '#cccccc'
          :
          'linear-gradient(170.21deg, rgba(17, 10, 14, 0.425) -10.91%, rgba(4, 1, 14, 0.582) 111.58%)',
      }}
    >
      <Router>
        <div className="App">
          <ToastContainer
            position="top-center"
            closeOnClick={true}
            draggable={false}
            closeButton={false}
            progressStyle={{ backgroundColor: '#597BA8' }}
            style={{
              marginTop: '100px', display: isMobile ? 'flex' : null, width: isMobile ? '90%' : 'fit-content', transform: isMobile ? 'scale(0.85)' : null,
              justifyContent: 'center', textAlign: 'center', alignItems: 'center'
            }}
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            toastStyle={{ backgroundColor: '#222222', color: '#ffffff', borderRadius: '70px' }} />
          {isNonMobile ? <NavBar /> : <NavBarMobile />}


          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/A2G_games/:game_Number/:game_Page' element={<CurrentGamePage />} />
            <Route path='/A2G_apps/:app_Number/:app_Page' element={<CurrentAppPage />} />
            <Route path='/A2G_games' element={<ALLGames />} />
            <Route path='/A2G_apps' element={<ALLApps />} />
            <Route path='/search' element={<SearchScreen />} />
            <Route path='/login' element={<ModaPopUpSignUp />} />
            <Route path="/A2G_promo" element={<DonateScreen />} />
            <Route path="/RETO_servers" element={<RETO_servers />} />
            <Route path="/privacy_policy" element={<PolicyScreen />} />
            <Route path="/terms_of_service" element={<AgreementScreen />} />
            <Route path='/DMCA' element={<DMCAScreen />} />
            <Route path='/faq' element={<CommonQuestions />} />
            <Route path='/404' element={<PageNotFound />} />
            <Route path='*' element={<PageNotFound />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default UserScreen2;
