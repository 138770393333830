import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, TextField, useMediaQuery } from "@mui/material";
import { toast } from 'react-toastify';

import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { formatDistanceToNowStrict } from 'date-fns';
import { useAuth } from '../../xFireBase/AuthContext';
import { BiSolidLike, BiLike, BiSolidDislike, BiDislike } from "react-icons/bi";
import { HiDotsVertical } from "react-icons/hi";
import non_ProfileIcon from '../../assets/img/icons/person-circle.svg';
import { Formik } from "formik";
import * as yup from "yup";

const Game_TheComment = ({ comment, index, deleting_comment, deleteComment, gameData }) => {

    const { user, CurrentLanguage, editGameComment, like_dislike_GameComment } = useAuth();
    const isNonMobile = useMediaQuery("(min-width:1200px");


    // the date of comment.comment_date is in the format of "2024-08-29T01:19:29.192Z"
    // we need to convert it to "1 second ago" or "1 minute ago" or "1 hour ago" or "1 day ago" or "1 week ago" or "1 month ago" or "1 year ago"
    const [commentDate, setCommentDate] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);

    const [Comment_index, setComment_index] = useState('');

    const [edits_comment, setEdits_comment] = useState(false);
    const [editing_comment, setEditing_comment] = useState(false);

    const [edit_submiting, setEdit_submiting] = useState(false);

    const [like_dislike_buttons, setLike_dislike_buttons] = useState(false);

    const [submiting_like, setSubmiting_like] = useState(false);
    const [submiting_dislike, setSubmiting_dislike] = useState(false);


    const CommentSchema = yup.object().shape({
        comment: yup.string()
            .min(2, 'Comment is too short')
            .max(200, 'Comment is too long')
            .required('Comment is required'),
    });

    const initialValues = {
        comment: comment.comment,
        comment_id: comment.comment_id,
        _id: comment?._id ? comment._id : '',
        comment_By: comment.comment_By,
    };

    const getTimeAgo = (dateString) => {

        const date = new Date(dateString);
        const timeAgo = formatDistanceToNowStrict(date, { addSuffix: true });

        const secondsAgo = Math.floor((new Date() - date) / 1000);
        const minutesAgo = Math.floor(secondsAgo / 60);
        const hoursAgo = Math.floor(minutesAgo / 60);
        const daysAgo = Math.floor(hoursAgo / 24);
        const weeksAgo = Math.floor(daysAgo / 7);
        const monthsAgo = Math.floor(weeksAgo / 4.35);
        const yearsAgo = Math.floor(monthsAgo / 12);

        if (CurrentLanguage === 'EN') {
            if (timeAgo.includes('minute') && secondsAgo < 60) {
                return `${secondsAgo} second${secondsAgo !== 1 ? 's' : ''} ago`;
            }
            return timeAgo;
        } else {
            if (yearsAgo > 0) return `منذ ${yearsAgo} سنة`;
            if (monthsAgo > 0) return `منذ ${monthsAgo} شهر`;
            if (weeksAgo > 0) return `منذ ${weeksAgo} أسبوع`;
            if (daysAgo > 0) return `منذ ${daysAgo} يوم`;
            if (hoursAgo > 0) return `منذ ${hoursAgo} ساعة`;
            if (minutesAgo > 0) return `منذ ${minutesAgo} دقيقة`;
            if (secondsAgo < 60) return `منذ ${secondsAgo} ثانية`;
        }
    };


    const Mobile_truncatedUser = comment?.comment_user_displayName?.length > 17
        ? comment.comment_user_displayName.slice(0, 17) + '...' : comment.comment_user_displayName;

    const isNonMobile_truncatedUser = comment?.comment_user_displayName?.length > 20
        ? comment.comment_user_displayName.slice(0, 20) + '...' : comment.comment_user_displayName;

    const truncatedComment = comment?.comment?.length > 100
        ? comment.comment.slice(0, 100) + '...' : comment.comment;


    const Edit_Comment = async (values) => {
        setComment_index(index);
        setEdit_submiting(true);

        try {
            // if the comment is the same as the original comment, then do nothing
            if (values.comment === comment.comment) {
                return;
            }

            // if the comment is different, then update the comment
            const edit_response = await editGameComment({ gameData, specific_comment: values });

            if (edit_response === 'done' || edit_response === true) {
                comment.comment = values.comment;

                toast.success(CurrentLanguage === 'EN' ? 'Comment Edited' : 'تم تعديل التعليق');
            }

        }
        catch (error) {
            toast.error(CurrentLanguage === 'EN' ? 'Error Editing Comment' : 'خطأ في تعديل التعليق');
            console.log('Error in Edit_Comment:', error);
        }
        finally {
            setEdit_submiting(false);
            setComment_index('');
            setEdits_comment(false);
            setEditing_comment(false);
            setSubmiting_like(false);
            setSubmiting_dislike(false);

        }
    };


    // handle the like and dislike buttons
    const handleLike = async (button_type) => {
        setLike_dislike_buttons(true);
        try {
            if (button_type === 'like') {
                setSubmiting_like(true);

                const like_dislike_response = await like_dislike_GameComment({
                    gameData,
                    specific_comment: {
                        comment_id: comment.comment_id,
                        comment_By: comment.comment_By,
                        comment: comment.comment,
                        comment_date: comment.comment_date,
                        button_Type: 'like'
                    }
                });

                if (like_dislike_response === 'done' || like_dislike_response === true) {
                    // if the user already liked the comment, remove the like, and also check if the user disliked the comment, if so remove the dislike
                    await comment?.comment_likes?.includes(user?.uid)
                        ? comment.comment_likes = comment.comment_likes.filter(id => id !== user?.uid)
                        : comment?.comment_likes?.push(user?.uid);

                    if (comment?.comment_dislikes?.includes(user?.uid)) {
                        comment.comment_dislikes = comment.comment_dislikes.filter(id => id !== user?.uid);
                    }
                }
            }
            else if (button_type === 'dislike') {
                setSubmiting_dislike(true);

                const like_dislike_response = await like_dislike_GameComment({
                    gameData,
                    specific_comment: {
                        comment_id: comment.comment_id,
                        comment_By: comment.comment_By,
                        comment: comment.comment,
                        comment_date: comment.comment_date,
                        button_Type: 'dislike'
                    }
                });

                if (like_dislike_response === 'done' || like_dislike_response === true) {
                    // if the user already disliked the comment, remove the dislike, and also check if the user liked the comment, if so remove the like
                    await comment?.comment_dislikes?.includes(user?.uid)
                        ? comment.comment_dislikes = comment.comment_dislikes.filter(id => id !== user?.uid)
                        : comment?.comment_dislikes?.push(user?.uid);

                    if (comment?.comment_likes?.includes(user?.uid)) {
                        comment.comment_likes = comment.comment_likes.filter(id => id !== user?.uid);
                    }
                }
            }
            else {
                console.log('Error in handleLike: button_type is not valid');
            }
        }
        catch (error) {
            console.log('Error in handleLike:', error);
        }
        finally {
            setEdit_submiting(false);
            setComment_index('');
            setEdits_comment(false);
            setEditing_comment(false);
            setLike_dislike_buttons(false);
            setSubmiting_like(false);
            setSubmiting_dislike(false);

        }

    }


    const makeLinks = (commentText) => {
        // Split by spaces, newlines, or a combination of both
        const words = commentText.split(/(\s+|\n+)/);

        return words.map((word, index) => {
            // Check if the word is a URL
            if (word.startsWith('https://') || word.startsWith('www.')) {
                return (
                    <React.Fragment key={index}>
                        <a href={word.startsWith('www.') ? `https://${word}` : word} target="_blank" rel="noopener noreferrer">
                            {word}
                        </a>
                        {' '} {/* Add space after the link */}
                    </React.Fragment>
                );
            }
            // Return the word as plain text with space or newline character intact
            return <React.Fragment key={index}>{word}</React.Fragment>;
        });
    };

    useEffect(() => {
        const timeAgo = getTimeAgo(comment?.comment_date);
        setCommentDate(timeAgo);
    }, [comment?.comment_date, CurrentLanguage]);

    let edits_comment_Ref = useRef();
    useEffect(() => {
        let edits_comment_Button = (event) => {
            if (edits_comment_Ref.current && !edits_comment_Ref.current.contains(event.target)) {
                setEdits_comment(false);
            }
        }
        document.addEventListener("mousedown", edits_comment_Button);
    }, []);


    return (
        <motion.div
            key={index}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            style={{
                display: 'flex',
                transition: 'all 0.5s ease-in-out',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                backgroundColor: deleting_comment === true && comment?.comment_id === comment?.comment_id
                    && comment?.comment_By === comment?.comment_By && Comment_index === index
                    ? '#4E12129C'
                    : edit_submiting === true && comment?.comment_id === comment?.comment_id
                        && comment?.comment_By === comment?.comment_By && Comment_index === index
                        ? '#122E4E9C'

                        : '#464545',
                borderRadius: '10px',
                padding: '10px',
                margin: '10px',
                width: '95%',
                height: 'auto',
            }}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={CommentSchema}
                onSubmit={async (values) => {
                    await Edit_Comment(values);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <>
                        <LazyLoadImage
                            src={comment?.comment_user_avatar ? comment?.comment_user_avatar : non_ProfileIcon}
                            alt='User Avatar'
                            style={{
                                borderRadius: '50%',
                                margin: '10px',
                                width: '40px',
                                height: '40px',
                            }}
                        />
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                margin: '10px',
                                width: '100%',
                                maxWidth: isNonMobile ? '450px' : '170px',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    width: '100%',
                                    alignItems: 'flex-start',
                                    marginBottom: '5px',
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: '5px',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            marginBottom: '5px',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                marginLeft: '20px',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: '#D8D8D8',
                                                    fontSize: '15px',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {comment?.comment_likes?.length.toLocaleString('en-US', { timeZone: 'Asia/Riyadh' })}
                                            </span>

                                            {comment?.comment_likes?.includes(user?.uid)
                                                ? <BiSolidLike
                                                    style={{
                                                        color: '#D8D8D8',
                                                        marginRight: '5px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        if (submiting_like === false && like_dislike_buttons === false) {
                                                            handleLike('like');
                                                        }
                                                    }}
                                                />
                                                : <BiLike
                                                    style={{
                                                        color: '#A8A8A8',
                                                        marginRight: '5px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        if (submiting_like === false && like_dislike_buttons === false) {
                                                            handleLike('like');
                                                        }
                                                    }}
                                                />
                                            }
                                        </div>

                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: '#D8D8D8',
                                                    fontSize: '15px',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {comment?.comment_dislikes?.length.toLocaleString('en-US', { timeZone: 'Asia/Riyadh' })}
                                            </span>

                                            {comment?.comment_dislikes?.includes(user?.uid)
                                                ? <BiSolidDislike
                                                    style={{
                                                        color: '#D8D8D8',
                                                        marginRight: '5px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        if (submiting_dislike === false && like_dislike_buttons === false) {
                                                            handleLike('dislike');
                                                        }
                                                    }}
                                                />
                                                : <BiDislike
                                                    style={{
                                                        color: '#A8A8A8',
                                                        marginRight: '5px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        if (submiting_dislike === false && like_dislike_buttons === false) {
                                                            handleLike('dislike');
                                                        }
                                                    }}
                                                />
                                            }
                                        </div>
                                    </div>

                                    {user?.uid === comment?.comment_By &&
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-end',
                                                alignItems: 'flex-end',
                                                marginBottom: '5px',
                                            }}
                                        >
                                            <div
                                                ref={edits_comment_Ref}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center',
                                                    marginLeft: '20px',
                                                }}
                                            >

                                                <HiDotsVertical

                                                    style={{
                                                        color: '#A8A8A8',
                                                        marginLeft: '5px',
                                                        cursor: 'pointer',
                                                    }}

                                                    onClick={() => {
                                                        if (edits_comment === false && editing_comment === false) {
                                                            setEdits_comment(true);
                                                            values.comment = comment.comment;
                                                        }
                                                        else {
                                                            setEditing_comment(false);
                                                            setEdits_comment(false);
                                                            setEdit_submiting(false);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>

                                {// pop up menu includes edit and delete buttons next to the comment on the right side
                                    edits_comment &&
                                    <div
                                        ref={edits_comment_Ref}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            position: 'relative',
                                            borderRadius: '10px',
                                            marginBottom: '10px',
                                            padding: '5px',
                                        }}
                                    >
                                        <Button
                                            style={{
                                                color: '#5999FA',
                                                backgroundColor: '#3A3939',
                                                textTransform: 'none', // Prevents uppercase text on the button
                                            }}
                                            disabled={edits_comment === false || deleting_comment === true || editing_comment === true
                                                || edit_submiting === true
                                                ? true
                                                : false
                                            }
                                            onClick={() => {
                                                setEditing_comment(!editing_comment);
                                                setEdits_comment(false);
                                            }}
                                        >
                                            {CurrentLanguage === 'EN' ? 'Edit' : 'تعديل'}
                                        </Button>
                                        <Button

                                            style={{
                                                color: deleting_comment === true
                                                    ? '#D9D9DAFF'
                                                    : '#FA5959',
                                                margin: '0 10px',
                                                backgroundColor: deleting_comment === true
                                                    ? '#181717FF'
                                                    : '#3A3939',
                                                textTransform: 'none', // Prevents uppercase text on the button
                                            }}
                                            disabled={edits_comment === false || deleting_comment === true || editing_comment === true
                                                || edit_submiting === true
                                                ? true
                                                : false
                                            }
                                            onClick={async () => {
                                                setComment_index(index);
                                                // delete the comment
                                                await deleteComment();
                                                setEdits_comment(false);
                                                setEdit_submiting(false);
                                                setComment_index('');
                                                setEdits_comment(false);
                                                setEditing_comment(false);
                                                setSubmiting_like(false);
                                                setSubmiting_dislike(false);
                                            }}
                                        >
                                            {CurrentLanguage === 'EN'
                                                ? deleting_comment === true ? 'Deleting...' : 'Delete'
                                                : deleting_comment === true ? 'يتم الحذف...' : 'حذف'
                                            }
                                        </Button>
                                    </div>
                                }

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: isNonMobile ? 'row' : 'column',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    <h6
                                        style={{
                                            color: '#5DB0FF',
                                            marginLeft: '10px',
                                            fontSize: '15px',

                                        }}
                                    >{isNonMobile ? isNonMobile_truncatedUser : Mobile_truncatedUser}
                                    </h6>
                                    <h6
                                        style={{
                                            color: '#A8A8A8',
                                            fontSize: '13px',
                                        }}
                                    >{commentDate}
                                    </h6>
                                </div>
                            </div>

                            {editing_comment ?
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        width: '100%',
                                    }}
                                >
                                    <TextField
                                        id='comment'
                                        name='comment'
                                        type='text'
                                        disabled={edit_submiting === true || deleting_comment === true
                                            ? true
                                            : false
                                        }
                                        label={CurrentLanguage === 'EN'
                                            ? 'Comment here...'
                                            : 'أكتب تعليقك هنا...'}
                                        variant='outlined'
                                        multiline
                                        rows={4}
                                        fullWidth
                                        value={values.comment}
                                        onChange={(e) => {
                                            // check if it starts with a space
                                            // or start with skiping first line
                                            if (e.target.value.startsWith(' ')
                                                || e.target.value.startsWith('\n')) {
                                                return;
                                            }
                                            else {
                                                handleChange(e);
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        style={{
                                            backgroundColor: '#555555',
                                            borderRadius: '10px',
                                            width: isNonMobile ? '90%' : '95%',

                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: '#CFCDCD',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',

                                            },
                                        }}
                                        inputProps={{
                                            style: {
                                                color: '#DFDDDD',
                                            },
                                        }}
                                        helperText={values.comment?.length > 200
                                            ? CurrentLanguage === 'EN'
                                                ? `Comment is too long ${
                                                // show the remaining characters
                                                200 - values.comment?.length
                                                }`
                                                : `التعليق طويل جدا ${
                                                // show the remaining characters
                                                200 - values.comment?.length
                                                }`
                                            : ''
                                        }
                                        FormHelperTextProps={{
                                            style: {
                                                color: '#FF0000',
                                                fontSize: '13px',
                                                height: '0px',
                                            }
                                        }}
                                    />
                                    <div>
                                        <Button
                                            type='submit'
                                            onClick={handleSubmit}
                                            disabled={errors.comment || values.comment === comment.comment
                                                || edit_submiting === true || deleting_comment === true
                                                ? true
                                                : false
                                            }
                                            style={{
                                                color: edit_submiting === true ? '#D9D9DAFF'
                                                    :
                                                    errors.comment || values.comment === comment.comment
                                                        ? '#5999FA'
                                                        : '#FFFFFF',
                                                backgroundColor:
                                                    edit_submiting === true
                                                        || errors.comment || values.comment === comment.comment
                                                        ? '#3A3939'
                                                        : '#1464DDFF',
                                                textTransform: 'none', // Prevents uppercase text on the button
                                                margin: '10px',
                                            }}
                                        >
                                            {CurrentLanguage === 'EN' ? 'Confirm' : 'تأكيد'}
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setEditing_comment(false)
                                                setEdits_comment(false)
                                                setEdit_submiting(false)
                                            }}
                                            disabled={deleting_comment === true || edit_submiting === true
                                                ? true
                                                : false
                                            }
                                            style={{
                                                color: '#FA5959',
                                                backgroundColor: '#3A3939',
                                                textTransform: 'none', // Prevents uppercase text on the button
                                                margin: '10px',
                                            }}
                                        >
                                            {CurrentLanguage === 'EN' ? 'Cancel' : 'إلغاء'}
                                        </Button>
                                    </div>
                                </div>
                                :
                                <h6
                                    aria-multiline="true"
                                    style={{
                                        color: '#CFCDCD',
                                        fontSize: '15px',
                                        wordWrap: 'break-word',
                                        whiteSpace: 'pre-wrap',
                                        width: '100%',
                                        maxWidth: '350px',

                                    }}
                                >
                                    {isExpanded ? makeLinks(comment.comment) : makeLinks(truncatedComment)}

                                    {comment?.comment?.length > 100 && (
                                        <Button
                                            onClick={() => setIsExpanded(!isExpanded)}
                                            style={{
                                                color: '#5999FA',
                                                textTransform: 'none', // Prevents uppercase text on the button
                                            }}
                                        >
                                            {isExpanded
                                                ? CurrentLanguage === 'EN' ? 'Show Less' : 'عرض أقل'
                                                : CurrentLanguage === 'EN' ? 'Show More' : 'عرض المزيد'
                                            }
                                        </Button>
                                    )}
                                </h6>}

                        </Box>
                    </>
                )}
            </Formik>
        </motion.div>
    )
}

export default Game_TheComment;

