import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import useMediaQuery from "@mui/material/useMediaQuery";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useAuth } from "../../../xFireBase/AuthContext";
import BO1_servers_screen from "./RETO_servers_screens/BO1_servers_screen";
import BO2_servers_screen from "./RETO_servers_screens/BO2_servers_screen";
import BO3_servers_screen from "./RETO_servers_screens/BO3_servers_screen";
import MW2_H2M_servers_screen from "./RETO_servers_screens/MW2_H2M_servers_screen";
import MW3_servers_screen from "./RETO_servers_screens/MW3_servers_screen";

export const FetchCard_Data = () => {

  const { CurrentLanguage, darkMode } = useAuth();
  const isNonMobile = useMediaQuery("(min-width:1200px)");
  const isNon_TooSmall = useMediaQuery("(max-width:521px)");

  const [stop_info_fetching, setStop_info_fetching] = useState(false);
  // upload all servers info and status to the to a collection inside the document "!RETOservers" 

  const [ALL_Total_Info, setALL_Total_Info] = useState([]);
  const [ALL_TotalRecentClients, setALL_TotalRecentClients] = useState([]);


  // Get all servers info from the API http://...../api/info
  const get_ALL_Total_Info = useCallback(async () => {
    try {
      if (stop_info_fetching === false) {
        const response = await axios.get("http://192.168.1.199:1624/api/info");
        const data = response.json();
        console.log('data: ', data);
        data.then((data) => {
          setALL_Total_Info([
            ...ALL_Total_Info,
            { id: 1, data: data ? data : [] }
          ]);
          setALL_TotalRecentClients([
            ...ALL_TotalRecentClients,
            { id: 1, data: data?.totalRecentClients ? data?.totalRecentClients : [] }
          ]);
        })
          .then(() => {
            setStop_info_fetching(true);
          });
      }
    }
    catch (err) {
      console.log('error: ', 'Internal server error.');
    }
  }, []);

  // get all servers info
  useEffect(() => {
    get_ALL_Total_Info()
  }, [get_ALL_Total_Info]);


  // get all total info
  let TotalRecentClients = [{
    name: ALL_TotalRecentClients?.map((doc) => {
      return doc.id === 1 ? doc.data.value : 0;
    }),
  }];

  let ALL_TotalInfo = [
    {
      totalConnectedClients: ALL_Total_Info?.map((doc) => {
        return doc.id === 1 ? doc.data.totalConnectedClients : 0;
      }),
      totalClientSlots: ALL_Total_Info?.map((doc) => {
        return doc.id === 1 ? doc.data.totalClientSlots : 0;
      }),
      totalTrackedClients: ALL_Total_Info?.map((doc) => {
        return doc.id === 1 ? doc.data.totalTrackedClients : 0;
      }),
      totalRecentClients: TotalRecentClients?.map((doc) => {
        return doc.name;
      }),
    }
  ];

  // get all total info
  let TotalRecentClients_2 = [{
    name: ALL_TotalRecentClients?.map((doc) => {
      return doc.id === 2 ? doc.data.value : 0;
    }),
  }];

  let ALL_TotalInfo_2 = [
    {
      totalConnectedClients: ALL_Total_Info?.map((doc) => {
        return doc.id === 2 ? doc.data.totalConnectedClients : 0;
      }),
      totalClientSlots: ALL_Total_Info?.map((doc) => {
        return doc.id === 2 ? doc.data.totalClientSlots : 0;
      }),
      totalTrackedClients: ALL_Total_Info?.map((doc) => {
        return doc.id === 2 ? doc.data.totalTrackedClients : 0;
      }),
      totalRecentClients: TotalRecentClients_2?.map((doc) => {
        return doc.name;
      }),
    }
  ];



  // Total Players joined the servers
  const Total_Joined_PLayers = () => {
    const totalTrackedClients_1 = parseInt(ALL_TotalInfo[0]?.totalTrackedClients > 0 ? ALL_TotalInfo[0]?.totalTrackedClients : 0);
    const totalTrackedClients_2 = parseInt(ALL_TotalInfo_2[0]?.totalTrackedClients > 0 ? ALL_TotalInfo_2[0]?.totalTrackedClients : 0);

    return totalTrackedClients_1 + totalTrackedClients_2;
  }


  // Total Players joined the servers Today
  const Total_Joined_PLayers_Today = () => {
    const totalRecentClients_1 = parseInt(ALL_TotalInfo[0]?.totalRecentClients > 0 ? ALL_TotalInfo[0]?.totalRecentClients : 0)
    const totalRecentClients_2 = parseInt(ALL_TotalInfo_2[0]?.totalRecentClients > 0 ? ALL_TotalInfo_2[0]?.totalRecentClients : 0)

    return totalRecentClients_1 + totalRecentClients_2;
  }

  // Total Players Online and in the servers
  const Total_Online_PLayers = () => {
    const totalConnectedClients_1 = parseInt(ALL_TotalInfo[0]?.totalConnectedClients > 0 ? ALL_TotalInfo[0]?.totalConnectedClients : 0)
    const totalConnectedClients_2 = parseInt(ALL_TotalInfo_2[0]?.totalConnectedClients > 0 ? ALL_TotalInfo_2[0]?.totalConnectedClients : 0)

    return totalConnectedClients_1 + totalConnectedClients_2;
  }



  const [selectedServer, setSelectedServer] = useState('BO2');


  const serverCard_bo2 = useRef();

  const serverCard_bo1 = useRef();

  const serverCard_mw2 = useRef();

  const serverCard_mw3 = useRef();

  const serverCard_bo3 = useRef();


  return (
    <section>

      <Container>
        <Row>
          <Col size={12}>
            <div >

              <Tab.Container id="" defaultActiveKey="first">
                <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab"
                  style={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1.5rem',
                    textAlign: CurrentLanguage === 'EN' ? 'center' : 'center', fontSize: '17px',
                    direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                    backgroundColor: darkMode === 'light' ? '#F5F5F5' : null,
                  }}
                >
                  {CurrentLanguage === 'EN' ?
                    <>
                      <Nav.Item>
                        <div>
                          <span>
                            Servers reached {' '}
                            <span style={{ color: '#0582E7', marginLeft: '2px' }}>
                              {Total_Joined_PLayers().toLocaleString() <= 9999 ?
                                Total_Joined_PLayers().toLocaleString() :
                                Total_Joined_PLayers().toLocaleString().slice(0, 4) + 'K+'
                              }{' '}
                            </span>
                          </span>
                        </div>
                      </Nav.Item>
                      <Nav.Item style={{ margin: isNonMobile ? null : '10px 0' }}>
                        <div>
                          <span>
                            Today's players {' '}
                            <span style={{ color: '#0582E7', marginLeft: '3px' }}>
                              {Total_Joined_PLayers_Today().toLocaleString() <= 9999 ?
                                Total_Joined_PLayers_Today().toLocaleString() :
                                Total_Joined_PLayers_Today().toLocaleString().slice(0, 4) + 'K+'
                              }{' '}
                            </span>
                          </span>
                        </div>
                      </Nav.Item>
                      <Nav.Item>
                        <div>
                          <span>
                            Online players {' '}
                            <span style={{ color: '#0582E7', marginLeft: '4px' }}>
                              {Total_Online_PLayers().toLocaleString() <= 9999 ?
                                Total_Online_PLayers().toLocaleString() :
                                Total_Online_PLayers().toLocaleString().slice(0, 4) + 'K+'
                              }{' '}
                            </span>
                          </span>
                        </div>
                      </Nav.Item>
                    </>
                    :
                    <>
                      <Nav.Item>
                        <div>
                          <span >
                            السيرفرات وصلت {' '}
                            <span style={{ color: '#0582E7' }}>
                              {Total_Joined_PLayers().toLocaleString() <= 9999 ?
                                Total_Joined_PLayers().toLocaleString() :
                                Total_Joined_PLayers().toLocaleString().slice(0, 4) + 'K+'
                              }{' '}
                            </span>
                          </span>
                        </div>
                      </Nav.Item>
                      <Nav.Item style={{ margin: isNonMobile ? null : '10px 0' }}>
                        <div>
                          <span>
                            عدد اللاعبين اليوم {' '}
                            <span style={{ color: '#0582E7' }}>
                              {Total_Joined_PLayers_Today().toLocaleString() <= 9999 ?
                                Total_Joined_PLayers_Today().toLocaleString() :
                                Total_Joined_PLayers_Today().toLocaleString().slice(0, 4) + 'K+'
                              }{' '}
                            </span>
                          </span>
                        </div>
                      </Nav.Item>
                      <Nav.Item>
                        <div>
                          <span>
                            عدد اللاعبين الان {' '}
                            <span style={{ color: '#0582E7' }}>
                              {Total_Online_PLayers().toLocaleString() <= 9999 ?
                                Total_Online_PLayers().toLocaleString() :
                                Total_Online_PLayers().toLocaleString().slice(0, 4) + 'K+'
                              }{' '}
                            </span>
                          </span>
                        </div>
                      </Nav.Item>
                    </>
                  }
                </Nav>
              </Tab.Container>
              <Tab.Container id="RETOserver_Section-tabs" defaultActiveKey="first">
                <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab" 
                style={{ transform: isNon_TooSmall ? 'scale(0.9)' : '',
                  backgroundColor: darkMode === 'light' ? '#F5F5F5' : null,
                }}
                >

                  {/* BO2 servers*/}
                  <Nav.Item ref={serverCard_bo2}>
                    <Nav.Link
                      onClick={() => {
                        setSelectedServer('BO2')
                      }}
                      style={{
                        color: darkMode === 'light' ? 'black' : null,
                      }}
                      eventKey="first">BO2</Nav.Link>
                  </Nav.Item>

                  {/* BO1 servers*/}
                  <Nav.Item ref={serverCard_bo1}>
                    <Nav.Link
                      onClick={() => {
                        setSelectedServer('BO1')
                      }}
                      style={{
                        color: darkMode === 'light' ? 'black' : null,
                      }}
                      eventKey="second">BO1</Nav.Link>
                  </Nav.Item>

                  {/* MW2 servers*/}
                  <Nav.Item ref={serverCard_mw2}>
                    <Nav.Link
                      onClick={() => {
                        setSelectedServer('MW2')
                      }}
                      style={{
                        color: darkMode === 'light' ? 'black' : null,
                      }}
                      eventKey="third">MW2</Nav.Link>
                  </Nav.Item>

                  {/* MW3 servers*/}
                  <Nav.Item ref={serverCard_mw3}>
                    <Nav.Link
                      onClick={() => {
                        setSelectedServer('MW3')
                      }}
                      style={{
                        color: darkMode === 'light' ? 'black' : null,
                      }}
                      eventKey="fourth">MW3</Nav.Link>
                  </Nav.Item>

                  {/* BO3 servers*/}
                  <Nav.Item ref={serverCard_bo3}>
                    <Nav.Link
                      onClick={() => {
                        setSelectedServer('BO3')
                      }}
                      style={{
                        color: darkMode === 'light' ? 'black' : null,
                      }}
                      eventKey="fifth">BO3</Nav.Link>
                  </Nav.Item>

                </Nav>
                <Tab.Content id="slideInUp">

                  <Tab.Pane eventKey="first">
                    {selectedServer === 'BO2' && <BO2_servers_screen />}
                  </Tab.Pane>

                  <Tab.Pane eventKey="second">
                    {selectedServer === 'BO1' && <BO1_servers_screen />}
                  </Tab.Pane>

                  <Tab.Pane eventKey="third">
                    {selectedServer === 'MW2' && <MW2_H2M_servers_screen />}
                  </Tab.Pane>

                  <Tab.Pane eventKey="fourth">
                    {selectedServer === 'MW3' && <MW3_servers_screen />}
                  </Tab.Pane>

                  <Tab.Pane eventKey="fifth">
                    {selectedServer === 'BO3' && <BO3_servers_screen />}
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>

    </section>
  )
}
