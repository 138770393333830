import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import TrackVisibility from 'react-on-screen';
import arrow1 from '../../assets/img/Others/arrow1.svg';
import arrow2 from '../../assets/img/Others/arrow2.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useAuth } from '../../xFireBase/AuthContext';
import { Link } from 'react-router-dom';

const App_MightLikeSection = ({ current_Name, current_Genres }) => {


  const { CurrentLanguage, ALL_Apps } = useAuth();
  const arrowRef = useRef();
  const isNonMobile = useMediaQuery('(min-width:1200px)');
  const isTablet = useMediaQuery('(min-width:522px) and (max-width:1199px)');
  const isMobile = useMediaQuery('(max-width:521px)');
  const [might_Visible, setMight_Visible] = useState(false);
  const [mightLikeGames, setMightLikeGames] = useState([]);


  useEffect(() => {
    if (window.location.href.includes('/A2G_apps')) {
      const RandomGames = ALL_Apps
        .filter((app) => {
          const appGenresArray = app.app_Genres;
          const current_GenresArray = current_Genres;
          const commonGenres = appGenresArray.filter((genre) =>
            current_GenresArray.includes(genre)
          );
          return commonGenres.length >= 2 && app.app_Name !== current_Name;
        })
        .sort(() => Math.random() - 0.5)
        .slice(0, 10);

      setMightLikeGames(RandomGames);
    }
  }, [ALL_Apps, current_Name, current_Genres]);

  return (
    <div className="Might-LikeGames">
      <TrackVisibility once>
        {({ isVisible }) => (
          <header
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              textAlign: 'right',
              width: '100%',
              direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
            }}
          >
            <h6
              className="Might-LikeGames-Title"
              style={{
                textAlign: CurrentLanguage === 'EN' ? 'left' : 'right',
                direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                fontSize: CurrentLanguage === 'EN' ? '1.4rem' : null,
              }}
            >
              {CurrentLanguage === 'EN'
                ? 'You might Like :'
                : 'ربما يعجبك أيضاً :'}
              {isVisible ? setMight_Visible(true) : null}
            </h6>

            {isNonMobile ? null : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  direction: CurrentLanguage === 'EN' ? 'rtl' : null,
                }}
              >
                <button
                  className={
                    isNonMobile
                      ? 'BannerSlider-arrowRight wow fadeInRight'
                      : 'BannerSlider-arrowRightMobile wow fadeInRight'
                  }
                  onClick={() => arrowRef.current.slickNext()}
                >
                  <img
                    loading="lazy"
                    draggable={false}
                    src={arrow2}
                    alt="arrow2"
                    onContextMenu={(e) => e.preventDefault()}
                  />
                </button>
                <button
                  className={
                    isNonMobile
                      ? 'BannerSlider-arrowLeft wow fadeInLeft'
                      : 'BannerSlider-arrowLeftMobile wow fadeInLeft'
                  }
                  onClick={() => arrowRef.current.slickPrev()}
                >
                  <img
                    loading="lazy"
                    draggable={false}
                    src={arrow1}
                    alt="arrow1"
                    onContextMenu={(e) => e.preventDefault()}
                  />
                </button>
              </div>
            )}
          </header>
        )}
      </TrackVisibility>

      {might_Visible ? (
        <div className="Might-LikeGames-Container">
          {mightLikeGames?.length >= 2 ? (
            <Slider
              dots={false}
              centerMode={true}
              draggable={true}
              swipe={true}
              onLazyLoad={true}
              slide="div"
              className="Might-LikeGames-Container-Slider"
              ref={isNonMobile ? null : arrowRef}
              arrows={isNonMobile ? true : false}
              centerPadding="0px"
              focusOnSelect={true}
              infinite={true}
              autoplay={true}
              autoplaySpeed={3000}
              slidesToShow={
                isNonMobile ? 4 : isMobile ? 1 : isTablet ? 2 : 1
              }
              slidesToScroll={
                isNonMobile ? 1 : isMobile ? 1 : isTablet ? 1 : 1
              }
            >
              {mightLikeGames
                .filter(
                  (mightLikeGame, index, self) =>
                    index ===
                    self.findIndex(
                      (t) =>
                        t.app_Number === mightLikeGame.app_Number &&
                        t.app_Page === mightLikeGame.app_Page
                    )
                )
                .sort((a, b) => {
                  if (
                    a.app_Name.toLowerCase().startsWith(current_Name.toLowerCase()) &&
                    b.app_Name.toLowerCase().startsWith(current_Name.toLowerCase())
                  ) {
                    return 0;
                  }
                  if (
                    a.app_Name.toLowerCase().startsWith(current_Name.toLowerCase())
                  ) {
                    return -1;
                  }
                  if (
                    b.app_Name.toLowerCase().startsWith(current_Name.toLowerCase())
                  ) {
                    return 1;
                  }
                  if (
                    a.app_Name.toLowerCase().includes(current_Name.toLowerCase()) &&
                    b.app_Name.toLowerCase().includes(current_Name.toLowerCase())
                  ) {
                    return 0;
                  }
                  if (
                    a.app_Name.toLowerCase().includes(current_Name.toLowerCase())
                  ) {
                    return -1;
                  }
                  if (
                    b.app_Name.toLowerCase().includes(current_Name.toLowerCase())
                  ) {
                    return 1;
                  }
                  return 0;
                })
                .map((mightLikeGame) => (
                  <div className="Might-LikeGames-Container-Item-card">
                    <Link
                      rel="noreferrer"
                      to={`/A2G_apps/${mightLikeGame.app_Number}/${mightLikeGame.app_Page}`}
                      style={{
                        textDecoration: 'none',
                        color: '#ffffff',
                        cursor: 'pointer',
                      }}
                    >
                      <div
                        size={12}
                        xs={6}
                        sm={6}
                        md={6}
                        lg={4}
                        xl={3}
                      >
                        <div className="Might-LikeGames-Container-Item-Image">
                          <span
                            className="RETO_watermark"
                            style={{
                              fontSize: isNonMobile ? '0.8rem' : '0.53rem',
                              color: 'white',
                              backgroundColor: 'rgba(0, 0, 0, 0.4)',
                              opacity: '0.65',
                              padding: '0.2rem',
                              textDecoration: 'none',
                              borderRadius: '0.2rem',
                              position: 'absolute',
                              top: isNonMobile ? '0.2rem' : isNonMobile === false && mightLikeGame.app_Picture[0].height > mightLikeGame.app_Picture[0].width ? '1rem' : '0.1rem',
                              left: isNonMobile ? '0.15rem' : '0.4rem',
                            }}
                          >
                            Arab2Games.com
                          </span>
                          <LazyLoadImage
                            effect="blur"
                            draggable={false}
                            src={mightLikeGame.app_Picture[0]}
                            alt="app_Picture"
                            onContextMenu={(e) => e.preventDefault()}
                          />
                        </div>

                        <div>

                          <h4
                            style={{
                              fontSize: '0.95rem',
                              justifyContent: 'flex-end',
                              alignItems: 'flex-end',
                              textAlign: 'left',
                              marginLeft: '0.5rem',
                              marginRight: '0.5rem',
                            }}
                          >
                            {`${mightLikeGame.app_Name.length >= 35 ? mightLikeGame.app_Name.slice(0, 35) + '...' : mightLikeGame.app_Name}`}
                          </h4>

                          <div
                            style={{
                              flexDirection: 'row',
                              display: 'flex',
                              color: '#969595',
                              fontSize: '0.75rem',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              textAlign: 'right',
                              marginLeft: '0.5rem',
                              marginRight: '0.5rem',
                            }}
                          >
                            <h6
                              style={{
                                fontSize: '0.79rem',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                marginLeft: '0.5rem',
                                marginTop: '2.5px',
                              }}
                            >
                              {`${mightLikeGame.app_Size}`}
                            </h6>
                            <h6
                              style={{
                                fontSize: '0.95rem',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                marginLeft: '0.5rem',
                                marginRight: '0.5rem',
                              }}
                            >
                              : بحجم
                            </h6>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </Slider>
          ) : mightLikeGames?.length <= 1 ? (
            <div
              draggable={false}
              className="Search-Result-Container-Slider"
            >
              {mightLikeGames
                .filter(
                  (mightLikeGames, index, self) =>
                    index ===
                    self.findIndex(
                      (t) =>
                        t.app_Number === mightLikeGames.app_Number &&
                        t.app_Page === mightLikeGames.app_Page
                    )
                )
                .sort((a, b) => {
                  if (
                    a.app_Name.toLowerCase().startsWith(current_Name.toLowerCase()) &&
                    b.app_Name.toLowerCase().startsWith(current_Name.toLowerCase())
                  ) {
                    return 0;
                  }
                  if (
                    a.app_Name.toLowerCase().startsWith(current_Name.toLowerCase())
                  ) {
                    return -1;
                  }
                  if (
                    b.app_Name.toLowerCase().startsWith(current_Name.toLowerCase())
                  ) {
                    return 1;
                  }
                  if (
                    a.app_Name.toLowerCase().includes(current_Name.toLowerCase()) &&
                    b.app_Name.toLowerCase().includes(current_Name.toLowerCase())
                  ) {
                    return 0;
                  }
                  if (
                    a.app_Name.toLowerCase().includes(current_Name.toLowerCase())
                  ) {
                    return -1;
                  }
                  if (
                    b.app_Name.toLowerCase().includes(current_Name.toLowerCase())
                  ) {
                    return 1;
                  }
                  return 0;
                })
                .map((mightLikeGames, index) => (
                  <div
                    key={index}
                    style={{ margin: '1rem' }}
                  >
                    <Link
                      key={index}
                      rel="noreferrer"
                      to={`/A2G_apps/${mightLikeGames.app_Number}/${mightLikeGames.app_Page}`}
                      style={{
                        textDecoration: 'none',
                        color: '#ffffff',
                        cursor: 'pointer',
                      }}
                    >
                      <div
                        className="Search-Result-Container-Item-card2"
                      >
                        <div className="Search-Result-Container-Item-Image">
                          <span
                            className="RETO_watermark"
                            style={{
                              fontSize: isNonMobile ? '0.8rem' : '0.53rem',
                              color: 'white',
                              backgroundColor: 'rgba(0, 0, 0, 0.4)',
                              opacity: '0.65',
                              padding: '0.2rem',
                              textDecoration: 'none',
                              borderRadius: '0.2rem',
                              position: 'absolute',
                              top: isNonMobile ? '0.2rem' : isNonMobile === false && mightLikeGames.app_Picture[0].height > mightLikeGames.app_Picture[0].width ? '1rem' : '0.1rem',
                              left: isNonMobile ? '0.15rem' : '0.4rem',
                            }}
                          >
                            Arab2Games.com
                          </span>
                          <LazyLoadImage
                            effect="blur"
                            draggable={false}
                            src={mightLikeGames.app_Picture[0]}
                            alt="app_Picture"
                            onContextMenu={(e) => e.preventDefault()}
                          />
                        </div>

                        <div>
                          <h4
                            style={{
                              fontSize: '0.95rem',
                              justifyContent: 'flex-end',
                              alignItems: 'flex-end',
                              textAlign: 'left',
                              marginLeft: '0.5rem',
                              marginRight: '0.5rem',
                            }}
                          >
                            {`${mightLikeGames.app_Name.length >= 35 ? mightLikeGames.app_Name.slice(0, 35) + '...' : mightLikeGames.app_Name}`}
                          </h4>

                          <div
                            style={{
                              flexDirection: 'row',
                              display: 'flex',
                              color: '#969595',
                              direction: 'ltr',
                              fontSize: '0.75rem',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              textAlign: 'right',
                              marginLeft: '0.5rem',
                              marginRight: '0.5rem',
                            }}
                          >
                            <h6
                              style={{
                                fontSize: '0.79rem',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                marginLeft: '0.5rem',
                                marginTop: '2.5px',
                              }}
                            >
                              {`${mightLikeGames.app_Size}`}
                            </h6>
                            <h6
                              style={{
                                fontSize: '0.95rem',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                marginLeft: '0.5rem',
                                marginRight: '0.5rem',
                              }}
                            >
                              : بحجم
                            </h6>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default App_MightLikeSection;
