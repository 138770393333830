// current game comment section
import React, { useState, useEffect } from 'react';
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from 'react-toastify';
import fetch_Loading from '../../assets/img/icons/Profile_Loading.svg'

import { Button, TextField, useMediaQuery } from "@mui/material";
import { motion } from 'framer-motion';
import { useAuth } from '../../xFireBase/AuthContext';
import Game_TheComment from './Game_TheComment';
import { RETO_defaultSettings } from '../Others/RETO_data';

const Game_CommentSection = ({ game }) => {

    const { user, userData, getGameComments, addGameComment, deleteGameComment, darkMode, CurrentLanguage } = useAuth();

    const [comments_fetched, setComments_fetched] = useState(false);

    const isNonMobile = useMediaQuery("(min-width:1200px");

    const [comments, setComments] = useState([]);

    const [badWord_used, setBadWord_used] = useState(false);
    const [the_badWords, setThe_badWords] = useState([]);

    const [submitting, setSubmitting] = useState(false);
    const [deleting_comment, setDeleting_comment] = useState(false);

    const [comments_Slice, setComments_Slice] = useState(3);

    // fetch the comments of the current game
    async function fetchComments() {
        if (game && comments_fetched === false && comments.length <= 0) {
            try {
                const fetchedComments = await getGameComments(game);
                if (fetchedComments) {
                    setComments(fetchedComments);
                    setComments_fetched(true);
                }
            } catch (error) {
                console.log('error fetching comments');
            }
        }
    }

    useEffect(() => {
        fetchComments();

    }, [game, comments_fetched, comments]);



    const CommentSchema = yup.object().shape({
        comment: yup.string()
            .min(2, 'Comment is too short')
            .max(200, 'Comment is too long')
            .required('Comment is required')
            // test each comment for bad words
            .test('badWords', 'Please avoid using bad words', async (value) => {
                return await word_checker(value) === false;
            })
        ,
    });

    const initialValues = {
        comment: '',
    };

    const generateCommentId = async (minLength = 40, maxLength = 60) => {
        try {
            let comment_id = '';
            while (comment_id.length < minLength) {
                comment_id += Math.random().toString(36).substring(2, 15); // Generates 13 characters at a time
            }
            // now check if the comment_id is already in the comments
            // if it is, generate a new one
            if (comments?.findIndex((comment) => comment.comment_id === comment_id) > -1) {
                return await generateCommentId();
            }

            return comment_id.substring(0, Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength);
        }
        catch (error) {
            console.log('error generating comment id', error);
        }
    };

    const setThe_newComment_data = async (values) => {
        let newComment_data = {};
        try {
            newComment_data = {
                comment_id: await generateCommentId(),
                comment_date: new Date(),
                comment_By: user?.uid,
                comment: values.comment,
                comment_likes: [],
                comment_dislikes: [],
                comment_replies: [],
            }

            return newComment_data;
        }
        catch (error) {
            console.log('error setting new comment data', error);
        }
    };

    const word_checker = async (comment) => {
        let badWord_used = false;
        let finished = false;
        setBadWord_used(false);
        setThe_badWords([]);

        try {
            let badWords = RETO_defaultSettings[0].badWords;
            let foundBadWords = []; // Temp array to hold found bad words

            if (badWords) {
                for (let i = 0; i < badWords.length; i++) {
                    if (comment.includes(badWords[i])) {
                        foundBadWords.push(badWords[i]); // Push bad word to temp array
                        badWord_used = true;
                    }
                }

                // Once loop is done, mark as finished
                finished = true;

                // Now check if bad word was used and the process is finished
                if (badWord_used && finished) {
                    setThe_badWords(foundBadWords); // Set the found bad words after loop is done
                    setBadWord_used(true);
                    return true;
                }
            }
            return false;
        } catch (error) {
            console.log('error checking bad words');
            return false;
        }
    };

    const addComment = async (values) => {
        setSubmitting(true);
        try {

            const badWord = await word_checker(values.comment);

            if (badWord === true) {
                setBadWord_used(false);
                setSubmitting(false);
                return;
            }

            // Replace each bad word with '****'
            let cleanComment = values.comment;
            the_badWords.forEach((badWord) => {
                const regex = new RegExp(badWord, 'gi');
                cleanComment = cleanComment.replace(regex, '****');
            });

            // update the comment with the clean comment
            values.comment = cleanComment;

            const newComment_data = await setThe_newComment_data(values);

            if (newComment_data.comment_id && newComment_data.comment_date
                && newComment_data.comment_By === user?.uid && newComment_data.comment) {


                const response = await addGameComment({ gameData: game, comment_values: newComment_data });

                if (response === true || response === 'done') {

                    toast.success(CurrentLanguage === 'EN'
                        ? 'Comment added successfully '
                        : 'تم إضافة التعليق بنجاح '
                    );

                    setComments([...comments, {

                        comment_id: newComment_data.comment_id,
                        comment_date: new Date(),
                        comment_By: user?.uid,
                        comment: values.comment,
                        comment_likes: [],
                        comment_dislikes: [],
                        comment_replies: [],
                        comment_user_avatar: user?.photoURL ? user?.photoURL : null,
                        comment_user_displayName: user?.displayName,
                    }]);

                    values.comment = '';
                }
                else {
                    console.log('error adding comment');
                    toast.error(CurrentLanguage === 'EN'
                        ? 'Error adding comment'
                        : 'حدث خطأ في إضافة التعليق'
                    );
                }
            }
            else {
                console.log('error setting new comment data');
            }

        }
        catch (error) {
            toast.error(CurrentLanguage === 'EN'
                ? 'Error adding comment'
                : 'حدث خطأ في إضافة التعليق'
            );

            console.log('error adding comment');
        }
        finally {
            setSubmitting(false);
        }
    };

    // delete the current comment
    const deleteComment = async (comment) => {
        setDeleting_comment(true);
        try {
            const confirmAlert = window.confirm(CurrentLanguage === 'EN'
                ? 'Are you sure you want to delete this comment ❓'
                : '❓ هل أنت متأكد أنك تريد حذف هذا التعليق؟'
            );
            if (confirmAlert === true) {
                const response = await deleteGameComment({ gameData: game, specific_comment: comment });

                if (response === true || response === 'done') {
                    toast.success(CurrentLanguage === 'EN'
                        ? 'Comment deleted successfully'
                        : 'تم حذف التعليق بنجاح'
                    );
                    // remove the comment from the comments
                    setComments(comments.filter((old_comments) => old_comments.comment_id !== comment.comment_id));
                }
                else {
                    toast.error(CurrentLanguage === 'EN'
                        ? 'Error deleting comment'
                        : 'حدث خطأ في حذف التعليق'
                    );
                    console.log('error deleting comment');
                }
            }
            else {
                setTimeout(() => {
                    alert(CurrentLanguage === 'EN'
                        ? 'Comment deletion canceled ✅'
                        : 'تم الغاء حذف التعليق ✅'
                    );
                }, 250);
            }
        }
        catch (error) {
            toast.error(CurrentLanguage === 'EN'
                ? 'Error deleting comment'
                : 'حدث خطأ في حذف التعليق'
            );
            console.log('error deleting comment');
        }
        finally {
            setDeleting_comment(false);
        }
    };

    // create an amazing comment section with a form to add comments and a list of comments
    // if the user is logged in, they can add comments to the game, otherwise they can only view the comments
    // the enite section background should be a light grey color
    // the form should be a light blue color and multple lines allowed with a submit button
    // the comments should be a light grey color with a light blue border
    // the comments should have a user avatar, username, and comment text
    return (
        <div
            className='Game-CommentSection'
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                backgroundColor: '#333333C5',
                borderRadius: '10px',
                padding: '10px',
                margin: '10px',
                marginTop: '50px',
                width: '95%',
                height: 'auto',
            }}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={CommentSchema}
                onSubmit={async (values) => {
                    await addComment(values);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <>
                        <div
                            className='Game-CommentSection-Title'
                        >
                            <h4>{CurrentLanguage === 'EN'
                                ? 'Comments : '
                                : 'التعليقات : '}
                                <span
                                    style={{
                                        color: darkMode === 'light'
                                            ? '#D1CECEFF'
                                            : '#D8D8D8',
                                    }}
                                >
                                    {comments?.length > 0
                                        ? comments?.length.toLocaleString('en-US', { timeZone: 'Asia/Riyadh' })
                                        : '0'}
                                </span>
                                {badWord_used === true &&
                                    <h6
                                        style={{
                                            color: 'red',
                                            marginTop: '10px',
                                        }}
                                    >
                                        {CurrentLanguage === 'EN'
                                            ? `Please avoid using bad words: ${the_badWords.join(', ')}`
                                            : `يرجى تجنب استخدام الكلمات السيئة : ${the_badWords.join(', ')}`
                                        }
                                    </h6>
                                }
                            </h4>
                        </div>
                        {comments_fetched === false ?
                            (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '10px',
                                        padding: '10px',
                                        width: '99%',
                                        height: 'auto',
                                    }}
                                >
                                    <img
                                        src={fetch_Loading}
                                        alt='fetch_Loading'
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                        }}
                                    />
                                </div>
                            )

                            :

                            !user ?
                                (
                                    <div
                                        className='Game-CommentSection-Form-Login'
                                    >
                                        <h5
                                            className='Game-CommentSection-Form-Textarea'
                                            style={{
                                                backgroundColor: '#6E1B1B',
                                                color: '#B4A1A1',
                                            }}
                                        >{CurrentLanguage === 'EN'
                                            ? 'Login to add a comment'
                                            : 'سجل دخولك لإضافة تعليق'}
                                        </h5>
                                    </div>
                                )
                                :
                                (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: isNonMobile ? 'row' : 'column-reverse',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            backgroundColor: '#3F3F3F',
                                            borderRadius: '10px',
                                            padding: '10px',
                                            width: '99%',
                                            height: 'auto',
                                        }}
                                    >
                                        <TextField
                                            id='comment'
                                            name='comment'
                                            disabled={submitting === true ? true : false}
                                            type='text'
                                            label={CurrentLanguage === 'EN'
                                                ? 'Comment here...'
                                                : 'أكتب تعليقك هنا...'}
                                            variant='outlined'
                                            multiline
                                            rows={2}
                                            fullWidth
                                            value={values.comment}
                                            onChange={(e) => {
                                                // check if it starts with a space
                                                // or start with skiping first line
                                                if (e.target.value.startsWith(' ')
                                                    || e.target.value.startsWith('\n')) {
                                                    return;
                                                }
                                                else {
                                                    handleChange(e);
                                                }
                                            }}
                                            onBlur={handleBlur}
                                            style={{
                                                backgroundColor: '#555555',
                                                margin: '10px',
                                                borderRadius: '10px',
                                                width: isNonMobile ? '80%' : '95%',
                                                border: badWord_used === true
                                                    ? '2px solid red'
                                                    : null,

                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: '#CFCDCD',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',

                                                },
                                            }}
                                            inputProps={{
                                                style: {
                                                    color: '#DFDDDD',
                                                },
                                            }}
                                            helperText={values.comment.length > 200
                                                ? CurrentLanguage === 'EN'
                                                    ? `Comment is too long ${
                                                    // show the remaining characters
                                                    200 - values.comment.length
                                                    }`
                                                    : `التعليق طويل جدا ${
                                                    // show the remaining characters
                                                    200 - values.comment.length
                                                    }`
                                                : ''
                                            }
                                            FormHelperTextProps={{
                                                style: {
                                                    color: '#FF0000',
                                                    fontSize: '13px',
                                                    height: '0px',
                                                }
                                            }}
                                        />
                                        <Button
                                            className='Game-CommentSection-Form-Button'
                                            type='submit'
                                            style={{
                                                width: '18%',
                                            }}
                                            disabled={
                                                values.comment.length < 2
                                                    || values.comment.length > 200
                                                    || values.comment.startsWith(' ')
                                                    || submitting === true
                                                    || badWord_used === true
                                                    ? true
                                                    : false

                                            }
                                            onClick={handleSubmit}

                                        >{CurrentLanguage === 'EN'
                                            ? submitting ? 'Sending...' : 'Send'
                                            : submitting ? 'جاري الإرسال..' : 'إرسال'
                                            }
                                        </Button>
                                    </div>
                                )
                        }

                        <div
                            className='Game-CommentSection-Comments'
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                backgroundColor: comments_fetched === false ? null : '#353535',
                                borderRadius: '10px',
                                marginTop: '30px',
                                padding: '10px',
                                width: '100%',
                                height: 'auto',
                                maxHeight: '600px', // Set the max height of the scrollable container
                                overflowY: 'auto', // Enable vertical scrolling
                                ovwerflowX: 'hidden', // Disable horizontal scrolling
                            }}
                        >
                            {comments?.length > 0 ?
                                comments
                                    ?.sort((a, b) => new Date(b.comment_date) - new Date(a.comment_date))
                                    ?.slice(0, comments_Slice)
                                    ?.map((comment, index) => (
                                        <Game_TheComment
                                            key={index}
                                            index={index}
                                            comment={comment}
                                            deleting_comment={deleting_comment}
                                            gameData={game}
                                            deleteComment={async () => {
                                                // delete the current comment the same index and should be the same user
                                                // filter to remove the comment by it's own id
                                                if (comment.comment_By === user.uid) {
                                                    await deleteComment(comment);
                                                }
                                            }}
                                        />
                                    ))
                                : comments_fetched === true &&
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '10px',
                                        padding: '10px',
                                        margin: '10px',
                                        width: '80%',
                                        height: 'auto',
                                    }}
                                >
                                    <h5
                                        style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            color: darkMode === 'light'
                                                ? '#D1CECEFF'
                                                : '#D8D8D8',
                                        }}
                                    >{CurrentLanguage === 'EN'
                                        ? 'No comments yet'
                                        : 'لا توجد تعليقات بعد'}
                                    </h5>
                                </div>
                            }

                            {comments_Slice < comments?.length && (
                                <motion.button
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5 }}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: '#3E6FA098',
                                        color: '#D8D8D8',
                                        borderRadius: '15px',
                                        padding: '10px',
                                        margin: '10px',
                                        width: '40%',
                                        height: 'auto',
                                    }}
                                    onClick={() => {
                                        setComments_Slice(comments_Slice + 8);
                                    }}
                                >{CurrentLanguage === 'EN'
                                    ? 'Load More Comments'
                                    : 'تحميل المزيد من التعليقات'}
                                </motion.button>
                            )}
                        </div>
                    </>
                )}
            </Formik>

        </div>
    );
}

export default Game_CommentSection;