import React, { useEffect, useState } from 'react'
import { Box, Button, TextField, Checkbox } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { firebase, database } from '../../../../xFireBase/Storage';
import {
    FaEnvelope, FaEye, FaEyeSlash, FaLock, FaUser
} from 'react-icons/fa';
import CheckMark from '../../../../assets/img/icons/CheckMark.svg';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../xFireBase/AuthContext';
import { Link } from 'react-router-dom';

const SignUpForm = () => {

    const { CurrentLanguage, User_IP_Address, User_Country } = useAuth();
    const [passwordShown, setPasswordShown] = useState(false);

    
    const SignUpSubmit = async (values) => {
        try {
            
            const authUser = await firebase.auth().createUserWithEmailAndPassword(values.User_Email, values.User_Password)

            await database.collection('users')
                .doc(values.User_Email)
                .set({
                    User_Age: 0,
                    User_Ban_Status: false,
                    User_Bio: "",
                    User_Birthday: "",
                    User_CreatedAt: new Date().toLocaleString('en-US', { timeZone: 'Asia/Riyadh' }),
                    User_Email: values.User_Email.toLowerCase().replace(/\s/g, ''),
                    User_EmailVerified: authUser.user.emailVerified ? true : false,
                    User_Email_Lowercase: values.User_Email.toLowerCase(),
                    User_FirstName: "",
                    User_ID: '',
                    User_LastName: "",
                    User_Last_Login: new Date().toLocaleString('en-US', { timeZone: 'Asia/Riyadh' }),
                    User_Password: values.User_Password.replace(/\s/g, ''),
                    User_Permission: "User",
                    User_PhoneNumber: values.User_PhoneNumber,
                    User_Platform: [
                        values.User_Platform1,
                        values.User_Platform2,
                        values.User_Platform3,
                    ],
                    User_Profile_Picture: "",
                    User_Status: 'متصل',
                    User_UserName: values.User_UserName.replace(/\s/g, ''),
                    User_UserName_Lowercase: values.User_UserName.toLowerCase(),
                    User_isFrom: [
                        User_Country,
                        values.User_City,
                    ],
                    User_IP_Address: User_IP_Address,
                    User_uid: authUser.user.uid,

                })
                .then(() => {
                    const user = firebase.auth().currentUser;
                    user.updateProfile({
                        displayName: values.User_UserName,
                        photoURL: values.User_Profile_Picture ? values.User_Profile_Picture : null,
                    })
                    database.collection('users').doc(values.User_Email).collection('more-details').doc('Profile_Management').set({
                        Turn_On_Off_DownloadHistory: true,
                        Turn_On_Off_NotificationsHistory: true,
                        Turn_On_Off_WatchHistory: true,
                    })
                    database.collection('users').doc(values.User_Email).collection('more-details').doc('more-details').set({
                        User_ALL_Bans_History: [],
                    })

                    database.collection('users').doc(values.User_Email).collection('more-details').doc('User_History').set({
                        none: [],
                    })
                    user.sendEmailVerification()
                .then(() => {
                    // go back to the previous page
                    window.history.back();
                 })
                })
        }
        catch (error) {
            CurrentLanguage === 'EN' 
            ? toast.error("An error occurred while creating the account")
            : toast.error("حدث خطأ أثناء إنشاء الحساب")
        }
    };
    
    const SignUp_checkoutSchema = yup.object().shape({
        User_Email: yup.string()
            .email(`${CurrentLanguage === 'EN' ? 'Invalid email' : 'البريد الإلكتروني غير صحيح'}`)
            .test('email-exists', `${CurrentLanguage === 'EN' ? 'Email already exists' : 'البريد الإلكتروني موجود من قبل'}`, async (value) => {
                const snapshot = await database.collection('users').where('User_Email_Lowercase', '==', value.toLowerCase()).get();
                return snapshot.empty;
            })
            .min(7, `${CurrentLanguage === 'EN' ? 'Email must be 7 characters at least' : 'البريد الإلكتروني يجب أن يكون أكثر من 7 أحرف'}`)
            .required(`${CurrentLanguage === 'EN' ? 'Please enter your email' : 'الرجاء إدخال البريد الإلكتروني'}`),
        User_Password: yup.string()
            .min(6, `${CurrentLanguage === 'EN' ? 'Password must be more than 5 characters' : 'يجب أن تتكون كلمة المرور من 6 أحرف على الأقل'}`)
            .required(`${CurrentLanguage === 'EN' ? 'Please enter your password' : 'الرجاء إدخال كلمة المرور'}`),
        User_UserName: yup.string()
        .test('username-exists', `${CurrentLanguage === 'EN' ? 'Username already exists' : 'اسم المستخدم موجود من قبل'}`, async (value) => {
                const snapshot = await database.collection('users').where('User_UserName_Lowercase', '==', value.toLowerCase()).get();
                return snapshot.empty;
            })
            .min(4, `${CurrentLanguage === 'EN' ? 'Username must be more than 3 characters' : 'اسم المستخدم يجب أن يكون أكثر من 3 أحرف'}`)
            .required(`${CurrentLanguage === 'EN' ? 'Please enter your username' : 'الرجاء إدخال اسم المستخدم'}`),
    });


    const SignUp_initialValues = {
        User_Email: "",
        User_Password: "",
        User_UserName: "",
        User_Age: "",
        User_PhoneNumber: "",
        User_Country: "",
        User_City: "",
        User_Platform1: "",
        User_Platform2: "",
        User_Platform3: "",
        User_Profile_Picture: "",
    };

    return (
        <Box style={{ flexDirection: 'row', display: 'flex' }}>
            <Formik
                onSubmit={SignUpSubmit}
                initialValues={SignUp_initialValues}
                validationSchema={SignUp_checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'flex-start' }}>
                          <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'flex-start', alignItems: "flex-start" }}>

                          <div style={{  width: '100%', flexDirection: 'column', display: 'flex', direction: 'ltr', textAlign: 'center', alignItems: "center",
                                justifyContent: 'center', marginBottom: '25px' }}>
                            <div style={{  width: '80%', flexDirection: 'row', display: 'flex', direction: 'ltr', textAlign: 'center', alignItems: "center",
                                    justifyContent: 'center', marginBottom: '25px' }}>
                                <FaUser style={{ color: '#FFFFFF65', fontSize: '20px', marginRight: '10px' }} />
                                <TextField
                                    style={{ backgroundColor: '#83818186', direction: 'ltr', justifyContent: "center", alignItems: "center", borderRadius: '15px',
                                    width: '100%', height: '100%', textAlign: 'center' }}
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label={CurrentLanguage === 'EN' ? 'Username' : 'اسم المستخدم'}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.User_UserName.replace(/\s/g, '')}
                                    name="User_UserName"
                                    error={!!touched.User_UserName && !!errors.User_UserName}
                                    sx={{ gridColumn: "span 4", input: { color: '#FFFFFF' } }}
                                    inputProps={{
                                        style: {
                                            textAlign: 'left',
                                            direction: 'ltr',
                                            color: '#FFFFFF',
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            textAlign: 'left',
                                            direction: 'ltr',
                                            color: values.User_UserName 
                                            && touched.User_UserName && errors.User_UserName ? '#F14141' : values.User_UserName ? '#71A3FF' 
                                            : 
                                            touched.User_UserName && errors.User_UserName ? '#F14141' : '#CFCFCF',
                                        }
                                    }}
                                />
                            </div>

                              { touched.User_UserName && errors.User_UserName ?
                                <span style={{ color: '#F14141', fontSize: '16px', marginTop: '85px', position: 'absolute', 
                                 direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl' }}>
                                 * {errors.User_UserName && touched.User_UserName && errors.User_UserName}
                                </span>
                                :
                                null
                              }

                          </div>

                           <div style={{  width: '100%', flexDirection: 'column', display: 'flex', direction: 'ltr', textAlign: 'center', alignItems: "center",
                                    justifyContent: 'center', marginBottom: '25px' }}>

                            <div style={{  width: '80%', flexDirection: 'row', display: 'flex', direction: 'ltr', textAlign: 'center', alignItems: "center",
                                    justifyContent: 'center', marginBottom: '25px' }}>
                                <FaEnvelope style={{ color: '#FFFFFF65', fontSize: '20px', marginRight: '10px' }} />
                                <TextField
                                    style={{ backgroundColor: '#83818186', direction: 'ltr', justifyContent: "center", alignItems: "center", borderRadius: '15px',
                                    width: '100%', height: '100%', textAlign: 'center', }}
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    placeholder='example@example.com'
                                    label={CurrentLanguage === 'EN' ? 'Email' : 'البريد الإلكتروني'}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.User_Email.replace(/\s/g, '')}
                                    name="User_Email"
                                    error={!!touched.User_Email && !!errors.User_Email}
                                    sx={{ gridColumn: "span 4", input: { color: '#FFFFFF' } }}
                                    inputProps={{
                                        style: {
                                            textAlign: 'left',
                                            direction: 'ltr',
                                            color: '#FFFFFF',
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            textAlign: 'left',
                                            direction: 'ltr',
                                            color: values.User_Email 
                                            && touched.User_Email && errors.User_Email ? '#F14141' : values.User_Email ? '#71A3FF' 
                                            : 
                                            touched.User_Email && errors.User_Email ? '#F14141' : '#CFCFCF',
                                        }
                                    }}
                                />
                            </div>


                              { touched.User_Email && errors.User_Email ?
                                <span style={{ color: '#F14141', fontSize: '16px', marginTop: '85px', position: 'absolute', 
                                 direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl' }}>
                                 * {errors.User_Email && touched.User_Email && errors.User_Email}
                                </span>
                                :
                                null
                              }

                           </div>

                           <div style={{  width: '100%', flexDirection: 'row', display: 'flex', direction: 'ltr', textAlign: 'center', alignItems: "center",
                                    justifyContent: 'center', marginBottom: '10px' }}>
                                        
                            <div style={{  width: '80%', flexDirection: 'row', display: 'flex', direction: 'ltr', textAlign: 'center', alignItems: "center",
                                    justifyContent: 'center', marginBottom: '10px' }}>
                                <FaLock style={{ color: '#FFFFFF65', fontSize: '20px', marginRight: '10px' }} />
                                <div style={{ backgroundColor: '#83818186', direction: 'ltr',display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center",
                                    borderRadius: '15px', width: '100%', height: '100%', textAlign: 'center' }}>
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type={passwordShown ? "text" : "password"}
                                    label={CurrentLanguage === 'EN' ? 'Password' : 'كلمة المرور'}
                                    autoComplete='new-password'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.User_Password.replace(/\s/g, '')}
                                    name="User_Password"
                                    error={!!touched.User_Password && !!errors.User_Password}
                                    sx={{ gridColumn: "span 4", input: { color: '#FFFFFF' } }}
                                    inputProps={{
                                        style: {
                                            textAlign: 'left',
                                            direction: 'ltr',
                                            color: '#FFFFFF',
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            textAlign: 'left',
                                            direction: 'ltr',
                                            color: values.User_Password 
                                            && touched.User_Password && errors.User_Password ? '#F14141' : values.User_Password ? '#71A3FF' 
                                            : 
                                            touched.User_Password && errors.User_Password ? '#F14141' : '#CFCFCF',
                                        }
                                    }}
                                />
                                <a
                                 onClick={() => setPasswordShown(!passwordShown)}  
                                 style={{ justifyContent: 'center', alignItems: 'center', width: '40px', transition: 'all 0.3s ease-in-out' }}>
                                    {passwordShown ?
                                     <FaEyeSlash style={{ color: '#000000B9', fontSize: '20px', width: '25px' }} />
                                     : 
                                     <FaEye style={{ color: '#000000B9', fontSize: '20px', width: '25px' }} />
                                    }
                                </a>
                                </div>

                                { touched.User_Password && errors.User_Password ?
                                 <span style={{ color: '#F14141', fontSize: '16px', marginTop: '85px', position: 'absolute', 
                                  direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl' }}>
                                  * {errors.User_Password && touched.User_Password && errors.User_Password}
                                 </span>
                                 :
                                 null
                                }
                              </div>
                             </div>
                            </div>

                            <div style={{ flexDirection: 'column', justifyContent: 'center' }} onClick={() => {
                            }}>
                                <div
                                    style={{ width: '90%', color: 'white', borderRadius: '30px', marginTop: '10px', direction: 'ltr', 
                                        backgroundColor: '#00000000', border: '1px solid #FFFFFF00', display: 'flex',
                                        justifyContent: 'center', alignItems: 'center', }}
                                >
                                    <LazyLoadImage
                                    effect='blur'
                                    draggable={false}
                                    alt='Policy'
                                    onContextMenu={(e) => e.preventDefault()}
                                    src={CheckMark} 
                                    style={{ marginLeft: '-5px', marginRight: '5px', width: '1.2rem', 
                                    height: '1.2rem', transition: 'all 0.2s ease-in-out' }} />

                                    <div style={
                                        {
                                            justifyContent: 'center', alignItems: 'center', textAlign: CurrentLanguage === 'EN' ? 'left' : 'right',
                                            marginLeft: '0.5rem', marginRight: '0.5rem', width: '100%', fontSize: '12px',
                                            height: '100%', paddingTop: '0.5rem', paddingBottom: '0.5rem', color: 'white', opacity: '0.70', borderRadius: '0.2rem'
                                        }}
                                    >
                                        <span style={
                                            {
                                                fontSize: '0.96rem'
                                            }}>
                                            {CurrentLanguage === 'EN' ? 'By Signing up you agree to the' : 'بتسجيلك فإنك توافق على'}
                                        </span>
                                        <span>
                                            <span style={{ fontSize: '12px'}}>
                                                <Link
                                                    to={`${window.location.origin}/privacy_policy`}
                                                    rel="noreferrer"
                                                    style={{ fontSize: '14px', textDecoration: 'none', color: '#09A6FA' }}>
                                                    {CurrentLanguage === 'EN' ? ' User Agreement' : ' اتفاقية الاستخدام'}
                                                </Link>
                                                <span style={
                                                    {
                                                        fontSize: '0.96rem'
                                                    }}>
                                                    {CurrentLanguage === 'EN' ? ' & you won\'t violate the' : ' و انك لن تتعدى على '}
                                                </span>
                                                <Link
                                                    to={`${window.location.origin}/terms_of_service`}
                                                    rel="noreferrer"
                                                    style={{ fontSize: '14px', textDecoration: 'none', color: '#09A6FA' }}>
                                                    {CurrentLanguage === 'EN' ? ' Terms of Service' : ' الشروط و الأحكام'}
                                                </Link>
                                            </span>
                                        </span>
                                    </div>

                                </div>
                                <Box display="flex" alignItems={"center"} justifyContent="center" flexDirection="column" marginTop={"15px"} >
                                    <Button 
                                    variant="contained" 
                                    type="submit"
                                    disabled={
                                     !values.User_Email
                                     || !values.User_UserName 
                                     || !values.User_Password
                                     || touched.User_Email && errors.User_Email
                                     || touched.User_UserName && errors.User_UserName
                                     || touched.User_Password && errors.User_Password
                                    }
                                    style={{
                                        backgroundColor: !values.User_Email 
                                        || !values.User_UserName
                                        || !values.User_Password
                                        || touched.User_Email && errors.User_Email
                                        || touched.User_UserName && errors.User_UserName
                                        || touched.User_Password && errors.User_Password
                                        ? '#1976D22D' : '#1976d2',
                                        
                                        color: !values.User_Email
                                        || !values.User_UserName
                                        || !values.User_Password
                                        || touched.User_Email && errors.User_Email
                                        || touched.User_UserName && errors.User_UserName
                                        || touched.User_Password && errors.User_Password
                                        ? '#FFFFFF5E' : '#FFFFFF',
                                    }}
                                    >
                                      {CurrentLanguage === 'EN' ? 'Sign Up' : 'إنشاء حساب'}
                                    </Button>
                                </Box>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </Box>
    );
};


export default SignUpForm