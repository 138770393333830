import axios from "axios";
import { firebase, database } from '../../xFireBase/Storage';

// game_Downloaded_Times_updater method
const game_Downloaded_Times_updater = async (gameData) => {
    try {
        const updateData = {
            game_Name: gameData.game_Name,
            game_Number: gameData.game_Number,
            game_Page: gameData.game_Page,
        }

        const response = await axios.post("https://arab2games-games-backend.vercel.app/api/arab2games/game_Downloaded_Times_updater", updateData)
            .then(async () => {
                if (firebase.auth().currentUser) {
                    await database.collection('users').doc(firebase.auth().currentUser.email).collection('more-details').doc('Profile_Management').get().then(async (doc) => {
                        if (doc.exists && doc.data().Turn_On_Off_DownloadHistory === true) {
                            await database.collection('users').doc(firebase.auth().currentUser.email).collection('more-details').doc('User_History').get().then(async (doc) => {
                                if (doc.exists) {
                                    const randomID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                                    await database
                                        .collection('users')
                                        .doc(firebase.auth().currentUser.email)
                                        .collection('more-details')
                                        .doc('User_History')
                                        .collection('User_Downloads')
                                        .doc(randomID)
                                        .set({
                                            Download_ID: randomID,
                                            Download_aType: 'game',
                                            Download_CreatedAt: new Date(),
                                            Download_ReleaseDate: gameData.game_ReleaseDate,
                                            Download_Name: gameData.game_Name,
                                            Download_Name_alias: gameData.game_Name_alias ? gameData.game_Name_alias : null,
                                            Download_Picture: gameData.game_Picture[0],
                                            Download_Genres: gameData.game_Genres,
                                            Download_Size: gameData.game_Size,
                                            Download_Description: gameData.game_Description,
                                            Download_Page: gameData.game_Page,
                                            Download_Number: gameData.game_Number,
                                            Download_Downloaded_At_Date: new Date().getDate() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getFullYear(),
                                            Download_Downloaded_At_Time: new Date().toLocaleTimeString('en-US', { timeZone: 'Asia/Riyadh' }),
                                        })
                                }
                            })
                        }
                    })
                }
            })
            .then(async () => {
                if (firebase.auth().currentUser) {
                    await database.collection('users').doc(firebase.auth().currentUser.email).collection('more-details').doc('Profile_Management').get().then(async (doc) => {
                        if (doc.exists && doc.data().Turn_On_Off_NotificationsHistory === true) {
                            await database.collection('users').doc(firebase.auth().currentUser.email).collection('more-details').doc('User_History').get().then(async (doc) => {
                                if (doc.exists) {
                                    await database
                                        .collection('users')
                                        .doc(firebase.auth().currentUser.email)
                                        .collection('more-details')
                                        .doc('User_History')
                                        .collection('User_Notification')
                                        .add({
                                            Notify_Message: `تم تحميل لعبة ${gameData.game_Name}`,
                                            Notify_Image: gameData.game_Picture[0],
                                            Notify_Status: 'new',
                                            Notify_aType: 'game',
                                            Notify_CreatedAt: new Date(),
                                            Notify_Size: gameData.game_Size,
                                            Notify_Name: gameData.game_Name,
                                            Notify_Genres: gameData.game_Genres,
                                            Notify_Name_alias: gameData.game_Name_alias ? gameData.game_Name_alias : null,
                                            Notify_Page: gameData.game_Page,
                                            Notify_Number: gameData.game_Number,
                                            Notify_At_Date: new Date().getDate() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getFullYear(),
                                            Notify_At_Time: new Date().toLocaleTimeString('en-US', { timeZone: 'Asia/Riyadh' }),
                                            Notify_Description: `تم تحميل لعبة ${gameData.game_Name}
                     في تاريخ ${new Date().getDate() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getFullYear()}
                     في الساعة ${new Date().toLocaleTimeString('en-US', { timeZone: 'Asia/Riyadh' })}
                     و حجمها ${gameData.game_Size}`,
                                        })
                                }
                            })
                        }
                    })
                }
            })
            .then(() => {
                return 'done';
            })

        return response;
    }
    catch (error) {
        console.log("❌ game_DownloadedTimesUpdater ~ couldn't update the download times ~ error :   ", error)
        return false;
    }
};

export default game_Downloaded_Times_updater;