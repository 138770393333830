import React from "react"
import { Helmet } from "react-helmet-async";
import { RETO_defaultSettings } from "../../hooks/Others/RETO_data";

function Page_Helmet({
    title,
    image,
    description,
    type,
    url,
    keywords,
    children = null,
}) {

    return (
        <Helmet>
            <html lang="en" />
            <title>{title}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={url} />
            <meta name="keywords" content={keywords} />
            <link rel="icon" content={RETO_defaultSettings[0].Website_Main_Logo} />

            {/* OG Tags */}
            {/* https://ogp.me/ */}
            <meta property="og:url" title={url} />
            <meta property="og:title" title={title} />
            <meta property="og:description" title={description} />
            <meta property="og:type" content="..." />
            <meta property="og:image" content={image} />

            {/* Twitter tags */}
            {/* https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started */}
            <meta property="twitter:site" title={'Arab2Games'} />
            <meta property="twitter:title" title={title} />
            <meta property="twitter:description" title={description} />
            <meta
                property="twitter:creator"
                content="twitter username of webpage content"
            />
            <meta property="twitter:image" content={image} />

            {/* https://moz.com/blog/meta-referrer-tag */}
            <meta name="referrer" content="origin-when-crossorigin" />

            {children}
        </Helmet>
    )
}

Helmet.defaultProps = {
    title: "Arab2Games",
    description: "احصل على افضل الالعاب و البرامج مجانية و كاملة بدون اي فيروسات",
    keywords: "A2G, Arab2Games, Arab2Game, arab2games, arab2game, عرب تو قيمز, عرب تو قيمنق, عرب 2 قيمز, apps, games, discord bots, arab2games, Arab2Games, Arab2Games.com, arab2games.com, arab2games.org, Arab2Games.org, arab2games.net, Arab2Games.net, arab2games.io, Arab2Games.io, arab2games.dev, Arab2Games.dev, crack, cracked, crack game, cracked game, crack games, cracked games, crack app, cracked app, crack apps, cracked apps, مكركة, كراك, العاب مكركة, مكركه, العاب مكركه, برامج مكركه, برامج مكركة, لعبة مكركة, لعبه مكركه, برنامج مكرك, العاب, لعبة, لعبه, برنامج, برامح, ريتو, لايك, لايك ريتو, arab2games, Arab2Games, ARAB2GAMES, Arab2Games, Arab2Games",
    type: "article",
    image: RETO_defaultSettings[0].Website_Main_Logo,
    url: "https://Arab2Games.com/"
}

export default Page_Helmet