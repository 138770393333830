import React, { useState } from 'react'
import useMediaQuery from "@mui/material/useMediaQuery";
import { Checkbox, Button } from '@mui/material';
import { Formik } from 'formik';
import { Check2Circle, Trash3 } from 'react-bootstrap-icons';
import { RETO_defaultSettings } from '../../../hooks/Others/RETO_data';
import { useAuth } from '../../../xFireBase/AuthContext';


const GmaeFilterGenresMenu = () => {

    const { CurrentLanguage } = useAuth();
    const isNonMobile = useMediaQuery("(min-width:992px)");

    const [SelectedGenres, setSelectedGenres] = useState([]);

    function handleFilterSubmit() {
        const selectedGenres = SelectedGenres;
        setTimeout(() => {
            // update the local item to the selected language
            RETO_defaultSettings[0].App_SelectedFilters = selectedGenres;
        }, 1000);
    }



    return (
        <div style={{
            backgroundColor: '#FFFFFF0C', borderRadius: '15px', display: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: 'center', textAlign: 'center',
            width: '100%', padding: '0.5rem', marginTop: '-45px', marginBottom: '40px', direction: 'rtl', transition: 'all 0.3s ease-in-out'
        }}>
            <Formik
                onSubmit={handleFilterSubmit}
                initialValues={{
                    Action: '',
                    Horror: '',
                    Adventure: '',
                    RPG: '',
                    Strategy: '',
                    Simulation: '',
                    Sports: '',
                    Racing: '',
                    Fighting: '',
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit} style={{ width: '100%', flexDirection: 'column', }}>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            <Button
                                className='AccountEdit-Form-Button'
                                type='submit'
                                style={{ width: isNonMobile ? '15%' : '40%', color: 'white', borderRadius: '30px', marginTop: '10px', backgroundColor: '#2563ffd9' }}
                                disabled={SelectedGenres.length <= 0 ? true : false}
                            >
                                <Check2Circle style={{ marginLeft: '5px', color: '#FFFFFF', transition: 'all 0.2s ease-in-out' }} />
                                {CurrentLanguage === 'EN' ? 'Apply' : 'تطبيق'}
                            </Button>

                            <Button
                                variant="outlined"
                                style={{ width: isNonMobile ? '15%' : '40%', color: 'white', borderRadius: '30px', marginTop: '10px' }}
                                disabled={SelectedGenres.length <= 0 && RETO_defaultSettings[0]?.App_SelectedFilters?.length <= 0 ? true : false}
                                onClick={() => {
                                    setSelectedGenres([]);
                                    RETO_defaultSettings[0].App_SelectedFilters = [];
                                }}
                            >
                                <Trash3 style={{
                                    marginLeft: '5px', color: SelectedGenres.length > 0 || RETO_defaultSettings[0]?.App_SelectedFilters?.length > 0
                                        ? '#FF3943' : '#D62F379A', transition: 'all 0.2s ease-in-out'
                                }} />
                                {CurrentLanguage === 'EN' ? 'Clear' : 'مسح'}
                            </Button>
                        </div>

                        {/* 1st row */}
                        <div style={{ flexDirection: isNonMobile ? 'row' : 'column', display: 'flex', width: '100%', justifyContent: 'center' }}>

                            {/* 1st column */}
                            <div style={{ flexDirection: isNonMobile ? 'column' : 'row', display: 'flex', width: isNonMobile ? '25%' : '100%', justifyContent: 'space-between' }}>
                                <Button
                                    fullWidth
                                    name='Editor'
                                    value={'Editor'}
                                    onBlur={handleBlur('Editor')}
                                    onChange={handleChange('Editor')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%', color: 'white', borderRadius: '30px', marginTop: '10px', backgroundColor: '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        // push the value of this button to the array and don't push it if it's already in the array, also remove it if it's already in the array
                                        // also don't remove the rest of the values in the array
                                        if (SelectedGenres.includes('Editor')) {
                                            const index = SelectedGenres.indexOf('Editor');
                                            if (index > -1) {
                                                SelectedGenres.splice(index, 1);
                                            }
                                            setSelectedGenres(SelectedGenres);
                                        }
                                        else {
                                            setSelectedGenres([...SelectedGenres, 'Editor']);
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'Editor' : 'تصاميم ( محرر )'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'Editor'}
                                        checked={SelectedGenres.includes('Editor') || RETO_defaultSettings[0]?.App_SelectedFilters?.includes('Editor') ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null,
                                            color: SelectedGenres.includes('Editor') ? '#2F5CD6' : '#FFFFFF7A', transition: 'all 0.2s ease-in-out'
                                        }}
                                    />
                                </Button>

                                <Button
                                    fullWidth
                                    name='For Gamers'
                                    value={'For Gamers'}
                                    onBlur={handleBlur('For Gamers')}
                                    onChange={handleChange('For Gamers')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%', color: 'white', borderRadius: '30px', marginTop: '10px', backgroundColor: '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        // push the value of this button to the array and don't push it if it's already in the array, also remove it if it's already in the array
                                        // also don't remove the rest of the values in the array
                                        if (SelectedGenres.includes('For Gamers')) {
                                            const index = SelectedGenres.indexOf('For Gamers');
                                            if (index > -1) {
                                                SelectedGenres.splice(index, 1);
                                            }
                                            setSelectedGenres(SelectedGenres);
                                        }
                                        else {
                                            setSelectedGenres([...SelectedGenres, 'For Gamers']);
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'For Gamers' : 'برامج للقيمرز'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'For Gamers'}
                                        checked={SelectedGenres.includes('For Gamers') || RETO_defaultSettings[0]?.App_SelectedFilters?.includes('For Gamers') ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null,
                                            color: SelectedGenres.includes('For Gamers') ? '#2F5CD6' : '#FFFFFF7A', transition: 'all 0.2s ease-in-out'
                                        }}
                                    />
                                </Button>
                            </div>

                            {/* 2nd column */}
                            <div style={{
                                flexDirection: isNonMobile ? 'column' : 'row', display: 'flex', width: isNonMobile ? '25%' : '100%',
                                marginTop: isNonMobile ? null : '20px', justifyContent: 'space-between'
                            }}>
                                <Button
                                    fullWidth
                                    name='Music'
                                    value={'Music'}
                                    onBlur={handleBlur('Music')}
                                    onChange={handleChange('Music')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%', color: 'white', borderRadius: '30px', marginTop: '10px', backgroundColor: '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        // push the value of this button to the array and don't push it if it's already in the array, also remove it if it's already in the array
                                        // also don't remove the rest of the values in the array
                                        if (SelectedGenres.includes('Music')) {
                                            const index = SelectedGenres.indexOf('Music');
                                            if (index > -1) {
                                                SelectedGenres.splice(index, 1);
                                            }
                                            setSelectedGenres(SelectedGenres);
                                        }
                                        else {
                                            setSelectedGenres([...SelectedGenres, 'Music']);
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'Music' : 'موسيقى'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'Music'}
                                        checked={SelectedGenres.includes('Music') || RETO_defaultSettings[0]?.App_SelectedFilters?.includes('Music') ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null,
                                            color: SelectedGenres.includes('Music') ? '#2F5CD6' : '#FFFFFF7A', transition: 'all 0.2s ease-in-out'
                                        }}
                                    />
                                </Button>

                                <Button
                                    fullWidth
                                    name='Screen Recorder'
                                    value={'Screen Recorder'}
                                    onBlur={handleBlur('Screen Recorder')}
                                    onChange={handleChange('Screen Recorder')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%', color: 'white', borderRadius: '30px', marginTop: '10px', backgroundColor: '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        // push the value of this button to the array and don't push it if it's already in the array, also remove it if it's already in the array
                                        // also don't remove the rest of the values in the array
                                        if (SelectedGenres.includes('Screen Recorder')) {
                                            const index = SelectedGenres.indexOf('Screen Recorder');
                                            if (index > -1) {
                                                SelectedGenres.splice(index, 1);
                                            }
                                            setSelectedGenres(SelectedGenres);
                                        }
                                        else {
                                            setSelectedGenres([...SelectedGenres, 'Screen Recorder']);
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'Screen Recorder' : 'تصوير الشاشة'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'Screen Recorder'}
                                        checked={SelectedGenres.includes('Screen Recorder') || RETO_defaultSettings[0]?.App_SelectedFilters?.includes('Screen Recorder') ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null,
                                            color: SelectedGenres.includes('Screen Recorder') ? '#2F5CD6' : '#FFFFFF7A', transition: 'all 0.2s ease-in-out'
                                        }}
                                    />
                                </Button>
                            </div>

                            {/* 3rd column */}
                            <div style={{
                                flexDirection: isNonMobile ? 'column' : 'row', display: 'flex', width: isNonMobile ? '25%' : '100%',
                                marginTop: isNonMobile ? null : '20px', justifyContent: 'space-between'
                            }}>
                                <Button
                                    fullWidth
                                    name='VPN'
                                    value={'VPN'}
                                    onBlur={handleBlur('VPN')}
                                    onChange={handleChange('VPN')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%', color: 'white', borderRadius: '30px', marginTop: '10px', backgroundColor: '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        // push the value of this button to the array and don't push it if it's already in the array, also remove it if it's already in the array
                                        // also don't remove the rest of the values in the array
                                        if (SelectedGenres.includes('VPN')) {
                                            const index = SelectedGenres.indexOf('VPN');
                                            if (index > -1) {
                                                SelectedGenres.splice(index, 1);
                                            }
                                            setSelectedGenres(SelectedGenres);
                                        }
                                        else {
                                            setSelectedGenres([...SelectedGenres, 'VPN']);
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'VPN' : 'VPN'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'VPN'}
                                        checked={SelectedGenres.includes('VPN') || RETO_defaultSettings[0]?.App_SelectedFilters?.includes('VPN') ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null,
                                            color: SelectedGenres.includes('VPN') ? '#2F5CD6' : '#FFFFFF7A', transition: 'all 0.2s ease-in-out'
                                        }}
                                    />
                                </Button>
                            </div>

                        </div>
                    </form>
                )}

            </Formik>
        </div>
    )
}

export default GmaeFilterGenresMenu