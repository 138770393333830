import React, { useEffect, useState } from 'react'
import { firebase } from './xFireBase/Storage';
import UserScreen from './screens/UserScreen'
import UserScreen2 from './screens/UserScreen2'
import { useAuth } from './xFireBase/AuthContext';


const AuthNavigation = () => {
  const { user } = useAuth()

  useEffect(() => {
    if (user) {
      console.log("✅ AuthCheck.js -> user: logged in")
    }
    else {
      console.log("❌ AuthCheck.js -> user: logged out")
    }
  }, [])

  return <>{user ? <UserScreen /> : <UserScreen2 />}</>
}

export default AuthNavigation


