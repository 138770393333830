import React, { useState } from 'react';
import useMediaQuery from "@mui/material/useMediaQuery";
import { FaQuestionCircle } from 'react-icons/fa';
import { RETO_defaultSettings } from "../hooks/Others/RETO_data";
import Page_Helmet from "../hooks/SEO/react_Helmet";
import { useAuth } from '../xFireBase/AuthContext';
import ScrollToTop from '../ScrollToTop';

function CommonQuestions() {

    const { CurrentLanguage, darkMode } = useAuth();
    const isNonMobile = useMediaQuery("(min-width:992px)");
    const isTablet = useMediaQuery("(max-width:991px)");
    const isMobile = useMediaQuery("(max-width:525px)");

    const [box_isHovered, setBox_isHovered] = useState(false);

    const [active, setActive] = useState(null);

    const data1 = {
        title: 'الأسئلة الشائعة',
        en_title: 'FAQ',
        rows: [
            {
                id: 1,
                title: 'هل الألعاب و البرامج المكركة تحتوي على فيروسات؟',
                en_title: 'does cracked games and programs contain viruses?',
                content: `لا, لا تحتوي على الفيروسات و يتم التحقق منهم قبل رفعهم. و في حال وجد الرجاء التواصل معي في الدسكورد ( اضغط هنا ) `,
                en_content: `No, they don't contain viruses and they are checked before uploading.`,
            },
            {
                id: 2,
                title: 'اذا أردت النسخ الأصلية, أين أجدها؟',
                en_title: 'If I want the original copies, where can I find them?',
                content: 'كل النسخ الأصلية موجودة في متجري, يمكنك الوصول إليه بالضغظ على كلمة المتجر فوق او اضغط هنا',
                en_content: 'All original copies are in my store, you can access it by clicking on the word store above or click here',
            },
            {
                id: 3,
                title: 'كيف أقوم بتحميل الألعاب و البرامج ؟',
                en_title: 'How do I download games and programs?',
                content: 'حالياً لا يوجد طريقة غير التورنت, لذا يجب ان يكون لديك برنامج تورنت مثل uTorrent و يمكنك تحميله بالضغط عليه ',
                en_content: 'Currently there is no way other than torrent, so you must have a torrent program such as uTorrent and you can download them by clicking on them',
            },
            {
                id: 4,
                title: 'لم اجد اللعبة التي ابحث عنها, ماذا أفعل ؟',
                en_title: 'I did not find the game I was looking for, what should I do?',
                content: 'تواصل معي عبر الدسكورد ( اضغط هنا ) / او برامج التواصل الإجتماعي موجودة في اَخر الصفحة. و سأقوم بتوفيرها لك.',
                en_content: 'Contact me via Discord ( click here ) / or via Social Media which are at the bottom of the page. And I will provide it to you.',
            },
            {
                id: 5,
                title: 'كيف اعرف ان اللعبة او البرنامج يعمل على جهازي ؟',
                en_title: 'How do I know that the game or program works on my device?',
                content: 'يوجد في كل صفحة للعبة او البرنامج متطلبات التشغيل, يمكنك الإطلاع عليها و مقارنتها مع جهازك.',
                en_content: 'There are in each page of the game or program the requirements, you can check them and compare them with your device.',
            },

        ],
    };


    return (
        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', height: '100%',
            paddingBottom: isMobile ? '200px' : '',
            transition: 'all 0.5s ease-in-out',
        }}>
            <Page_Helmet
                title="Arab2Games | FAQ"
                description="احصل على افضل الالعاب و البرامج مجانية و كاملة بدون اي فيروسات"
                image={RETO_defaultSettings[0].Website_Main_Logo}
                url="https://Arab2Games.com/"
                keywords="A2G, Arab2Games, Arab2Game, arab2games, arab2game, عرب تو قيمز, عرب تو قيمنق, عرب 2 قيمز, apps, games, discord bots, arab2games, Arab2Games, Arab2Games.com, arab2games.com, arab2games.org, Arab2Games.org, arab2games.net, Arab2Games.net, arab2games.io, Arab2Games.io, arab2games.dev, Arab2Games.dev, crack, cracked, crack game, cracked game, crack games, cracked games, crack app, cracked app, crack apps, cracked apps, مكركة, كراك, العاب مكركة, مكركه, العاب مكركه, برامج مكركه, برامج مكركة, لعبة مكركة, لعبه مكركه, برنامج مكرك, العاب, لعبة, لعبه, برنامج, برامح, ريتو, لايك, لايك ريتو, arab2games, Arab2Games, ARAB2GAMES, Arab2Games, Arab2Games"
            />
            <ScrollToTop />

            <div
                className="MainScreen-FAQSection"
                style={{
                    paddingBottom: '100px',
                }}>

                <div
                    className={isNonMobile ? 'PolicyScreen-bx' : 'PolicyScreen-bx-Mobile'}
                    style={{
                        maxWidth: isMobile ? 'auto' : isTablet ? '770px' : '1200px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        margin: '0 auto',
                        textAlign: 'center',
                        transition: 'all 0.5s ease-in-out',
                        boxShadow: darkMode === 'light'
                            ? box_isHovered === true ? '0 0 20px 0 #42297c' : '0 0 20px 0 #4644449D'
                            : box_isHovered === true ? '0 0 20px 0 #42297c' : '0 0 20px 0 #e2dada31',
                    }}
                    onMouseEnter={() => setBox_isHovered(true)}
                    onMouseLeave={() => setBox_isHovered(false)}
                >
                    <header
                        style={{
                            transition: 'all 0.5s ease-in-out',
                            marginBottom: isMobile ? '20px' : '50px',
                            display: 'flex',
                            paddingRight: '30px',
                            paddingTop: '50px',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            width: 'fit-content',
                            transform: 'scale(0.97)',
                            padding: '0.8rem',
                            marginTop: '20px',
                            boxShadow: darkMode === 'light'
                                ? '0 0 20px 0 #4644449D'
                                : '0 0 20px 0 #e2dada31',
                            borderRadius: '1.2rem',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                        }}>
                        <h2
                            style={{
                                color: darkMode === 'light' ? '#000000' : '#ffffff',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                fontSize: '1.8rem',
                            }}
                        >
                            <FaQuestionCircle size={30} style={{ marginLeft: '15px' }} />

                            {CurrentLanguage === 'EN' ? data1.en_title : data1.title}
                        </h2>
                    </header>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',
                            width: '100%',
                            height: '100%',
                            flexWrap: 'wrap',
                            maxWidth: '1200px',
                            margin: '0 auto',
                            paddingBottom: isMobile ? '18px' : '180px',
                        }}
                    >
                        {data1.rows.map((row, index) => (
                            <div
                                className='MainScreen-FAQ'
                                key={index}
                                style={{
                                    transform: isMobile ? 'scale(0.9)' : null,
                                }}
                            >
                                <div
                                    className='MainScreen-FAQ-Title'
                                    onClick={() => {
                                        active === row.id ? setActive(null) : setActive(row.id);
                                    }}
                                    style={{
                                        marginTop: isMobile ? '10px' : null,

                                    }}
                                >
                                    <div className='MainScreen-FAQ-container' style={{
                                        // multiple lines of text
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        width: '100%',
                                        height: '100%',
                                        maxWidth: isMobile ? '300px' : null,
                                        margin: '0 auto',
                                    }}>
                                        <p style={{ fontSize: isMobile ? '14px' : null }}>{CurrentLanguage === 'EN' ? row.en_title : row.title}</p>
                                        <span>
                                            {active === row.id ? '-' : '+'}
                                        </span>
                                    </div>
                                </div>

                                <div
                                    className={(active === row.id ? 'show' : '') + ' MainScreen-FAQ-Content'}
                                    style={{
                                        backgroundColor: active === row.id ? '#141427' : null,
                                        borderEndEndRadius: active === row.id ? '50px' : null,
                                        borderEndStartRadius: active === row.id ? '50px' : null,
                                    }}
                                >
                                    <div className='MainScreen-FAQ-container'>
                                        <p>
                                            {row.id === 2 ?
                                                CurrentLanguage === 'EN' ?
                                                    // search for the word 'click here' and change it to the link of the store
                                                    row.en_content.split('click here').map((item, index) => (
                                                        <span key={index}>
                                                            {item}
                                                            {index !== row.en_content.split('click here').length - 1 ? (
                                                                <a href='https://likereto.store/' target='_blank' rel='noreferrer'>
                                                                    click here
                                                                </a>
                                                            ) : null}
                                                        </span>
                                                    ))
                                                    :
                                                    // search for the word 'اضغط هنا' and change it to the link of the store
                                                    row.content.split('اضغط هنا').map((item, index) => (
                                                        <span key={index}>
                                                            {item}
                                                            {index !== row.content.split('اضغط هنا').length - 1 ? (
                                                                <a href='https://likereto.store/' target='_blank' rel='noreferrer'>
                                                                    اضغط هنا
                                                                </a>
                                                            ) : null}
                                                        </span>
                                                    ))
                                                :
                                                row.id === 4 ?
                                                    CurrentLanguage === 'EN' ?
                                                        // search for the word 'click here' and change it to the link of the discord server
                                                        row.en_content.split('click here').map((item, index) => (
                                                            <span key={index}>
                                                                {item}
                                                                {index !== row.en_content.split('click here').length - 1 ? (
                                                                    <a href='https://discord.com/invite/jwx23GMAXm' target='_blank' rel='noreferrer'>
                                                                        click here
                                                                    </a>
                                                                ) : null}
                                                            </span>
                                                        ))
                                                        :
                                                        // search for the word 'اضغط هنا' and change it to the link of the discord server
                                                        row.content.split('اضغط هنا').map((item, index) => (
                                                            <span key={index}>
                                                                {item}
                                                                {index !== row.content.split('اضغط هنا').length - 1 ? (
                                                                    <a href='https://discord.com/invite/jwx23GMAXm' target='_blank' rel='noreferrer'>
                                                                        اضغط هنا
                                                                    </a>
                                                                ) : null}
                                                            </span>
                                                        ))
                                                    :
                                                    row.id === 1 ?
                                                        CurrentLanguage === 'EN' ?
                                                            // search for the word 'click here' and change it to the link of the discord server
                                                            row.en_content.split('click here').map((item, index) => (
                                                                <span key={index}>
                                                                    {item}
                                                                    {index !== row.en_content.split('click here').length - 1 ? (
                                                                        <a href='https://discord.com/invite/jwx23GMAXm' target='_blank' rel='noreferrer'>
                                                                            click here
                                                                        </a>
                                                                    ) : null}
                                                                </span>
                                                            ))
                                                            :
                                                            // search for the word 'اضغط هنا' and change it to the link of the discord server
                                                            row.content.split('اضغط هنا').map((item, index) => (
                                                                <span key={index}>
                                                                    {item}
                                                                    {index !== row.content.split('اضغط هنا').length - 1 ? (
                                                                        <a href='https://discord.com/invite/jwx23GMAXm' target='_blank' rel='noreferrer'>
                                                                            اضغط هنا
                                                                        </a>
                                                                    ) : null}
                                                                </span>
                                                            ))
                                                        :
                                                        row.id === 3 ?
                                                            CurrentLanguage === 'EN' ?
                                                                // search for the word 'click here' and change it to the link of the discord server
                                                                row.en_content.split('uTorrent').map((item, index) => (
                                                                    <span key={index}>
                                                                        {item}
                                                                        {index !== row.en_content.split('uTorrent').length - 1 ? (
                                                                            <a href='https://www.utorrent.com/downloads/complete/track/stable/os/win/' target='_blank' rel='noreferrer'>
                                                                                uTorrent
                                                                            </a>
                                                                        ) : null}
                                                                    </span>
                                                                ))
                                                                :
                                                                // search for the word 'اضغط هنا' and change it to the link of the discord server
                                                                row.content.split('uTorrent').map((item, index) => (
                                                                    <span key={index}>
                                                                        {item}
                                                                        {index !== row.content.split('uTorrent').length - 1 ? (
                                                                            <a href='https://www.utorrent.com/downloads/complete/track/stable/os/win/' target='_blank' rel='noreferrer'>
                                                                                uTorrent
                                                                            </a>
                                                                        ) : null}
                                                                    </span>
                                                                ))
                                                            :
                                                            CurrentLanguage === 'EN' ? row.en_content : row.content
                                            }

                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CommonQuestions;