import React, { useState, useEffect, useRef } from 'react';
import "react-multi-carousel/lib/styles.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { toast } from 'react-toastify';
import { Button } from "react-bootstrap";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Modal, VideoPlayer } from '../../hooks/Modals/GamePlayVideo/GamePlayVideo';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton';
import dowloadIcon from '../../assets/img/icons/download.svg';
import DiscordIcon from '../../assets/img/icons/discord.svg';
import InstagramIcon from '../../assets/img/icons/instagram.svg';
import CopyLinkIcon from '../../assets/img/icons/copy-icon.svg';
import CheckMark from '../../assets/img/icons/CheckMark.svg';
import play_video from '../../assets/img/icons/youtube.svg';
import Download_Loading from '../../assets/img/icons/Profile_Loading.svg';
import CommentSection from '../../hooks/currentGame_Comment/Game_CommentSection';
import MightLikeSection from '../../hooks/currentGameMightLikeSection/Game_MightLikeSection';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  FaDownload,
  FaEye,
  FaStar,
} from 'react-icons/fa';
import TrackVisibility from 'react-on-screen';
import { useAuth } from '../../xFireBase/AuthContext';
import WarningMark from '../../assets/img/icons/WarningMark.gif';
import WrongMark from '../../assets/img/icons/wrongMark.gif';


const ResizedMightLikeSection = React.memo(MightLikeSection);

export const CurrentGameCard = ({
  key, All_gameData, game_Page,
}) => {

  const { CurrentLanguage, darkMode, User_OS_Platform, User_IP_Address,
    game_Downloaded_Times_updater, game_watched_times_updater
  } = useAuth();

  const isNonMobile = useMediaQuery("(min-width:1200px)");
  const isMobile_TooSmall = useMediaQuery("(max-width: 450px)");

  const [show_everything, setShow_everything] = useState(false);

  const [current_game_Details, setCurrent_game_Details] = useState([]);
  const [current_game_Trailer_Link, setCurrent_game_Trailer_Link] = useState([]);

  const [game_Reviews, setGame_Reviews] = useState([]);
  const [game_Requirements, setGame_Requirements] = useState([]);

  const [game_PlayButtonClicked, setGame_PlayButtonClicked] = useState(false);

  const [box_isHovered, setBox_isHovered] = useState(false);



  const [game_Description_Show, setGame_Description_Show] = useState(false);

  const gameLink = `${window.location.href}`;

  const [Download_DirectLink_Start, setDownload_DirectLink_Start] = useState(false);
  const [Download_TorrentLink_Start, setDownload_TorrentLink_Start] = useState(false);

  const [isViewed, setIsViewed] = useState(false);

  // set the rest of the game data
  useEffect(() => {
    const axiosFetchData = async () => {
      setShow_everything(false);
      if (All_gameData.game_Page === game_Page) {
        setCurrent_game_Details(All_gameData);
        setCurrent_game_Trailer_Link(All_gameData.game_Trailer_Link);
        setGame_Reviews(All_gameData.game_Reviews || []); // use empty array if game_Reviews is undefined/null
        setGame_Requirements(All_gameData.game_Requirements || []); // use empty array if game_Requirements is undefined/null
      }

    };

    axiosFetchData();

  }, [All_gameData, game_Page]);

  useEffect(() => {
    const ShowEverything = async () => {
      if (current_game_Details) {
        setShow_everything(true);
      }
    }
    ShowEverything();
  }, [show_everything]);


  // detect if the user is watching the game card
  const handleView = () => {
    setIsViewed(true);
  };

  // handle the copy game link button
  const handleCopyLink = () => {
    const notify = () => toast.success(CurrentLanguage === 'EN' ? 'Game Link Copied' : 'تم نسخ رابط اللعبة');
    // notify the user that the link has been copied
    notify();
  };


  // update the app watch times
  const handle_game_watched_times_updater = async () => {
    try {
      await game_watched_times_updater({ All_gameData, User_IP_Address })
    }
    catch (error) {
    }
  }


  // update the game downloaded times
  const handle_game_Downloaded_Times_updater = async () => {
    try {
      await game_Downloaded_Times_updater(All_gameData)
    }
    catch (error) {
    }
  }

  // handle the download button for direct link 
  const handleDownloadDirectLink = async () => {

    try {
      if (User_OS_Platform === 'Desktop') {
        setDownload_DirectLink_Start(true);
        await handle_game_Downloaded_Times_updater();
      }
      else if (User_OS_Platform === 'Mobile') {
        toast.error('لا يمكنك تحميل اللعبة من هاتفك');
      }
      setDownload_DirectLink_Start(false);
    }
    catch (error) {
      toast.error('❌ حدث خطأ أثناء جذب رابط المباشر');
      setDownload_DirectLink_Start(false);
    }
  }

  // handle the download button for torrent link
  const handleDownloadTorrentLink = async () => {
    try {
      if (User_OS_Platform === 'Desktop') {
        setDownload_TorrentLink_Start(true);
        await handle_game_Downloaded_Times_updater();
      }
      else if (User_OS_Platform === 'Mobile') {
        toast.error('لا يمكنك تحميل اللعبة من هاتفك');
      }
      setDownload_TorrentLink_Start(false);
    }
    catch (error) {
      toast.error('❌ حدث خطأ أثناء جذب التورنت');
      setDownload_TorrentLink_Start(false);
    }
  }

  // Function to process the description and replace URLs with links
  const processDescription = (desc) => {
    return desc.split(' ').map((word, index) => {
      return word.startsWith('https://') ? (
        <a
          key={index}
          href={word}
          target='_self'
          rel="noopener noreferrer"
          style={{ color: '#2F8DF8' }}
        >
          <h4 style={{ display: 'inline', color: '#2F8DF8' }}>{"رابط " + ' \n'}</h4>
        </a>
      ) : (
        word + ' '
      );
    });
  };

  useEffect(() => {
    if (isViewed) {
      const watchedBy_DB = All_gameData?.game_watched_times?.watched_By
        ? All_gameData?.game_watched_times?.watched_By : null; // use empty array if game_watched_times is undefined/null 

      if (watchedBy_DB !== null && watchedBy_DB !== undefined) {

        const watchedBy = watchedBy_DB;

        if (User_IP_Address?.length > 0) {
          if (!watchedBy?.includes(User_IP_Address)) {
            watchedBy?.push(User_IP_Address);
            setTimeout(() => {
              handle_game_watched_times_updater();
            }, 500);
          }
        }

      }

      setTimeout(() => {
        setIsViewed(false);
      }, 1500);

    }

  }, [isViewed, All_gameData?.game_watched_times?.watched_By, User_IP_Address, handle_game_watched_times_updater]);



  let GamePlayVideoRef = useRef();
  useEffect(() => {
    let GamePlayVideo = (event) => {
      if (GamePlayVideoRef.current && !GamePlayVideoRef.current.contains(event.target)) {
        setGame_PlayButtonClicked(false);
      }
    }
    document.addEventListener("mousedown", GamePlayVideo);
  }, []);

  return (
    <div key={key}>

      <div ref={GamePlayVideoRef}>
        {game_PlayButtonClicked &&
          <Modal show={game_PlayButtonClicked} handleClose={() => setGame_PlayButtonClicked(false)}>
            <VideoPlayer video_Trailer_Link={current_game_Trailer_Link ? current_game_Trailer_Link : null} handleClose={() => setGame_PlayButtonClicked(false)} />
          </Modal>
        }
      </div>
      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
        alignItems: 'center', width: isNonMobile ? '' : '200%', height: '100%', borderRadius: '0.2rem'
      }}>
        <TrackVisibility once>
          {({ isVisible }) => isVisible ?
            <div>
              {isVisible && handleView()}
              <h3
                style={{
                  color: darkMode === 'light' ? '#000' : '#fff',
                }}
              >
                {show_everything === false ?
                  <Skeleton
                    style={{
                      width: '100%', justifyContent: "center", alignItems: "center", display: "flex",
                      margin: "0 auto", transition: "all 0.5s ease",
                    }}
                    height={30}
                    width={100}
                  />
                  :
                  All_gameData.game_Name
                }
              </h3>
            </div>
            :
            <h3
              style={{
                color: darkMode === 'light' ? '#000' : '#fff',
              }}
            >
              {show_everything === false ?
                <Skeleton
                  style={{
                    width: '100%', justifyContent: "center", alignItems: "center", display: "flex",
                    margin: "0 auto", transition: "all 0.5s ease",
                  }}
                  height={20}
                  width={40}
                />
                :
                All_gameData.game_Name
              }
            </h3>
          }
        </TrackVisibility>
      </div>

      <div style={{
        justifyContent: 'space-between', padding: '0.5rem', borderRadius: '0.2rem',
        alignItems: 'center', width: isNonMobile ? '' : '115%', height: '100%',
      }}>

        <div style={{
          textAlign: 'right', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', display: 'flex', flexDirection: 'row',
          width: isNonMobile ? '' : '200%', height: '100%', borderRadius: '0.2rem', paddingBottom: '10px', paddingTop: '20px'
        }}>

          {show_everything === false ?
            <Skeleton
              style={{
                width: '100%', justifyContent: "center", alignItems: "center", display: "flex",
                margin: "0 auto", transition: "all 0.5s ease",
              }}
              height={20}
              width={40}
            />
            :
            <h5 style={{
              textAlign: 'right',
              color: darkMode === 'light' ? '#000' : '#ffffff',
              direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr',
              fontSize: '1.1rem',
              marginRight: CurrentLanguage === 'EN' ? '15px' : '',
              marginLeft: CurrentLanguage === 'EN' ? '' : '15px'
            }}>
              {game_Reviews > 0 ? game_Reviews : '0'}
              <FaStar style={{
                color: darkMode === 'light' ? '#F7A52BFA' : '#FFB547EA',
                marginLeft: CurrentLanguage === 'EN' ? '' : '3px',
                marginRight: CurrentLanguage === 'EN' ? '3px' : '',
                marginTop: isMobile_TooSmall ? '-3px' : null
              }} />
            </h5>
          }

          <h5 style={{
            textAlign: 'right',
            color: darkMode === 'light' ? '#000' : '#ffffff',
            direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr',
            fontSize: '1.1rem',
            marginLeft: '10px',
            marginRight: '10px'
          }}
          > | </h5>

          {show_everything === false ?
            <Skeleton
              style={{
                width: '100%', justifyContent: "center", alignItems: "center", display: "flex",
                margin: "0 auto", transition: "all 0.5s ease",
              }}
              height={20}
              width={40}
            />
            :
            <h5 style={{
              textAlign: 'right',
              color: darkMode === 'light' ? '#000' : '#ffffff',
              direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr',
              fontSize: '1.1rem',
            }}
            >
              {All_gameData?.game_Downloaded_Times}{' '}
              <FaDownload style={{
                color: darkMode === 'light' ? '#9642F7' : '#B67CF8AF',
                marginLeft: CurrentLanguage === 'EN' ? '' : '3px',
                marginRight: CurrentLanguage === 'EN' ? '3px' : '',
                marginTop: isMobile_TooSmall ? '-3px' : null
              }} />
            </h5>
          }

          <h5 style={{
            textAlign: 'right',
            color: darkMode === 'light' ? '#000' : '#ffffff',
            direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr',
            fontSize: '1.1rem',
            marginLeft: '10px',
            marginRight: '10px'
          }}> | </h5>

          {show_everything === false ?
            <Skeleton
              style={{
                width: '100%', justifyContent: "center", alignItems: "center", display: "flex",
                margin: "0 auto", transition: "all 0.5s ease",
              }}
              height={20}
              width={40}
            />
            :
            <h5 style={{
              textAlign: 'right',
              color: darkMode === 'light' ? '#000' : '#ffffff',
              direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr',
              fontSize: '1.1rem',
            }}
            >
              {All_gameData?.game_watched_times?.watched_By?.length ? All_gameData?.game_watched_times?.watched_By?.length : 0}
              <FaEye style={{
                color: darkMode === 'light' ? '#D82C2CEF' : '#DF6868D5',
                marginLeft: CurrentLanguage === 'EN' ? '' : '3px',
                marginRight: CurrentLanguage === 'EN' ? '3px' : '',
                marginTop: isMobile_TooSmall ? '-3px' : null
              }} />
            </h5>
          }
        </div>

      </div>
      <div
        style={{
          textDecoration: 'none', color: 'white', direction: 'rtl', alignItems: 'flex-start', justifyContent: 'flex-end', textAlign: 'right', margin: '0.5rem',
          display: 'flex', flexDirection: isNonMobile ? 'row' : 'column', width: isNonMobile ? '100%' : '215%', height: '100%',
        }}
      >
        {/** the box on the right */}
        <div className="currentgames-rightbx" style={{ flexDirection: 'row', marginTop: '0px', direction: CurrentLanguage === 'EN' ? 'ltr' : '' }}>

          <div
          >
            {show_everything === false ?
              <Skeleton
                style={{
                  width: '97%', justifyContent: "center", alignItems: "center", display: "flex",
                  margin: "0 auto", transition: "all 0.5s ease",
                  borderRadius: '15px'
                }}
                height={isMobile_TooSmall ? 200 : 300}
                width={isMobile_TooSmall ? '100%' : '100%'}
              />
              :
              <TrackVisibility once>
                {({ isVisible }) => isVisible ?
                  <div>
                    {isVisible && handleView()}
                    <LazyLoadImage
                      effect="blur"
                      src={All_gameData?.game_Picture[0]}
                      draggable={false}
                      onContextMenu={(e) => e.preventDefault()}
                      alt='game_Picture'
                      style={{
                        width: '97%', justifyContent: "center", alignItems: "center", display: "flex",
                        margin: "0 auto", transition: "all 0.5s ease",
                        borderRadius: '15px'
                      }}
                    />
                  </div>
                  :
                  <LazyLoadImage
                    effect="blur"
                    src={All_gameData?.game_Picture[0]}
                    draggable={false}
                    onContextMenu={(e) => e.preventDefault()}
                    alt='game_Picture'
                    style={{
                      width: '97%', justifyContent: "center", alignItems: "center", display: "flex",
                      margin: "0 auto", transition: "all 0.5s ease",
                      borderRadius: '15px'
                    }}
                  />
                }
              </TrackVisibility>}
          </div>


          <div >
            <div style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
              textAlign: 'right', width: '100%', padding: '0.5rem'
            }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', textAlign: 'right', width: '100%', padding: '0.5rem', paddingBottom: '20px' }}>
                <span style={{
                  marginBottom: '15px',
                  marginLeft: '15px',
                  textAlign: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                  fontSize: '1.15rem',
                  color: darkMode === 'light' ? '#000' : '#ffffff',
                  direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                  borderRadius: '0.2rem'
                }}
                >
                  {CurrentLanguage === 'EN' ? 'Share Link : ' : 'شارك رابط اللعبة : '}
                </span>
                <span className="navbar-text">
                  {show_everything === false ?
                    <Skeleton
                      style={{
                        width: '100%', justifyContent: "center", alignItems: "center", display: "flex",
                        margin: "0 auto", transition: "all 0.5s ease",
                      }}
                      height={20}
                      width={100}
                    />
                    :
                    <div className="social-icon margin-">
                      <a
                        href='https://discord.gg/jwx23GMAXm'
                        target='_blank'
                        rel='noreferrer'
                        className="discord"
                        style={{ cursor: 'pointer' }}
                      >
                        <img
                          loading='lazy'
                          draggable={false}
                          src={DiscordIcon}
                          alt="DiscordIcon"
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      </a>
                      <a
                        href='https://www.instagram.com/arab2games/'
                        target='_blank'
                        rel='noreferrer'
                        className="instagram"
                        style={{ cursor: 'pointer' }}
                      >
                        <img
                          loading='lazy'
                          draggable={false}
                          src={InstagramIcon}
                          alt="InstagramIcon"
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      </a>
                      <CopyToClipboard text={gameLink} onCopy={handleCopyLink}>
                        <a
                          className="default"
                          style={{ cursor: 'pointer' }}>
                          <img
                            loading='lazy'
                            draggable={false}
                            src={CopyLinkIcon}
                            alt="CopyLinkIcon"
                            onContextMenu={(e) => e.preventDefault()}
                          />
                        </a>
                      </CopyToClipboard>
                    </div>
                  }
                </span>
              </div>
            </div>
          </div>
          <div
            draggable={false}

            onClick={() => {
              if (show_everything === false) return;
              setGame_PlayButtonClicked(true);
            }}
            style={{
              cursor: show_everything === false ? 'default' : 'pointer',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'right',
              textAlign: 'center',
              alignItems: 'center',
              marginRight: isMobile_TooSmall ? '7px' : null,
              width: 'fit-content',
              height: '40px',
              marginBottom: '25px',
              opacity: show_everything === false ? '0.5' : '1',
              backgroundColor: show_everything === false ? '#35333346' : '#550606',
              border: '1px solid #2f2f2f',
              boxShadow: show_everything === false ? '0 0 20px 0 #35333346' : '0 0 20px 0 #550606',
              borderRadius: '2rem',
              padding: '0.5rem',
              transform: isMobile_TooSmall ? 'scale(0.95)' : null,
            }}>
            <h6 style={{ textAlign: 'center', direction: 'rtl', fontSize: '18px', marginRight: '15px', marginLeft: '15px' }}>
              {CurrentLanguage === 'EN'
                ? 'game Trailer'
                : ' إعلان اللعبة '
              }
            </h6>
            <LazyLoadImage
              effect="blur"
              draggable={false}
              src={play_video}
              alt='play_video'
              onContextMenu={(e) => e.preventDefault()}
              style={{ width: '35px', height: '35px' }} />

          </div>

          <div
            style={{
              width: isMobile_TooSmall ? '96%' : null,
              marginRight: isMobile_TooSmall ? '7px' : null,
            }}
          >

            <div style={{
              display: 'flex', flexDirection: isMobile_TooSmall ? 'column' : 'row', alignItems: 'center', width: '100%',
              transform: isMobile_TooSmall ? 'scale(0.95)' : 'scale(1)'
            }}>
              <h3 style={{
                textAlign: CurrentLanguage === 'EN' ? 'left' : 'right',
                direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                width: isMobile_TooSmall ? '100%' : '',
                color: darkMode === 'light' ? '#000' : '#ffffff',
                fontSize: '1.2rem',
                marginRight: '15px',
                marginLeft: '15px'
              }}>
                {CurrentLanguage === 'EN' ? 'Genres :' : ' التصنيف : '}
              </h3>
              <h5 style={{
                textAlign: 'right',
                direction: 'ltr',
                marginRight: '15px',
                marginLeft: '5px'
              }}
              >
                {show_everything === false ?
                  <Skeleton
                    style={{
                      width: '100%', justifyContent: "center", alignItems: "center", display: "flex",
                      margin: "0 auto", transition: "all 0.5s ease",
                    }}
                    height={20}
                    width={100}
                  />
                  :
                  All_gameData?.game_Genres && All_gameData?.game_Genres?.length >= 4 ? All_gameData?.game_Genres?.slice(0, 4)?.map((genre, index) => {
                    return (
                      <span
                        key={index}
                        style={{
                          color: darkMode === 'light' ? '#000' : '#ffffff',
                          fontSize: '1.1rem',
                          marginRight: '5px',
                          marginLeft: '5px'
                        }}
                      >
                        {genre.length >= 15 ? genre.slice(0, 15) + '...' : genre}
                      </span>
                    )
                  }) : All_gameData?.game_Genres?.map((genre, index) => {
                    return (
                      <span
                        key={index}
                        style={{
                          color: darkMode === 'light' ? '#000' : '#ffffff',
                          fontSize: '1.1rem',
                          marginRight: '5px',
                          marginLeft: '5px'
                        }}
                      >
                        {genre.length >= 15 ? genre.slice(0, 15) + '...' : genre}
                      </span>
                    )
                  })}
              </h5>
            </div>

            <header>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: '15px' }}>
                <h3 style={{
                  textAlign: 'right',
                  color: darkMode === 'light' ? '#000' : '#ffffff',
                  direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                  fontSize: '1.2rem',
                  marginRight: '15px',
                  marginLeft: '15px'
                }}
                >
                  {CurrentLanguage === 'EN' ? 'Size :' : ' الحجم : '}
                </h3>
                <h3 style={{
                  textAlign: 'right',
                  color: darkMode === 'light' ? '#000' : '#ffffff',
                  direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                  fontSize: '1.2rem',
                  marginRight: '15px',
                  marginLeft: '15px'
                }}
                >
                  {CurrentLanguage === 'EN' ? 'Release Date :' : ' تاريخ الاصدار : '}
                </h3>
              </div>
            </header>

            <div style={{
              textAlign: 'right', justifyContent: 'space-between', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', display: 'flex', flexDirection: 'row',
              width: '100%', height: '100%', borderRadius: '0.2rem', paddingBottom: '20px', paddingTop: '10px'
            }}>

              <h5 style={{
                color: darkMode === 'light' ? '#000' : '#ffffff',
                textAlign: 'right',
                direction: 'ltr',
                marginRight: '5px'
              }}
              >
                {show_everything === false ?
                  <Skeleton
                    style={{
                      width: '100%', justifyContent: "center", alignItems: "center", display: "flex",
                      margin: "0 auto", transition: "all 0.5s ease",
                    }}
                    height={20}
                    width={70}
                  />
                  :
                  All_gameData?.game_Size}
              </h5>
              <h5 style={{
                color: darkMode === 'light' ? '#000' : '#ffffff',
                textAlign: 'right',
                direction: 'ltr',
                marginRight: '5px',
                marginLeft: '15px'
              }}
              >
                {show_everything === false ?
                  <Skeleton
                    style={{
                      width: '100%', justifyContent: "center", alignItems: "center", display: "flex",
                      margin: "0 auto", transition: "all 0.5s ease",
                    }}
                    height={20}
                    width={70}
                  />
                  :
                  All_gameData?.game_ReleaseDate}
              </h5>
            </div>
            <div >
              <header style={{
                paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end',
                textAlign: 'right', width: '100%', padding: '0.5rem'
              }}>
                <h2 style={{
                  fontSize: '1.15rem',
                  color: darkMode === 'light' ? '#000' : '#ffffff',
                  borderRadius: '0.2rem'
                }}
                >
                  {CurrentLanguage === 'EN' ? 'game Story :' : 'قصة اللعبة : '}
                </h2>
              </header>

              <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center', transition: '0.3s ease-in-out', }}>

                <h4 style={
                  {
                    fontSize: '0.95rem',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    textAlign: 'right',
                    marginLeft: '0.5rem',
                    marginRight: '0.5rem',
                    height: '100%',
                    paddingTop: '0.5rem',
                    color: darkMode === 'light' ? '#000' : '#ffffff',
                    opacity: '0.80',
                    borderRadius: '0.2rem',
                    whiteSpace: 'pre-line',
                    transition: '0.3s ease-in-out',
                  }}
                  // allow the multi line text
                  aria-multiline="true"
                  // allow the text to be read by the screen reader
                  aria-label="game Description"
                >
                  {show_everything === false ?
                    <Skeleton
                      style={{
                        width: '100%', transition: "all 0.5s ease",
                      }}
                      height={100}
                      width={200}
                    />
                    :
                    game_Description_Show
                      ? processDescription(All_gameData?.game_Description)
                      : All_gameData?.game_Description?.length > 1100
                        ? processDescription(All_gameData?.game_Description?.slice(0, 1100)) // Truncate to 1100 characters first
                          .concat('...') // Add the ellipsis after truncating
                        : processDescription(All_gameData?.game_Description)

                  }
                </h4>

                {All_gameData?.game_Description?.length > 1100 &&
                  // a button to show the rest of the description
                  <Button
                    onClick={() => setGame_Description_Show(!game_Description_Show)}
                    className='currentgames-Downloadbtn'
                    style={
                      {
                        fontSize: '1rem', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginLeft: '0.5rem', marginRight: '0.5rem',
                        height: '100%', width: '40%', paddingTop: '0.01rem', paddingBottom: '0.01rem', color: 'white', opacity: '0.7',
                        borderRadius: '0.5rem', transition: '0.3s ease-in-out',
                        backgroundColor: '#2F2F2FA2', border: '1px solid #2F2F2FA2', boxShadow: '0 0 20px 0 #2F2F2FA2'
                      }
                    }>
                    <span style={{ textDecoration: 'none', color: 'white', transition: '0.3s ease-in-out' }}>
                      {CurrentLanguage === 'EN' ? game_Description_Show ? 'Show Less' : 'Show More' : game_Description_Show ? 'عرض أقل' : 'عرض المزيد'}
                    </span>
                  </Button>
                }
              </div>

            </div>
          </div>

          {isNonMobile &&
            <CommentSection
              game={current_game_Details}
            />}

        </div>

        {/** the box on the left */}
        <div style={{ width: '98%', }}>
          <div
            size={12}
            xs={6}
            sm={6}
            md={6}
            lg={4}
            xl={3}
            className={isNonMobile ? 'currentgames-leftbx' : 'currentgames-leftbx-Mobile'}
            style={{
              direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
              boxShadow: box_isHovered === true ? '0 0 20px 0 #42297c'
                : darkMode === 'light'
                  ? '0 0 20px 0 #14141488'
                  : '0 0 20px 0 #e2dada1a',
              transform: isMobile_TooSmall ? 'scale(0.96)' : null,
            }}
            onMouseEnter={() => setBox_isHovered(true)}
            onMouseLeave={() => setBox_isHovered(false)}
          >

            <div style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',
              alignItems: 'flex-start', textAlign: 'right', width: '100%', padding: '0.5rem'
            }}
            >

              <header style={{
                paddingRight: '30px', paddingTop: '50px', display: 'flex',
                flexDirection: 'row', justifyContent: 'space-between',
                alignItems: 'flex-end', textAlign: 'right', width: '100%', padding: '0.5rem'
              }}
              >
                <h2
                  style={{
                    fontSize: '1.15rem',
                    color: darkMode === 'light' ? '#000000' : '#ffffff',
                    borderRadius: '0.2rem'
                  }}
                >{CurrentLanguage === 'EN' ? 'Download game :' : 'تحميل اللعبة :'}</h2>

              </header>
              <div style={{
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%', padding: '0.5rem',
              }}>
                {show_everything === false ?
                  <Skeleton
                    className='currentgames-Downloadbtn'
                    style={{
                      width: '100%',
                      transition: "all 0.5s ease",
                    }}
                    height={40}
                    width={100}
                  />
                  :
                  <Button
                    disabled={Download_DirectLink_Start || All_gameData?.game_Download_Link?.length <= 0 ? true : false}
                    onClick={() => handleDownloadDirectLink()}
                    className='currentgames-Downloadbtn'
                    style={
                      {
                        fontSize: '1rem', justifyContent: 'flex-start', alignItems: 'flex-end', textAlign: 'right', marginLeft: '0.5rem', marginRight: '0.5rem',
                        height: '100%', paddingTop: '0.5rem', paddingBottom: '0.5rem', color: 'white',
                        opacity: current_game_Details?.game_Download_Link?.length <= 0 ? '0.65' : '1',
                        borderRadius: '0.5rem',
                        transition: '0.3s ease-in-out',
                        backgroundColor: Download_DirectLink_Start || All_gameData?.game_Download_Link?.length <= 0 ? '#3170C285' : '#3170C2',
                        border: '1px solid #2f2f2f',
                        boxShadow: Download_DirectLink_Start || All_gameData?.game_Download_Link?.length <= 0
                          ? '0 0 20px 0 #3170C285' : '0 0 20px 0 #3170C2'
                      }
                    }>
                    <LazyLoadImage
                      effect="blur"
                      draggable={false}
                      src={current_game_Details?.game_Download_Link?.length <= 0
                        ? WrongMark
                        : Download_DirectLink_Start ? Download_Loading : dowloadIcon}
                      alt='Download_DirectLink_Start'
                      onContextMenu={(e) => e.preventDefault()}
                      style={{ width: '1.2rem', height: '1.2rem', marginLeft: '0.5rem', transition: '0.3s ease-in-out' }} />
                    <span
                      style={{ textDecoration: 'none', color: 'white', transition: '0.3s ease-in-out' }}>
                      {Download_DirectLink_Start ? CurrentLanguage === 'EN'
                        ? 'getting the link'
                        : 'جاري جذب الرابط'
                        :
                        CurrentLanguage === 'EN'
                          ? ' Direct Link'
                          : 'تحميل برابط مباشر'
                      }
                    </span>
                  </Button>
                }

                {show_everything === false ?
                  <Skeleton
                    className='currentgames-Downloadbtn'
                    style={{
                      width: '100%',
                      transition: "all 0.5s ease",
                    }}
                    height={40}
                    width={100}
                  />
                  :
                  <Button
                    disabled={Download_TorrentLink_Start || All_gameData?.game_Download_Torrent_Link?.length <= 0 ? true : false}
                    onClick={() => handleDownloadTorrentLink()}
                    className='currentgames-Downloadbtn'
                    style={
                      {
                        fontSize: '1rem', justifyContent: 'flex-start', alignItems: 'flex-end', textAlign: 'right', marginLeft: '0.5rem', marginRight: '0.5rem',
                        height: '100%', paddingTop: '0.5rem', paddingBottom: '0.5rem', color: 'white',
                        opacity: All_gameData?.game_Download_Torrent_Link?.length <= 0 ? '0.65' : '1',
                        borderRadius: '0.5rem',
                        transition: '0.3s ease-in-out',
                        backgroundColor: Download_TorrentLink_Start || All_gameData?.game_Download_Torrent_Link?.length <= 0 ? '#1E7442A8' : '#1E7442',
                        border: '1px solid #2f2f2f',
                        boxShadow: Download_TorrentLink_Start || All_gameData?.game_Download_Torrent_Link?.length <= 0
                          ? '0 0 20px 0 #1E7442A8' : '0 0 20px 0 #1E7442'
                      }
                    }>
                    <LazyLoadImage
                      effect="blur"
                      draggable={false}
                      src={All_gameData?.game_Download_Torrent_Link?.length <= 0
                        ? WrongMark
                        :
                        Download_TorrentLink_Start ? Download_Loading : dowloadIcon}
                      alt='Download_TorrentLink_Start'
                      onContextMenu={(e) => e.preventDefault()}
                      style={{ width: '1.2rem', height: '1.2rem', marginLeft: '0.5rem', transition: '0.3s ease-in-out' }} />
                    <span
                      style={{ textDecoration: 'none', color: 'white', transition: '0.3s ease-in-out' }}>
                      {Download_TorrentLink_Start ? CurrentLanguage === 'EN'
                        ? 'getting the torrent'
                        : 'جاري جذب التورنت'
                        :
                        CurrentLanguage === 'EN'
                          ? 'Torrent Link'
                          : 'تحميل تورنت'
                      }
                    </span>
                  </Button>
                }
              </div>
              <br />

              <span
                style={{
                  color: darkMode === 'light' ? '#000000' : '#ffffff',
                }}
              >
                {CurrentLanguage === 'EN'
                  ? 'Need a torrent app ? '
                  : ' ما عندك برنامج التورنت ؟'
                }
                <a
                  href='https://www.utorrent.com/downloads/complete/track/stable/os/win/'
                  target='_self' rel="noopener noreferrer"
                  style={{ color: '#2E79CE', marginRight: '10px' }}>
                  uTorrent
                </a>
                <span
                  style={{
                    color: darkMode === 'light' ? '#000000' : '#ffffff',
                    fontSize: '0.9rem',
                    marginTop: '10px'
                  }}
                >
                  {CurrentLanguage === 'EN'
                    ? ' or '
                    : ' أو '
                  }
                  <a
                    href='https://www.fosshub.com/qBittorrent.html#'
                    target='_blank' rel="noopener noreferrer"
                    style={{ color: '#2E79CE', marginRight: '10px' }}>
                    qbittorrent
                  </a>
                </span>
              </span>
            </div>

            <div style={{
              color: darkMode === 'light' ? '#000000' : '#ffffff',
              marginTop: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              textAlign: 'right',
              width: '100%',
              padding: '0.2rem'
            }}
            >

              {show_everything === false ?
                <Skeleton
                  style={{
                    width: '100%', justifyContent: "center", alignItems: "center", display: "flex",
                    alignItems: 'flex-start', textAlign: 'right', marginLeft: '0.5rem', marginRight: '0.5rem',
                    transition: "all 0.5s ease",
                  }}
                  height={20}
                  width={70}
                />
                :
                <h4 style={{
                  fontSize: '1.04rem',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  textAlign: 'right',
                  marginLeft: '0.5rem',
                  marginRight: '0.5rem',
                  height: '100%',
                  paddingBottom: '0.2rem',
                  opacity: '0.85',
                  borderRadius: '0.2rem'
                }}
                >
                  <LazyLoadImage
                    effect="blur"
                    draggable={false}
                    src={All_gameData?.game_Price?.startsWith('مجانية') ? CheckMark : WarningMark}
                    alt='game_Price'
                    onContextMenu={(e) => e.preventDefault()}
                    style={{
                      width: '1.2rem', height: '1.2rem',
                      marginLeft: CurrentLanguage === 'EN' ? '' : '0.5rem', marginRight: CurrentLanguage === 'EN' ? '0.5rem' : ''
                    }} />
                  {All_gameData?.game_Price?.startsWith('مجانية') ? CurrentLanguage === 'EN' ? 'Free' : 'مجانية' : All_gameData?.game_Price}
                </h4>
              }
              {show_everything === false ?
                <Skeleton
                  style={{
                    width: '100%', justifyContent: "center", alignItems: "center", display: "flex",
                    alignItems: 'flex-start', textAlign: 'right', marginLeft: '0.5rem', marginRight: '0.5rem',
                    transition: "all 0.5s ease",
                  }}
                  height={20}
                  width={70}
                />
                :
                <h4 style={{
                  fontSize: '1.04rem',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  textAlign: 'right',
                  marginLeft: '0.5rem',
                  marginRight: '0.5rem',
                  height: '100%',
                  paddingBottom: '0.2rem',
                  opacity: '0.85',
                  borderRadius: '0.2rem'
                }}
                >
                  <LazyLoadImage
                    effect="blur"
                    draggable={false}
                    src={CheckMark}
                    alt='CheckMark'
                    onContextMenu={(e) => e.preventDefault()}
                    style={{
                      width: '1.2rem', height: '1.2rem', marginLeft: CurrentLanguage === 'EN' ? '' : '0.5rem',
                      marginRight: CurrentLanguage === 'EN' ? '0.5rem' : ''
                    }} />

                  {CurrentLanguage === 'EN' ? 'Full game' : 'اللعبة كاملة'}
                </h4>
              }
              {show_everything === false ?
                <Skeleton
                  style={{
                    width: '100%', justifyContent: "center", alignItems: "center", display: "flex",
                    alignItems: 'flex-start', textAlign: 'right', marginLeft: '0.5rem', marginRight: '0.5rem',
                    transition: "all 0.5s ease",
                  }}
                  height={20}
                  width={70}
                />
                :
                <h4 style={
                  {
                    fontSize: '1.04rem', justifyContent: 'flex-start', alignItems: 'flex-start', textAlign: 'right', marginLeft: '0.5rem', marginRight: '0.5rem',
                    height: '100%', paddingBottom: '0.5rem', opacity: '0.85', borderRadius: '0.2rem'
                  }
                }>
                  {All_gameData?.game_AntiVirus?.startsWith('خالية' || ' خالية' || 'خالية ') ?
                    <div>
                      <LazyLoadImage
                        effect="blur"
                        draggable={false}
                        src={CheckMark}
                        alt='CheckMark'
                        onContextMenu={(e) => e.preventDefault()}
                        style={{
                          width: '1.2rem', height: '1.2rem', marginLeft: CurrentLanguage === 'EN' ? '' : '0.5rem',
                          marginRight: CurrentLanguage === 'EN' ? '0.5rem' : ''
                        }} />
                      <span>{All_gameData?.game_AntiVirus?.startsWith('خالية' || ' خالية' || 'خالية ') ? CurrentLanguage === 'EN'
                        ? 'free of viruses'
                        : All_gameData?.game_AntiVirus
                        : All_gameData?.game_AntiVirus
                      }
                      </span>
                    </div>
                    : All_gameData?.game_AntiVirus?.startsWith('احتمال' || 'مشكوك' || 'يمكن' || 'محتمل') ?
                      <div>
                        <LazyLoadImage
                          effect="blur"
                          draggable={false}
                          src={WarningMark}
                          alt='WarningMark'
                          onContextMenu={(e) => e.preventDefault()}
                          style={{ width: '1.8rem', height: '1.8rem', marginLeft: '0.2rem', marginRight: '-0.2rem' }} />
                        <span>{All_gameData?.game_AntiVirus}</span>
                      </div>

                      :
                      <div>
                        <LazyLoadImage
                          effect="blur"
                          draggable={false}
                          src={WrongMark}
                          alt='WrongMark'
                          onContextMenu={(e) => e.preventDefault()}
                          style={{ width: '1.2rem', height: '1.2rem', marginLeft: '0.5rem' }} />
                        <span>{All_gameData?.game_AntiVirus}</span>
                      </div>
                  }
                </h4>
              }
            </div>

            <div style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', direction: 'rtl', alignItems: 'flex-end', textAlign: 'right', width: '100%',
              padding: '0.5rem',
              color: darkMode === 'light' ? '#000000' : '#ffffff',
            }}>
              <header style={{
                paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end',
                direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                textAlign: CurrentLanguage === 'EN' ? 'left' : 'right', width: '100%', padding: '0.5rem'
              }}>
                <h2 style={{ fontSize: '1.15rem', borderRadius: '0.2rem' }}
                >{CurrentLanguage === 'EN'
                  ? 'Minimum Requirements :'
                  : 'الحد الأدنى لتشغيل اللعبة :'
                  }
                </h2>
              </header>
              {game_Requirements?.almost_anyPC ?
                <h4 style={{
                  fontSize: '0.95rem', paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                  alignItems: 'flex-end', textAlign: 'right', width: '100%', padding: '0.5rem', opacity: '0.70', borderRadius: '0.2rem'
                }}>
                  {show_everything === false ?
                    <Skeleton
                      style={{
                        width: '100%',
                        margin: "0 auto", transition: "all 0.5s ease",
                      }}
                      height={20}
                      width={100}
                    />
                    :
                    game_Requirements?.almost_anyPC}
                </h4>
                :
                <div style={{
                  display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', alignItems: 'flex-end',
                  textAlign: 'right', width: '100%'
                }}>

                  <h4 style={{
                    fontSize: '0.95rem', paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: 'row',
                    justifyContent: CurrentLanguage === 'EN' ? 'left' : 'right', alignItems: 'flex-end', textAlign: CurrentLanguage === 'EN' ? 'left' : 'right',
                    width: '100%', padding: '0.5rem', opacity: '0.70', borderRadius: '0.2rem'
                  }}>
                    {CurrentLanguage === 'EN' ? 'CPU : ' : 'المعالج : '}
                    <span
                      style={{
                        textAlign: 'right', justifyContent: 'right', alignItems: 'flex-end', direction: 'ltr', marginRight: CurrentLanguage === 'EN' ? '' : '3px',
                        marginLeft: CurrentLanguage === 'EN' ? '3px' : ''
                      }}>
                      {show_everything === false ?
                        <Skeleton
                          style={{
                            width: '100%',
                            margin: "0 auto", transition: "all 0.5s ease",
                          }}
                          height={20}
                          width={100}
                        />
                        :
                        game_Requirements.CPU ? game_Requirements.CPU : CurrentLanguage === 'EN' ? 'unknown...' : 'غير معروف...'}
                    </span>
                  </h4>

                  <h4 style={{
                    fontSize: '0.95rem', paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: 'row',
                    justifyContent: CurrentLanguage === 'EN' ? 'left' : 'right', alignItems: 'flex-end', textAlign: CurrentLanguage === 'EN' ? 'left' : 'right',
                    width: '100%', padding: '0.5rem', opacity: '0.70', borderRadius: '0.2rem'
                  }}>
                    {CurrentLanguage === 'EN' ? 'GPU : ' : 'كرت الشاشة : '}
                    <span
                      style={{
                        textAlign: 'right', justifyContent: 'right', alignItems: 'flex-end', direction: 'ltr', marginRight: CurrentLanguage === 'EN' ? '' : '3px',
                        marginLeft: CurrentLanguage === 'EN' ? '3px' : ''
                      }}>
                      {show_everything === false ?
                        <Skeleton
                          style={{
                            width: '100%',
                            margin: "0 auto", transition: "all 0.5s ease",
                          }}
                          height={20}
                          width={100}
                        />
                        :
                        game_Requirements.GPU ? game_Requirements.GPU : CurrentLanguage === 'EN' ? 'unknown...' : 'غير معروف...'}
                    </span>
                  </h4>

                  <h4 style={{
                    fontSize: '0.95rem', paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: 'row',
                    justifyContent: CurrentLanguage === 'EN' ? 'left' : 'right', alignItems: 'flex-end', textAlign: CurrentLanguage === 'EN' ? 'left' : 'right',
                    width: '100%', padding: '0.5rem', opacity: '0.70', borderRadius: '0.2rem'
                  }}>
                    {CurrentLanguage === 'EN' ? 'RAM : ' : 'الرام : '}
                    <span
                      style={{
                        textAlign: 'right', justifyContent: 'right', alignItems: 'flex-end', direction: 'ltr', marginRight: CurrentLanguage === 'EN' ? '' : '3px',
                        marginLeft: CurrentLanguage === 'EN' ? '3px' : ''
                      }}>
                      {show_everything === false ?
                        <Skeleton
                          style={{
                            width: '100%',
                            margin: "0 auto", transition: "all 0.5s ease",
                          }}
                          height={20}
                          width={100}
                        />
                        :
                        game_Requirements.RAM ? game_Requirements.RAM : CurrentLanguage === 'EN' ? 'unknown...' : 'غير معروف...'}
                    </span>
                  </h4>
                </div>
              }

            </div>

          </div>

          {/* the box on the left down */}
          <div style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', direction: 'rtl', alignItems: 'flex-end', textAlign: 'right', width: '100%',
            padding: '0.5rem'
          }}>
            <header style={{
              paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end',
              direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
              textAlign: CurrentLanguage === 'EN' ? 'left' : 'right', width: '100%', padding: '0.5rem'
            }}>
              <h2 style={{
                fontSize: '1.15rem',
                color: darkMode === 'light' ? '#000' : '#ffffff',
                borderRadius: '0.2rem'
              }}
              >{CurrentLanguage === 'EN'
                ? 'Notes :'
                : 'ملاحظات :'
                }
              </h2>
            </header>
            <div style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', alignItems: 'flex-end',
              textAlign: 'right', width: '100%'
            }}>

              <h4 style={{
                fontSize: '0.95rem', paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: 'row',
                justifyContent: CurrentLanguage === 'EN' ? 'left' : 'right', alignItems: 'flex-end',
                textAlign: CurrentLanguage === 'EN' ? 'left' : 'right',
                width: '100%', padding: '0.5rem',
                color: darkMode === 'light' ? '#000' : '#ffffff',
                opacity: '0.70',
                borderRadius: '0.2rem'
              }}>
                {CurrentLanguage === 'EN'
                  ? 'Re uploaded / Modified in : '
                  : 'تم إعادة التعديل / الرفع في  : '}
                <span
                  style={{
                    textAlign: 'right', justifyContent: 'right', alignItems: 'flex-end', direction: 'ltr',
                    marginRight: CurrentLanguage === 'EN' ? '' : '3px',
                    marginLeft: CurrentLanguage === 'EN' ? '3px' : '',
                    color: darkMode === 'light' ? '#000' : '#ffffff',
                    fontSize: '1.1rem',
                    fontWeight: 'bold'
                  }}>
                  {show_everything === false ?
                    <Skeleton
                      style={{
                        width: '100%',
                        margin: "0 auto", transition: "all 0.5s ease",
                      }}
                      height={20}
                      width={100}
                    />
                    :
                    new Date(All_gameData?.game_Added_Date).toLocaleString('en-US', { timeZone: 'Asia/Riyadh' }).split(',')[0]
                  }
                </span>
              </h4>
            </div>
          </div>
        </div>

        {!isNonMobile &&
          <CommentSection
            game={current_game_Details}
          />}
      </div>

      <div
        className='Might-LikeGamesSection'
      >
        <ResizedMightLikeSection
          current_Name={All_gameData?.game_Name}
          current_Genres={All_gameData?.game_Genres}
          show_everything={show_everything}
          setShow_everything_False={() => {
            setShow_everything(false);
          }}
        />
      </div>
    </div>
  )
}
