

export const RETO_defaultSettings = [
  {
    id: 1,
    Website_Main_Logo: `${window.location.origin}/LOGO_A2G_1.png`,
    Game_SelectedFilters: [],
    start_Filter_Genres: false,
    Game_SelectedSort: '',
    App_SelectedFilters: [],
    App_SelectedSort: '',

    badWords: [
      // in arabic
      "كس",
      "كسمك",
      "كسمكم",
      "سكس",
      "زب",
      "زبي",
      "زبك",
      "زبه",
      "كسك",
      "كسه",
      "كسها",
      "طيز",
      "طيزك",
      "طيزي",
      "طيزه",
      "طيزها",
      "مكوه",
      "مكوتي",
      "مكوتك",
      "مكوته",
      "مكوتها",
      "خرقك",
      "خرقي",
      "خرقها",
      "ديود",
      "ديدي",
      "ديدك",
      "نهود",
      "نهودي",
      "نهودك",
      "ديودها",
      "ديوده",
      "نهوده",
      "نهودها",
      "مخنث",
      "مخنثه",
      "مخنثين",
      "مخنثات",
      "خنيث",
      "خنيثه",
      "منيوك",
      "منيوكه",
      "منيوكة",
      "منيوكي",
      "منيوكين",
      "منيوكات",
      "منيوكاتي",
      "منيوكاتك",
      "منيوكاته",
      "منيوكاتها",
      "شرموط",
      "شرموطه",
      "شرموطة",
      "شرموطي",
      "شرموطين",
      "شرموطات",
      "شرموطاتي",
      "شرموطاتك",
      "شرموطاته",
      "شرموطاتها",
      "شراميط",
      "شراميطي",
      "شراميطك",
      "شراميطه",
      "شراميطها",
      "شراميطات",

      // in english
      "pussy",
      "sex",
      "fuck",
      "dick",
      "penis",
      "boobs",
      "boob",
      "pie",
      "ass",
      "asshole",
      "tits",
    ]
  },
];

