import React, { useState, useEffect, useCallback } from 'react';
import useMediaQuery from "@mui/material/useMediaQuery";
import { firebase, database } from "../../xFireBase/Storage";
import ScrollToTop from '../../ScrollToTop';
import UserLoggedInIcon from '../../assets/img/icons/person-circle.svg';
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import EditProfile from '../../assets/img/icons/EditProfile.svg';
import AccountEdit from '../../components/HomeMiddle/myProfile/AccountEdit';
import { toast } from 'react-toastify';

import Preloader from "../../hooks/Others/PreLoader";
import DownloadPage from '../../components/HomeMiddle/History/DownloadPage';
import NotificationPage from '../../components/HomeMiddle/History/NotificationPage';
import { RETO_defaultSettings } from "../../hooks/Others/RETO_data";
import Page_Helmet from "../../hooks/SEO/react_Helmet";
import { useAuth } from '../../xFireBase/AuthContext';

const ProfileScreen = () => {

  const { CurrentLanguage, darkMode } = useAuth();
  const isNonMobile = useMediaQuery("(min-width:571px)");
  const [load, upadateLoad] = useState(true);
  const [showPage, setShowPage] = useState('AccountEdit');

  const [all_user_data, setAll_user_data] = useState([]);

  const [userName, setUserName] = useState("");
  const [MouseEnter_ProfilePicture, setMouseEnter_ProfilePicture] = useState(false);

  const [TheDisplayName, setTheDisplayName] = useState(null);
  const [userFullName, setUserFullName] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);


  const getUserData = useCallback(async () => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      database
        .collection("users")
        .doc(currentUser.email)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setAll_user_data(doc.data());

            setUserName(doc.data().User_UserName);
            setUserFirstName(doc.data().User_FirstName.length > 0 && doc.data().User_FirstName !== '' && doc.data().User_FirstName !== null && doc.data().User_FirstName !== ' '
              && !doc.data().User_FirstName.startsWith(' ') && doc.data().User_FirstName ? doc.data().User_FirstName : null);

            setUserLastName(doc.data().User_LastName.length > 0 && doc.data().User_LastName !== '' && doc.data().User_LastName !== null && doc.data().User_LastName !== ' '
              && !doc.data().User_LastName.startsWith(' ') && doc.data().User_LastName ? doc.data().User_LastName : null);
          }
        })
        .then(() => {
          userFirstName && userLastName ? setUserFullName(userFirstName + " " + userLastName) : setUserFullName(null);
        })
    }
  }, [userFirstName, userLastName]);

  // check only once if the user have a profile picture or not
  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const Submit_Change_DisplayName = async (values) => {
    // notify the user that the display name has been changed
    const notify = () => toast.success(
      CurrentLanguage === 'EN'
        ? `Changed ! The page will be refreshed after 3 seconds`
        : `تم التغيير  ! سيتم تحديث الصفحة بعد 3 ثواني`);
    notify();

    setTimeout(() => {
      window.location.reload();
    }, 2300);
  }

  const ShowError = async (ErrorMessage, ERROR) => {
    // notify the user that the image type is not accepted
    const notify = () => toast.error(ErrorMessage);
    notify();
  }


  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [])


  return (
    <section className={darkMode === 'light' ? 'myProfileSection light' : "myProfileSection dark"} >
      <Page_Helmet
        title="Arab2Games | Profile"
        type="article"
        description="صفحة الحساب الشخصي للمستخدم"
        image={RETO_defaultSettings[0].Website_Main_Logo}
        url={window.location.href ? window.location.href : "https://Arab2Games.com/"}
        keywords="A2G, Arab2Games, Arab2Game, arab2games, arab2game, عرب تو قيمز, عرب تو قيمنق, عرب 2 قيمز, apps, games, discord bots, arab2games, Arab2Games, Arab2Games.com, arab2games.com, arab2games.org, Arab2Games.org, arab2games.net, Arab2Games.net, arab2games.io, Arab2Games.io, arab2games.dev, Arab2Games.dev, crack, cracked, crack game, cracked game, crack games, cracked games, crack app, cracked app, crack apps, cracked apps, مكركة, كراك, العاب مكركة, مكركه, العاب مكركه, برامج مكركه, برامج مكركة, لعبة مكركة, لعبه مكركه, برنامج مكرك, العاب, لعبة, لعبه, برنامج, برامح, ريتو, لايك, لايك ريتو, arab2games, Arab2Games, ARAB2GAMES, Arab2Games, Arab2Games"

      />
      <ScrollToTop />
      <Preloader load={load} darkMode={darkMode} />
      <Container>
        <Row>
          <Col size={12}>
            <div>

              <div style={{
                direction: 'rtl',
                width: '100%',
                height: '100%',
                border: 'none',
                outline: 'none',
                fontSize: '1.2rem',
                textAlign: 'center',
                justifyContent: 'right',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                transition: 'all 0.3s ease-in-out',
              }}>
                <div
                  onMouseEnter={() => setMouseEnter_ProfilePicture(true)}
                  onMouseLeave={() => setMouseEnter_ProfilePicture(false)}
                  onClick={() => {
                    // open the input type file to change the profile picture, then update the profile picture and save it to the database and photoURL
                    const input = document.createElement('input');
                    input.type = 'file';
                    // only accept image types: png, jpg, jpeg,
                    input.accept = 'image/png, image/jpg, image/jpeg';
                    // only accept one file
                    input.multiple = false;
                    // only accept max size of 330kb
                    input.maxSize = 330000;
                    // when the user select a file
                    input.onchange = (e) => {
                      // check if the user selected a file or not, and if it one of the accepted types: png, jpg, jpeg
                      // also check if the file size is less than 600kb or not, also accept only one file
                      if (!e.target.files || e.target.files.length === 0 || !e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)
                        || e.target.files[0].size > 330000 || e.target.files.length > 1) {
                        if (!e.target.files || e.target.files.length === 0) {
                          CurrentLanguage === 'EN'
                            ? ShowError('Please select an image', true)
                            : ShowError('الرجاء اختيار صورة', true);
                        }
                        else if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
                          CurrentLanguage === 'EN'
                            ? ShowError('Please select an image of type \n png, jpg, jpeg', true)
                            : ShowError('الرجاء اختيار صورة من نوع \n png, jpg, jpeg', true);
                        }
                        else if (e.target.files[0].size > 330000) {
                          CurrentLanguage === 'EN'
                            ? ShowError('Please select an image less than 330kb', true)
                            : ShowError('الرجاء اختيار صورة اقل من 330 كيلوبايت', true);
                        }
                        else if (e.target.files.length > 1) {
                          CurrentLanguage === 'EN'
                            ? ShowError('Please select only one image', true)
                            : ShowError('الرجاء اختيار صورة واحدة فقط', true);
                        }
                      }
                      else {
                        const file = e.target.files[0];
                        const storageRef =
                          // a folder in the storage called user_profile_pictures and create a new folder with the user uid and save the profile picture in it, also change the file name to the user uid
                          firebase.storage().ref(`user_profile_pictures/${firebase.auth().currentUser.uid}/${firebase.auth().currentUser.uid}`)
                        storageRef.put(file).then(() => {
                          storageRef.getDownloadURL().then((url) => {
                            firebase.auth().currentUser.updateProfile({
                              photoURL: url
                            })
                              .then(() => {
                                database
                                  .collection("users")
                                  .doc(firebase.auth().currentUser.email)
                                  .update({
                                    User_Profile_Picture: url
                                  })
                                  .then(() => {
                                    // notify the user that the profile picture has been changed
                                    const notify = () => toast.success(
                                      CurrentLanguage === 'EN'
                                        ? `Picture Changed ! The page will be refreshed after 3 seconds`
                                        : `تم تغيير الصورة بنجاح ! سيتم تحديث الصفحة بعد 3 ثواني`);
                                    notify();
                                    setTimeout(() => {
                                      window.location.reload();
                                    }, 2300);
                                  })
                              })
                          })
                        })
                      }
                    }
                    input.click();
                  }}
                  style={{
                    width: '118px', height: '118px', borderRadius: '43%', cursor: 'pointer',
                    opacity: MouseEnter_ProfilePicture ? '0.5' : null, transition: 'all 0.3s ease-in-out'
                  }}>
                  <img
                    loading='lazy'
                    draggable={false}
                    src={EditProfile}
                    alt="Edit"
                    onContextMenu={(e) => e.preventDefault()}
                    style={{ backgroundColor: '#046DCF', borderRadius: '50%', position: 'absolute', width: '20px', height: '20px', marginTop: '92px', marginRight: '92px' }} />

                  <img
                    loading='lazy'
                    draggable={false}
                    src={all_user_data.User_Profile_Picture !== ''
                      ? all_user_data.User_Profile_Picture
                      : UserLoggedInIcon}
                    alt="user"
                    style={{ width: '116px', height: '116px', marginTop: '1px', borderRadius: '50%', backgroundColor: '#3F3F3F' }} />

                </div>

                <span
                  style={{
                    marginRight: '10px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    color: darkMode === 'light' ? '#000000' : null
                  }}
                >
                  <h3>{firebase.auth().currentUser.displayName}</h3>
                  <p style={{ 
                    marginTop: '-5px', 
                    marginRight: '10px',
                    color: darkMode === 'light' ? '#000000B9' : null
                    }}
                    >
                      {userName}@
                    </p>

                </span>

              </div>
              <div style={{
                direction: 'rtl',
                width: '100%',
                marginBottom: '20px',
                height: '100%',
                border: 'none',
                outline: 'none',
                fontSize: '1.2rem',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                color: darkMode === 'light' ? '#000000' : null
              }}>
                <h5>
                  {CurrentLanguage === 'EN'
                    ? ': Display Name'
                    : 'اسم العرض :'}
                </h5>
                <div style={{ color: '#ffffff', flexDirection: 'row', display: 'flex' }}>
                  <select
                    value={TheDisplayName}
                    onChange={(e) => {
                      if (e.target.value === userName) {
                        setTheDisplayName('userName');
                        firebase.auth().currentUser.updateProfile({
                          displayName: e.target.value
                        })
                        database
                          .collection("users")
                          .doc(firebase.auth().currentUser.email)
                          .update({
                            userDisplayName: 'userName'
                          })
                          .then(() => {
                            Submit_Change_DisplayName();
                          })

                      }
                      else if (e.target.value === userFullName) {
                        setTheDisplayName('FullName');
                        firebase.auth().currentUser.updateProfile({
                          displayName: e.target.value
                        })
                        database
                          .collection("users")
                          .doc(firebase.auth().currentUser.email)
                          .update({
                            userDisplayName: 'FullName'
                          })
                          .then(() => {
                            Submit_Change_DisplayName();
                          })
                      }
                    }}
                    style={{
                      color: darkMode === 'light' ? '#ffffff' : '#cccccc', 
                      width: '100%', 
                      height: '100%', 
                      border: 'none', 
                      outline: 'none', 
                      fontSize: '1.2rem', textAlign: 'center', 
                      justifyContent: 'center', alignItems: 'center',
                      display: 'flex', 
                      flexDirection: 'row', 
                      backgroundColor: darkMode === 'light' ? '#252525' : '#4A4B4B', 
                      borderRadius: '10px',
                      marginBottom: '20px'
                    }}>
                    <option selected disabled >{
                      CurrentLanguage === 'EN'
                        ? 'Choose a display name'
                        : 'اختر اسم العرض'
                    }
                    </option>
                    <option value={userName}>{userName}</option>
                    {userFullName === null || userFullName === '' || userFullName === 'null' || userFullName === undefined || userFullName === 'undefined'
                      ? <option>{
                        CurrentLanguage === 'EN'
                          ? 'No first name / last name'
                          : 'لا يوجد اسم اول / اسم اخير'
                      }</option>
                      : <option value={userFullName}>{userFullName}</option>
                    }
                  </select>
                </div>

              </div>

              <br />
              <br />
              <br />
              <Tab.Container id="" defaultActiveKey="first" >
              </Tab.Container>
              <Tab.Container id={isNonMobile ? "myProfileSection-tabs" : ''} defaultActiveKey="first" >
                <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab" style={{
                  transform: 'scale(0.9)', width: '110%', height: '110%', marginLeft: '-5%', marginRight: '-5%'
                }}>
                  <Nav.Item onClick={() => setShowPage('AccountEdit')}>
                    <Nav.Link eventKey="first">
                      {
                        CurrentLanguage === 'EN'
                          ? 'Account Settings'
                          : 'إدارة الحساب'
                      }
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item onClick={() => setShowPage('DownloadsHistory')}>
                    <Nav.Link eventKey="second">
                      {
                        CurrentLanguage === 'EN'
                          ? 'Downloads History'
                          : 'سجل التحميلات'
                      }
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item onClick={() => setShowPage('NotificationHistory')}>
                    <Nav.Link eventKey="third">
                      {
                        CurrentLanguage === 'EN'
                          ? 'Notification History'
                          : 'سجل الإشعارات'
                      }
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item onClick={() => setShowPage('WatchLater')}>
                    <Nav.Link eventKey="fourth">
                      {
                        CurrentLanguage === 'EN'
                          ? 'Watch Later'
                          : 'قائمة المشاهدة لاحقاً'
                      }
                    </Nav.Link>
                  </Nav.Item>

                </Nav>
                <Tab.Content id="slideInUp" >
                  <Tab.Pane eventKey="first">
                    <Row>
                      {showPage === 'AccountEdit' ?
                        <AccountEdit />
                        : null
                      }
                    </Row>
                  </Tab.Pane>

                  <Tab.Pane eventKey="second">
                    <Row>
                      {showPage === 'DownloadsHistory' ?
                        <DownloadPage />
                        : null
                      }
                    </Row>
                  </Tab.Pane>

                  <Tab.Pane eventKey="third">
                    <Row>
                      {showPage === 'NotificationHistory' ?
                        <NotificationPage />
                        : null
                      }
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fourth">
                    <Row>
                      {showPage === 'WatchLater' ?
                        <h1>الصفحة غير موجودة</h1>
                        : null
                      }
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}


export default ProfileScreen;

/**
                   <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">BO1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">BO2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">MW2</Nav.Link>
                    </Nav.Item>
                    <h5 style={{
                      color: '#ffffff',
                      fontSize: '20px',
                      fontWeight: 'bold',
                      fontFamily: 'sans-serif',
                      marginLeft: '10px',
                      marginRight: '10px',

                    }}>   : ترتيب حسب </h5>

                  </Nav>
 */