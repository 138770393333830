import React, { useState, useEffect, useMemo } from "react";
import { Col } from "react-bootstrap";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { database } from "../../xFireBase/Storage";
import { useAuth } from "../../xFireBase/AuthContext";

const BannerNews = () => {
  const { CurrentLanguage, darkMode } = useAuth();
  const isNonMobile = useMediaQuery("(min-width:1200px)");
  const [StartWriter, setStartWriter] = useState(false);
  const [toRotate, setToRotate] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setStartWriter(true);
    }, 1200);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchBreakNews = async () => {
      const docRef = database
        .collection('users')
        .doc('@important')
        .collection('more-details')
        .doc('!important');
      
      const doc = await docRef.get();

      if (doc.exists) {
        const data = CurrentLanguage === 'EN' ? doc.data().BreakNews_EN : doc.data().BreakNews;
        setToRotate(data);
      }
    };
    
    fetchBreakNews();
  }, [CurrentLanguage]);

  const BreakNewsArray = useMemo(() => {
    return toRotate.slice(0, 5).map((item, index) => ({
      [`BreakNews${index + 1}`]: item || '',
    }));
  }, [toRotate]);

  const words = useMemo(() => {
    return BreakNewsArray.map(item => Object.values(item).join('')).filter(Boolean);
  }, [BreakNewsArray]);

  const [currentText] = useTypewriter({
    words: StartWriter ? (words.length > 0 ? words : ['']) : [''],
    loop: true,
    delaySpeed: 1000,
    typeSpeed: 40
  });

  return (
    <div className="BreakNews" id="home">
      <div className="container">
        <Col xs={12} md={6} xl={7}>

              <div
                style={{
                  direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                  textAlign: 'right',
                  marginTop: '40px',
                  alignItems: 'right',
                  justifyContent: 'right',
                  flexDirection: 'row',
                  width: isNonMobile ? '170%' : '100%',
                  display: 'inline-block',
                }}
              >
                <div 
                className="Home_Words"
                style={{
                  background: darkMode === 'light' ? '#FFFFFFB9' : '#0000004d'
                }}
                >
                  <span 
                  className="tagline"
                  style={{
                    background: darkMode === 'light' ? '#BB1D2DDC' : '#bb1d2da1',
                    color: darkMode === 'light' ? '#000000' :'#ffffff',
                    fontWeight: 'bold',
                  }}
                  >
                    {CurrentLanguage === 'EN' ? 'News' : 'اَخر الأخبار : '}
                  </span>
                  <span
                    id='TypeWriter'
                    style={{
                      display: 'inline-flex',
                      fontSize: '15px',
                      fontWeight: 'bold',
                      color: darkMode === 'light' ? '#000000' :'#ffffff',
                      marginTop: '4px',
                    }}
                  >
                    {currentText}
                  </span>
                  <Cursor />
                </div>
              </div>
        </Col>
      </div>
    </div>
  );
};

export default BannerNews;
