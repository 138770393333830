import React, { useState, useEffect, useRef } from 'react';
import useMediaQuery from "@mui/material/useMediaQuery";
import { toast } from 'react-toastify';
import { Button } from "react-bootstrap";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Modal, VideoPlayer } from '../../hooks/Modals/GamePlayVideo/GamePlayVideo';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import StarSolid from '../../assets/img/icons/star-solid.svg';
import dowloadIcon from '../../assets/img/icons/download.svg';
import DiscordIcon from '../../assets/img/icons/discord.svg';
import InstagramIcon from '../../assets/img/icons/instagram.svg';
import CopyLinkIcon from '../../assets/img/icons/copy-icon.svg';
import CheckMark from '../../assets/img/icons/CheckMark.svg';
import play_video from '../../assets/img/icons/youtube.svg';
import Download_Loading from '../../assets/img/icons/Profile_Loading.svg';
import App_MightLikeSection from '../../hooks/currentAppMightLikeSection/App_MightLikeSection';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  FaDownload,
  FaEye,
} from 'react-icons/fa';
import TrackVisibility from 'react-on-screen';
import { useAuth } from '../../xFireBase/AuthContext';
import WarningMark from '../../assets/img/icons/WarningMark.gif';
import WrongMark from '../../assets/img/icons/wrongMark.gif';



const ResizedMightLikeSection = React.memo(App_MightLikeSection);

export const CurrentAppCard = ({
  key, All_appData, app_Page,
}) => {



  const { CurrentLanguage, User_OS_Platform, User_IP_Address, app_Downloaded_Times_updater, app_watched_times_updater } = useAuth();


  const isNonMobile = useMediaQuery("(min-width:1200px)");
  const isMobile_TooSmall = useMediaQuery("(max-width: 420px)");

  const [app_Reviews, setApp_Reviews] = useState([]);
  const [app_Requirements, setApp_Requirements] = useState([]);

  const [app_PlayButtonClicked, setApp_PlayButtonClicked] = useState(false);

  const appLink = `${window.location.href}`;

  const [Download_DirectLink_Start, setDownload_DirectLink_Start] = useState(false);
  const [Download_TorrentLink_Start, setDownload_TorrentLink_Start] = useState(false);

  const [isViewed, setIsViewed] = useState(false);

  useEffect(() => {
    const axiosFetchData = () => {

      setTimeout(() => {
        if (All_appData.app_Page === app_Page) {
          setApp_Reviews(All_appData.app_Reviews || []);
          setApp_Requirements(All_appData.app_Requirements || []);
        }

      }, 200);
    };

    axiosFetchData();

  }, []);


  const handleView = () => {
    setIsViewed(true);
  };

  // handle the copy app link button
  const handleCopyLink = () => {
    const notify = () => toast.success(CurrentLanguage === 'EN' ? "App link copied" : "تم نسخ رابط البرنامج");
    // notify the user that the link has been copied
    notify();
  };


  // update the app watch times
  const handle_app_watched_times_updater = async () => {
    try {
      await app_watched_times_updater({ All_appData, User_IP_Address })
    }
    catch (error) {
    }
  }


  // update the app downloaded times
  const handle_app_Downloaded_Times_updater = async () => {
    try {
      await app_Downloaded_Times_updater(All_appData)
    }
    catch (error) {
    }
  }

  // handle the download button for direct link 
  const handleDownloadDirectLink = async () => {

    try {
      if (User_OS_Platform === 'Desktop') {
        setDownload_DirectLink_Start(true);
        await handle_app_Downloaded_Times_updater();
      }
      else if (User_OS_Platform === 'Mobile') {
        toast.error('لا يمكنك تحميل البرنامج من هاتفك');
      }
      setDownload_DirectLink_Start(false);
    }
    catch (error) {
      toast.error('❌ حدث خطأ أثناء جذب رابط اذمباشر');
      setDownload_DirectLink_Start(false);
    }
  }

  // handle the download button for torrent link
  const handleDownloadTorrentLink = async () => {
    try {
      if (User_OS_Platform === 'Desktop') {
        setDownload_TorrentLink_Start(true);
        await handle_app_Downloaded_Times_updater();
      }
      else if (User_OS_Platform === 'Mobile') {
        toast.error('لا يمكنك تحميل البرنامج من هاتفك');
      }
      setDownload_TorrentLink_Start(false);
    }
    catch (error) {
      toast.error('❌ حدث خطأ أثناء جذب التورنت');
      setDownload_TorrentLink_Start(false);
    }
  }


  useEffect(() => {
    if (isViewed) {
      const watchedBy_DB = All_appData?.app_watched_times?.watched_By
        ? All_appData?.app_watched_times?.watched_By : null; // use empty array if app_watched_times is undefined/null 

      if (watchedBy_DB !== null && watchedBy_DB !== undefined) {

        const watchedBy = watchedBy_DB;

        if (User_IP_Address.length > 0) {
          if (!watchedBy.includes(User_IP_Address)) {
            watchedBy.push(User_IP_Address);
            setTimeout(() => {
              handle_app_watched_times_updater();
            }, 500);
          }
        }

      }

      setTimeout(() => {
        setIsViewed(false);
      }, 1500);

    }

  }, [isViewed, All_appData?.app_watched_times?.watched_By, User_IP_Address, handle_app_watched_times_updater]);



  let AppPlayVideoRef = useRef();
  useEffect(() => {
    let AppPlayVideo = (event) => {
      if (AppPlayVideoRef.current && !AppPlayVideoRef.current.contains(event.target)) {
        setApp_PlayButtonClicked(false);
      }
    }
    document.addEventListener("mousedown", AppPlayVideo);
  }, []);


  return (
    <div key={key}>

      <div ref={AppPlayVideoRef}>
        {app_PlayButtonClicked &&
          <Modal show={app_PlayButtonClicked} handleClose={() => setApp_PlayButtonClicked(false)}>
            <VideoPlayer video_Trailer_Link={All_appData.app_Trailer_Link} handleClose={() => setApp_PlayButtonClicked(false)} />
          </Modal>
        }
      </div>
      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
        alignItems: 'center', width: isNonMobile ? '' : '200%', height: '100%', borderRadius: '0.2rem'
      }}>
        <TrackVisibility once>
          {({ isVisible }) => isVisible ?
            <div>
              {isVisible && handleView()}
              <h3>
                {All_appData.app_Name}
              </h3>
            </div>
            :
            <h3>
              {All_appData.app_Name}
            </h3>
          }
        </TrackVisibility>
      </div>

      <div style={{
        justifyContent: 'space-between', padding: '0.5rem', borderRadius: '0.2rem',
        alignItems: 'center', width: isNonMobile ? '' : '115%', height: '100%',
      }}>

        <div style={{
          textAlign: 'right', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', display: 'flex', flexDirection: 'row',
          width: isNonMobile ? '' : '200%', height: '100%', borderRadius: '0.2rem', paddingBottom: '10px', paddingTop: '20px'
        }}>

          <h5 style={{
            textAlign: 'right', direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr', fontSize: '1.1rem',
            marginRight: CurrentLanguage === 'EN' ? '15px' : '', marginLeft: CurrentLanguage === 'EN' ? '' : '15px'
          }}>
            {app_Reviews > 0 ? app_Reviews : '0'}
            <LazyLoadImage
              effect="blur"
              alt='StarSolid'
              draggable={false}
              src={StarSolid}
              onContextMenu={(e) => e.preventDefault()}
              style={{
                width: '20px', height: '20px', marginRight: CurrentLanguage === 'EN' ? '5px' : '',
                marginLeft: CurrentLanguage === 'EN' ? '' : '5px', marginTop: '-4px'
              }} />
          </h5>

          <h5 style={{ textAlign: 'right', direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr', fontSize: '1.1rem', marginLeft: '10px', marginRight: '10px' }}> | </h5>

          <h5 style={{ textAlign: 'right', direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr', fontSize: '1.1rem', }}>
            {All_appData.app_Downloaded_Times}{' '}
            <FaDownload style={{
              color: '#B67CF8AF', marginLeft: CurrentLanguage === 'EN' ? '' : '3px', marginRight: CurrentLanguage === 'EN' ? '3px' : '',
              marginTop: isMobile_TooSmall ? '-3px' : null
            }} /></h5>

          <h5 style={{ textAlign: 'right', direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr', fontSize: '1.1rem', marginLeft: '10px', marginRight: '10px' }}> | </h5>

          <h5 style={{ textAlign: 'right', direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr', fontSize: '1.1rem', }}>
            {All_appData?.app_watched_times?.watched_By?.length ? All_appData?.app_watched_times?.watched_By?.length : 0}
            <FaEye style={{
              color: '#DF6868D5', marginLeft: CurrentLanguage === 'EN' ? '' : '3px', marginRight: CurrentLanguage === 'EN' ? '3px' : '',
              marginTop: isMobile_TooSmall ? '-3px' : null
            }} /></h5>
        </div>

      </div>
      <div
        style={{
          textDecoration: 'none', color: 'white', direction: 'rtl', alignItems: 'flex-start', justifyContent: 'flex-end', textAlign: 'right', margin: '0.5rem',
          display: 'flex', flexDirection: isNonMobile ? 'row' : 'column', justifyContent: 'space-between', width: isNonMobile ? '100%' : '215%', height: '100%',
        }}
      >
        {/** the box on the right */}
        <div className="currentgames-rightbx" style={{ flexDirection: 'row', marginTop: '0px', direction: CurrentLanguage === 'EN' ? 'ltr' : '' }}>

          <div
          >
            <TrackVisibility once>
              {({ isVisible }) => isVisible ?
                <div>
                  {isVisible && handleView()}
                  <LazyLoadImage
                    effect="blur"
                    src={All_appData.app_Picture[0]}
                    draggable={false}
                    onContextMenu={(e) => e.preventDefault()}
                    alt='app_Picture'
                    style={{
                      width: '100%', justifyContent: "center", alignItems: "center", display: "flex",
                      margin: "0 auto", transition: "all 0.5s ease",
                    }}
                  />
                </div>
                :
                <LazyLoadImage
                  effect="blur"
                  src={All_appData.app_Picture[0]}
                  draggable={false}
                  onContextMenu={(e) => e.preventDefault()}
                  alt='app_Picture'
                  style={{
                    width: '100%', justifyContent: "center", alignItems: "center", display: "flex",
                    margin: "0 auto", transition: "all 0.5s ease",
                  }}
                />
              }
            </TrackVisibility>
          </div>


          <div >
            <div style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
              textAlign: 'right', width: '100%', padding: '0.5rem'
            }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', textAlign: 'right', width: '100%', padding: '0.5rem', paddingBottom: '20px' }}>
                <span style={{
                  marginBottom: '15px', marginLeft: '15px', textAlign: 'center', alignContent: 'center', justifyContent: 'center', fontSize: '1.15rem', color: 'white',
                  direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', borderRadius: '0.2rem'
                }}
                >{CurrentLanguage === 'EN' ? 'Share Link : ' : 'شارك رابط البرنامج : '}</span>
                <span className="navbar-text">
                  <div className="social-icon margin-">
                    <a
                      href='https://discord.gg/jwx23GMAXm'
                      target="_blank"
                      rel="noopener noreferrer"
                      className="discord"
                      style={{ cursor: 'pointer' }}
                    >
                      <img
                        loading='lazy'
                        draggable={false}
                        src={DiscordIcon}
                        alt="DiscordIcon"
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </a>
                    <a
                      href='https://www.instagram.com/arab2games/'
                      target="_blank"
                      rel="noopener noreferrer"
                      className="instagram"
                      style={{ cursor: 'pointer' }}
                    >
                      <img
                        loading='lazy'
                        draggable={false}
                        src={InstagramIcon}
                        alt="InstagramIcon"
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </a>
                    <CopyToClipboard text={appLink} onCopy={handleCopyLink}>
                      <a
                        className="default"
                        style={{ cursor: 'pointer' }}>
                        <img
                          loading='lazy'
                          draggable={false}
                          src={CopyLinkIcon}
                          alt="CopyLinkIcon"
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      </a>
                    </CopyToClipboard>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div
            draggable={false}
            onClick={() => {
              setApp_PlayButtonClicked(true);
            }}
            style={{
              cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'right', textAlign: 'center', alignItems: 'center', width: 'fit-content',
              height: '40px', marginBottom: '25px', backgroundColor: '#550606', border: '1px solid #2f2f2f', boxShadow: '0 0 20px 0 #550606',
              borderRadius: '2rem', padding: '0.5rem'
            }}>
            <h6 style={{ textAlign: 'center', direction: 'rtl', fontSize: '1.1rem', marginRight: '15px', marginLeft: '15px' }}>{CurrentLanguage === 'EN'
              ? 'app Trailer'
              : ' إعلان البرنامج '}</h6>
            <LazyLoadImage
              effect="blur"
              draggable={false}
              src={play_video}
              alt='play_video'
              onContextMenu={(e) => e.preventDefault()}
              style={{ width: '35px', height: '35px' }} />

          </div>
          <div>
            <div style={{
              display: 'flex', flexDirection: isMobile_TooSmall ? 'column' : 'row', alignItems: 'center', width: '100%',
              transform: isMobile_TooSmall ? 'scale(0.95)' : 'scale(1)'
            }}>
              <h3 style={{
                textAlign: CurrentLanguage === 'EN' ? 'left' : 'right', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', width: isMobile_TooSmall ? '100%' : '',
                fontSize: '1.2rem', marginRight: '15px', marginLeft: '15px'
              }}>
                {CurrentLanguage === 'EN' ? 'Genres :' : ' التصنيف : '}
              </h3>
              <h5 style={{ textAlign: 'right', direction: 'ltr', marginRight: '15px', marginLeft: '5px' }}>
                {All_appData.app_Genres && All_appData.app_Genres.length >= 4 ? All_appData.app_Genres.slice(0, 4).map((genre, index) => {
                  return (
                    <span key={index} style={{ color: 'white', fontSize: '1.1rem', marginRight: '5px', marginLeft: '5px' }}>
                      {genre.length >= 15 ? genre.slice(0, 15) + '...' : genre}
                    </span>
                  )
                }) : All_appData.app_Genres.map((genre, index) => {
                  return (
                    <span key={index} style={{ color: 'white', fontSize: '1.1rem', marginRight: '5px', marginLeft: '5px' }}>
                      {genre.length >= 15 ? genre.slice(0, 15) + '...' : genre}
                    </span>
                  )
                })}
              </h5>
            </div>
            <header>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: '15px' }}>
                <h3 style={{ textAlign: 'right', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', fontSize: '1.2rem', marginRight: '15px', marginLeft: '15px' }}>
                  {CurrentLanguage === 'EN' ? 'Size :' : ' الحجم : '}
                </h3>
                <h3 style={{ textAlign: 'right', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', fontSize: '1.2rem', marginRight: '15px', marginLeft: '15px' }}>
                  {CurrentLanguage === 'EN' ? 'Release Date :' : ' تاريخ الاصدار : '}
                </h3>
              </div>
            </header>
            <div style={{
              textAlign: 'right', justifyContent: 'space-between', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', display: 'flex', flexDirection: 'row',
              width: '100%', height: '100%', borderRadius: '0.2rem', paddingBottom: '20px', paddingTop: '10px'
            }}>

              <h5 style={{ textAlign: 'right', direction: 'ltr', marginRight: '5px' }}>
                {All_appData.app_Size}
              </h5>
              <h5 style={{ textAlign: 'right', direction: 'ltr', marginRight: '5px', marginLeft: '15px' }}>
                {All_appData.app_ReleaseDate}
              </h5>
            </div>
            <div >
              <header style={{ paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', textAlign: 'right', width: '100%', padding: '0.5rem' }}>
                <h2 style={{ fontSize: '1.15rem', color: 'white', borderRadius: '0.2rem' }}
                >{CurrentLanguage === 'EN' ? 'app description :' : 'وصف البرنامج : '}</h2>
              </header>
              <h4 style={
                {
                  fontSize: '0.95rem', justifyContent: 'flex-end', alignItems: 'flex-end', textAlign: 'right', marginLeft: '0.5rem', marginRight: '0.5rem',
                  height: '100%', paddingTop: '0.5rem', paddingBottom: '0.5rem', color: 'white', opacity: '0.70', borderRadius: '0.2rem'
                }}
              >
                {All_appData.app_Description}
              </h4>
            </div>
          </div>

        </div>

        {/** the box on the left */}
        <div style={{ width: '98%' }}>
          <div
            size={12}
            xs={6}
            sm={6}
            md={6}
            lg={4}
            xl={3}
            className={isNonMobile ? 'currentgames-leftbx' : 'currentgames-leftbx-Mobile'}
            style={{ direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl' }}
          >

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', textAlign: 'right', width: '100%', padding: '0.5rem' }}>
              <header style={{ paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', textAlign: 'right', width: '100%', padding: '0.5rem' }}>
                <h2 style={{ fontSize: '1.15rem', color: 'white', borderRadius: '0.2rem' }}
                >{CurrentLanguage === 'EN' ? 'Download app :' : 'تحميل البرنامج :'}</h2>
              </header>
              <div style={{
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%', padding: '0.5rem',
              }}>
                <Button
                  disabled={Download_DirectLink_Start || All_appData.app_Download_Link.length <= 0 ? true : false}
                  onClick={() => handleDownloadDirectLink()}
                  className='currentgames-Downloadbtn'
                  style={
                    {
                      fontSize: '1rem', justifyContent: 'flex-start', alignItems: 'flex-end', textAlign: 'right', marginLeft: '0.5rem', marginRight: '0.5rem',
                      height: '100%', paddingTop: '0.5rem', paddingBottom: '0.5rem', color: 'white', opacity: '0.85', borderRadius: '0.5rem', transition: '0.3s ease-in-out',
                      backgroundColor: Download_DirectLink_Start || All_appData.app_Download_Link.length <= 0 ? '#3170C285' : '#3170C2',
                      border: '1px solid #2f2f2f',
                      boxShadow: Download_DirectLink_Start || All_appData.app_Download_Link.length <= 0 ? '0 0 20px 0 #3170C285' : '0 0 20px 0 #3170C2'
                    }
                  }>
                  <LazyLoadImage
                    effect="blur"
                    draggable={false}
                    src={Download_DirectLink_Start ? Download_Loading : dowloadIcon}
                    alt='Download_DirectLink_Start'
                    onContextMenu={(e) => e.preventDefault()}
                    style={{ width: '1.2rem', height: '1.2rem', marginLeft: '0.5rem', transition: '0.3s ease-in-out' }} />
                  <span
                    style={{ textDecoration: 'none', color: 'white', transition: '0.3s ease-in-out' }}>
                    {Download_DirectLink_Start ? CurrentLanguage === 'EN'
                      ? 'getting the link'
                      : 'جاري جذب الرابط'
                      :
                      CurrentLanguage === 'EN'
                        ? ' Direct Link'
                        : 'تحميل برابط مباشر'
                    }
                  </span>
                </Button>
                <Button
                  onClick={() => handleDownloadTorrentLink()}
                  className='currentgames-Downloadbtn'
                  style={
                    {
                      fontSize: '1rem', justifyContent: 'flex-start', alignItems: 'flex-end', textAlign: 'right', marginLeft: '0.5rem', marginRight: '0.5rem',
                      height: '100%', paddingTop: '0.5rem', paddingBottom: '0.5rem', color: 'white', opacity: '0.85', borderRadius: '0.5rem', transition: '0.3s ease-in-out',
                      backgroundColor: Download_TorrentLink_Start || All_appData.app_Download_Torrent_Link.length <= 0 ? '#1E7442A8' : '#1E7442',
                      border: '1px solid #2f2f2f',
                      boxShadow: Download_TorrentLink_Start || All_appData.app_Download_Torrent_Link.length <= 0 ? '0 0 20px 0 #1E7442A8' : '0 0 20px 0 #1E7442'
                    }
                  }>
                  <LazyLoadImage
                    effect="blur"
                    draggable={false}
                    src={Download_TorrentLink_Start ? Download_Loading : dowloadIcon}
                    alt='Download_TorrentLink_Start'
                    onContextMenu={(e) => e.preventDefault()}
                    style={{ width: '1.2rem', height: '1.2rem', marginLeft: '0.5rem', transition: '0.3s ease-in-out' }} />
                  <span
                    style={{ textDecoration: 'none', color: 'white', transition: '0.3s ease-in-out' }}>
                    {Download_TorrentLink_Start ? CurrentLanguage === 'EN'
                      ? 'getting the torrent'
                      : 'جاري جذب التورنت'
                      :
                      CurrentLanguage === 'EN'
                        ? 'Torrent Link'
                        : 'تحميل تورنت'
                    }
                  </span>
                </Button>
              </div>

              <span>
                {CurrentLanguage === 'EN'
                  ? 'Need the original copy, at a cheap price ? Check 👉🏻'
                  : ' تبي النسخة الأصلية و بسعر رخيص ؟ 👈🏻'
                }
                <a
                  href='https://Arab2Games.store/'
                  target='_blank' rel="noopener noreferrer"
                  style={{ color: '#2E79CE', marginRight: '10px' }}>
                  {CurrentLanguage === 'EN'
                    ? ' My Store '
                    : ' رابط المتجر '
                  }
                </a>
              </span>

              <br />

              <span>
                {CurrentLanguage === 'EN'
                  ? 'Need a torrent app ? '
                  : ' ما عندك برنامج التورنت ؟'
                }
                <a
                  href='https://www.utorrent.com/downloads/complete/track/stable/os/win/'
                  target='_self' rel="noopener noreferrer"
                  style={{ color: '#2E79CE', marginRight: '10px' }}>
                  uTorrent
                </a>
              </span>
            </div>

            <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', textAlign: 'right', width: '100%', padding: '0.2rem' }}>

              <h4 style={
                {
                  fontSize: '1.04rem', justifyContent: 'flex-start', alignItems: 'flex-start', textAlign: 'right', marginLeft: '0.5rem', marginRight: '0.5rem',
                  height: '100%', paddingBottom: '0.2rem', color: 'white', opacity: '0.85', borderRadius: '0.2rem'
                }
              }>
                <LazyLoadImage
                  effect="blur"
                  draggable={false}
                  src={All_appData.app_Price.startsWith('مجانية') ? CheckMark : WarningMark}
                  alt='app_Price'
                  onContextMenu={(e) => e.preventDefault()}
                  style={{
                    width: '1.2rem', height: '1.2rem',
                    marginLeft: CurrentLanguage === 'EN' ? '' : '0.5rem', marginRight: CurrentLanguage === 'EN' ? '0.5rem' : ''
                  }} />
                {All_appData.app_Price.startsWith('مجانية') ? CurrentLanguage === 'EN' ? 'Free' : 'مجانية' : All_appData.app_Price}
              </h4>
              <h4 style={
                {
                  fontSize: '1.04rem', justifyContent: 'flex-start', alignItems: 'flex-start', textAlign: 'right', marginLeft: '0.5rem', marginRight: '0.5rem',
                  height: '100%', paddingBottom: '0.2rem', color: 'white', opacity: '0.85', borderRadius: '0.2rem'
                }
              }>
                <LazyLoadImage
                  effect="blur"
                  draggable={false}
                  src={CheckMark}
                  alt='CheckMark'
                  onContextMenu={(e) => e.preventDefault()}
                  style={{
                    width: '1.2rem', height: '1.2rem', marginLeft: CurrentLanguage === 'EN' ? '' : '0.5rem',
                    marginRight: CurrentLanguage === 'EN' ? '0.5rem' : ''
                  }} />

                {CurrentLanguage === 'EN' ? 'Full app' : 'البرنامج كاملة'}
              </h4>
              <h4 style={
                {
                  fontSize: '1.04rem', justifyContent: 'flex-start', alignItems: 'flex-start', textAlign: 'right', marginLeft: '0.5rem', marginRight: '0.5rem',
                  height: '100%', paddingBottom: '0.5rem', color: 'white', opacity: '0.85', borderRadius: '0.2rem'
                }
              }>
                {All_appData.app_AntiVirus.startsWith('خالية' || ' خالية' || 'خالية ') ?
                  <div>
                    <LazyLoadImage
                      effect="blur"
                      draggable={false}
                      src={CheckMark}
                      alt='CheckMark'
                      onContextMenu={(e) => e.preventDefault()}
                      style={{
                        width: '1.2rem', height: '1.2rem', marginLeft: CurrentLanguage === 'EN' ? '' : '0.5rem',
                        marginRight: CurrentLanguage === 'EN' ? '0.5rem' : ''
                      }} />
                    <span>{All_appData.app_AntiVirus.startsWith('خالية' || ' خالية' || 'خالية ') ? CurrentLanguage === 'EN'
                      ? 'free of viruses'
                      : All_appData.app_AntiVirus
                      : All_appData.app_AntiVirus
                    }
                    </span>
                  </div>
                  : All_appData.app_AntiVirus.startsWith('احتمال' || 'مشكوك' || 'يمكن' || 'محتمل') ?
                    <div>
                      <LazyLoadImage
                        effect="blur"
                        draggable={false}
                        src={WarningMark}
                        alt='WarningMark'
                        onContextMenu={(e) => e.preventDefault()}
                        style={{ width: '1.8rem', height: '1.8rem', marginLeft: '0.2rem', marginRight: '-0.2rem' }} />
                      <span>{All_appData.app_AntiVirus}</span>
                    </div>

                    :
                    <div>
                      <LazyLoadImage
                        effect="blur"
                        draggable={false}
                        src={WrongMark}
                        alt='WrongMark'
                        onContextMenu={(e) => e.preventDefault()}
                        style={{ width: '1.2rem', height: '1.2rem', marginLeft: '0.5rem' }} />
                      <span>{All_appData.app_AntiVirus}</span>
                    </div>
                }
              </h4>
            </div>

            <div style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', direction: 'rtl', alignItems: 'flex-end', textAlign: 'right', width: '100%',
              padding: '0.5rem'
            }}>
              <header style={{
                paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end',
                direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                textAlign: CurrentLanguage === 'EN' ? 'left' : 'right', width: '100%', padding: '0.5rem'
              }}>
                <h2 style={{ fontSize: '1.15rem', color: 'white', borderRadius: '0.2rem' }}
                >{CurrentLanguage === 'EN'
                  ? 'Minimum Requirements :'
                  : 'الحد الأدنى لتشغيل البرنامج :'
                  }
                </h2>
              </header>
              {app_Requirements?.almost_anyPC ?

                <h4 style={{
                  fontSize: '0.95rem', paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                  alignItems: 'flex-end', textAlign: 'right', width: '100%', padding: '0.5rem', color: 'white', opacity: '0.70', borderRadius: '0.2rem'
                }}>
                  {app_Requirements.almost_anyPC}
                </h4>
                :
                <div style={{
                  display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', alignItems: 'flex-end',
                  textAlign: 'right', width: '100%'
                }}>
                  <h4 style={{
                    fontSize: '0.95rem', paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                    alignItems: 'flex-end', textAlign: CurrentLanguage === 'EN' ? 'left' : 'right', width: '100%', padding: '0.5rem', color: 'white', opacity: '0.70',
                    borderRadius: '0.2rem'
                  }}>
                    {CurrentLanguage === 'EN' ? 'CPU : ' : 'المعالج : '}{app_Requirements.CPU
                      ? app_Requirements.CPU
                      : CurrentLanguage === 'EN' ? 'unknown...' : 'غير معروف...'}
                  </h4>
                  <h4 style={{
                    fontSize: '0.95rem', paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                    alignItems: 'flex-end', textAlign: 'right', width: '100%', padding: '0.5rem', color: 'white', opacity: '0.70', borderRadius: '0.2rem'
                  }}>
                    {CurrentLanguage === 'EN' ? 'GPU : ' : 'كرت الشاشة : '}{app_Requirements.GPU
                      ? app_Requirements.GPU
                      : CurrentLanguage === 'EN' ? 'unknown...' : 'غير معروف...'}
                  </h4>
                  <h4 style={{
                    fontSize: '0.95rem', paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: 'row',
                    justifyContent: CurrentLanguage === 'EN' ? 'left' : 'right', alignItems: 'flex-end', textAlign: CurrentLanguage === 'EN' ? 'left' : 'right',
                    width: '100%', padding: '0.5rem', color: 'white', opacity: '0.70', borderRadius: '0.2rem'
                  }}>
                    {CurrentLanguage === 'EN' ? 'RAM : ' : 'الرام : '}
                    <span
                      style={{
                        textAlign: 'right', justifyContent: 'right', alignItems: 'flex-end', direction: 'ltr', marginRight: CurrentLanguage === 'EN' ? '' : '3px',
                        marginLeft: CurrentLanguage === 'EN' ? '3px' : ''
                      }}>
                      {app_Requirements.RAM ? app_Requirements.RAM : CurrentLanguage === 'EN' ? 'unknown...' : 'غير معروف...'}
                    </span>
                  </h4>
                </div>
              }


            </div>

          </div>
        </div>
      </div>

      <div
        className='Might-LikeGamesSection'
      >
        <ResizedMightLikeSection current_Name={All_appData.app_Name} current_Genres={All_appData.app_Genres} />
      </div>
    </div>
  )
}