import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FaSearch, FaTimes } from "react-icons/fa";
import Search_Loading from '../../assets/img/icons/Profile_Loading.svg'
import SearchAppsResults from "../../hooks/Search/SearchResults/SearchAppsResults";
import SearchGamesResults from "../../hooks/Search/SearchResults/SearchGamesResults";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { RETO_defaultSettings } from "../../hooks/Others/RETO_data";
import Page_Helmet from "../../hooks/SEO/react_Helmet";
import { useAuth } from "../../xFireBase/AuthContext";
import ScrollToTop from "../../ScrollToTop";
import WrongMark from '../../assets/img/icons/wrongMark.gif';



const SearchScreen = () => {


  const { darkMode, CurrentLanguage, ALL_Games, ALL_Apps } = useAuth();

  const isNonMobile = useMediaQuery("(min-width:992px)");
  const isTablet = useMediaQuery("(max-width:768px)" && "(min-width:526px)");
  const isMobile = useMediaQuery("(max-width: 521px)")


  const [SearchValue, setSearchValue] = useState("");
  const [start_search, setStart_search] = useState(false);
  const [Loading_search, setLoading_search] = useState(false);
  const [error_search, setError_search] = useState('');
  const [searchResult_Games, setSearchResult_Games] = useState([]);
  const [searchResult_Apps, setSearchResult_Apps] = useState([]);


  useEffect(() => {
    if (Loading_search === true && searchResult_Apps.length <= 0 && searchResult_Games?.length <= 0) {
      setError_search('لا يوجد نتائج للبحث');
    }
    else if (Loading_search === true && searchResult_Apps.length <= 0 && searchResult_Games.length <= 0 && ALL_Games.length <= 0 && ALL_Apps.length <= 0) {
      setError_search('حدث خطأ ما، يرجى المحاولة مرة أخرى او تحديث الصفحة');
    }
    else {
      setError_search('');
    }
  }, [Loading_search, searchResult_Apps, searchResult_Games, ALL_Games, ALL_Apps])

  // Games Search
  useEffect(() => {
    if (SearchValue.length > 0 && ALL_Games.length > 0 && SearchValue !== "" && start_search === true) {
      setTimeout(() => {
        setStart_search(false);
        setLoading_search(false);
        setSearchResult_Games(ALL_Games?.filter((game) => game?.game_Name?.toLowerCase()?.includes(SearchValue.toLowerCase())
          || game?.game_Name_alias?.toLowerCase()?.includes(SearchValue.toLowerCase())));

      }, 1000);
    } else if (SearchValue.length === 0 || SearchValue === "" || ALL_Games.length === 0) {
      setStart_search(false);
      setSearchResult_Games([]);
    }
  }, [SearchValue])

  // Apps Search 
  useEffect(() => {
    if (SearchValue.length > 0 && ALL_Apps.length > 0 && SearchValue !== "" && start_search === true) {
      setTimeout(() => {
        setStart_search(true);
        setSearchResult_Apps(ALL_Apps?.filter((App) => App?.app_Name?.toLowerCase()?.includes(SearchValue.toLowerCase())));
      }, 1000);
    } else if (SearchValue.length === 0 || SearchValue === "" || ALL_Apps.length === 0) {
      setStart_search(false);
      setSearchResult_Apps([]);
    }
  }, [SearchValue])



  return (
    <section className="SearchScreenSection" id="search"
      style={{
        transition: 'all 0.5s ease-in-out',
        minHeight: '1000px',
        height: '1400px',
      }}
    >
      <Page_Helmet
        title="Arab2Games | Search"
        type="article"
        description="ابحث عن افضل الالعاب والبرامج والبوتات المجانية والمكركة"
        image={RETO_defaultSettings[0].Website_Main_Logo}
        url={window.location.href ? window.location.href : "https://Arab2Games.com/"}
        keywords="A2G, Arab2Games, Arab2Game, arab2games, arab2game, عرب تو قيمز, عرب تو قيمنق, عرب 2 قيمز, apps, games, discord bots, arab2games, Arab2Games, Arab2Games.com, arab2games.com, arab2games.org, Arab2Games.org, arab2games.net, Arab2Games.net, arab2games.io, Arab2Games.io, arab2games.dev, Arab2Games.dev, crack, cracked, crack game, cracked game, crack games, cracked games, crack app, cracked app, crack apps, cracked apps, مكركة, كراك, العاب مكركة, مكركه, العاب مكركه, برامج مكركه, برامج مكركة, لعبة مكركة, لعبه مكركه, برنامج مكرك, العاب, لعبة, لعبه, برنامج, برامح, ريتو, لايك, لايك ريتو, arab2games, Arab2Games, ARAB2GAMES, Arab2Games, Arab2Games"
      />
      <ScrollToTop />
      <Container>
        <Row>
          <Col size={12}>

            <div >
              <div style={{ marginBottom: '50px' }}>
                <Box style={{ flexDirection: 'column', display: 'flex', width: '100%', position: 'relative' }}>
                  <form style={{ marginBottom: '80px', justifyContent: 'center', textAlign: 'center', alignItems: "center" }}>
                    <div style={{ width: '90%', height: '100%', flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ width: '100%', height: '100%', flexDirection: 'column', display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                        <div style={{
                          width: '100%', height: '100%', flexDirection: 'row', display: 'flex', textAlign: 'center', alignItems: "center",
                          justifyContent: 'center', marginBottom: '25px', direction: CurrentLanguage === 'EN' ? 'rtl' : null
                        }}>

                          <div
                            style={{
                              direction: CurrentLanguage === 'EN' ? 'rtl' : null, display: 'flex', flexDirection: 'row', justifyContent: "center",
                              alignItems: "center", cursor: 'pointer', borderRadius: '15px', margin: '0 10px', textAlign: 'center',
                              color: darkMode === 'light' ? '#000000' : '#ffffff',
                            }}
                            onClick={() => {
                              // go back to the previous page without using history
                              window.history.back();
                            }}

                          >
                            <FaTimes
                              style={{
                                color: darkMode === 'light' ? '#000000A1' : '#FFFFFF65',
                                fontSize: '20px',
                                width: '25px'
                              }}
                            />
                            {CurrentLanguage === 'EN' ? 'close' : 'إغلاق'}
                          </div>
                          <div style={{
                            direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', width: '100%', height: '100%', justifyContent: "center",
                            alignItems: "center", textAlign: 'center', display: 'flex', flexDirection: 'row',
                          }}>
                            <a
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                height: '100%',
                                padding: '10px',
                                borderRadius: '25px',
                                border: 'none',
                                outline: 'none',
                                fontSize: '1.2rem',
                                textAlign: 'center',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: darkMode === 'light' ? '#B4B4B4' : '#F5F5F52A',
                              }
                              }
                            >
                              <a
                                style={{
                                  color: darkMode === 'light' ? '#000000A1' : '#fff',
                                  fontSize: '1.2rem',
                                  marginLeft: '15px'
                                }}
                              >
                                <FaSearch />
                              </a>

                              <input
                                variant="filled"
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    // do nothing and don't refresh the page
                                    e.preventDefault();
                                  }
                                }}
                                name="SearchValue"
                                label={CurrentLanguage === 'EN' ? "search" : "البحث"}
                                placeholder={CurrentLanguage === 'EN' ? 'Type here' : 'اكتب هنا'}
                                value={SearchValue}
                                autoFocus={true}
                                onChange={(e) => {
                                  // the value should not start with space
                                  if (e.target.value.startsWith(" ")) {
                                    setStart_search(false);
                                    setLoading_search(false);
                                    return;
                                  }
                                  else {
                                    setStart_search(true);
                                    setLoading_search(true);
                                    setSearchValue(e.target.value);
                                    setTimeout(() => {
                                      setStart_search(false);
                                      setLoading_search(false);
                                    }, 1000);
                                  }
                                }}
                                type="text"
                                style={{
                                  backgroundColor: 'transparent',
                                  border: 'none',
                                  outline: 'none',
                                  textAlign: 'center',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '100%',
                                  height: '90%',
                                  fontSize: '1.05rem',
                                  color: darkMode === 'light' ? '#000000' : '#ffffff',
                                  direction: 'ltr',
                                  fontFamily: 'Cairo',
                                  fontWeight: 'bold',
                                  padding: '0',
                                  margin: '0',
                                }}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div style={{ flexDirection: 'row', justifyContent: 'center' }}>
                        {Loading_search === true ?
                          <div style={{ width: '100%', height: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: "center", textAlign: 'center' }}>
                            <h2
                              style={{
                                color: darkMode === 'light' ? '#000000' : '#ffffff',
                                fontSize: '1.2rem',
                                cursor: 'pointer',
                                marginLeft: '15px'

                              }}
                            >
                              {ALL_Apps.length > 0 && ALL_Games.length > 0 ? CurrentLanguage === 'EN' ? 'Searching..' : 'جاري البحث'
                                : ALL_Apps.length <= 0 && ALL_Games.length > 0 ? CurrentLanguage === 'EN' ? 'Searching in games' : 'جاري البحث في الألعاب'
                                  : ALL_Apps.length > 0 && ALL_Games.length <= 0 ? CurrentLanguage === 'EN' ? 'Searching in apps' : 'جاري البحث في التطبيقات'
                                    : ALL_Apps.length <= 0 && ALL_Games.length <= 0 ? CurrentLanguage === 'EN' ? 'something went wrong' : 'حدث خطأ ما'
                                      : CurrentLanguage === 'EN' ? 'something went wrong' : 'حدث خطأ ما'
                              }
                              {Loading_search === false ?
                                error_search.length > 0 ? error_search : null
                                :
                                null
                              }
                            </h2>
                            <LazyLoadImage
                              effect="blur"
                              draggable={false}
                              src={
                                ALL_Apps.length > 0 && ALL_Games.length > 0 ? Search_Loading
                                  : ALL_Apps.length <= 0 && ALL_Games.length > 0 ? Search_Loading
                                    : ALL_Apps.length > 0 && ALL_Games.length <= 0 ? Search_Loading
                                      : ALL_Apps.length <= 0 && ALL_Games.length <= 0 ? WrongMark
                                        : WrongMark
                              }
                              alt='Loading_search'
                              onContextMenu={(e) => e.preventDefault()}
                              style={{ width: '30px', height: '30px', display: Loading_search ? 'flex' : 'none' }} />
                          </div>
                          :
                          null
                        }
                      </div>
                    </div>
                  </form>

                </Box>
              </div>

              <div>

                <Row
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  {start_search && (searchResult_Games.length > 0 || searchResult_Apps.length > 0) ?
                    <div style={{ width: '100%', height: '100%', flexDirection: 'column', display: 'flex', justifyContent: 'flex-start', alignItems: "center", textAlign: 'center' }}>
                      <div style={{
                        width: isNonMobile ? '1300px' : isTablet ? '700px' : isMobile ? 'auto' : 'auto', height: '800px', flexDirection: 'column',
                        display: 'flex', marginTop: '-100px', paddingBottom: '50px',
                        direction: 'rtl', justifyContent: 'right', alignItems: "right", textAlign: 'right'
                      }}>

                        {SearchValue.length > 0 && ALL_Games.length > 0 && SearchValue !== "" && start_search === true
                          && searchResult_Games.length > 0
                          ? <SearchGamesResults searchResult={searchResult_Games} searchInput={SearchValue} />
                          :
                          null
                        }

                        {SearchValue.length > 0 && ALL_Apps.length > 0 && SearchValue !== "" && start_search === true
                          && searchResult_Apps.length > 0
                          ? <SearchAppsResults searchResult={searchResult_Apps} searchInput={SearchValue} />
                          :
                          null
                        }
                      </div>
                    </div>
                    :
                    null
                  }
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

/**
                   <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">BO1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">BO2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">MW2</Nav.Link>
                    </Nav.Item>
                    <h5 style={{
                      color: '#ffffff',
                      fontSize: '20px',
                      fontWeight: 'bold',
                      fontFamily: 'sans-serif',
                      marginLeft: '10px',
                      marginRight: '10px',

                    }}>   : ترتيب حسب </h5>

                  </Nav>
 */

export default SearchScreen;