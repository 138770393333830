import React from "react";
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import useMediaQuery from "@mui/material/useMediaQuery";
import BreakNews from "../components/HomeTop/BannerNews";
import { MainScreenBanner } from "./MainScreenBanner";
import Page_Helmet from "../hooks/SEO/react_Helmet";
import ScrollToTop from "../ScrollToTop";
import { useAuth } from "../xFireBase/AuthContext";
import { RETO_defaultSettings } from "../hooks/Others/RETO_data";


const HomeScreen = () => {

  const { darkMode } = useAuth();

  const isTablet = useMediaQuery("(max-width:768px)" && "(min-width:526px)");
  const isMobile = useMediaQuery("(max-width:525px)");

  /*  const [FirstSection_Images, setFirstSection_Images] = useState([
      {
        id: 1,
        img: 'https://cdn.discordapp.com/attachments/1120600422369988649/1131073622786719824/desktop-wallpaper-squid-game-workers-in-mask-fan-art.jpg',
        title: 'Home Main Screen',
      },
      {
        id: 2,
        img: 'https://cdn.discordapp.com/attachments/1120600422369988649/1129624127859327046/watch-dogs-aiden-pearce-wallpaper-thumbdds.jpg',
        title: 'Watch Dogs',
      },
    ]);
  
    const [CurentImage, setCurentImage] = useState(FirstSection_Images[0].img);
  
  
  
    useEffect(() => {
      setCurentImage(FirstSection_Images[0].img);
    }, [FirstSection_Images]);*/

  return (
    <div>
      <Page_Helmet
        title="Arab2Games"
        type="article"
        description="احصل على افضل الالعاب و البرامج مجانية و كاملة بدون اي فيروسات"
        image={RETO_defaultSettings[0].Website_Main_Logo}
        url="https://Arab2Games.com/"
        keywords="A2G, Arab2Games, Arab2Game, arab2games, arab2game, عرب تو قيمز, عرب تو قيمنق, عرب 2 قيمز, apps, games, discord bots, arab2games, Arab2Games, Arab2Games.com, arab2games.com, arab2games.org, Arab2Games.org, arab2games.net, Arab2Games.net, arab2games.io, Arab2Games.io, arab2games.dev, Arab2Games.dev, crack, cracked, crack game, cracked game, crack games, cracked games, crack app, cracked app, crack apps, cracked apps, مكركة, كراك, العاب مكركة, مكركه, العاب مكركه, برامج مكركه, برامج مكركة, لعبة مكركة, لعبه مكركه, برنامج مكرك, العاب, لعبة, لعبه, برنامج, برامح, ريتو, لايك, لايك ريتو, arab2games, Arab2Games, ARAB2GAMES, Arab2Games, Arab2Games"
      />
      <ScrollToTop />
      <div className="App">
        <div
          className="MainScreen-FirstSection"
          style={{
            justifyContent: 'space-between',
            flexDirection: 'column',
            display: 'flex',
            transition: 'all 0.5s ease-in-out',
          }}
        >
          <BreakNews />
          <div style={{ paddingBottom: '100px', width: isMobile ? '95%' : null, height: '100%' }}>
            <MainScreenBanner />
          </div>
          {
            /*<img
              loading="lazy"
              draggable={false}
              src={CurentImage}
              alt="Banner"
              onContextMenu={(e) => e.preventDefault()}
              style={{
                position: 'absolute',
                top: '0',
                zIndex: '-1',
                opacity: '0.06',
                justifyContent: 'center',
                alignItems: 'center',
                width: isMobile ? '100%' : '',
                height: isMobile ? '800px' : isTablet ? '950px' : '',
                transition: 'all 0.5s ease-in-out',
              }}
  
            />*/
          }
        </div>
      </div>
    </div >
  )
}

export default HomeScreen;
