import React from "react";
import { FetchCard_Data } from './other/FetchCard_Data'
import { Container, Row, Col } from "react-bootstrap";
import { useAuth } from "../../xFireBase/AuthContext";


const RETO_servers_Screen = () => {

  const { CurrentLanguage, darkMode } = useAuth();

  return (
    <section className="RETOserver_Section" id="RETO_servers">

      <Container>
        <Row>
          <Col size={12}>
            <div
              style={{
                color: darkMode === 'light' ? 'black' : null,
              }}
            >
              {CurrentLanguage === 'EN'
                ? <h2>RETO servers</h2>
                : <h2>RETO سيرفرات</h2>
              }
              {CurrentLanguage === 'EN'
                ? <p
                  style={{
                    color: darkMode === 'light' ? 'black' : null,
                  }}
                >
                  If you can't join the server..copy the IP and port and paste them in the game console ( ~ key above Tab key)
                </p>

                : <p
                  style={{
                    color: darkMode === 'light' ? 'black' : null,
                  }}
                >
                  في حال ما حصلت سيرفري داخل اللعبة ..انسخ رقم اي بي مع البورت و الصقهم في كونسل حق اللعبة (زر ~ حرف ذ فوق زر تاب)
                </p>
              }

              {CurrentLanguage === 'EN'
                ? <p
                  style={{
                    color: darkMode === 'light' ? 'black' : null,
                  }}
                >
                  🟢 = Server is working

                </p>
                :
                <p
                  style={{
                    color: darkMode === 'light' ? 'black' : null,
                  }}
                >
                  شغال السيرفر=🟢
                </p>
              }
              {CurrentLanguage === 'EN'
                ? <p
                  style={{
                    color: darkMode === 'light' ? 'black' : null,
                  }}
                >
                  🔴 = Server is offline

                </p>
                : <p
                  style={{
                    color: darkMode === 'light' ? 'black' : null,
                  }}
                >
                  غير شغال السيرفر=🔴
                </p>
              }

              {CurrentLanguage === 'EN'
                ? <p
                  style={{
                    color: darkMode === 'light' ? 'black' : null,
                  }}
                >
                  Wanna know the top 3 players ? Click on the server image 🔥
                </p>
                : <p
                  style={{
                    color: darkMode === 'light' ? 'black' : null,
                  }}
                >
                  حاب تعرف مين التوب 3 ؟ اضغط على صورة السيرفر 🔥
                </p>
              }
              {CurrentLanguage === 'EN'
                ? <p
                  style={{
                    color: darkMode === 'light' ? 'black' : null,
                  }}
                >
                  In case the servers didn't show up 👇🏻..move your mouse ( scroll ) and they will appear
                </p>
                :
                <p
                  style={{
                    color: darkMode === 'light' ? 'black' : null,
                  }}
                >
                  فيه حال ما طلعت السيرفرات هنا فالموقع👇🏻.. حرك الماوس شوي و بتطلع لك ( سكرول ) او غير قائمة و ارجع
                </p>
              }

              <FetchCard_Data />

            </div>
          </Col>
        </Row>
      </Container>

    </section>
  )
}

export default RETO_servers_Screen;
