import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { Row } from "react-bootstrap";
import { RETO_servers_Card } from "../../../../components/HomeMiddle/RETO_servers_Card";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrow1 from "../../../../assets/img/Others/arrow1.svg";
import arrow2 from "../../../../assets/img/Others/arrow2.svg";

const BO1_servers_screen = () => {

  const isNonMobile = useMediaQuery("(min-width:1200px)");
  const isTablet = useMediaQuery("(min-width:522px)");
  const isNon_TooSmall = useMediaQuery("(max-width:521px)");

  const [stop_server_data_fetching, setStop_server_data_fetching] = useState(false);
  const [stop_server_status_fetching, setStop_server_status_fetching] = useState(false);


  const arrowRef_bo1_1 = useRef();
  const arrowRef_bo1_2 = useRef();

  const [Bo1_mp_FFA_sniping, setBo1_mp_FFA_sniping] = useState([]);
  const [Bo1_mp_FFA_sniping_Status, setBo1_mp_FFA_sniping_Status] = useState([]);
  const [Bo1_mp_FFA_sniping_Names, setBo1_mp_FFA_sniping_Names] = useState([]);
  const [Bo1_mp_FFA_sniping_currentMap, setBo1_mp_FFA_sniping_currentMap] = useState([]);

  const [Bo1_mp_SnD_sniping, setBo1_mp_SnD_sniping] = useState([]);
  const [Bo1_mp_SnD_sniping_Status, setBo1_mp_SnD_sniping_Status] = useState([]);
  const [Bo1_mp_SnD_sniping_Names, setBo1_mp_SnD_sniping_Names] = useState([]);
  const [Bo1_mp_SnD_sniping_currentMap, setBo1_mp_SnD_sniping_currentMap] = useState([]);

  const [Bo1_zm_All_Maps_4_players, setBo1_zm_All_Maps_4_players] = useState([]);
  const [Bo1_zm_AllMaps_4_players_Status, setBo1_zm_all_maps_4players_Status] = useState([]);
  const [Bo1_zm_All_Maps_4_players_Names, setBo1_zm_All_Maps_4_players_Names] = useState([]);
  const [Bo1_zm_All_Maps_4_players_currentMap, setBo1_zm_All_Maps_4_players_currentMap] = useState([]);



  // Get all servers info from the API http://...../api/server
  const get_ALL_servers_Info = useCallback(async () => {
    try {
      if (stop_server_data_fetching === false) {
        const response = await axios.get("http://192.168.1.199:1624/api/server");
        const data = response.json();

        await data.then(async (data) => {
          await data?.map((server) => {
            if (server.id === 12700128961) {
              setBo1_mp_FFA_sniping(server ? server : []);
            }
            if (server.id === 12700128962) {
              setBo1_mp_SnD_sniping(server ? server : []);
            }
            if (server.id === 12700128963) {
              setBo1_zm_All_Maps_4_players(server ? server : []);
            }
          });
        })
          .then(() => {
            setStop_server_data_fetching(true);
          });
      }
    }
    catch (err) {
      console.log('error: ', 'Internal server error.');
    }
  }, []);

  // Get all servers info from the API http://....../api/status
  const get_ALL_servers_status = useCallback(async () => {
    try {
      if (stop_server_status_fetching === false) {
        const response = await axios.get("http://192.168.1.199:1624/api/status");
        const data = response.json();
        console.log('data: ', data);

        await data.then(async (data) => {
          await data?.map((server) => {
            if (server.id === 12700128961) {
              setBo1_mp_FFA_sniping_Status(server ? server : []);
              setBo1_mp_FFA_sniping_Names(server.players ? server.players : []);
              setBo1_mp_FFA_sniping_currentMap(server.map ? server.map : []);
            }
            if (server.id === 12700128962) {
              setBo1_mp_SnD_sniping_Status(server ? server : []);
              setBo1_mp_SnD_sniping_Names(server.players ? server.players : []);
              setBo1_mp_SnD_sniping_currentMap(server.map ? server.map : []);
            }
            if (server.id === 12700128963) {
              setBo1_zm_all_maps_4players_Status(server ? server : []);
              setBo1_zm_All_Maps_4_players_Names(server.players ? server.players : []);
              setBo1_zm_All_Maps_4_players_currentMap(server.map ? server.map : []);
            }
          });
        })
          .then(() => {
            setStop_server_status_fetching(true);
          });
      }
    }
    catch (err) {
      console.log('error: ', 'Internal server error.');
    }
  }, []);


  // get all servers info
  useEffect(() => {
    get_ALL_servers_Info()
  }, [get_ALL_servers_Info]);

  // get all servers status
  useEffect(() => {
    get_ALL_servers_status()
  }, [get_ALL_servers_status]);


  let PLAYERSNAMES_Bo1_mp_FFA_sniping = [];
  Bo1_mp_FFA_sniping_Names.map((player) => {
    PLAYERSNAMES_Bo1_mp_FFA_sniping.push({
      name: player.name,
      score: player.score,
      ping: player.ping,
      level: player.level,

    });
  });

  let CURRENTMAP_Bo1_mp_FFA_sniping = [{
    name: Bo1_mp_FFA_sniping_currentMap.name,
    alias: Bo1_mp_FFA_sniping_currentMap.alias
  }];


  let PLAYERSNAMES_Bo1_mp_SnD_sniping = [];
  Bo1_mp_SnD_sniping_Names.map((player) => {
    PLAYERSNAMES_Bo1_mp_SnD_sniping.push({
      name: player.name,
      score: player.score,
      ping: player.ping,
      level: player.level,

    });
  });

  let CURRENTMAP_Bo1_mp_SnD_sniping = [{
    name: Bo1_mp_SnD_sniping_currentMap.name,
    alias: Bo1_mp_SnD_sniping_currentMap.alias
  }];

  let PLAYERSNAMES_Bo1_zm_AllMaps_4_players = [];
  Bo1_zm_All_Maps_4_players_Names.map((player) => {
    PLAYERSNAMES_Bo1_zm_AllMaps_4_players.push({
      name: player.name,
      score: player.score,
      ping: player.ping,
      level: player.level,
    });
  });

  let CURRENTMAP_Bo1_zm_AllMaps_4_players = [{
    name: Bo1_zm_All_Maps_4_players_currentMap.name,
    alias: Bo1_zm_All_Maps_4_players_currentMap.alias
  }];


  const Servers_Info_Bo1 = [
    {
      serverType: 'BO1',
      serverRegion: 'KSA',
      serverDesc: 'mp',
      title: 'RETO | Sniping |KSA',
      description: "FFAsniping_1",
      image: 'https://reto-main-dashboard.vercel.app/RETO_servers_old_cod/bo1/bo1_mp_1.jpg',
      id: 172310728961,
      hostname: Bo1_mp_FFA_sniping.hostname,
      maxPlayers: Bo1_mp_FFA_sniping.maxClients,
      currentPlayers: Bo1_mp_FFA_sniping.clientNum,
      gameType: Bo1_mp_FFA_sniping_Status.gameMode,
      ip: Bo1_mp_FFA_sniping.listenAddress,
      port: Bo1_mp_FFA_sniping.listenPort,
      serverStatus: Bo1_mp_FFA_sniping_Status.isOnline,
      PlayersNow: PLAYERSNAMES_Bo1_mp_FFA_sniping,

      currentMap: `${CURRENTMAP_Bo1_mp_FFA_sniping.map((map) => {
        return map.alias + ' ';
      })}`,
    },

    {
      serverType: 'BO1',
      serverRegion: 'KSA',
      serverDesc: 'mp',
      title: 'RETO |Snip S&D |KSA',
      description: "S&Dsniping_1",
      image: 'https://reto-main-dashboard.vercel.app/RETO_servers_old_cod/bo1/bo1_mp_2.jpg',
      id: 172310728962,
      hostname: Bo1_mp_SnD_sniping.hostname,
      maxPlayers: Bo1_mp_SnD_sniping.maxClients,
      currentPlayers: Bo1_mp_SnD_sniping.clientNum,
      gameType: Bo1_mp_SnD_sniping_Status.gameMode,
      ip: Bo1_mp_SnD_sniping.listenAddress,
      port: Bo1_mp_SnD_sniping.listenPort,
      serverStatus: Bo1_mp_SnD_sniping_Status.isOnline,
      PlayersNow: PLAYERSNAMES_Bo1_mp_SnD_sniping,

      currentMap: `${CURRENTMAP_Bo1_mp_SnD_sniping.map((map) => {
        return map.alias + ' ';
      })}`,
    },
    {
      serverType: 'BO1',
      serverRegion: 'KSA',
      serverDesc: 'zm',
      title: 'RETO | All maps +MapVOTE | KSA',
      description: "Design & Development",
      image: 'https://cdn.discordapp.com/attachments/847105106298011668/1092130818702454925/ezgif.com-webp-to-jpg_1.jpg',
      id: 172310728963,
      hostname: Bo1_zm_All_Maps_4_players.hostname,

      maxPlayers: Bo1_zm_All_Maps_4_players.maxClients,
      currentPlayers: Bo1_zm_All_Maps_4_players.clientNum,
      gameType: Bo1_zm_AllMaps_4_players_Status.gameMode,
      ip: Bo1_zm_All_Maps_4_players.listenAddress,
      port: Bo1_zm_All_Maps_4_players.listenPort,
      serverStatus: Bo1_zm_AllMaps_4_players_Status.isOnline,
      PlayersNow: PLAYERSNAMES_Bo1_zm_AllMaps_4_players,

      currentMap: `${CURRENTMAP_Bo1_zm_AllMaps_4_players.map((map) => {
        return (
          // black ops 2 maps 
          map.name === 'zm_buried' ? 'Buried' :
            map.name === 'zm_tomb' ? 'Origins' :
              map.name === 'zm_nuked' ? 'Nuketown' :
                map.name === 'zm_prison' ? 'Mob of the dead' :
                  map.name === 'zm_transit' ? 'Tranzit' :
                    map.name === 'zm_highrise' ? 'Die Rise'
                      :
                      map.name + ' ' + map.alias
        );
      })}`,
    },
  ];


  return (
    <section>

      {isNonMobile ? (
        <div>
          <div style={{ marginBottom: '50px' }}>
            <Slider
              ref={arrowRef_bo1_1}
              dots={false}
              infinite={false}
              draggable={true}
              swipe={true}
              arrows={false}
              className='Search-Result-Container-Slider'
              focusOnSelect={true}
              autoplay={true}
              autoplaySpeed={3000}
              slidesToShow={
                isNonMobile ? Servers_Info_Bo1?.length > 4 ? 4 : Servers_Info_Bo1?.length === 4 || Servers_Info_Bo1?.length >= 3 ? 3
                  : Servers_Info_Bo1?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
              slidesToScroll={
                isNonMobile ? Servers_Info_Bo1?.length > 4 ? 4 : Servers_Info_Bo1?.length === 4 || Servers_Info_Bo1?.length >= 3 ? 3
                  : Servers_Info_Bo1?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
            >
              {
                Servers_Info_Bo1.map((server, index) => {
                  return (
                    server.serverType === 'BO1' && server.serverDesc === 'mp' && server.currentPlayers >= 1 ?
                      <RETO_servers_Card
                        key={index}
                        {...server}
                      />
                      :
                      server.serverType === 'BO1' && server.serverDesc === 'mp' ?
                        <RETO_servers_Card
                          key={index}
                          {...server}
                        />
                        : null
                  )
                })
              }
            </Slider>
          </div>

          <div>
            <Slider
              ref={arrowRef_bo1_2}
              dots={false}
              infinite={false}
              draggable={true}
              swipe={true}
              arrows={false}
              className='Search-Result-Container-Slider'
              focusOnSelect={true}
              autoplay={true}
              autoplaySpeed={3000}
              slidesToShow={
                isNonMobile ? Servers_Info_Bo1?.length > 4 ? 4 : Servers_Info_Bo1?.length === 4 || Servers_Info_Bo1?.length >= 3 ? 3
                  : Servers_Info_Bo1?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
              slidesToScroll={
                isNonMobile ? Servers_Info_Bo1?.length > 4 ? 4 : Servers_Info_Bo1?.length === 4 || Servers_Info_Bo1?.length >= 3 ? 3
                  : Servers_Info_Bo1?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
            >
              {
                Servers_Info_Bo1.map((server, index) => {
                  return (
                    server.serverType === 'BO1' && server.serverDesc === 'zm' && server.currentPlayers >= 1 ?
                      <RETO_servers_Card
                        key={index}
                        {...server}
                      />
                      :
                      server.serverType === 'BO1' && server.serverDesc === 'zm' ?
                        <RETO_servers_Card
                          key={index}
                          {...server}
                        />
                        : null
                  )
                })
              }
            </Slider>
          </div>
        </div>
      ) : (
        <Row>
          <div style={{
            flexWrap: isNonMobile ? 'wrap' : isNon_TooSmall ? '' : 'wrap',
            flexDirection: isNonMobile ? '' : isNon_TooSmall ? 'column' : 'column', height: isNonMobile ? '100%' : '100%',
            padding: isNonMobile ? '' : isNon_TooSmall ? '' : '0 10px', overflow: 'hidden'

          }}>
            <div style={{ marginBottom: '50px', marginTop: '50px' }}>
              {!isNonMobile ?
                <div style={{
                  alignItems: "center", position: 'relative', zIndex: '1', marginBottom: '20px', flexDirection: 'row', display: 'flex',
                  marginLeft: '25px', justifyContent: '', width: '100%'
                }}>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowLeft wow fadeInLeft" : "BannerSlider-arrowLeftMobile wow fadeInLeft"}
                    onClick={() => arrowRef_bo1_1.current.slickPrev()}
                  >
                    <img src={arrow1} alt="arrow1" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowRight wow fadeInRight" : "BannerSlider-arrowRightMobile wow fadeInRight"}
                    onClick={() => arrowRef_bo1_1.current.slickNext()()}
                  >
                    <img src={arrow2} alt="arrow2" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                </div>
                : null}
              <Slider
                ref={arrowRef_bo1_1}
                dots={false}
                infinite={false}
                draggable={true}
                swipe={true}
                arrows={false}
                className='Search-Result-Container-Slider'
                focusOnSelect={true}
                autoplay={true}
                autoplaySpeed={3000}
                slidesToShow={
                  isNonMobile ? Servers_Info_Bo1?.length > 4 ? 4 : Servers_Info_Bo1?.length === 4 || Servers_Info_Bo1?.length >= 3 ? 3
                    : Servers_Info_Bo1?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
                slidesToScroll={
                  isNonMobile ? Servers_Info_Bo1?.length > 4 ? 4 : Servers_Info_Bo1?.length === 4 || Servers_Info_Bo1?.length >= 3 ? 3
                    : Servers_Info_Bo1?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
              >
                {
                  Servers_Info_Bo1.map((server, index) => {
                    return (
                      server.serverType === 'BO1' && server.serverDesc === 'mp' && server.currentPlayers >= 1 ?
                        <RETO_servers_Card
                          key={index}
                          {...server}
                        />
                        :
                        server.serverType === 'BO1' && server.serverDesc === 'mp' ?
                          <RETO_servers_Card
                            key={index}
                            {...server}
                          />
                          : null
                    )
                  })
                }
              </Slider>
            </div>

            <div style={{ marginBottom: '50px', marginTop: '50px' }}>
              {!isNonMobile ?
                <div style={{
                  alignItems: "center", position: 'relative', zIndex: '1', marginBottom: '20px', flexDirection: 'row', display: 'flex',
                  marginLeft: '25px', justifyContent: '', width: '100%'
                }}>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowLeft wow fadeInLeft" : "BannerSlider-arrowLeftMobile wow fadeInLeft"}
                    onClick={() => arrowRef_bo1_2.current.slickPrev()}
                  >
                    <img src={arrow1} alt="arrow1" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowRight wow fadeInRight" : "BannerSlider-arrowRightMobile wow fadeInRight"}
                    onClick={() => arrowRef_bo1_2.current.slickNext()()}
                  >
                    <img src={arrow2} alt="arrow2" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                </div>
                : null}
              <Slider
                ref={arrowRef_bo1_2}
                dots={false}
                infinite={false}
                draggable={true}
                swipe={true}
                arrows={false}
                className='Search-Result-Container-Slider'
                focusOnSelect={true}
                autoplay={true}
                autoplaySpeed={3000}
                slidesToShow={
                  isNonMobile ? Servers_Info_Bo1?.length > 4 ? 4 : Servers_Info_Bo1?.length === 4 || Servers_Info_Bo1?.length >= 3 ? 3
                    : Servers_Info_Bo1?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
                slidesToScroll={
                  isNonMobile ? Servers_Info_Bo1?.length > 4 ? 4 : Servers_Info_Bo1?.length === 4 || Servers_Info_Bo1?.length >= 3 ? 3
                    : Servers_Info_Bo1?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
              >
                {
                  Servers_Info_Bo1.map((server, index) => {
                    return (
                      server.serverType === 'BO1' && server.serverDesc === 'zm' && server.currentPlayers >= 1 ?
                        <RETO_servers_Card
                          key={index}
                          {...server}
                        />
                        :
                        server.serverType === 'BO1' && server.serverDesc === 'zm' ?
                          <RETO_servers_Card
                            key={index}
                            {...server}
                          />
                          : null
                    )
                  })
                }
              </Slider>
            </div>

          </div>
        </Row>
      )}
    </section>
  )
}


export default BO1_servers_screen;