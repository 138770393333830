import React, { useState, useEffect } from 'react'
import { firebase, database } from '../../../xFireBase/Storage';
import useMediaQuery from "@mui/material/useMediaQuery";
import { toast } from 'react-toastify';
import { Checkbox, Button } from '@mui/material';
import { Formik } from 'formik';
import { Trash3 } from 'react-bootstrap-icons';
import { useAuth } from '../../../xFireBase/AuthContext';


const Edit_WDN_History = () => {

    const { CurrentLanguage, darkMode } = useAuth();

    const isNonMobile = useMediaQuery("(min-width:992px)");

    const [Old_Settings, setOld_Settings] = useState([]);

    const [Turn_On_Off_WatchHistory, setTurn_On_Off_WatchHistory] = useState(false);
    const [Turn_On_Off_DownloadHistory, setTurn_On_Off_DownloadHistory] = useState(false);
    const [Turn_On_Off_NotificationsHistory, setTurn_On_Off_NotificationsHistory] = useState(false);

    const [Delete_WatchHistory, setDelete_WatchHistory] = useState(false);
    const [Delete_DownloadHistory, setDelete_DownloadHistory] = useState(false);
    const [Delete_NotificationsHistory, setDelete_NotificationsHistory] = useState(false);

    // get the user's settings management 
    useEffect(() => {
        const user = firebase.auth().currentUser;
        const userSettingsRef = database.collection('users').doc(user.email).collection('more-details').doc('Profile_Management');
        userSettingsRef.get().then((doc) => {
            if (doc.exists) {
                const data = doc.data();
                setOld_Settings(data);
                setTurn_On_Off_WatchHistory(data.Turn_On_Off_WatchHistory);
                setTurn_On_Off_DownloadHistory(data.Turn_On_Off_DownloadHistory);
                setTurn_On_Off_NotificationsHistory(data.Turn_On_Off_NotificationsHistory);
            }
        })
    }, [])

    const Submit_Watch_and_Download_Mangement = async (values) => {
        const user = firebase.auth().currentUser;
        const userSettingsRef = database.collection('users').doc(user.email).collection('more-details').doc('Profile_Management');
        await userSettingsRef.update({
            Turn_On_Off_WatchHistory: Turn_On_Off_WatchHistory,
            Turn_On_Off_DownloadHistory: Turn_On_Off_DownloadHistory,
            Turn_On_Off_NotificationsHistory: Turn_On_Off_NotificationsHistory,
        }).then(() => {
            if (Delete_WatchHistory === true) {
                database.collection('users').doc(user.email).collection('more-details').doc('User_History')?.collection('User_WatchHistory')?.get()?.then((querySnapshot) => {
                    if (!querySnapshot.empty) {
                        querySnapshot.forEach((doc) => {
                            doc.ref.delete();
                        })
                    }
                })
                    .then(() => {
                        setDelete_WatchHistory(false);
                    })
            }
            if (Delete_DownloadHistory === true) {
                database.collection('users').doc(user.email).collection('more-details').doc('User_History')?.collection('User_Downloads')?.get()?.then((querySnapshot) => {
                    if (!querySnapshot.empty) {
                        querySnapshot.forEach((doc) => {
                            doc.ref.delete();
                        })
                    }
                }
                )
                    .then(() => {
                        setDelete_DownloadHistory(false);
                    })
            }
            if (Delete_NotificationsHistory === true) {
                database.collection('users').doc(user.email).collection('more-details').doc('User_History').collection('User_Notification').get().then((querySnapshot) => {
                    if (!querySnapshot.empty) {
                        querySnapshot.forEach((doc) => {
                            doc.ref.delete();
                        })
                    }
                }
                )
                    .then(() => {
                        setDelete_NotificationsHistory(false);
                    })
            }
        })
            .then(() => {
                // Settings updated
                const notify = () => toast.success(`تم تغيير الإعدادات بنجاح !`);
                notify();
                setTimeout(() => {
                    window.location.reload();
                }, 2300);
            })
            .catch((error) => {
                // An error occurred
                const notify = () => toast.error(`حدث خطأ أثناء تغيير الإعدادات !`);
                notify();
            });
    }


    return (
        <div style={{
            backgroundColor: darkMode === 'light' ? '#FFFFFF62' : '#FFFFFF0C',
            borderRadius: '15px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'right',
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
            padding: '0.5rem',
        }}>
            <Formik onSubmit={Submit_Watch_and_Download_Mangement}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit} style={{ width: '100%', flexDirection: 'column', }}>
                        <div style={{ flexDirection: isNonMobile ? 'row' : 'column', display: 'flex', width: '100%', justifyContent: 'space-between' }}>

                            <div style={{ flexDirection: 'column', display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <Button
                                    fullWidth
                                    name='Turn_On_Off_WatchHistory'
                                    value={Turn_On_Off_WatchHistory}
                                    onBlur={handleBlur('Turn_On_Off_WatchHistory')}
                                    onChange={handleChange('Turn_On_Off_WatchHistory')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '80%',
                                        color: darkMode === 'light' ? '#000000' : '#ffffff',
                                        borderRadius: '30px'
                                    }}
                                    onClick={() => {
                                        setTurn_On_Off_WatchHistory(!Turn_On_Off_WatchHistory)
                                    }}
                                >
                                    {Turn_On_Off_WatchHistory === true ? 'إيقاف سجل المشاهدة' : 'تفعيل سجل المشاهدة'}
                                </Button>

                                <Button
                                    fullWidth
                                    name='Delete_WatchHistory'
                                    value={Delete_WatchHistory}
                                    onBlur={handleBlur('Delete_WatchHistory')}
                                    onChange={handleChange('Delete_WatchHistory')}
                                    style={{
                                        width: isNonMobile ? '65%' : '80%',
                                        color: darkMode === 'light' ? '#000000' : '#ffffff',
                                        borderRadius: '30px',
                                        marginTop: '10px',
                                        backgroundColor: '#00000000',
                                        border: '1px solid #FFFFFF00'
                                    }}
                                    onClick={() => {
                                        setDelete_WatchHistory(!Delete_WatchHistory)
                                    }}
                                >
                                    <Trash3 style={{ marginLeft: '5px', color: Delete_WatchHistory === true ? '#FF3943' : '#D62F379A', transition: 'all 0.2s ease-in-out' }} />
                                    حذف سجل المشاهدة
                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={Delete_WatchHistory}
                                        checked={Delete_WatchHistory === true ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: '10px', marginLeft: '-20px',
                                            color: Delete_WatchHistory === true ? '#2F5CD6' : '#FFFFFF7A', transition: 'all 0.2s ease-in-out'
                                        }}
                                    />
                                </Button>
                            </div>

                            <div style={{ flexDirection: 'column', display: 'flex', width: '100%' }}>
                                <Button
                                    fullWidth
                                    name='Turn_On_Off_DownloadHistory'
                                    value={Turn_On_Off_DownloadHistory}
                                    onBlur={handleBlur('Turn_On_Off_DownloadHistory')}
                                    onChange={handleChange('Turn_On_Off_DownloadHistory')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '80%',
                                        color: darkMode === 'light' ? '#000000' : '#ffffff',
                                        borderRadius: '30px'
                                    }}
                                    onClick={() => {
                                        setTurn_On_Off_DownloadHistory(!Turn_On_Off_DownloadHistory)
                                    }}
                                >
                                    {Turn_On_Off_DownloadHistory === true ? 'إيقاف سجل التحميل' : 'تفعيل سجل التحميل'}
                                </Button>

                                <Button
                                    fullWidth
                                    name='Delete_DownloadHistory'
                                    value={Delete_DownloadHistory}
                                    onBlur={handleBlur('Delete_DownloadHistory')}
                                    onChange={handleChange('Delete_DownloadHistory')}
                                    style={{
                                        width: isNonMobile ? '65%' : '80%',
                                        color: darkMode === 'light' ? '#000000' : '#ffffff',
                                        borderRadius: '30px',
                                        marginTop: '10px',
                                        backgroundColor: '#00000000',
                                        border: '1px solid #FFFFFF00'
                                    }}
                                    onClick={() => {
                                        setDelete_DownloadHistory(!Delete_DownloadHistory)
                                    }}
                                >
                                    <Trash3 style={{ marginLeft: '5px', color: Delete_DownloadHistory === true ? '#FF3943' : '#D62F379A', transition: 'all 0.2s ease-in-out' }} />
                                    حذف سجل التحميل
                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={Delete_DownloadHistory}
                                        checked={Delete_DownloadHistory === true ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: '10px', marginLeft: '-20px',
                                            color: Delete_DownloadHistory === true ? '#2F5CD6' : '#FFFFFF7A', transition: 'all 0.2s ease-in-out'
                                        }}
                                    />
                                </Button>

                            </div>

                            <div style={{ flexDirection: 'column', display: 'flex', width: '100%' }}>
                                <Button
                                    fullWidth
                                    name='Turn_On_Off_NotificationsHistory'
                                    value={Turn_On_Off_NotificationsHistory}
                                    onBlur={handleBlur('Turn_On_Off_NotificationsHistory')}
                                    onChange={handleChange('Turn_On_Off_NotificationsHistory')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '80%',
                                        color: darkMode === 'light' ? '#000000' : '#ffffff',
                                        borderRadius: '30px'
                                    }}
                                    onClick={() => {
                                        setTurn_On_Off_NotificationsHistory(!Turn_On_Off_NotificationsHistory)
                                    }}
                                >
                                    {Turn_On_Off_NotificationsHistory === true ? 'إيقاف سجل التنبيهات' : 'تفعيل سجل التنبيهات'}
                                </Button>
                            </div>
                        </div>
                        <Button
                            className='AccountEdit-Form-Button'
                            type='submit'
                            style={{ width: isNonMobile ? '22%' : '80%', color: 'white', borderRadius: '30px', marginTop: '10px', backgroundColor: 'rgb(37, 99, 235)' }}
                            disabled={
                                Turn_On_Off_WatchHistory === Old_Settings.Turn_On_Off_WatchHistory &&
                                Turn_On_Off_DownloadHistory === Old_Settings.Turn_On_Off_DownloadHistory &&
                                Turn_On_Off_NotificationsHistory === Old_Settings.Turn_On_Off_NotificationsHistory &&

                                Delete_WatchHistory === false &&
                                Delete_DownloadHistory === false &&
                                Delete_NotificationsHistory === false
                            }
                        >
                            حفظ التغييرات
                        </Button>
                    </form>
                )}

            </Formik>
        </div>
    )
}

export default Edit_WDN_History

/*
                        <Button
                            fullWidth
                            name='Delete_NotificationsHistory'
                            value={Delete_NotificationsHistory}
                            onBlur={handleBlur('Delete_NotificationsHistory')}
                            onChange={handleChange('Delete_NotificationsHistory')}
                            style={{ width: isNonMobile ? '65%' : '80%', color: 'white', borderRadius: '30px', marginTop: '10px', backgroundColor: '#00000000', border: '1px solid #FFFFFF00' }}
                            onClick={() => {
                                setDelete_NotificationsHistory(!Delete_NotificationsHistory)
                            }}
                        >
                            <Trash3 style={{ marginLeft: '5px', color: Delete_NotificationsHistory === true ? '#FF3943' : '#D62F379A', transition: 'all 0.2s ease-in-out' }} />
                            حذف سجل التنبيهات
                            <Checkbox
                                type='checkbox'
                                disabled onClick="return false"
                                value={Delete_NotificationsHistory}
                                checked={Delete_NotificationsHistory === true ? true : false}
                                style={{ width: '15px', height: '15px', marginRight: '10px', marginLeft: '-20px',
                                color: Delete_NotificationsHistory === true ? '#2F5CD6' : '#FFFFFF7A', transition: 'all 0.2s ease-in-out' }}
                            />
                        </Button>
*/