import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Box, Button } from "@mui/material";
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { useAuth } from "../xFireBase/AuthContext";
import { RETO_defaultSettings } from "../hooks/Others/RETO_data";

const PageNotFound = ({ setPageNotFound }) => {
    const { darkMode } = useAuth();

    return (
        <div style={{ textAlign: "center", marginTop: "100px" }}>
            <LazyLoadImage
                effect="blur"
                draggable={false}
                src={RETO_defaultSettings[0].Website_Main_Logo}
                alt="logo"
                onContextMenu={(e) => e.preventDefault()}
                style={{ width: "150px", height: "150px", borderRadius: '30%', marginBottom: "20px" }}
            />
            <br />
            <h2
                style={{
                    color: darkMode === 'light' ? 'black' : null,
                }}
            >
                404
            </h2>
            <h3
                style={{
                    color: darkMode === 'light' ? 'black' : null,
                }}
            >
                ما فيه صفحة بهذا الرابط
            </h3>
            <br />
            <h3
                style={{
                    color: darkMode === 'light' ? 'black' : null,
                }}
            >
                تأكد من الرابط
            </h3>
            <br />
            <h3
                style={{
                    color: darkMode === 'light' ? 'black' : null,
                }}
            >
                او انتقل الى الصفحة الرئيسية
            </h3>
            <br />
            <Link to="/" style={{ textDecoration: "none", color: "black", marginLeft: "10px" }}>
                <Box display="flex" justifyContent="end" mt="20px" style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                    <Button
                        color="primary" variant="contained"
                        style={{ marginRight: '10px' }}
                    >
                        الصفحة الرئيسية
                    </Button>
                </Box>
            </Link>
            <br />
        </div>
    );
}

export default PageNotFound;