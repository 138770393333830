import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useAuth } from '../../xFireBase/AuthContext';

const InstallPrompt = () => {
    const { CurrentLanguage, User_OS_Platform } = useAuth();
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };

        const handleAppInstalled = () => {
            console.log('a2hs installed');
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        window.addEventListener('appinstalled', handleAppInstalled);
        console.log('deferredPrompt', deferredPrompt)
        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
            window.removeEventListener('appinstalled', handleAppInstalled);
        };
    }, []);

    const InstallPromptChecker = () => {
        if (User_OS_Platform === 'Mobile') {
            alert(CurrentLanguage === 'EN'
                ? `To install it, click on the share icon at the bottom of your screen, \n then click on "Add to Home Screen"`
                : `لتحميل التطبيق، اضغط على أيقونة المشاركة في أسفل شاشتك \n ثم اضغط على "إضافة إلى الشاشة الرئيسية"`
            );
            return;
        }
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                setDeferredPrompt(null);
            });
        }
    };

    return (
        <Button
            variant="contained"
            color="primary"
            size="small"
            id="App_Download_Button"
            style={{
                margin: '10px 0', justifyContent: 'center', alignItems: 'center', display: 'flex', width: '40%', color: '#DFDFDF',
                backgroundColor: '#5A1515', fontSize: CurrentLanguage === 'EN' ? '13.5px' : '15px', fontWeight: 'bold', borderRadius: '10px', padding: '5px 10px'
            }}
            onClick={InstallPromptChecker}
        >
            {CurrentLanguage === 'EN' ? 'Install app' : 'تحميل التطبيق'}
        </Button>
    );
};

export default InstallPrompt;
