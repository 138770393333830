import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { Row } from "react-bootstrap";
import { RETO_servers_Card } from "../../../../components/HomeMiddle/RETO_servers_Card";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrow1 from "../../../../assets/img/Others/arrow1.svg";
import arrow2 from "../../../../assets/img/Others/arrow2.svg";

const MW3_servers_screen = () => {

  const isNonMobile = useMediaQuery("(min-width:1200px)");
  const isTablet = useMediaQuery("(min-width:522px)");
  const isNon_TooSmall = useMediaQuery("(max-width:521px)");

  const [stop_server_data_fetching, setStop_server_data_fetching] = useState(false);
  const [stop_server_status_fetching, setStop_server_status_fetching] = useState(false);


  const arrowRef_IW5_1 = useRef();
  const arrowRef_IW5_2 = useRef();

  const [iw5_mp_FFA_sniping, setIw5_mp_FFA_sniping] = useState([]);
  const [iw5_mp_FFA_sniping_Status, setIw5_mp_FFA_sniping_Status] = useState([]);
  const [iw5_mp_FFA_sniping_Names, setIw5_mp_FFA_sniping_Names] = useState([]);
  const [iw5_mp_FFA_sniping_currentMap, setIw5_mp_FFA_sniping_currentMap] = useState([]);

  const [iw5_mp_TDM_sniping, setIw5_mp_TDM_sniping] = useState([]);
  const [iw5_mp_TDM_sniping_Status, setIw5_mp_TDM_sniping_Status] = useState([]);
  const [iw5_mp_TDM_sniping_Names, setIw5_mp_TDM_sniping_Names] = useState([]);
  const [iw5_mp_TDM_sniping_currentMap, setIw5_mp_TDM_sniping_currentMap] = useState([]);

  const [iw5_mp_TDM_Everything, setiw5_mp_TDM_Everything] = useState([]);
  const [iw5_mp_TDM_Everything_Status, setiw5_mp_TDM_Everything_Status] = useState([]);
  const [iw5_mp_TDM_Everything_Names, setiw5_mp_TDM_Everything_Names] = useState([]);
  const [iw5_mp_TDM_Everything_currentMap, setiw5_mp_TDM_Everything_currentMap] = useState([]);



  // Get all servers info from the API http://...../api/server
  const get_ALL_servers_Info = useCallback(async () => {
    try {
      if (stop_server_data_fetching === false) {
        const response = await axios.get("http://192.168.1.199:1624/api/server");
        const data = response.json();

        data.then((data) => {
          data?.map((server) => {
            if (server.id === 172310728966) {
              setIw5_mp_FFA_sniping(server ? server : []);
            }
            if (server.id === 172310728967) {
              setIw5_mp_TDM_sniping(server ? server : []);
            }
            if (server.id === 172310728968) {
              setiw5_mp_TDM_Everything(server ? server : []);
            }
          });
        })
          .then(() => {
            setStop_server_data_fetching(true);
          });
      }
    }
    catch (err) {
      console.log('error: ', 'Internal server error.');
    }
  }, []);

  // Get all servers info from the API http://....../api/status
  const get_ALL_servers_status = useCallback(async () => {
    try {
      if (stop_server_status_fetching === false) {
        const response = await axios.get("http://192.168.1.199:1624/api/status");
        const data = response.json();

        data.then((data) => {
          data?.map((server) => {
            if (server.id === 172310728966) {
              setIw5_mp_FFA_sniping_Status(server ? server : []);
              setIw5_mp_FFA_sniping_Names(server.players ? server.players : []);
              setIw5_mp_FFA_sniping_currentMap(server.map ? server.map : []);
            }
            if (server.id === 172310728967) {
              setIw5_mp_TDM_sniping_Status(server ? server : []);
              setIw5_mp_TDM_sniping_Names(server.players ? server.players : []);
              setIw5_mp_TDM_sniping_currentMap(server.map ? server.map : []);
            }
            if (server.id === 172310728968) {
              setiw5_mp_TDM_Everything_Status(server ? server : []);
              setiw5_mp_TDM_Everything_Names(server.players ? server.players : []);
              setiw5_mp_TDM_Everything_currentMap(server.map ? server.map : []);
            }
          });
        })
          .then(() => {
            setStop_server_status_fetching(true);
          });
      }
    }
    catch (err) {
      console.log('error: ', 'Internal server error.');
    }
  }, []);


  // get all servers info
  useEffect(() => {
    get_ALL_servers_Info()
  }, [get_ALL_servers_Info]);

  // get all servers status
  useEffect(() => {
    get_ALL_servers_status()
  }, [get_ALL_servers_status]);


  let PLAYERSNAMES_iw5_mp_FFA_sniping = [];
  iw5_mp_FFA_sniping_Names.map((player) => {
    PLAYERSNAMES_iw5_mp_FFA_sniping.push({
      name: player.name,
      score: player.score,
      ping: player.ping,
      level: player.level,

    });
  });

  let CURRENTMAP_iw5_mp_FFA_sniping = [{
    name: iw5_mp_FFA_sniping_currentMap.name,
    alias: iw5_mp_FFA_sniping_currentMap.alias
  }];

  let PLAYERSNAMES_iw5_mp_TDM_sniping = [];
  iw5_mp_TDM_sniping_Names.map((player) => {
    PLAYERSNAMES_iw5_mp_TDM_sniping.push({
      name: player.name,
      score: player.score,
      ping: player.ping,
      level: player.level,

    });
  });

  let CURRENTMAP_iw5_mp_TDM_sniping = [{
    name: iw5_mp_TDM_sniping_currentMap.name,
    alias: iw5_mp_TDM_sniping_currentMap.alias
  }];

  let PLAYERSNAMES_iw5_mp_TDM_Everything = [];
  iw5_mp_TDM_Everything_Names.map((player) => {
    PLAYERSNAMES_iw5_mp_TDM_Everything.push({
      name: player.name,
      score: player.score,
      ping: player.ping,
      level: player.level,
    });
  });

  let CURRENTMAP_iw5_mp_TDM_Everything = [{
    name: iw5_mp_TDM_Everything_currentMap.name,
    alias: iw5_mp_TDM_Everything_currentMap.alias
  }];


  const Servers_Info_IW5 = [
    {
      serverType: 'IW5',
      serverRegion: 'KSA',
      serverDesc: 'mp',
      title: 'RETO | FFA Sniping |KSA',
      description: "Design & Development",
      image: 'https://wallpapercave.com/wp/UXyLT2M.jpg',
      id: 172310728966,
      hostname: iw5_mp_FFA_sniping.hostname,
      maxPlayers: iw5_mp_FFA_sniping.maxClients,
      currentPlayers: iw5_mp_FFA_sniping.clientNum,
      gameType: iw5_mp_FFA_sniping.currentGameType,
      ip: iw5_mp_FFA_sniping.listenAddress,
      port: iw5_mp_FFA_sniping.listenPort,
      serverStatus: iw5_mp_FFA_sniping_Status.isOnline,
      PlayersNow: PLAYERSNAMES_iw5_mp_FFA_sniping,

      currentMap: `${CURRENTMAP_iw5_mp_FFA_sniping.map((map) => {
        return map.name + ' ' + map.alias + ' ';
      })}`,
    },

    {
      serverType: 'IW5',
      serverRegion: 'KSA',
      serverDesc: 'mp',
      title: 'RETO | TDM Sniping |KSA',
      description: "Design & Development",
      image: 'https://c4.wallpaperflare.com/wallpaper/469/317/242/call-of-duty-4-modern-warfare-call-of-duty-soldier-weapon-wallpaper-preview.jpg',
      id: 172310728967,
      hostname: iw5_mp_TDM_sniping.hostname,
      maxPlayers: iw5_mp_TDM_sniping.maxClients,
      currentPlayers: iw5_mp_TDM_sniping.clientNum,
      gameType: iw5_mp_TDM_sniping.currentGameType,
      ip: iw5_mp_TDM_sniping.listenAddress,
      port: iw5_mp_TDM_sniping.listenPort,
      serverStatus: iw5_mp_TDM_sniping_Status.isOnline,
      PlayersNow: PLAYERSNAMES_iw5_mp_TDM_sniping,

      currentMap: `${CURRENTMAP_iw5_mp_TDM_sniping.map((map) => {
        return map.name + ' ' + map.alias + ' ';
      })}`,
    },

    {
      serverType: 'IW5',
      serverRegion: 'KSA',
      serverDesc: 'mp',
      title: 'RETO | Team Deathmatch |KSA',
      description: "Design & Development",
      image: 'https://c4.wallpaperflare.com/wallpaper/469/317/242/call-of-duty-4-modern-warfare-call-of-duty-soldier-weapon-wallpaper-preview.jpg',
      id: 172310728968,
      hostname: iw5_mp_TDM_Everything.hostname,
      maxPlayers: iw5_mp_TDM_Everything.maxClients,
      currentPlayers: iw5_mp_TDM_Everything.clientNum,
      gameType: iw5_mp_TDM_Everything.currentGameType,
      ip: iw5_mp_TDM_Everything.listenAddress,
      port: iw5_mp_TDM_Everything.listenPort,
      serverStatus: iw5_mp_TDM_Everything_Status.isOnline,
      PlayersNow: PLAYERSNAMES_iw5_mp_TDM_Everything,

      currentMap: `${CURRENTMAP_iw5_mp_TDM_Everything.map((map) => {
        return map.name + ' ' + map.alias + ' ';
      })}`,
    },
  ];


  return (
    <section>

      {isNonMobile ? (
        <div>
          <div style={{ marginBottom: '50px' }}>
            <Slider
              ref={arrowRef_IW5_1}
              dots={false}
              infinite={false}
              draggable={true}
              swipe={true}
              arrows={false}
              className='Search-Result-Container-Slider'
              focusOnSelect={true}
              autoplay={true}
              autoplaySpeed={3000}
              slidesToShow={
                isNonMobile ? Servers_Info_IW5?.length > 4 ? 4 : Servers_Info_IW5?.length === 4 || Servers_Info_IW5?.length >= 3 ? 3
                  : Servers_Info_IW5?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
              slidesToScroll={
                isNonMobile ? Servers_Info_IW5?.length > 4 ? 4 : Servers_Info_IW5?.length === 4 || Servers_Info_IW5?.length >= 3 ? 3
                  : Servers_Info_IW5?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
            >
              {
                Servers_Info_IW5.map((server, index) => {
                  return (
                    server.serverType === 'IW5' && server.serverDesc === 'mp' && server.currentPlayers >= 1 ?
                      <RETO_servers_Card
                        key={index}
                        {...server}
                      />
                      :
                      server.serverType === 'IW5' && server.serverDesc === 'mp' ?
                        <RETO_servers_Card
                          key={index}
                          {...server}
                        />
                        : null
                  )
                })
              }
            </Slider>
          </div>

          <div>
            <Slider
              ref={arrowRef_IW5_2}
              dots={false}
              infinite={false}
              draggable={true}
              swipe={true}
              arrows={false}
              className='Search-Result-Container-Slider'
              focusOnSelect={true}
              autoplay={true}
              autoplaySpeed={3000}
              slidesToShow={
                isNonMobile ? Servers_Info_IW5?.length > 4 ? 4 : Servers_Info_IW5?.length === 4 || Servers_Info_IW5?.length >= 3 ? 3
                  : Servers_Info_IW5?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
              slidesToScroll={
                isNonMobile ? Servers_Info_IW5?.length > 4 ? 4 : Servers_Info_IW5?.length === 4 || Servers_Info_IW5?.length >= 3 ? 3
                  : Servers_Info_IW5?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
            >
              {
                Servers_Info_IW5.map((server, index) => {
                  return (
                    server.serverType === 'IW5' && server.serverDesc === 'zm' && server.currentPlayers >= 1 ?
                      <RETO_servers_Card
                        key={index}
                        {...server}
                      />
                      :
                      server.serverType === 'IW5' && server.serverDesc === 'zm' ?
                        <RETO_servers_Card
                          key={index}
                          {...server}
                        />
                        : null
                  )
                })
              }
            </Slider>
          </div>
        </div>
      ) : (
        <Row>
          <div style={{
            flexWrap: isNonMobile ? 'wrap' : isNon_TooSmall ? '' : 'wrap',
            flexDirection: isNonMobile ? '' : isNon_TooSmall ? 'column' : 'column', height: isNonMobile ? '100%' : '100%',
            padding: isNonMobile ? '' : isNon_TooSmall ? '' : '0 10px', overflow: 'hidden'

          }}>
            <div style={{ marginBottom: '50px', marginTop: '50px' }}>
              {!isNonMobile ?
                <div style={{
                  alignItems: "center", position: 'relative', zIndex: '1', marginBottom: '20px', flexDirection: 'row', display: 'flex',
                  marginLeft: '25px', justifyContent: '', width: '100%'
                }}>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowLeft wow fadeInLeft" : "BannerSlider-arrowLeftMobile wow fadeInLeft"}
                    onClick={() => arrowRef_IW5_1.current.slickPrev()}
                  >
                    <img src={arrow1} alt="arrow1" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowRight wow fadeInRight" : "BannerSlider-arrowRightMobile wow fadeInRight"}
                    onClick={() => arrowRef_IW5_1.current.slickNext()()}
                  >
                    <img src={arrow2} alt="arrow2" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                </div>
                : null}
              <Slider
                ref={arrowRef_IW5_1}
                dots={false}
                infinite={false}
                draggable={true}
                swipe={true}
                arrows={false}
                className='Search-Result-Container-Slider'
                focusOnSelect={true}
                autoplay={true}
                autoplaySpeed={3000}
                slidesToShow={
                  isNonMobile ? Servers_Info_IW5?.length > 4 ? 4 : Servers_Info_IW5?.length === 4 || Servers_Info_IW5?.length >= 3 ? 3
                    : Servers_Info_IW5?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
                slidesToScroll={
                  isNonMobile ? Servers_Info_IW5?.length > 4 ? 4 : Servers_Info_IW5?.length === 4 || Servers_Info_IW5?.length >= 3 ? 3
                    : Servers_Info_IW5?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
              >
                {
                  Servers_Info_IW5.map((server, index) => {
                    return (
                      server.serverType === 'IW5' && server.serverDesc === 'mp' && server.currentPlayers >= 1 ?
                        <RETO_servers_Card
                          key={index}
                          {...server}
                        />
                        :
                        server.serverType === 'IW5' && server.serverDesc === 'mp' ?
                          <RETO_servers_Card
                            key={index}
                            {...server}
                          />
                          : null
                    )
                  })
                }
              </Slider>
            </div>

            <div style={{ marginBottom: '50px', marginTop: '50px' }}>
              {!isNonMobile ?
                <div style={{
                  alignItems: "center", position: 'relative', zIndex: '1', marginBottom: '20px', flexDirection: 'row', display: 'flex',
                  marginLeft: '25px', justifyContent: '', width: '100%'
                }}>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowLeft wow fadeInLeft" : "BannerSlider-arrowLeftMobile wow fadeInLeft"}
                    onClick={() => arrowRef_IW5_2.current.slickPrev()}
                  >
                    <img src={arrow1} alt="arrow1" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowRight wow fadeInRight" : "BannerSlider-arrowRightMobile wow fadeInRight"}
                    onClick={() => arrowRef_IW5_2.current.slickNext()()}
                  >
                    <img src={arrow2} alt="arrow2" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                </div>
                : null}
              <Slider
                ref={arrowRef_IW5_2}
                dots={false}
                infinite={false}
                draggable={true}
                swipe={true}
                arrows={false}
                className='Search-Result-Container-Slider'
                focusOnSelect={true}
                autoplay={true}
                autoplaySpeed={3000}
                slidesToShow={
                  isNonMobile ? Servers_Info_IW5?.length > 4 ? 4 : Servers_Info_IW5?.length === 4 || Servers_Info_IW5?.length >= 3 ? 3
                    : Servers_Info_IW5?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
                slidesToScroll={
                  isNonMobile ? Servers_Info_IW5?.length > 4 ? 4 : Servers_Info_IW5?.length === 4 || Servers_Info_IW5?.length >= 3 ? 3
                    : Servers_Info_IW5?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
              >
                {
                  Servers_Info_IW5.map((server, index) => {
                    return (
                      server.serverType === 'IW5' && server.serverDesc === 'zm' && server.currentPlayers >= 1 ?
                        <RETO_servers_Card
                          key={index}
                          {...server}
                        />
                        :
                        server.serverType === 'IW5' && server.serverDesc === 'zm' ?
                          <RETO_servers_Card
                            key={index}
                            {...server}
                          />
                          : null
                    )
                  })
                }
              </Slider>
            </div>

          </div>
        </Row>
      )}
    </section>
  )
}


export default MW3_servers_screen;