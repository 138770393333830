import axios from "axios";

// game_get_Comments method
const game_get_Comments = async (gameData) => {
    try {
        const updateData = {
            _id: gameData._id,
            game_Name: gameData.game_Name,
            game_Number: gameData.game_Number,
            game_Page: gameData.game_Page,
        }

        const response = await axios.post("https://arab2games-games-backend.vercel.app/api/arab2games/game_get_Comments", updateData)
            .then((response) => {
                if (response.status === 200) {

                    const fetchedComments = response.data.game_Comments_encryptedData;

                    return fetchedComments;
                }
            })

        return response;
    }
    catch (error) {
        console.log("❌ game_get_Comments ~ couldn't get the game comments ~ error :   ")
        return false;
    }
};

export default game_get_Comments;