import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { RETO_defaultSettings } from "../../hooks/Others/RETO_data";
import Page_Helmet from "../../hooks/SEO/react_Helmet";
import { useAuth } from "../../xFireBase/AuthContext";
import ScrollToTop from "../../ScrollToTop";
import WarningMark from '../../assets/img/icons/WarningMark.gif';


const DMCAScreen = () => {


  const { CurrentLanguage, darkMode } = useAuth();
  const isNonMobile = useMediaQuery("(min-width:992px)");

  const [box_isHovered, setBox_isHovered] = useState(false);


  const Policy_array_AR = [
    {
      id: 1,
      desc: `موقع Arab2Games لا يستضيف أي محتوى على السيرفر الخاص به,
       و إنما يقوم بوضع روابط و تضمين لمحتوى مرفوع و موجود على الإنترنت و على سيرفرات خارجية`,
    },
    {
      id: 2,
      desc: `موقع Arab2Games غير مسؤول عن أي محتوى تم تحميله على سيرفرات خارجية و مواقع خارجية " طرف ثالث ", 
       و بهذا فإن موقع Arab2Games لا ينتهك حقوق الطبع و النشر و قانون الألفية للملكية الرقمية DMCA \n 
       و في حال كان لديك شكوى خاصة بالروابط و التضامين الخارجية, الرجاء التواصل مع إدارة هذه السيرفرات و المواقع الخارجية.`
    },
  ];

  // it will be exactly the same array as the AR one, but in english
  const Policy_array_EN = [
    {
      id: 1,
      desc: `RETO website does not host any content on its own server, 
      but rather linking to embedding content that was uploaded to popular Online Hosting / available on the Internet / external servers`,
    },
    {
      id: 2,
      desc: `RETO website is not responsible for any content uploaded to external servers and external websites "third party", 
      and thus RETO website does not violate copyright 
      and the Digital Millennium Copyright Act DMCA \n
      If you have a complaint about external links and embeds, please contact the administration of these external servers and websites.`
    },
  ];


  return (
    <section
      className="PolicyScreenSection" id="DMCAScreen"
      style={{
        transition: 'all 0.5s ease',
        height: 'auto', display: 'flex',
        justifyContent: 'center', alignItems: 'flex-start',
      }}
    >
      <Page_Helmet
        title="Arab2Games | DMCA"
        type="article"
        description="Arab2Games | DMCA"
        image={RETO_defaultSettings[0].Website_Main_Logo}
        url={window.location.href ? window.location.href : "https://Arab2Games.com/"}
        keywords="A2G, Arab2Games, Arab2Game, arab2games, arab2game, عرب تو قيمز, عرب تو قيمنق, عرب 2 قيمز, apps, games, discord bots, arab2games, Arab2Games, Arab2Games.com, arab2games.com, arab2games.org, Arab2Games.org, arab2games.net, Arab2Games.net, arab2games.io, Arab2Games.io, arab2games.dev, Arab2Games.dev, crack, cracked, crack game, cracked game, crack games, cracked games, crack app, cracked app, crack apps, cracked apps, مكركة, كراك, العاب مكركة, مكركه, العاب مكركه, برامج مكركه, برامج مكركة, لعبة مكركة, لعبه مكركه, برنامج مكرك, العاب, لعبة, لعبه, برنامج, برامح, ريتو, لايك, لايك ريتو, arab2games, Arab2Games, ARAB2GAMES, Arab2Games, Arab2Games"
      />
      <ScrollToTop />
      <Container>
        <Row>
          <Col size={12}>
            <div style={{ marginBottom: '50px' }}>
              <Box style={{ flexDirection: 'column', display: 'flex', width: '100%', position: 'relative' }}>
                <div style={{ width: '100%', }}>
                  <div
                    size={12}
                    xs={6}
                    sm={6}
                    md={6}
                    lg={4}
                    xl={3}
                    className={isNonMobile ? 'PolicyScreen-bx' : 'PolicyScreen-bx-Mobile'}
                    style={{
                      transition: 'all 0.5s ease-in-out',
                      direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                      boxShadow: darkMode === 'light'
                        ? box_isHovered === true ? '0 0 20px 0 #42297c' : '0 0 20px 0 #4644449D'
                        : box_isHovered === true ? '0 0 20px 0 #42297c' : '0 0 20px 0 #e2dada31'
                    }}
                    onMouseEnter={() => setBox_isHovered(true)}
                    onMouseLeave={() => setBox_isHovered(false)}
                  >

                    <div style={{
                      display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                      textAlign: CurrentLanguage === 'EN' ? 'left' : 'right',
                      width: '100%', padding: '0.5rem'
                    }}>
                      <header className="PolicyScreen-bx-Title">
                        <h2 style={{
                          transition: 'all 0.4s ease-in -out',
                          fontSize: '1.15rem',
                          color: darkMode === 'light' ? 'black' : 'white',
                          borderRadius: '0.2rem'
                        }}
                        >
                          Arab2Games | DMCA
                        </h2>

                      </header>

                      <span style={{ marginRight: '3px' }}>
                        {CurrentLanguage === 'EN'
                          ?
                          Policy_array_EN.map((item) => {
                            return (
                              <div key={item.id} style={{ margin: '25px 0', display: 'flex', flexDirection: "row" }}>
                                <div>
                                  <LazyLoadImage
                                    effect="blur"
                                    draggable={false}
                                    src={WarningMark}
                                    alt='WarningMark'
                                    onContextMenu={(e) => e.preventDefault()}
                                    style={{
                                      width: '1.2rem', height: '1.2rem', marginLeft: '0.5rem'
                                    }} />
                                </div>
                                <span style={{
                                  transition: 'all 0.4s ease',
                                  fontSize: '1.1rem',
                                  color: darkMode === 'light' ? '#3A3939' : '#A7A7A7',
                                  marginLeft: '0.5rem'
                                }}
                                >
                                  {
                                    // check if the current item and wrap the word that has the link with an actual link
                                    item.desc.split(' ').map((word) => {
                                      return word.startsWith('https')
                                        ? <a
                                          href={word}
                                          target='_blank'
                                          rel="noreferrer"
                                          style={{ color: '#3170C2' }}>
                                          {word}
                                        </a>
                                        :
                                        <span>{word} </span>
                                    })
                                  }
                                </span>
                              </div>
                            )
                          })
                          :
                          Policy_array_AR.map((item) => {
                            return (
                              <div key={item.id} style={{ margin: '25px 0', display: 'flex', flexDirection: "row" }}>
                                <div>
                                  <LazyLoadImage
                                    effect="blur"
                                    draggable={false}
                                    src={WarningMark}
                                    alt='WarningMark'
                                    onContextMenu={(e) => e.preventDefault()}
                                    style={{
                                      width: '1.2rem', height: '1.2rem', marginLeft: '0.5rem'
                                    }} />
                                </div>
                                <span style={{
                                  transition: 'all 0.4s ease',
                                  fontSize: '1.1rem',
                                  color: darkMode === 'light' ? '#3A3939' : '#A7A7A7',
                                  marginLeft: '0.5rem'
                                }}
                                >
                                  {
                                    // check if the current item and wrap the word that has the link with an actual link
                                    item.desc.split(' ').map((word) => {
                                      return word.startsWith('https')
                                        ? <a
                                          href={word}
                                          target='_blank'
                                          rel="noreferrer"
                                          style={{ color: '#3170C2' }}>
                                          {word}
                                        </a>
                                        :
                                        <span>{word} </span>
                                    })
                                  }
                                </span>
                              </div>
                            )
                          })
                        }
                      </span>

                      <br />
                    </div>

                  </div>
                </div>
              </Box>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}


export default DMCAScreen;