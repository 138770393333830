import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { Row } from "react-bootstrap";
import { RETO_servers_Card } from "../../../../components/HomeMiddle/RETO_servers_Card";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrow1 from "../../../../assets/img/Others/arrow1.svg";
import arrow2 from "../../../../assets/img/Others/arrow2.svg";

const MW2_H2M_servers_screen = () => {

  const isNonMobile = useMediaQuery("(min-width:1200px)");
  const isTablet = useMediaQuery("(min-width:522px)");
  const isNon_TooSmall = useMediaQuery("(max-width:521px)");

  const [stop_server_data_fetching, setStop_server_data_fetching] = useState(false);
  const [stop_server_status_fetching, setStop_server_status_fetching] = useState(false);


  const arrowRef_mw2_1 = useRef();
  const arrowRef_mw2_2 = useRef();

  const [mw2_mp_FFA_sniping, setMW2_mp_FFA_sniping] = useState([]);
  const [mw2_mp_FFA_sniping_Status, setMW2_mp_FFA_sniping_Status] = useState([]);
  const [mw2_mp_FFA_sniping_Names, setMW2_mp_FFA_sniping_Names] = useState([]);
  const [mw2_mp_FFA_sniping_currentMap, setMW2_mp_FFA_sniping_currentMap] = useState([]);

  const [mw2_mp_TDM_sniping, setMW2_mp_TDM_sniping] = useState([]);
  const [mw2_mp_TDM_sniping_Status, setMW2_mp_TDM_sniping_Status] = useState([]);
  const [mw2_mp_TDM_sniping_Names, setMW2_mp_TDM_sniping_Names] = useState([]);
  const [mw2_mp_TDM_sniping_currentMap, setMW2_mp_TDM_sniping_currentMap] = useState([]);



  // Get all servers info from the API http://...../api/server
  const get_ALL_servers_Info = useCallback(async () => {
    try {
      if (stop_server_data_fetching === false) {
        const response = await axios.get("http://192.168.1.199:1624/api/server");
        const data = response.json();

        data.then((data) => {
          data?.map((server) => {
            if (server.id === 172310728963) {
              setMW2_mp_FFA_sniping(server ? server : []);
            }
            if (server.id === 172310728964) {
              setMW2_mp_TDM_sniping(server ? server : []);
            }
          });
        })
          .then(() => {
            setStop_server_data_fetching(true);
          });
      }
    }
    catch (err) {
      console.log('error: ', 'Internal server error.');
    }
  }, []);

  // Get all servers info from the API http://....../api/status
  const get_ALL_servers_status = useCallback(async () => {
    try {
      if (stop_server_status_fetching === false) {
        const response = await axios.get("http://192.168.1.199:1624/api/status");
        const data = response.json();

        data.then((data) => {
          data?.map((server) => {
            if (server.id === 172310728963) {
              setMW2_mp_FFA_sniping_Status(server ? server : []);
              setMW2_mp_FFA_sniping_Names(server.players ? server.players : []);
              setMW2_mp_FFA_sniping_currentMap(server.map);
            }
            if (server.id === 172310728964) {
              setMW2_mp_TDM_sniping_Status(server ? server : []);
              setMW2_mp_TDM_sniping_Names(server.players ? server.players : []);
              setMW2_mp_TDM_sniping_currentMap(server.map ? server.map : []);
            }
          });
        })
          .then(() => {
            setStop_server_status_fetching(true);
          });
      }
    }
    catch (err) {
      console.log('error: ', 'Internal server error.');
    }
  }, []);


  // get all servers info
  useEffect(() => {
    get_ALL_servers_Info()
  }, [get_ALL_servers_Info]);

  // get all servers status
  useEffect(() => {
    get_ALL_servers_status()
  }, [get_ALL_servers_status]);


  let PLAYERSNAMES_mw2_mp_FFA_sniping = [];
  mw2_mp_FFA_sniping_Names.map((player) => {
    PLAYERSNAMES_mw2_mp_FFA_sniping.push({
      name: player.name,
      score: player.score,
      ping: player.ping,
      level: player.level,

    });
  });

  let CURRENTMAP_mw2_mp_FFA_sniping = [{
    name: mw2_mp_FFA_sniping_currentMap.name,
    alias: mw2_mp_FFA_sniping_currentMap.alias
  }];

  let PLAYERSNAMES_mw2_mp_TDM_sniping = [];
  mw2_mp_TDM_sniping_Names.map((player) => {
    PLAYERSNAMES_mw2_mp_TDM_sniping.push({
      name: player.name,
      score: player.score,
      ping: player.ping,
      level: player.level,

    });
  });

  let CURRENTMAP_mw2_mp_TDM_sniping = [{
    name: mw2_mp_TDM_sniping_currentMap.name,
    alias: mw2_mp_TDM_sniping_currentMap.alias
  }];


  const Servers_Info_MW2 = [
    {
      serverType: 'MW2',
      serverRegion: 'KSA',
      serverDesc: 'mp',
      title: 'RETO | FFA Sniping |KSA',
      description: "Design & Development",
      image: 'https://e1.pxfuel.com/desktop-wallpaper/123/561/desktop-wallpaper-sniper-ghost-warrior-2-1280x720-call-of-duty-ghost-snipers.jpg',
      id: 172310728963,
      hostname: mw2_mp_FFA_sniping.hostname,
      maxPlayers: mw2_mp_FFA_sniping.maxClients,
      currentPlayers: mw2_mp_FFA_sniping.clientNum,
      gameType: mw2_mp_FFA_sniping.currentGameType,
      ip: mw2_mp_FFA_sniping.listenAddress,
      port: mw2_mp_FFA_sniping.listenPort,
      serverStatus: mw2_mp_FFA_sniping_Status.isOnline,
      PlayersNow: PLAYERSNAMES_mw2_mp_FFA_sniping,

      currentMap: `${CURRENTMAP_mw2_mp_FFA_sniping.map((map) => {
        return map.name + ' ' + map.alias + ' ';
      })}`,
    },

    {
      serverType: 'MW2',
      serverRegion: 'KSA',
      serverDesc: 'mp',
      title: 'RETO | TDM Sniping |KSA',
      description: "Design & Development",
      image: 'https://wallpaperaccess.com/full/2204625.jpg',
      id: 172310728964,
      hostname: mw2_mp_TDM_sniping.hostname,
      maxPlayers: mw2_mp_TDM_sniping.maxClients,
      currentPlayers: mw2_mp_TDM_sniping.clientNum,
      gameType: mw2_mp_TDM_sniping.currentGameType,
      ip: mw2_mp_TDM_sniping.listenAddress,
      port: mw2_mp_TDM_sniping.listenPort,
      serverStatus: mw2_mp_TDM_sniping_Status.isOnline,
      PlayersNow: PLAYERSNAMES_mw2_mp_TDM_sniping,

      currentMap: `${CURRENTMAP_mw2_mp_TDM_sniping.map((map) => {
        return map.name + ' ' + map.alias + ' ';
      })}`,
    },

    {
      serverType: 'MW2',
      serverRegion: 'KSA',
      serverDesc: 'mp',
      title: 'RETO | Team Deathmatch |KSA',
      description: "Design & Development",
      image: 'https://e0.pxfuel.com/wallpapers/140/1004/desktop-wallpaper-soldiers-video-games-call-of-duty-call-of-duty-modern-warfare-2.jpg',
      id: 172310728965,
      hostname: mw2_mp_TDM_sniping.hostname,
      maxPlayers: mw2_mp_TDM_sniping.maxClients,
      currentPlayers: mw2_mp_TDM_sniping.clientNum,
      gameType: mw2_mp_TDM_sniping.currentGameType,
      ip: mw2_mp_TDM_sniping.listenAddress,
      port: mw2_mp_TDM_sniping.listenPort,
      serverStatus: mw2_mp_TDM_sniping_Status.isOnline,
      PlayersNow: PLAYERSNAMES_mw2_mp_TDM_sniping,

      currentMap: `${CURRENTMAP_mw2_mp_TDM_sniping.map((map) => {
        return map.name + ' ' + map.alias + ' ';
      })}`,
    },
  ];


  return (
    <section>

      {isNonMobile ? (
        <div>
          <div style={{ marginBottom: '50px' }}>
            <Slider
              ref={arrowRef_mw2_1}
              dots={false}
              infinite={false}
              draggable={true}
              swipe={true}
              arrows={false}
              className='Search-Result-Container-Slider'
              focusOnSelect={true}
              autoplay={true}
              autoplaySpeed={3000}
              slidesToShow={
                isNonMobile ? Servers_Info_MW2?.length > 4 ? 4 : Servers_Info_MW2?.length === 4 || Servers_Info_MW2?.length >= 3 ? 3
                  : Servers_Info_MW2?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
              slidesToScroll={
                isNonMobile ? Servers_Info_MW2?.length > 4 ? 4 : Servers_Info_MW2?.length === 4 || Servers_Info_MW2?.length >= 3 ? 3
                  : Servers_Info_MW2?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
            >
              {
                Servers_Info_MW2.map((server, index) => {
                  return (
                    server.serverType === 'MW2' && server.serverDesc === 'mp' && server.currentPlayers >= 1 ?
                      <RETO_servers_Card
                        key={index}
                        {...server}
                      />
                      :
                      server.serverType === 'MW2' && server.serverDesc === 'mp' ?
                        <RETO_servers_Card
                          key={index}
                          {...server}
                        />
                        : null
                  )
                })
              }
            </Slider>
          </div>

          <div>
            <Slider
              ref={arrowRef_mw2_2}
              dots={false}
              infinite={false}
              draggable={true}
              swipe={true}
              arrows={false}
              className='Search-Result-Container-Slider'
              focusOnSelect={true}
              autoplay={true}
              autoplaySpeed={3000}
              slidesToShow={
                isNonMobile ? Servers_Info_MW2?.length > 4 ? 4 : Servers_Info_MW2?.length === 4 || Servers_Info_MW2?.length >= 3 ? 3
                  : Servers_Info_MW2?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
              slidesToScroll={
                isNonMobile ? Servers_Info_MW2?.length > 4 ? 4 : Servers_Info_MW2?.length === 4 || Servers_Info_MW2?.length >= 3 ? 3
                  : Servers_Info_MW2?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
            >
              {
                Servers_Info_MW2.map((server, index) => {
                  return (
                    server.serverType === 'MW2' && server.serverDesc === 'zm' && server.currentPlayers >= 1 ?
                      <RETO_servers_Card
                        key={index}
                        {...server}
                      />
                      :
                      server.serverType === 'MW2' && server.serverDesc === 'zm' ?
                        <RETO_servers_Card
                          key={index}
                          {...server}
                        />
                        : null
                  )
                })
              }
            </Slider>
          </div>
        </div>
      ) : (
        <Row>
          <div style={{
            flexWrap: isNonMobile ? 'wrap' : isNon_TooSmall ? '' : 'wrap',
            flexDirection: isNonMobile ? '' : isNon_TooSmall ? 'column' : 'column', height: isNonMobile ? '100%' : '100%',
            padding: isNonMobile ? '' : isNon_TooSmall ? '' : '0 10px', overflow: 'hidden'

          }}>
            <div style={{ marginBottom: '50px', marginTop: '50px' }}>
              {!isNonMobile ?
                <div style={{
                  alignItems: "center", position: 'relative', zIndex: '1', marginBottom: '20px', flexDirection: 'row', display: 'flex',
                  marginLeft: '25px', justifyContent: '', width: '100%'
                }}>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowLeft wow fadeInLeft" : "BannerSlider-arrowLeftMobile wow fadeInLeft"}
                    onClick={() => arrowRef_mw2_1.current.slickPrev()}
                  >
                    <img src={arrow1} alt="arrow1" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowRight wow fadeInRight" : "BannerSlider-arrowRightMobile wow fadeInRight"}
                    onClick={() => arrowRef_mw2_1.current.slickNext()()}
                  >
                    <img src={arrow2} alt="arrow2" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                </div>
                : null}
              <Slider
                ref={arrowRef_mw2_1}
                dots={false}
                infinite={false}
                draggable={true}
                swipe={true}
                arrows={false}
                className='Search-Result-Container-Slider'
                focusOnSelect={true}
                autoplay={true}
                autoplaySpeed={3000}
                slidesToShow={
                  isNonMobile ? Servers_Info_MW2?.length > 4 ? 4 : Servers_Info_MW2?.length === 4 || Servers_Info_MW2?.length >= 3 ? 3
                    : Servers_Info_MW2?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
                slidesToScroll={
                  isNonMobile ? Servers_Info_MW2?.length > 4 ? 4 : Servers_Info_MW2?.length === 4 || Servers_Info_MW2?.length >= 3 ? 3
                    : Servers_Info_MW2?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
              >
                {
                  Servers_Info_MW2.map((server, index) => {
                    return (
                      server.serverType === 'MW2' && server.serverDesc === 'mp' && server.currentPlayers >= 1 ?
                        <RETO_servers_Card
                          key={index}
                          {...server}
                        />
                        :
                        server.serverType === 'MW2' && server.serverDesc === 'mp' ?
                          <RETO_servers_Card
                            key={index}
                            {...server}
                          />
                          : null
                    )
                  })
                }
              </Slider>
            </div>

            <div style={{ marginBottom: '50px', marginTop: '50px' }}>
              {!isNonMobile ?
                <div style={{
                  alignItems: "center", position: 'relative', zIndex: '1', marginBottom: '20px', flexDirection: 'row', display: 'flex',
                  marginLeft: '25px', justifyContent: '', width: '100%'
                }}>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowLeft wow fadeInLeft" : "BannerSlider-arrowLeftMobile wow fadeInLeft"}
                    onClick={() => arrowRef_mw2_2.current.slickPrev()}
                  >
                    <img src={arrow1} alt="arrow1" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowRight wow fadeInRight" : "BannerSlider-arrowRightMobile wow fadeInRight"}
                    onClick={() => arrowRef_mw2_2.current.slickNext()()}
                  >
                    <img src={arrow2} alt="arrow2" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                </div>
                : null}
              <Slider
                ref={arrowRef_mw2_2}
                dots={false}
                infinite={false}
                draggable={true}
                swipe={true}
                arrows={false}
                className='Search-Result-Container-Slider'
                focusOnSelect={true}
                autoplay={true}
                autoplaySpeed={3000}
                slidesToShow={
                  isNonMobile ? Servers_Info_MW2?.length > 4 ? 4 : Servers_Info_MW2?.length === 4 || Servers_Info_MW2?.length >= 3 ? 3
                    : Servers_Info_MW2?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
                slidesToScroll={
                  isNonMobile ? Servers_Info_MW2?.length > 4 ? 4 : Servers_Info_MW2?.length === 4 || Servers_Info_MW2?.length >= 3 ? 3
                    : Servers_Info_MW2?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
              >
                {
                  Servers_Info_MW2.map((server, index) => {
                    return (
                      server.serverType === 'MW2' && server.serverDesc === 'zm' && server.currentPlayers >= 1 ?
                        <RETO_servers_Card
                          key={index}
                          {...server}
                        />
                        :
                        server.serverType === 'MW2' && server.serverDesc === 'zm' ?
                          <RETO_servers_Card
                            key={index}
                            {...server}
                          />
                          : null
                    )
                  })
                }
              </Slider>
            </div>

          </div>
        </Row>
      )}
    </section>
  )
}


export default MW2_H2M_servers_screen;