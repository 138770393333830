import React, { useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { RETO_defaultSettings } from "../hooks/Others/RETO_data";
import Page_Helmet from "../hooks/SEO/react_Helmet";
import { useAuth } from "../xFireBase/AuthContext";
import ScrollToTop from "../ScrollToTop";
import WarningMark from '../assets/img/icons/WarningMark.gif';

const DonateScreen = () => {


  const { CurrentLanguage, darkMode } = useAuth();
  const isNonMobile = useMediaQuery("(min-width:992px)");

  const [box_isHovered, setBox_isHovered] = useState(false);



  const main_Description = `طبعاً زي ما انتم عارفين انه المواقع و الاستمرار فيها يتطلب بعض المبالغ عشان تستمر,
   
   و الصراحة قررت اسوي هالصفحة للي حاب يساعدني في تحسين و استمرار الموقع و تطويره بأي مبلغ حاب يقدمه للموقع و اكون شاكر له جداً
    و راح يكون شيء صدق حلو لما بعضكم يساعدني فالموضوع هذا.
   
   حالياً السيرفرات الخاصة تتطلب من 50 الى 200 دولار شهرياً. 
   و شفت انه فعلاً مبلغ كبير و ما راح اقدر اتحمله لفترة طويلة.


   اضغط على صورة الطريقة اللي حاب تستعملها للدعم و شكراً لك مقدماً مرة ثانية.
  `;
  const main_Description_EN = `As you know, the sites and their continuity require some amounts in order to continue,
  
  And frankly, I decided to make this page for those who want to help me in improving and continuing the site and developing it with any amount they want to offer to the site and I would be very grateful to him
   And it will be a really nice thing when some of you help me in this matter.
   
   Currently, the site is running on a servers and requires from 50 to 200 dollars per month.
   And I felt that it is really a large amount and I will not be able to handle it for a long time.

   Click on the image of the method you want to use to support and thank you in advance again.
   `;

  const main_Title = 'اولاً شكراً مقدماً لتقديمكم المساعدة للموقع و دعمه و المساهمة في تطويره و تحسينه و استمراره.'
  const main_Title_EN = 'Thank you in advance for your help to the site and its support and contribution to its development, improvement and continuity.'

  const Donations_Methods = [
    {
      id: 1,
      method_title: 'PayPal',
      method_DonateLink: 'https://www.paypal.com/paypalme/LikeRetoo',
      method_Img: 'https://reto-main-dashboard.vercel.app/main_images/Paypal_2014_logo.png',
    },
    {
      id: 2,
      method_title: 'StreamLabs',
      method_DonateLink: 'https://streamlabs.com/likeretoo/tip',
      method_Img: 'https://reto-main-dashboard.vercel.app/main_images/Streamlabs.png',
    },
  ];


  return (
    <section className="PolicyScreenSection" id="A2G_promo" style={{
      height: 'auto', display: 'flex',
      justifyContent: 'center', alignItems: 'flex-start'
    }}>
      <Page_Helmet
        title="Arab2Games | Donation"
        type='article'
        description="تبرع لنا لكي نستطيع جعل الموقع و السيرفر يستمرون ✨"
        image={RETO_defaultSettings[0].Website_Main_Logo}
        url="https://Arab2Games.com/"
        keywords="A2G, Arab2Games, Arab2Game, arab2games, arab2game, عرب تو قيمز, عرب تو قيمنق, عرب 2 قيمز, apps, games, discord bots, arab2games, Arab2Games, Arab2Games.com, arab2games.com, arab2games.org, Arab2Games.org, arab2games.net, Arab2Games.net, arab2games.io, Arab2Games.io, arab2games.dev, Arab2Games.dev, crack, cracked, crack game, cracked game, crack games, cracked games, crack app, cracked app, crack apps, cracked apps, مكركة, كراك, العاب مكركة, مكركه, العاب مكركه, برامج مكركه, برامج مكركة, لعبة مكركة, لعبه مكركه, برنامج مكرك, العاب, لعبة, لعبه, برنامج, برامح, ريتو, لايك, لايك ريتو, arab2games, Arab2Games, ARAB2GAMES, Arab2Games, Arab2Games"
      />
      <ScrollToTop />
      <Container>
        <Row>
          <Col size={12}>
            <div style={{ marginBottom: '50px' }}>
              <Box style={{ flexDirection: 'column', display: 'flex', width: '100%', position: 'relative' }}>
                <div style={{ width: '100%', }}>
                  <div
                    size={12}
                    xs={6}
                    sm={6}
                    md={6}
                    lg={4}
                    xl={3}
                    className={isNonMobile ? 'PolicyScreen-bx' : 'PolicyScreen-bx-Mobile'}
                    style={{
                      direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                      boxShadow: darkMode === 'light'
                        ? box_isHovered === true ? '0 0 20px 0 #42297c' : '0 0 20px 0 #4644449D'
                        : box_isHovered === true ? '0 0 20px 0 #42297c' : '0 0 20px 0 #e2dada31',
                    }}
                    onMouseEnter={() => setBox_isHovered(true)}
                    onMouseLeave={() => setBox_isHovered(false)}
                  >

                    <div style={{
                      display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                      textAlign: CurrentLanguage === 'EN' ? 'left' : 'right',
                      width: '100%', padding: '0.5rem'
                    }}>
                      <header className="PolicyScreen-bx-Title">
                        <h2 style={{
                          fontSize: '1.15rem', color: darkMode === 'light' ? '#000000' : '#ffffff',
                          borderRadius: '0.2rem'
                        }}
                        >
                          {CurrentLanguage === 'EN'
                            ? 'DONATIONS'
                            : 'دعم الموقع ( دونيشن )'
                          }
                        </h2>

                      </header>

                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', }}>

                        <div style={{ display: 'flex', flexDirection: 'row', margin: '40px 0', justifyContent: 'center', textAlign: 'center' }}>
                          <LazyLoadImage
                            effect="blur"
                            draggable={false}
                            src={WarningMark}
                            alt='WarningMark'
                            onContextMenu={(e) => e.preventDefault()}
                            style={{
                              width: '1.2rem', height: '1.2rem', marginLeft: '0.5rem'
                            }} />
                          <h2 style={{
                            fontSize: '1.2rem',
                            color: darkMode === 'light' ? '#000000' : '#F1F1F1',
                            marginLeft: '0.5rem',
                            flexWrap: 'wrap',
                            // multi line text
                            whiteSpace: 'pre-line',
                            wordWrap: 'break-word',
                            wordBreak: 'break-word',
                            hyphens: 'auto',
                            textAlign: CurrentLanguage === 'EN' ? 'left' : 'right',
                          }}>
                            {CurrentLanguage === 'EN'
                              ? main_Title_EN
                              : main_Title
                            }
                          </h2>
                        </div>

                        <div style={{
                          display: 'flex', flexDirection: 'row', marginLeft: CurrentLanguage === 'EN' ? null : 'auto',
                          marginRight: CurrentLanguage === 'EN' ? 'auto' : null
                        }}>
                          <LazyLoadImage
                            effect="blur"
                            draggable={false}
                            src={WarningMark}
                            alt='WarningMark'
                            onContextMenu={(e) => e.preventDefault()}
                            style={{
                              width: '1.2rem', height: '1.2rem', marginLeft: '0.5rem'
                            }} />

                          <h2 style={{
                            fontSize: '1.1rem',
                            color: darkMode === 'light' ? '#000000CB' : '#A7A7A7',
                            marginLeft: '0.5rem',
                            flexWrap: 'wrap',
                            // multi line text
                            whiteSpace: 'pre-line',
                            wordWrap: 'break-word',
                            wordBreak: 'break-word',
                            hyphens: 'auto',
                            textAlign: CurrentLanguage === 'EN' ? 'left' : 'right',
                          }}>
                            {CurrentLanguage === 'EN'
                              ? main_Description_EN
                              : main_Description
                            }
                          </h2>
                        </div>

                      </div>

                      <div style={{ display: 'flex', flexDirection: 'row', marginRight: '3px', flexWrap: 'wrap', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        {
                          Donations_Methods.map((item) => {
                            return (
                              <a
                                key={item.id}
                                href={item.method_DonateLink}
                                target='_self'
                                rel="noreferrer"
                                className={darkMode === 'light' ? "Payment-Methods-bx light" : "Payment-Methods-bx dark"}
                                style={{
                                  margin: '25px 55px', display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center',
                                  textAlign: 'center', cursor: 'pointer', textDecoration: 'none',
                                }}
                                onClick={() => window.open(item.method_DonateLink, '_self')}
                              >
                                <span style={{
                                  fontSize: '1.1rem',
                                  color: darkMode === 'light' ? '#000000' : '#A7A7A7',
                                  marginLeft: '0.5rem'
                                }}
                                >
                                  {
                                    // check if the current item and wrap the word that has the link with an actual link
                                    item.method_title.split(' ').map((word) => {
                                      return word.startsWith('https')
                                        ? <a
                                          href={word}
                                          target='_blank'
                                          rel="noreferrer"
                                          style={{ color: '#3170C2' }}>
                                          {word}
                                        </a>
                                        :
                                        <span>{word} </span>
                                    })
                                  }
                                </span>

                                <div>
                                  <LazyLoadImage
                                    effect="blur"
                                    draggable={false}
                                    src={item.method_Img}
                                    alt='WarningMark'
                                    onContextMenu={(e) => e.preventDefault()}
                                    style={{
                                      width: '100%', height: '100%', marginLeft: '0.5rem', maxWidth: '200px', maxHeight: '200px',
                                    }} />
                                </div>

                              </a>
                            )
                          })
                        }
                      </div>

                      <br />
                    </div>

                  </div>
                </div>
              </Box>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}


export default DonateScreen;