import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { GamesCard } from "../../components/HomeMiddle/GamesCard";
import TrackVisibility from 'react-on-screen';
import useMediaQuery from "@mui/material/useMediaQuery";
import BannerSlider from "../../components/HomeTop/BannerSlider";
import Profile_Loading from '../../assets/img/icons/Profile_Loading.svg'
import { FaDownload, FaSortAmountDownAlt } from "react-icons/fa";
import { Filter } from "react-bootstrap-icons";
import GameFilterGenresMenu from './other/Game-GenresFilter-Menu';
import GameFilterSortMenu from './other/Game-SortFilter-Menu';
import { MdClose } from "react-icons/md";
import { RETO_defaultSettings } from "../../hooks/Others/RETO_data";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";
import { useAuth } from "../../xFireBase/AuthContext";
import Page_Helmet from "../../hooks/SEO/react_Helmet";
import ScrollToTop from "../../ScrollToTop";

const ALLGames = () => {


  const { ALL_Games, darkMode, gamesWrapper, gamesWrapper_LoadMore, CurrentLanguage } = useAuth();

  const isNonMobile = useMediaQuery("(min-width:992px)");
  const isMobile = useMediaQuery("(max-width:521px)");

  const [Top5CrackedGames, setTop5CrackedGames] = useState([]);
  const [LoadMoreGames, setLoadMoreGames] = useState(false);
  const [Stop_LoadMoreGames, setStop_LoadMoreGames] = useState(false);

  const [something_happened, setSomething_happened] = useState(false);

  const [GameFilter_Genres, setGameFilter_Genres] = useState(false);

  const [GameFilter_Sort, setGameFilter_Sort] = useState(false);


  // get the top 5 cracked games
  const get_Top5CrackedGames = useCallback(() => {
    try {
      const response = ALL_Games.sort((a, b) => {
        return b.game_Downloaded_Times - a.game_Downloaded_Times;
      }
      ).slice(0, 5);
      setTop5CrackedGames(response);
    }
    catch (error) {
    }
  }, [ALL_Games]);

  const filteredAndSortedGames = useCallback(() => {
    let filteredGames = ALL_Games;

    if (RETO_defaultSettings[0].Game_SelectedFilters.length > 0) {
      filteredGames = filteredGames.filter((doc) => {
        return RETO_defaultSettings[0].Game_SelectedFilters.some((filter) => {
          return doc.game_Genres.some((genre) => genre.toLowerCase().includes(filter.toLowerCase()));
        });
      });
    }

    // Sort based on RETO_defaultSettings[0].Game_SelectedSort here

    return filteredGames;
  }, [ALL_Games, gamesWrapper]);

  // get the top 5 cracked games
  useEffect(() => {
    get_Top5CrackedGames();
  }, [get_Top5CrackedGames]);

  // get the filtered and sorted games
  useEffect(() => {
    filteredAndSortedGames();
  }, [filteredAndSortedGames]);



  if (!ALL_Games || ALL_Games.length <= 0 || ALL_Games === undefined || ALL_Games === null) {
    setTimeout(() => {
      setSomething_happened(true);
    }, 10000);
  }

  const LoadingMoreGames = () => {
    setLoadMoreGames(true)
    try {
      gamesWrapper_LoadMore();
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setTimeout(() => {
        setStop_LoadMoreGames(true)
      }, 1100)
      setTimeout(() => {
        setLoadMoreGames(false)
        setStop_LoadMoreGames(false)
      }, 1200)
    }

  }

  return (
    <div>
      <ScrollToTop />
      <Page_Helmet
        title="Arab2Games | Games"
        description="العاب مكركة مجانية و بدون فيروسات"
        keywords="A2G, Arab2Games, Arab2Game, arab2games, arab2game, عرب تو قيمز, عرب تو قيمنق, عرب 2 قيمز, apps, games, discord bots, arab2games, Arab2Games, Arab2Games.com, arab2games.com, arab2games.org, Arab2Games.org, arab2games.net, Arab2Games.net, arab2games.io, Arab2Games.io, arab2games.dev, Arab2Games.dev, crack, cracked, crack game, cracked game, crack games, cracked games, crack app, cracked app, crack apps, cracked apps, مكركة, كراك, العاب مكركة, مكركه, العاب مكركه, برامج مكركه, برامج مكركة, لعبة مكركة, لعبه مكركه, برنامج مكرك, العاب, لعبة, لعبه, برنامج, برامح, ريتو, لايك, لايك ريتو, arab2games, Arab2Games, ARAB2GAMES, Arab2Games, Arab2Games"
        type="article"
        image={RETO_defaultSettings[0].Website_Main_Logo}
        url={window.location.href ? window.location.href : "https://Arab2Games.com/"}
      />
      <section
        className={darkMode === 'light' ? 'gamesSection light' : "gamesSection dark"}
        id="A2G_games"
        style={{
          transition: 'all 0.5s ease-in-out',
        }}
      >
        <Container>
          <Row>
            <Col size={12}>
              <div>
                <div style={{ marginRight: isNonMobile ? '-40px' : '' }}>

                  <p
                    style={{
                      width: '76%',
                      color: darkMode === 'light' ? '#000000' : '#ffffff'
                    }}
                  >
                    {CurrentLanguage === 'EN'
                      ? '❗ if you didn\'t find the game you want, let me know on social media & I will provide it for you'
                      : '❗ ودك بلعبة بس ما حصلتها ؟ تواصل معاي على السوشال ميديا و راح اوفرها'
                    }
                  </p>

                </div>
                <div>
                  <div style={{
                    flexDirection: isNonMobile ? 'row' : 'column', display: 'flex', alignItems: isNonMobile ? 'flex-start' : 'center', justifyContent: 'center',
                    alignContent: 'center', direction: 'ltr', width: '100%', maxWidth: '1300px', height: '100%'
                  }}>

                    {ALL_Games && ALL_Games.length > 0 && ALL_Games !== undefined && ALL_Games !== null &&
                      <div style={{ marginTop: '30px' }}>
                        <BannerSlider docs_Games={ALL_Games} />
                      </div>
                    }

                    <div style={{
                      width: isNonMobile ? '40%' : 'auto', height: '100%', marginBottom: '40px', display: 'flex',
                      justifyContent: 'flex-start', alignItems: 'flex-start', alignContent: 'flex-start', flexDirection: 'column', direction: 'rtl'
                    }}>
                      <h5
                        style={{
                          marginRight: '20px',
                          color: darkMode === 'light' ? '#000000' : '#ffffff'
                        }}
                      >
                        {CurrentLanguage === 'EN'
                          ? 'Top downloaded this week'
                          : 'الأكثر تحميلاً هذا الأسبوع'
                        }
                      </h5>

                      <div style={{
                        width: isNonMobile ? '100%' : 'auto', height: '100%', margin: isNonMobile ? '' : '40px 0px', display: 'flex',
                        justifyContent: 'flex-start', alignItems: 'flex-start', alignContent: 'flex-start', flexDirection: 'row', direction: 'rtl'
                      }}>
                        <Col
                          size={10}
                          sm={6}
                          md={3}
                          style={{
                            height: '100%', marginRight: isNonMobile ? '' : '50px', borderRadius: 10, marginBottom: 20, padding: '10px', right: '0px',
                            boxShadow: '0 0 10px #1551A0', width: '80%'
                          }}>
                          {Top5CrackedGames.map((doc, index) => {
                            return (
                              <Link
                                key={index}
                                to={`/A2G_games/${doc.game_Number}/${doc.game_Page}`}
                                target="_self"
                                rel="noreferrer"
                                style={{ textDecoration: 'none', color: '#fff' }}
                              >
                                <div className={darkMode === 'light' ? 'Top-5Games-inner light' : 'Top-5Games-inner dark'}
                                  style={{ marginLeft: "10px" }}
                                >
                                  <div style={{
                                    display: 'flex', alignItems: 'center',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    direction: 'rtl',
                                    width: '200px', height: '100%'
                                  }}
                                  >
                                    <LazyLoadImage
                                      key={index}
                                      effect="blur"
                                      style={{ width: '100%', height: '100%' }}
                                      src={doc.game_Picture[0]}
                                      alt='top5-img'
                                      onContextMenu={(e) => e.preventDefault()}
                                    />
                                  </div>
                                  <div className='Top-5Games-right-part'>
                                    <div className='Top-5Games-content'>
                                      <div className='Top-5Games-content-gameName' style={{ marginLeft: CurrentLanguage === 'EN' ? '3px' : '' }}>
                                        {doc.game_Name.length > 23 ? doc.game_Name.slice(0, 23) + '...' : doc.game_Name}
                                      </div>
                                    </div>
                                    <div style={{
                                      flexDirection: 'column', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', alignContent: 'flex-start',
                                      direction: 'ltr'
                                    }}>
                                      <span style={{
                                        marginLeft: '5px',
                                        color: darkMode === 'light' ? '#000000D0' : '#a3a3a3',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        alignContent: 'center'
                                      }}
                                      >
                                        {doc.game_Downloaded_Times}
                                        <FaDownload
                                          style={{
                                            color: darkMode === 'light' ? '#A35BF7DA' : '#B67CF8AF',
                                            marginLeft: '3px'
                                          }}
                                        />
                                      </span>
                                      <span style={{
                                        direction: 'ltr', marginLeft: '5px',
                                        color: darkMode === 'light' ? '#000000D0' : '#a3a3a3',
                                      }}>
                                        {doc.game_Size}
                                      </span>
                                    </div>

                                  </div>
                                </div>
                              </Link>
                            )
                          })}

                        </Col>
                      </div>
                    </div>
                  </div>

                  <div>

                    <header style={{
                      marginBottom: '35px', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', textAlign: 'right', display: 'flex',
                      justifyContent: 'space-between', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row',
                    }}>
                      <div style={{
                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', textAlign: 'center', display: 'flex', justifyContent: 'center',
                        alignItems: 'center', flexDirection: 'row',
                        color: darkMode === 'light' ? '#000000' : '#ffffff'
                      }}>
                        <h4 style={{ color: '#ff434c', marginLeft: CurrentLanguage === 'EN' ? '' : '10px', marginRight: CurrentLanguage === 'EN' ? '10px' : '' }}>|</h4>
                        <h4>
                          {CurrentLanguage === 'EN'
                            ? 'Latest Games'
                            : 'اَخر الألعاب'
                          }</h4>
                        <h4 style={{ color: '#ff434c', marginLeft: CurrentLanguage === 'EN' ? '' : '10px', marginRight: CurrentLanguage === 'EN' ? '10px' : '' }}>  </h4>

                        <h4>
                          {ALL_Games?.length > 0
                            ? ALL_Games.length.toLocaleString('en-US', { timeZone: 'Asia/Riyadh' })
                            : '0'
                          }
                        </h4>
                      </div>

                      <div style={{ marginTop: '30px', width: isMobile ? '80%' : '40%', marginLeft: CurrentLanguage === 'EN' ? isMobile ? null : 'auto' : null }}>
                        <Tab.Container id="gamesSection-tabs">
                          <Nav
                            variant="pills"
                            className="nav-pills mb-5 justify-content-center align-items-center"
                            id="pills-tab"
                            style={{ transform: isMobile ? 'scale(0.9)' : '', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl' }}>

                            <Nav.Item style={{ margin: '0 auto 0 auto', width: '50%' }}>
                              <Nav.Link
                                onClick={() => {
                                  setTimeout(() => {
                                    setGameFilter_Sort(false)
                                    setGameFilter_Genres(!GameFilter_Genres)
                                  }, 200)
                                }}
                                eventKey="first"
                                style={{
                                  flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center', alignContent: 'center',
                                  direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr', textAlign: 'center',
                                  borderRight: CurrentLanguage === 'EN' ? '1px solid rgba(255, 255, 255, 0.5)' : null,
                                  borderLeft: CurrentLanguage === 'EN' ? null : '1px solid rgba(255, 255, 255, 0.5)'
                                }}
                              >
                                <span>{CurrentLanguage === 'EN' ? 'filter' : 'التصنيف'}</span>
                                {GameFilter_Genres
                                  ?
                                  <MdClose style={{
                                    marginLeft: CurrentLanguage === 'EN' ? null : '5px', transform: 'scale(1.2)',
                                    marginRight: CurrentLanguage === 'EN' ? '5px' : null
                                  }} />
                                  :
                                  <Filter style={{
                                    marginLeft: CurrentLanguage === 'EN' ? null : '5px', transform: 'scale(1.2)',
                                    marginRight: CurrentLanguage === 'EN' ? '5px' : null
                                  }} />
                                }

                              </Nav.Link>
                            </Nav.Item>

                            <Nav.Item style={{ margin: '0 auto 0 auto', width: '50%' }}>
                              <Nav.Link
                                onClick={() => {
                                  setTimeout(() => {
                                    setGameFilter_Genres(false)
                                    setGameFilter_Sort(!GameFilter_Sort)
                                  }, 200);
                                }}
                                eventKey="second"
                                style={{
                                  flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center', alignContent: 'center',
                                  direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', textAlign: 'center',
                                  borderLeft: CurrentLanguage === 'EN' ? '1px solid rgba(255, 255, 255, 0.5)' : null,
                                  borderRight: CurrentLanguage === 'EN' ? null : '1px solid rgba(255, 255, 255, 0.5)',
                                  marginLeft: CurrentLanguage === 'EN' ? '20px' : null,
                                  marginRight: CurrentLanguage === 'EN' ? null : '20px'
                                }}
                              >
                                <span>{CurrentLanguage === 'EN' ? 'sort' : 'الترتيب'}</span>
                                {GameFilter_Sort
                                  ?
                                  <MdClose style={{
                                    marginLeft: CurrentLanguage === 'EN' ? '5px' : null, transform: 'scale(1.2)',
                                    marginRight: CurrentLanguage === 'EN' ? null : '5px'
                                  }} />
                                  :
                                  <FaSortAmountDownAlt style={{
                                    marginLeft: CurrentLanguage === 'EN' ? '5px' : null, transform: 'scale(1.2)',
                                    marginRight: CurrentLanguage === 'EN' ? null : '5px'
                                  }} />

                                }
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Tab.Container>
                      </div>
                    </header>

                    {GameFilter_Genres &&
                      <div className={GameFilter_Genres ? "GameFilter_Genres_fadeIn" : "GameFilter_Genres_fadeOut"}>
                        <GameFilterGenresMenu
                          closeFilter={() => setGameFilter_Genres(false)}
                        />
                      </div>
                    }
                    {GameFilter_Sort &&
                      <div className={GameFilter_Sort ? "GameFilter_Genres_fadeIn" : "GameFilter_Genres_fadeOut"}>
                        <GameFilterSortMenu
                          closeSort={() => setGameFilter_Sort(false)}
                        />
                      </div>
                    }


                    <Row
                      className={GameFilter_Genres ? "GameFilter__Active_MoveDown_Smoothly" : null}
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        alignContent: 'center',
                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                      }}
                    >
                      {
                        ALL_Games.length > 0 ?
                          ALL_Games
                            .filter((doc) => {
                              if (RETO_defaultSettings[0].Game_SelectedFilters.length > 0) {
                                return RETO_defaultSettings[0].Game_SelectedFilters.some((filter) => {
                                  // Convert filter and doc.game_Genres to lowercase before comparison
                                  return doc.game_Genres.some((genre) => genre.toLowerCase().includes(filter.toLowerCase()));
                                });
                              } else {
                                return doc;
                              }
                            })
                            // then check if the RETO_defaultSettings[0].Game_SelectedSort is not empty, then sort the games by the selected sort
                            .sort((a, b) => {
                              if (RETO_defaultSettings[0].Game_SelectedSort.length > 0) {
                                if (RETO_defaultSettings[0].Game_SelectedSort === 'TopDownloaded') {
                                  return b.game_Downloaded_Times - a.game_Downloaded_Times;
                                } else if (RETO_defaultSettings[0].Game_SelectedSort === 'TopWatched') {
                                  return b.game_watched_times.watched_By.length - a.game_watched_times.watched_By.length;
                                } else if (RETO_defaultSettings[0].Game_SelectedSort === 'TopRated') {
                                  return b.game_Reviews - a.game_Reviews;
                                } else if (RETO_defaultSettings[0].Game_SelectedSort === 'Newest') {
                                  return new Date(b.createdAt) - new Date(a.createdAt);
                                } else if (RETO_defaultSettings[0].Game_SelectedSort === 'Oldest') {
                                  return new Date(a.createdAt) - new Date(b.createdAt);
                                } else if (RETO_defaultSettings[0].Game_SelectedSort === 'A-Z') {
                                  return a.game_Name.localeCompare(b.game_Name);
                                } else if (RETO_defaultSettings[0].Game_SelectedSort === 'Z-A') {
                                  return b.game_Name.localeCompare(a.game_Name);
                                }
                              } else {
                                return new Date(b.game_Added_Date) - new Date(a.game_Added_Date);
                              }
                            })
                            .slice(0, gamesWrapper)
                            .map((doc, index) => {

                              return (
                                <Col xs={6} sm={6} md={6} lg={4} xl={3} key={index}
                                >
                                  <Link
                                    to={`/A2G_games/${doc.game_Number}/${doc.game_Page}`}
                                    style={{ textDecoration: 'none', color: '#fff' }}
                                    rel='noreferrer'
                                    target="_self"
                                  >
                                    <GamesCard
                                      key={index}
                                      All_gameData={doc}
                                    />
                                  </Link>
                                </Col>
                              )
                            })
                          :
                          <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center' }}>
                            {something_happened === false ?
                              <LazyLoadImage
                                effect="blur"
                                alt="Profile_Loading"
                                draggable={false}
                                src={Profile_Loading}
                                onContextMenu={(e) => e.preventDefault()}
                                style={{ width: '30px', height: '30px', display: 'flex' }} />
                              :
                              null
                            }
                            {something_happened === false && ALL_Games.length <= 0 ?
                              <h1>{CurrentLanguage === 'EN'
                                ? 'Loading Games'
                                : 'جاري تحميل الالعاب'}</h1>
                              :
                              something_happened === true && ALL_Games.length <= 0 ?
                                <h3>{CurrentLanguage === 'EN'
                                  ? 'Something went wrong ❌'
                                  : '❌ حدث خطأ ما'}
                                </h3>
                                :
                                null
                            }
                          </div>
                      }

                      {gamesWrapper < ALL_Games.length && Stop_LoadMoreGames === false ?
                        <div
                          style={{
                            textDecoration: 'none',
                            cursor: 'pointer',
                            width: '150%',
                            height: '100%',
                            padding: '10px',
                            borderRadius: '25px',
                            border: 'none',
                            outline: 'none',
                            fontSize: '1.2rem',
                            marginBottom: '0px',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#fff',
                          }}
                        >
                          <TrackVisibility once>
                            {({ isVisible }) =>
                              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {isVisible ? LoadingMoreGames() : null}
                                <span style={{ opacity: '0' }}>.</span>
                                {LoadMoreGames === true ?
                                  <LazyLoadImage
                                    effect="blur"
                                    draggable={false}
                                    src={Profile_Loading}
                                    alt='Profile_Loading'
                                    style={{ width: '50px', height: '50px', display: 'flex' }} />
                                  :
                                  null
                                }
                              </div>
                            }
                          </TrackVisibility>
                        </div>
                        :
                        null
                      }
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default ALLGames;

/*
                    <h2 style={{ marginTop: '30px', transform: 'scale(0.6)' }}>
                      {CurrentLanguage === 'EN'
                      ? 'All games don\'t have any virus,  dont worry' 
                      : 'كل الالعاب خالية من الفيروسات فلا تشيل هم'
                      }
                    </h2>
*/


/*<p style={{ direction: 'rtl', width: '76%', color: '#C2C0C0' }}>
                    {CurrentLanguage === 'EN'
                      ? 'Note: All games are cracked and free .. the original copies are in the store❗'
                      : '❗ملاحظة: الالعاب كلها مكركة مجانية .. النسخ الأصلية بالمتجر '
                    }
                    <a href='https://likereto.store/' target='_blank' rel='noreferrer'>
                      {CurrentLanguage === 'EN'
                        ? 'click here'
                        : ' اضغط هنا'
                      }
                    </a>
                  </p>
*/