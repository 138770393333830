import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import useMediaQuery from "@mui/material/useMediaQuery";
import Profile_Loading from '../../../assets/img/icons/Profile_Loading.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import WrongMark from '../../../assets/img/icons/wrongMark.gif';


export const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? 'game-PlayVideoOn' : null;
  const isNonMobile = useMediaQuery("(min-width:1300px)");
  const isNonMobile_TooSmall = useMediaQuery("(min-width: 1200px)");
  const isMobile_TooSmall = useMediaQuery("(max-width: 1199px)");
  
  const style = {
    width: isNonMobile ? '42%' : isNonMobile_TooSmall ? '48%' : isMobile_TooSmall ? '85%' : '75%',
    height: isNonMobile ? '50%'  : isNonMobile_TooSmall ? '40%' : isMobile_TooSmall ? '30%' : '15%',
    top: isNonMobile ? '' : isNonMobile_TooSmall ? '-25%' : isMobile_TooSmall ? '-25%' : '-25%', 
    left: isNonMobile ? '' : isNonMobile_TooSmall ? '10%' : isMobile_TooSmall ? '5%' : '5%',
    bottom: isNonMobile ? '18%' : isNonMobile_TooSmall ? '20%' : isMobile_TooSmall ? '25%' : '30%', 
    
  };

  return (
    <div 
    style={{
      position: 'fixed',
      width: '100%',
      height: '100%',
      zIndex: '999999',
      background: 'rgba(0, 0, 0, 0.5)',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    }}
    className={showHideClassName} onClick={handleClose}>
      <section className="game-PlayVideo-main" style={style}>
        {children}
      </section>
    </div>
  );
};

export const VideoPlayer = ({ video_Trailer_Link, handleClose }) => {


  const [loading_timer, setloading_timer] = useState(false);
  const [loading_error_message, setloading_error_message] = useState('');


  useEffect(() => {
    let timer;
    if (video_Trailer_Link && video_Trailer_Link.trim() !== '') {
      timer = setTimeout(() => {
        setloading_timer(true);
      }, 245);
    } else {
      setTimeout(() => {
        setloading_error_message('حدث خطأ أثناء تحميل الفيديو \n يرجى المحاولة مرة أخرى');
      }, 5000);

      setTimeout(() => {
        handleClose();
      }, 8000);

      setTimeout(() => {
        setloading_timer(false);
      }, 8200);
    }

    return () => clearTimeout(timer);
  }, [handleClose, video_Trailer_Link]);

  const loadingContent = (
    <div className='game-PlayVideo-player' style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.8) 100%)', borderRadius: '10px' }}
    >
      <LazyLoadImage
        effect="blur"
        draggable={false} 
        src={loading_error_message.length > 0 ? WrongMark : Profile_Loading}
        alt='loading_error_message'
        onContextMenu={(e) => e.preventDefault()}
        style={{ width: '30px', height: '30px', display: 'flex' }}
      />
      <h3 style={{color: 'white', flexWrap: 'wrap', display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center', marginTop: '10px',
        maxWidth: '400px'}}>
        {loading_error_message.length > 0 ? loading_error_message : 'جاري تحميل الفيديو'}
      </h3>
      {loading_error_message.length > 0 ?
          <span style={{fontSize: '0.8rem', color: 'white', display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center', marginTop: '10px',
            maxWidth: '400px'}}>
            سيتم الإغلاق تلقائياً بعد 3 ثواني
          </span>
          : null}
    </div>
  );

  return (
    <div className='game-PlayVideo-wrapper' style={{position: 'absolute'}}>
      {loading_timer === false ? loadingContent : (
        <ReactPlayer
          className='game-PlayVideo-player'
          url={video_Trailer_Link}
          width='100%'
          height='100%'
          controls={true}
        />
      )}
    </div>
  );
};
