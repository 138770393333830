import React, { useState, useEffect, useCallback } from 'react';
import Notification from './Notification';
import { firebase, database } from '../../xFireBase/Storage';
import Search_Loading from '../../assets/img/icons/Profile_Loading.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useAuth } from '../../xFireBase/AuthContext';
import WrongMark from '../../assets/img/icons/wrongMark.gif';

const NotificationSection = () => {


  const { CurrentLanguage } = useAuth();
  const [backendNotification, setBackendNotification] = useState([]);
  const [notificationWrapper, setNotificationWrapper] = useState(5);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  // get user notification only once
  const getNotification = useCallback(() => {
    const user = firebase.auth().currentUser;

    if (user) {
      const unsubscribe = database
        .collection('users')
        .doc(user.email)
        .collection('more-details')
        .doc('User_History')
        .collection('User_Notification')
        .orderBy('Notify_CreatedAt', 'desc')
        .onSnapshot(
          (snapshot) => {
            const newNotifications = snapshot.docs.map((doc) => ({
              ...doc.data(),
            }));
           
            setTimeout(() => {
              setBackendNotification(newNotifications);
              setLoading(false);
            }, 1000);
          },
          (error) => {
            setError(error);
            setLoading(false);
          }
        );

      return () => unsubscribe();
    }
  }, []);

  // get user notification only once
  useEffect(() => {
    getNotification();
  }, [getNotification]);

  return (
    <div className={CurrentLanguage === 'EN' ? 'ENProfile-NotificationsSection' : 'Profile-NotificationsSection'}>
      <div className={CurrentLanguage === 'EN' ? 'ENProfile-NotificationsSection-Container' : 'Profile-NotificationsSection-Container'}>
        <div className='tableScroll' style={{ height: '300px' }}>
          {loading ? (
            <div style={{ width: '100%', height: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'center', color: '#D1D2D3' }}>
              <span>
                {CurrentLanguage === 'EN' ? 'Loading..' : 'جاري التحميل'}
              </span>
              <LazyLoadImage
                effect="blur"
                draggable={false}
                src={Search_Loading}
                alt='backendNotification'
                onContextMenu={(e) => e.preventDefault()}
                style={{ width: '20px', height: '20px', marginRight: '10px' }}
              />
            </div>
          ) : error ? (
            <div style={{ width: '100%', height: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'center', color: '#D1D2D3' }}>
              <span>
                {CurrentLanguage === 'EN' ? 'Error loading notifications' : 'حدث خطأ أثناء تحميل الإشعارات'}
              </span>
              <LazyLoadImage
                effect="blur"
                draggable={false}
                src={WrongMark}
                alt='backendNotification'
                onContextMenu={(e) => e.preventDefault()}
                style={{ width: '20px', height: '20px', marginRight: '10px' }}
              />
            </div>
          ) : backendNotification.length > 0 ? (
            backendNotification.slice(0, notificationWrapper).map((data) => (
              <Notification key={data.id} data={data ? data : null} />
            ))
          ) : (
            <div style={{ width: '100%', height: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'center', color: '#D1D2D3' }}>
              <span>{CurrentLanguage === 'EN' ? 'No Notifications' : 'لا يوجد إشعارات'}</span>
              <LazyLoadImage
                effect="blur"
                draggable={false}
                src={WrongMark}
                alt='backendNotification'
                onContextMenu={(e) => e.preventDefault()}
                style={{ width: '20px', height: '20px', marginRight: '10px' }}
              />
            </div>
          )}
          {notificationWrapper < backendNotification.length && !loading && !error && (
            <div
              onClick={() => {
                notificationWrapper < 10 ? setNotificationWrapper(notificationWrapper + 5) 
                : 
                // go the notification page
                window.location.href.includes('/myProfile') 
                ? setNotificationWrapper(notificationWrapper + 5) 
                : window.location.href = '/myProfile'
              }}
              style={{
                textDecoration: 'none',
                cursor: 'pointer',
                width: '50%',
                marginRight: 'auto',
                marginLeft: 'auto',
                padding: '5px',
                borderRadius: '25px',
                border: 'none',
                outline: 'none',
                fontSize: '1.09rem',
                marginBottom: '0px',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#D1D2D3',
                backgroundColor: '#F5F5F510',
              }}
            >
              <div>
                <span style={{ fontSize: CurrentLanguage === 'EN' ? '15px' : null }}>
                  {CurrentLanguage === 'EN' ? 'Load More' : 'تحميل المزيد'}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationSection;
