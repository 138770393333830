import React, { useState, useEffect, useMemo } from 'react';
import KSA_Flag from '../../assets/img/icons/KSA-region.svg';
import Europe_Flag from '../../assets/img/icons/Europe-region.svg';
import USA_Flag from '../../assets/img/icons/USA-region.svg';
import useMediaQuery from "@mui/material/useMediaQuery";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useAuth } from '../../xFireBase/AuthContext';

export const RETO_servers_Card = ({
  serverType,
  serverRegion,
  serverDesc,
  title,
  image,
  id,
  description,
  hostname,
  currentMap,
  maxPlayers,
  currentPlayers,
  gameType,
  ip,
  port,
  serverStatus,
  PlayersNow,
}) => {


  const { CurrentLanguage } = useAuth();
  
  const isNonMobile = useMediaQuery("(min-width:1200px)");
  const [imageHover, setImageHover] = useState(false);
  const [NowPlaying, setNowPlaying] = useState(false);
  const [NowplayerName, setNowplayerName] = useState([]);

  const SortOrder = { Owner: 1, User: 2 };

  const [Top3Players, setTop3Players] = useState({
    Top1: {},
    Top2: {},
    Top3: {},
  });

  const getNowPlaying = useMemo(() => {
    if (currentPlayers > 0) {
      setNowPlaying(true);
      setNowplayerName({
        ...NowplayerName,
        PlayersNow,
      });
    } else {
      setNowPlaying(false);
    }
  }, [currentPlayers, PlayersNow, NowplayerName]);

  useEffect(() => {
    if (serverStatus === true) {
      const interval = setInterval(() => {
        getNowPlaying();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [serverStatus, getNowPlaying]);

  useEffect(() => {
    if (currentPlayers > 0) {
      const interval = setInterval(() => {
        setNowplayerName({
          ...NowplayerName,
          PlayersNow,
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [currentPlayers, PlayersNow]);

  useEffect(() => {
    if (NowplayerName.PlayersNow) {
      const interval = setInterval(() => {
        const sortedPlayers = [...NowplayerName.PlayersNow].sort(
          (a, b) => b.score - a.score
        );
        setTop3Players({
          ...Top3Players,
          Top1: sortedPlayers[0] || {},
          Top2: sortedPlayers[1] || {},
          Top3: sortedPlayers[2] || {},
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [NowplayerName.PlayersNow]);

  const ShowingAllPlayersInfo = useMemo(() => {
    if (NowplayerName.PlayersNow && NowplayerName.PlayersNow.length > 0) {
      return (
        [...NowplayerName.PlayersNow]
          .sort((a, b) => b.score - a.score)
          .sort((a, b) => SortOrder[a.level] - SortOrder[b.level])
          .map((player, index) => (
            <tr key={index}>
              <td
                style={{
                  fontWeight: '500',
                  fontFamily:
                    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                }}
              >
                {player.level === 'Owner' ? `👑 ${player.name}` : `👤 ${player.name.length > 7 ? `${player.name.substring(0, 7)}...` : player.name}`}
              </td>
              <td style={{ color: 'gray' }}>{player.score.toLocaleString()}</td>
              <td style={{ color: player.ping >= 80 ? 'red' : player.ping >= 50 ? '#FFAF02' : '#07A52F' }}>{player.ping}</td>
            </tr>
          ))
      );
    } else {
      return null; // Or render a message like "No players"
    }
  }, [NowplayerName.PlayersNow, CurrentLanguage]);

  return (
    <div
      className='games-card'
      style={{
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out',
        boxShadow: serverDesc === 'zm' ? '0 0 10px #610808CB' : '0 0 10px #1551A0',
        maxWidth: '325px',
        maxHeight: '463.64px',
      }}
    >
      <div className="proj-imgbxContainer">
        <div 
        className="proj-imgbx"
        onMouseEnter={() => setImageHover(true)}
        onMouseLeave={() => setImageHover(false)}
        style={{ transition: 'all 0.4s ease-in-out' }}
        >
          <LazyLoadImage
            effect="blur"
            loading='lazy'
            alt='serverimage'
            draggable={false}
            src={image}
            style={{ height: isNonMobile ? '172px' : '', filter: imageHover ? 'grayscale(47.5%' : null, opacity: imageHover ? '0.8' : null}}
            onContextMenu={(e) => e.preventDefault()}
          />
          <div className="proj-txtx" style={{ zIndex: '99999'}}>
            <h5 style={{ textAlign: 'left', marginBottom: 10, marginLeft: 10, }}>Top 3: </h5>
            <h6 style={{
              textAlign: 'left',
              marginLeft: 10,
              fontFamily:
                '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
            }}>
              {Top3Players?.Top1?.name ? `🥇 ${Top3Players?.Top1?.name.length > 10 ? `${Top3Players?.Top1?.name.substring(0, 10)}...` : Top3Players?.Top1?.name}` : ''}
            </h6>

            <h6 style={{ textAlign: 'left', marginLeft: 10 }}>
              {Top3Players?.Top2?.name ? `🥈 ${Top3Players?.Top2?.name.length > 10 ? `${Top3Players?.Top2?.name.substring(0, 10)}...` : Top3Players?.Top2?.name}` : ''}
            </h6>

            <h6 style={{ textAlign: 'left', marginLeft: 10 }}>
              {Top3Players?.Top3?.name ? `🥉 ${Top3Players?.Top3?.name.length > 10 ? `${Top3Players?.Top3?.name.substring(0, 10)}...` : Top3Players?.Top3?.name}` : ''}
            </h6>

            <h6 style={{ color: serverDesc === 'zm' ? 'red' : 'orange' }}>
              {gameType === 'zclassic' || gameType === 'zstandard' ? 'Zombie' :
                gameType === 'dm' ? 'Free For All' :
                  gameType === 'sd' ? 'Search & Destroy' :
                    gameType === 'war' || gameType === 'tdm' ? 'Team DeathMatch' :
                      'Unknown'
              }</h6>
          </div>
        </div>
        <img
          loading='lazy'
          draggable={false}
          alt='serverflag'
          onContextMenu={(e) => e.preventDefault()}
          src={serverRegion === 'KSA' ? KSA_Flag : serverRegion === 'Europe' ? Europe_Flag : serverRegion === 'USA' ? USA_Flag : null}
          style={{
            width: 33,
            height: 33,
            borderRadius: 30,
            position: 'absolute',
            top: -5,
            left: 3,
          }}
        />
      </div>

      <div>
        <h4 style={{ fontSize: '1.2rem', flexDirection: 'row', justifyContent: 'space-between' }}>
          {title.length > 25 ? `${title.substring(0, 25)}..` : title} {serverStatus === true ? '🟢' : '🔴'}
        </h4>

        <h4 style={{
          fontSize: '1.2rem', flexDirection: 'row', color: serverDesc === 'zm' && currentPlayers < maxPlayers ? 'red' : serverDesc === 'zm' ? 'orange' : '#A04664', marginRight: 50
        }}>
          {`🎮${currentPlayers}/${maxPlayers}`}
        </h4>
        {CurrentLanguage === 'EN'
          ? <h4 style={{ color: '#3176CF', fontSize: '19px' }}>map : </h4>
          : <h4 style={{ color: '#3176CF' }}>: الماب</h4>
        }

        <h6 style={{
          fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
        }}>{currentMap}</h6>
        {CurrentLanguage === 'EN'
          ? <h4 style={{ color: '#3176CF', fontSize: '19px', marginTop: '10px' }}>ip + port : </h4>
          : <h4 style={{ color: '#3176CF' }}>: الأي بي + البورت</h4>
        }
        <span>{`connect ${ip}:${port}`}</span>
      </div>
      {CurrentLanguage === 'EN'
        ? <h4 style={{ color: '#3176CF', fontSize: '19px', marginTop: '10px' }}>Players : </h4>
        : <h4 style={{ color: '#3176CF' }}>: اللاعبين</h4>
      }
      <div className='RETOserversInfo'>
        {NowPlaying === true ?
          // if NowplayerName.PlayersNow.length > 5 then make the table scrollable
          NowplayerName.PlayersNow.length > 5 ?
            <div className='tableScroll' style={{justifyContent: 'left', alignItems: 'flex-start', textAlign: 'left'}}>
              <table>
                <thead>
                  <tr>
                    {CurrentLanguage === 'EN'
                      ? <th>Name</th>
                      : <th>الاسم</th>
                    }
                    {CurrentLanguage === 'EN'
                      ? <th>Score</th>
                      : <th>النقاط</th>
                    }
                    {CurrentLanguage === 'EN'
                      ? <th>Ping</th>
                      : <th>البنق</th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {ShowingAllPlayersInfo}
                </tbody>
              </table>
            </div>
            :
            <table>
              <thead>
                <tr>
                  {CurrentLanguage === 'EN'
                    ? <th>Name</th>
                    : <th>الاسم</th>
                  }
                  {CurrentLanguage === 'EN'
                    ? <th>Score</th>
                    : <th>النقاط</th>
                  }
                  {CurrentLanguage === 'EN'
                    ? <th>Ping</th>
                    : <th>البنق</th>
                  }
                </tr>
              </thead>
              <tbody>
                {ShowingAllPlayersInfo}
              </tbody>
            </table>
          :
          CurrentLanguage === 'EN' ? 'No players' : 'لا يوجد لاعبين'
        }
      </div>
    </div>
  );
};