import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { AppsCard } from "../../components/HomeMiddle/AppsCard";
import TrackVisibility from 'react-on-screen';
import useMediaQuery from "@mui/material/useMediaQuery";
import BannerSlider from "../../components/HomeTop/BannerSlider";
import Profile_Loading from '../../assets/img/icons/Profile_Loading.svg'
import { FaDownload, FaSortAmountDownAlt } from "react-icons/fa";
import { Filter } from "react-bootstrap-icons";
import AppFilterGenresMenu from './other/App-GenresFilter-Menu';
import AppFilterSortMenu from './other/App-SortFilter-Menu';
import { MdClose } from "react-icons/md";
import { RETO_defaultSettings } from "../../hooks/Others/RETO_data";

import Page_Helmet from "../../hooks/SEO/react_Helmet";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useAuth } from "../../xFireBase/AuthContext";
import { Link } from "react-router-dom";
import ScrollToTop from "../../ScrollToTop";

const ALLApps = () => {

  const { ALL_Apps, CurrentLanguage } = useAuth();


  const isNonMobile = useMediaQuery("(min-width:992px)");
  const isMobile = useMediaQuery("(max-width:521px)");

  const [appsWrapper, setAppsWrapper] = useState(12);
  const [LoadMoreApps, setLoadMoreApps] = useState(false);
  const [Stop_LoadMoreApps, setStop_LoadMoreApps] = useState(false);

  const [Top5CrackedApps, setTop5CrackedApps] = useState([]);


  const [something_happened, setSomething_happened] = useState(false);

  const [AppFilter_Genres, setAppFilter_Genres] = useState(false);

  const [AppFilter_Sort, setAppFilter_Sort] = useState(false);



  // every time RETO_defaultSettings[0].App_SelectedFilters || RETO_defaultSettings[0].App_SelectedSort changes, update the state of appsWrapper and re-render the component

  useEffect(() => {
    if (RETO_defaultSettings[0].App_SelectedFilters.length > 0 || RETO_defaultSettings[0].App_SelectedSort !== 'Newest') {
      setAppsWrapper(12);
    }
    if (RETO_defaultSettings[0].App_SelectedFilters.length > 0 || RETO_defaultSettings[0].App_SelectedSort === 'Newest') {
      setAppsWrapper(12);
    }
    else if (RETO_defaultSettings[0].App_SelectedFilters.length <= 0 || RETO_defaultSettings[0].App_SelectedSort === 'Newest') {
      setAppsWrapper(12);
    }
    else if (RETO_defaultSettings[0].App_SelectedFilters.length <= 0 && RETO_defaultSettings[0].App_SelectedSort !== 'Newest') {
      setAppsWrapper(12);
    }

  }, [RETO_defaultSettings[0].App_SelectedFilters, RETO_defaultSettings[0].App_SelectedSort]);


  // check if RETO_defaultSettings[0].App_SelectedFilters is not empty and every time it changes, update the state

  useEffect(() => {
    // get the apps from ALL_Apps and sort them by app_Downloaded_Times
    setTop5CrackedApps(ALL_Apps.sort((a, b) => b.app_Downloaded_Times - a.app_Downloaded_Times).slice(0, 5));
  }, [ALL_Apps]);

  if (!ALL_Apps || ALL_Apps.length <= 0 || ALL_Apps === undefined || ALL_Apps === null) {
    setTimeout(() => {
      setSomething_happened(true);
    }, 10000);
  }

  const LoadingMoreApps = () => {
    setLoadMoreApps(true)

    setTimeout(() => {
      setStop_LoadMoreApps(true)
    }, 1100)
    setTimeout(() => {
      setLoadMoreApps(false)
      setStop_LoadMoreApps(false)
      setAppsWrapper(appsWrapper + 12);
    }, 1200)
  }

  return (
    <div>
      <Page_Helmet
        title="Arab2Games | Apps"
        type="article"
        description="برامج مكركة مجانية و بدون فيروسات"
        image={RETO_defaultSettings[0].Website_Main_Logo}
        url={window.location.href ? window.location.href : "https://Arab2Games.com/"}
        keywords="A2G, Arab2Games, Arab2Game, arab2games, arab2game, عرب تو قيمز, عرب تو قيمنق, عرب 2 قيمز, apps, games, discord bots, arab2games, Arab2Games, Arab2Games.com, arab2games.com, arab2games.org, Arab2Games.org, arab2games.net, Arab2Games.net, arab2games.io, Arab2Games.io, arab2games.dev, Arab2Games.dev, crack, cracked, crack game, cracked game, crack games, cracked games, crack app, cracked app, crack apps, cracked apps, مكركة, كراك, العاب مكركة, مكركه, العاب مكركه, برامج مكركه, برامج مكركة, لعبة مكركة, لعبه مكركه, برنامج مكرك, العاب, لعبة, لعبه, برنامج, برامح, ريتو, لايك, لايك ريتو, arab2games, Arab2Games, ARAB2GAMES, Arab2Games, Arab2Games"
      />
      <ScrollToTop />
      <section className="gamesSection" id="A2G_apps">
        <Container>
          <Row>
            <Col size={12}>
              <div>
                <div style={{ marginRight: isNonMobile ? '-40px' : '' }}>
                  <p style={{ width: '76%', color: '#ffffff' }}>
                    {CurrentLanguage === 'EN'
                      ? 'if you didn\'t find the App you want, let me know on social media & I will provide it for you'
                      : 'ودك ببرنامج بس ما حصلتها ؟ تواصل معاي على السوشال ميديا و راح اوفرها'
                    }
                  </p>
                  <p style={{ direction: 'rtl', width: '76%', color: '#C2C0C0' }}>
                    {CurrentLanguage === 'EN'
                      ? 'Note: All Apps either Original free / Cracked & free .. all the Original paid versions are in the store❗'
                      : '❗ملاحظة: البرامج كلها مجانية أصلية او مكركة مجانية.. النسخ الأصلية بالمتجر'
                    }
                    <a href='https://likereto.store/' target='_blank' rel='noreferrer'>
                      {CurrentLanguage === 'EN'
                        ? 'click here'
                        : ' اضغط هنا'
                      }
                    </a>
                  </p>
                </div>

                <div style={{
                  flexDirection: isNonMobile ? 'row' : 'column', display: 'flex', alignItems: isNonMobile ? 'flex-start' : 'center', justifyContent: 'center',
                  alignContent: 'center', direction: 'ltr', width: '100%', maxWidth: '1300px', height: '100%'
                }}>

                  {ALL_Apps && ALL_Apps.length > 0 && ALL_Apps !== undefined && ALL_Apps !== null &&
                    <div style={{ marginTop: '30px' }}>
                      <BannerSlider docs_Apps={ALL_Apps ? ALL_Apps : []} />
                    </div>
                  }

                  <div style={{
                    width: isNonMobile ? '40%' : 'auto', height: '100%', marginBottom: '40px', display: 'flex',
                    justifyContent: 'flex-start', alignItems: 'flex-start', alignContent: 'flex-start', flexDirection: 'column', direction: 'rtl'
                  }}>
                    <h5 style={{ marginRight: '20px' }}>
                      {CurrentLanguage === 'EN'
                        ? 'Top downloaded this week'
                        : 'الأكثر تحميلاً هذا الأسبوع'
                      }
                    </h5>

                    <div style={{
                      width: isNonMobile ? '100%' : 'auto', height: '100%', margin: isNonMobile ? '' : '40px 0px', display: 'flex',
                      justifyContent: 'flex-start', alignItems: 'flex-start', alignContent: 'flex-start', flexDirection: 'row', direction: 'rtl'
                    }}>
                      <Col
                        size={10}
                        sm={6}
                        md={3}
                        style={{
                          height: '100%', marginRight: isNonMobile ? '' : '50px', borderRadius: 10, marginBottom: 20, padding: '10px', right: '0px',
                          boxShadow: '0 0 10px #1551A0', width: '80%'
                        }}>
                        {Top5CrackedApps.map((doc, index) => {
                          return (
                            <Link
                              key={index}
                              to={`/A2G_apps/${doc.app_Number}/${doc.app_Page}`}
                              target="_self"
                              rel="noreferrer"
                              style={{ textDecoration: 'none', color: '#fff' }}
                            >
                              <div className='Top-5Games-inner' style={{ marginLeft: "10px" }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', alignContent: 'center', direction: 'rtl', width: '200px', height: '100%' }}>
                                  <LazyLoadImage
                                    effect="blur"
                                    draggable={false}
                                    style={{ borderRadius: '10px' }}
                                    src={doc.app_Picture[0]}
                                    alt='top5-img'
                                    onContextMenu={(e) => e.preventDefault()}
                                  />
                                </div>
                                <div className='Top-5Games-right-part'>
                                  <div className='Top-5Games-content'>
                                    <div className='Top-5Games-content-gameName' style={{ marginLeft: CurrentLanguage === 'EN' ? '3px' : '' }}>
                                      {doc.app_Name.length > 27 ? doc.app_Name.slice(0, 27) + '...' : doc.app_Name}
                                    </div>
                                  </div>
                                  <div style={{
                                    flexDirection: 'column', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', alignContent: 'flex-start',
                                    direction: 'ltr'
                                  }}>
                                    <span style={{ marginLeft: '5px', color: 'rgb(163, 163, 163)', display: 'flex', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
                                      {doc.app_Downloaded_Times}
                                      <FaDownload style={{ color: '#B67CF8AF', marginLeft: '3px' }} />
                                    </span>
                                    <span style={{
                                      direction: 'ltr', marginLeft: '5px',
                                      color: 'rgb(163, 163, 163)'
                                    }}>
                                      {doc.app_Size}
                                    </span>
                                  </div>

                                </div>
                              </div>
                            </Link>
                          )
                        })}

                      </Col>
                    </div>
                  </div>
                </div>

                <div>

                  <header style={{
                    marginBottom: '35px', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', textAlign: 'right', display: 'flex',
                    justifyContent: 'space-between', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row',
                  }}>
                    <div style={{
                      direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', textAlign: 'center', display: 'flex', justifyContent: 'center',
                      alignItems: 'center', flexDirection: 'row',
                    }}>
                      <h4 style={{ color: '#ff434c', marginLeft: CurrentLanguage === 'EN' ? '' : '10px', marginRight: CurrentLanguage === 'EN' ? '10px' : '' }}>|</h4>
                      <h4>
                        {CurrentLanguage === 'EN'
                          ? 'Latest Apps'
                          : 'اَخر البرامج'
                        }</h4>
                    </div>

                    <div style={{ marginTop: '30px', width: isMobile ? '80%' : '40%', marginLeft: CurrentLanguage === 'EN' ? isMobile ? null : 'auto' : null }}>
                      <Tab.Container id="gamesSection-tabs">
                        <Nav
                          variant="pills"
                          className="nav-pills mb-5 justify-content-center align-items-center"
                          id="pills-tab"
                          style={{ transform: isMobile ? 'scale(0.9)' : '', direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl' }}>

                          <Nav.Item style={{ margin: '0 auto 0 auto', width: '50%' }}>
                            <Nav.Link
                              onClick={() => {
                                setTimeout(() => {
                                  setAppFilter_Sort(false)
                                  setAppFilter_Genres(!AppFilter_Genres)
                                }, 200)
                              }}
                              eventKey="first"
                              style={{
                                flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center', alignContent: 'center',
                                direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr', textAlign: 'center',
                                borderRight: CurrentLanguage === 'EN' ? '1px solid rgba(255, 255, 255, 0.5)' : null,
                                borderLeft: CurrentLanguage === 'EN' ? null : '1px solid rgba(255, 255, 255, 0.5)'
                              }}
                            >
                              <span>{CurrentLanguage === 'EN' ? 'filter' : 'التصنيف'}</span>
                              {AppFilter_Genres
                                ?
                                <MdClose style={{
                                  marginLeft: CurrentLanguage === 'EN' ? null : '5px', transform: 'scale(1.2)',
                                  marginRight: CurrentLanguage === 'EN' ? '5px' : null
                                }} />
                                :
                                <Filter style={{
                                  marginLeft: CurrentLanguage === 'EN' ? null : '5px', transform: 'scale(1.2)',
                                  marginRight: CurrentLanguage === 'EN' ? '5px' : null
                                }} />
                              }

                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item style={{ margin: '0 auto 0 auto', width: '50%' }}>
                            <Nav.Link
                              onClick={() => {
                                setTimeout(() => {
                                  setAppFilter_Genres(false)
                                  setAppFilter_Sort(!AppFilter_Sort)
                                }, 200);
                              }}
                              eventKey="second"
                              style={{
                                flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center', alignContent: 'center',
                                direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', textAlign: 'center',
                                borderLeft: CurrentLanguage === 'EN' ? '1px solid rgba(255, 255, 255, 0.5)' : null,
                                borderRight: CurrentLanguage === 'EN' ? null : '1px solid rgba(255, 255, 255, 0.5)',
                                marginLeft: CurrentLanguage === 'EN' ? '20px' : null,
                                marginRight: CurrentLanguage === 'EN' ? null : '20px'
                              }}
                            >
                              <span>{CurrentLanguage === 'EN' ? 'sort' : 'الترتيب'}</span>
                              {AppFilter_Sort
                                ?
                                <MdClose style={{
                                  marginLeft: CurrentLanguage === 'EN' ? '5px' : null, transform: 'scale(1.2)',
                                  marginRight: CurrentLanguage === 'EN' ? null : '5px'
                                }} />
                                :
                                <FaSortAmountDownAlt style={{
                                  marginLeft: CurrentLanguage === 'EN' ? '5px' : null, transform: 'scale(1.2)',
                                  marginRight: CurrentLanguage === 'EN' ? null : '5px'
                                }} />

                              }
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Tab.Container>
                    </div>
                  </header>

                  {AppFilter_Genres &&
                    <div className={AppFilter_Genres ? "GameFilter_Genres_fadeIn" : "GameFilter_Genres_fadeOut"}>
                      <AppFilterGenresMenu />
                    </div>
                  }
                  {AppFilter_Sort &&
                    <div className={AppFilter_Sort ? "GameFilter_Genres_fadeIn" : "GameFilter_Genres_fadeOut"}>
                      <AppFilterSortMenu />
                    </div>
                  }


                  <Row
                    className={AppFilter_Genres ? "GameFilter__Active_MoveDown_Smoothly" : null}
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      alignContent: 'center',
                      direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                    }}
                  >
                    {
                      ALL_Apps.length > 0 ?
                        ALL_Apps
                          .filter((doc) => {
                            if (RETO_defaultSettings[0].App_SelectedFilters.length > 0) {
                              return RETO_defaultSettings[0].App_SelectedFilters.some((filter) => {
                                // Convert filter and doc.app_Genres to lowercase before comparison
                                return doc.app_Genres.some((genre) => genre.toLowerCase().includes(filter.toLowerCase()));
                              });
                            } else {
                              return doc;
                            }
                          })
                          // then check if the RETO_defaultSettings[0].App_SelectedSort is not empty, then sort the apps by the selected sort
                          .sort((a, b) => {
                            if (RETO_defaultSettings[0].App_SelectedSort.length > 0) {
                              if (RETO_defaultSettings[0].App_SelectedSort === 'TopDownloaded') {
                                return b.app_Downloaded_Times - a.app_Downloaded_Times;
                              } else if (RETO_defaultSettings[0].App_SelectedSort === 'TopWatched') {
                                return b.app_watched_times.watched_By.length - a.app_watched_times.watched_By.length;
                              } else if (RETO_defaultSettings[0].App_SelectedSort === 'TopRated') {
                                return b.app_Reviews - a.app_Reviews;
                              } else if (RETO_defaultSettings[0].App_SelectedSort === 'Newest') {
                                return new Date(b.createdAt) - new Date(a.createdAt);
                              } else if (RETO_defaultSettings[0].App_SelectedSort === 'Oldest') {
                                return new Date(a.createdAt) - new Date(b.createdAt);
                              } else if (RETO_defaultSettings[0].App_SelectedSort === 'A-Z') {
                                return a.app_Name.localeCompare(b.app_Name);
                              } else if (RETO_defaultSettings[0].App_SelectedSort === 'Z-A') {
                                return b.app_Name.localeCompare(a.app_Name);
                              }
                            } else {
                              return new Date(b.createdAt) - new Date(a.createdAt);
                            }
                          })
                          .slice(0, appsWrapper)
                          .map((doc, index) => {

                            return (
                              <Col xs={6} sm={6} md={6} lg={4} xl={3} key={index}>
                                <Link
                                  to={`/A2G_apps/${doc.app_Number}/${doc.app_Page}`}
                                  style={{ textDecoration: 'none', color: '#fff' }}
                                  rel='noreferrer'
                                >
                                  <AppsCard
                                    key={index}
                                    All_appData={doc}
                                  />
                                </Link>
                              </Col>
                            )
                          })
                        :
                        <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center' }}>
                          {something_happened === false ?
                            <LazyLoadImage
                              effect="blur"
                              alt="Profile_Loading"
                              draggable={false}
                              src={Profile_Loading}
                              onContextMenu={(e) => e.preventDefault()}
                              style={{ width: '30px', height: '30px', display: 'flex' }} />
                            :
                            null
                          }
                          {something_happened === false && ALL_Apps.length <= 0 ?
                            <h1>{CurrentLanguage === 'EN'
                              ? 'Loading Apps'
                              : 'جاري تحميل البرامج'}</h1>
                            :
                            something_happened === true && ALL_Apps.length <= 0 ?
                              <h3>{CurrentLanguage === 'EN'
                                ? 'Something went wrong ❌'
                                : '❌ حدث خطأ ما'}
                              </h3>
                              :
                              null
                          }
                        </div>
                    }

                    {appsWrapper < ALL_Apps.length && ALL_Apps.length > 12 && Stop_LoadMoreApps === false ?
                      <div
                        style={{
                          textDecoration: 'none',
                          cursor: 'pointer',
                          width: '150%',
                          height: '100%',
                          padding: '10px',
                          borderRadius: '25px',
                          border: 'none',
                          outline: 'none',
                          fontSize: '1.2rem',
                          marginBottom: '0px',
                          textAlign: 'center',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: '#fff',
                        }}
                      >
                        <TrackVisibility>
                          {({ isVisible }) =>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              {isVisible ? LoadingMoreApps() : null}
                              <span style={{ opacity: '0' }}>.</span>
                              {LoadMoreApps === true ?
                                <LazyLoadImage
                                  effect="blur"
                                  draggable={false}
                                  src={Profile_Loading}
                                  alt='Profile_Loading'
                                  style={{ width: '50px', height: '50px', display: 'flex' }} />
                                :
                                null
                              }
                            </div>
                          }
                        </TrackVisibility>
                      </div>
                      :
                      null
                    }
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default ALLApps;


/*
                    <h2 style={{ marginTop: '30px', transform: 'scale(0.6)' }}>
                      {CurrentLanguage === 'EN'
                      ? 'All Apps don\'t have any virus,  dont worry' 
                      : 'كل البرامج خالية من الفيروسات فلا تشيل هم'
                      }
                    </h2>
*/