import { useState, useEffect, useRef } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import useMediaQuery from "@mui/material/useMediaQuery";
import navIcon1 from '../../assets/img/icons/discord.svg';
import navIcon3 from '../../assets/img/icons/instagram.svg';
import UserProffileIcon from '../../assets/img/icons/user-profile-icon.svg';
import UserLoggedInIcon from '../../assets/img/icons/person-circle.svg';
import userIcon from '../../assets/icons/Dropdown/Profile/person-fill-gear.svg'
import editIcon from '../../assets/icons/Dropdown/Profile/list-ul.svg'
import NotificationIcon from '../../assets/icons/Dropdown/Profile/bell.svg'
import NotificationIcon_2 from '../../assets/icons/Dropdown/Profile/bell-fill.svg'
import WatchedHistory from '../../assets/icons/Dropdown/Profile/clock-history.svg'
import { RETO_defaultSettings } from "../../hooks/Others/RETO_data";
import logoutIcon from '../../assets/icons/Dropdown/Profile/box-arrow-left.svg'
import KSA_Flag from '../../assets/img/icons/KSA-region.svg'
import USA_Flag from '../../assets/img/icons/USA-region.svg'
import { firebase, database } from '../../xFireBase/Storage';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { MdOutlineDarkMode } from "react-icons/md";


import {
  useLocation
} from "react-router-dom";
import {
  FaStar,
  FaSearch,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { MdClose, MdKeyboardBackspace } from "react-icons/md";
import { CSSTransition } from "react-transition-group";
import NotificationSection from "../../hooks/Profile_Notifications/NotificationSection";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../xFireBase/AuthContext";

export const NavBar = () => {

  const { CurrentLanguage, darkMode, toggleDarkMode } = useAuth();
  const isNonMobile = useMediaQuery("(min-width:768px)");
  const [displayName, setDisplayName] = useState('');
  const [userNotification, setUserNotification] = useState([]);

  const [BanMessage_Sent, setBanMessage_Sent] = useState(false);

  const [userProfilePicture, setUserProfilePicture] = useState('');

  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState('MainProfile');

  const [openLanguageMenu, setOpenLanguageMenu] = useState(false);
  const [LanguageMenu, setLanguageMenu] = useState('LanguageMenu');
  const [language_changed, setLanguage_changed] = useState(false);

  const [MenuHeight, setMenuHeight] = useState(null);

  const [darkMode_Btn_Hover, setDarkMode_Btn_Hover] = useState(false);

  const [Expand, setExpand] = useState(false);
  const [activeLink, setActiveLink] = useState('');
  const [scrolled, setScrolled] = useState(false);
  const [currentPath, setCurrentPath] = useState('/');
  const location = useLocation();

  // calc the height of the menu
  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }


  // check only once if the user have a profile picture or not
  useEffect(() => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      database
        .collection("users")
        .doc(currentUser.email)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const profilepicture = doc.data().User_Profile_Picture;
            setUserProfilePicture(profilepicture);
          }
        })
    }
  }, [])

  const sendBanMessage = (BanDetails) => {
    setBanMessage_Sent(true);

    if (BanDetails.User_Ban_Status === true) {
      setBanMessage_Sent(true);

      setTimeout(() => {
        toast.info(`${CurrentLanguage === 'EN' ? 'Checking' : 'جاري التحقق'}`, {
          toastId: 'BanMessage_2',
        })
      }, 1500);
      // ban_ExpirationDate is like this : 10/25/2023, 12:00:00 AM. So we need to split it to get the date only
      const ban_ExpirationDate_Date = BanDetails.User_Ban_ExpirationDate.split(',')[0];
      const currentDate = new Date(); // Get the current date
      const expirationDate = new Date(ban_ExpirationDate_Date);
      setTimeout(() => {
        if (expirationDate <= currentDate && BanDetails.User_Ban_Type === 'temporary') {
          // Ban expiration is in the past
          toast.success(`
            ${CurrentLanguage === 'EN'
              ? `You are no longer banned`
              : `لقد تم رفع الحظر عنك !`}
            `, {
            toastId: 'BanMessage3',
          })
        }
        else {
          toast.error(`
         ${CurrentLanguage === 'EN'
              ? `You are banned
            ${BanDetails.User_Ban_Type === 'permanent' ? 'permanently' : 'temporarily'}
            because of | ${BanDetails.User_Ban_Reason_EN} |
            ${BanDetails.User_Ban_Type === 'temporary' ? `until ${expirationDate.toLocaleString('en-US', { timeZone: 'Asia/Riyadh' }).split(',')[0]}` : ''}
            Contact the administration on Discord if there is an error
        `
              : `لقد تم حظرك 
          ${BanDetails.User_Ban_Type === 'permanent' ? 'مؤبد' : BanDetails.User_Ban_Type === 'temporary' ? 'مؤقتاً' : ','}
          بسبب | ${BanDetails.User_Ban_Reason} |
          ${BanDetails.User_Ban_Type === 'temporary' ? `حتى ${expirationDate.toLocaleString('en-US', { timeZone: 'Asia/Riyadh' }).split(',')[0]}` : ''}
          تواصل مع الإدارة في الدسكورد في حال وجود خطأ
        `}
        `, {
            toastId: 'BanMessage',
          })
        }
      }, 5200);

      setTimeout(() => {
        if (expirationDate <= currentDate && BanDetails.User_Ban_Type === 'temporary') {
          database.collection('users')
            .doc(firebase?.auth()?.currentUser?.email)
            .update({
              User_Ban_Status: false,
              User_Status: 'متصل',
              User_Last_Login: new Date().toLocaleString('en-US', { timeZone: 'Asia/Riyadh' }),
            })
            .then(() => {
              database.collection('users')
                .doc(firebase?.auth()?.currentUser?.email)
                .get()
                .then((doc) => {
                  database.collection('users')
                    .doc('@important')
                    .collection('more-details')
                    .doc('Banned_Users')
                    .collection('Banned_List')
                    .doc(doc.data().User_uid)
                    .delete()
                })
            }
            )
        }
        else {
          database.collection('users')
            .doc(firebase?.auth()?.currentUser?.email)
            .update({
              User_Status: `مبند ${BanDetails.User_Ban_Type === 'permanent' ? 'مؤبد' : 'مؤقت'}`,
              User_Last_Login: new Date().toLocaleString('en-US', { timeZone: 'Asia/Riyadh' }),
            })
            .then(() => {
              database.collection('users')
                .doc(firebase?.auth()?.currentUser?.email)
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    // add a new document to the banned users collection
                    database.collection('users')
                      .doc('@important')
                      .collection('more-details')
                      .doc('Banned_Users')
                      .collection('Banned_List')
                      .doc(doc.data().User_uid)
                      .get()
                      .then((snap) => {
                        if (!snap.exists) {
                          database.collection('users')
                            .doc('@important')
                            .collection('more-details')
                            .doc('Banned_Users')
                            .collection('Banned_List')
                            .doc(doc.data().User_uid)
                            .set({
                              User_Ban_Status: doc.data().User_Ban_Status,
                              User_IP_Address: doc.data().User_IP_Address,
                              User_Email: doc.data().User_Email,
                              User_uid: doc.data().User_uid,
                              User_Ban_Reason: BanDetails.User_Ban_Reason,
                              User_Ban_Reason_EN: BanDetails.User_Ban_Reason_EN,
                              User_Ban_Type: BanDetails.User_Ban_Type,
                              User_Ban_By: BanDetails.User_Ban_By,
                              User_unBan_By: BanDetails.User_unBan_By,
                              User_Ban_Date: BanDetails.User_Ban_Date,
                              User_Ban_ExpirationDate: BanDetails.User_Ban_ExpirationDate,
                            })
                        }
                      })
                      .then(() => {
                        database.collection('users')
                          .doc(firebase?.auth()?.currentUser?.email)
                          .onSnapshot((doc) => {
                            if (doc.data().User_Status.includes('مبند')) {
                              firebase?.auth()?.signOut()
                                .then(() => {
                                  window.location.href = '/';
                                })
                            }
                            else {
                              toast.error('حدث خطأ ما.. ');
                            }
                          })

                      })
                  }
                })
            }
            )
        }
      }, 7300);
    }
  }

  const get_BanStatus = (data) => {
    if (data.User_Ban_Status === true) {
      if (BanMessage_Sent === false) {
        sendBanMessage(data);
      }
    }
  }

  useEffect(() => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      database
        .collection("users")
        .doc(currentUser.email)
        .get()
        .then((doc) => {
          if (doc.exists) {
            database.collection('users')
              .doc('@important')
              .collection('more-details')
              .doc('Banned_Users')
              .collection('Banned_List')
              .doc(doc.data().User_uid)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  const data = doc.data();
                  get_BanStatus(data);
                }
              })
          }
        })
    }
  }, [])

  // get user notification only once 
  const get_Notification = useCallback(() => {
    if (firebase.auth().currentUser) {
      database
        .collection('users')
        .doc(firebase.auth().currentUser.email)
        .collection('more-details')
        .doc('User_History')
        .collection('User_Notification')
        .orderBy('Notify_CreatedAt', 'desc')
        .onSnapshot((snapshot) => {
          if (snapshot.docs.length > 0) {

            snapshot.docs.map(doc => {
              return doc.data().Notify_Status !== 'opened' ?
                setUserNotification(snapshot.docs.map(doc => ({
                  id: doc.id,
                  data: doc.data()
                })))
                :
                null
            })
          }
          else {
            setUserNotification([])
          }
        })
    }
  }, []);

  // get user notification only once
  useEffect(() => {
    get_Notification();
  }, [get_Notification]);

  useEffect(() => {
    async function getUserData() {
      const currentUser = firebase.auth().currentUser;
      if (currentUser && currentUser.displayName) {
        setDisplayName(currentUser.displayName);
      }
    }
    getUserData();
  }, [displayName]);

  let ProfileMenuRef = useRef();
  let LanguageMenuRef = useRef();
  let NavBarMenuRef = useRef();

  // NavBarMenuRef
  useEffect(() => {
    let ProfileMenuHandler = (event) => {
      if (NavBarMenuRef.current && !NavBarMenuRef.current.contains(event.target)) {
        setExpand(false);
      }
    }
    document.addEventListener("mousedown", ProfileMenuHandler);
  }, []);

  // ProfileMenuRef
  useEffect(() => {
    let ProfileMenuHandler = (event) => {
      if (ProfileMenuRef.current && !ProfileMenuRef.current.contains(event.target)) {
        setOpenProfileMenu(false);
        setActiveMenu('MainProfile')
      }
    }
    document.addEventListener("mousedown", ProfileMenuHandler);
  }, []);

  // LanguageMenuRef
  useEffect(() => {
    let LanguageMenuHandler = (event) => {
      if (LanguageMenuRef.current && !LanguageMenuRef.current.contains(event.target)) {
        setOpenLanguageMenu(false);
        setLanguageMenu('LanguageMenu')
      }
    }
    document.addEventListener("mousedown", LanguageMenuHandler);
  }, []);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  useEffect(() => {
    if (currentPath !== '/' && !currentPath.startsWith('/')) {
      setCurrentPath('/');
    }
  }, [currentPath]);

  useEffect(() => {
    const onScroll = () => {
      isNonMobile ?
        window.scrollY > 120 ? setScrolled(true) : setScrolled(false)
        :
        window.scrollY > 70 ? setScrolled(true) : setScrolled(false)
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [isNonMobile])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
    setExpand(false);
  }


  // ProfileDropDown Component
  function ProfileDropDown(props) {

    const handleNotifications = () => {
      if (props.text === 'الإشعارات' || props.text === 'Notifications') {
        userNotification.map((item) => {
          return item.Notify_Status !== 'opened' ?
            database.collection('users')
              .doc(firebase?.auth()?.currentUser?.email)
              .collection('more-details')
              .doc('User_History')
              .collection('User_Notification')
              .doc(item.id)
              .update({
                Notify_Status: 'opened',
              })
              .then(() => {
                setUserNotification([]);
              })
            :
            null
        })
      }
    }
    const checkNotifications_opendOrNot = () => {
      let count = 0;
      userNotification.map((item) => {
        // return the count of the notifications that are not opened yet
        return item.data.Notify_Status !== 'opened' ? count++ : null;
      })
      return count;
    }

    const handleSignOut = async () => {
      if (props.text === 'تسجيل الخروج' || props.text === 'Logout') {
        try {
          await database.collection('users')
            .doc(firebase?.auth()?.currentUser?.email)
            .update({
              User_Status: 'غير متصل',
              User_Last_Login: new Date().toLocaleString('en-US', { timeZone: 'Asia/Riyadh' }),
            })
            .then(() => {
              database.collection('users')
                .doc(firebase?.auth()?.currentUser?.email)
                .onSnapshot((doc) => {
                  if (doc.data().User_Status === 'غير متصل') {
                    firebase?.auth()?.signOut()
                  }
                  else {
                    toast.error('حدث خطأ ما.. ');
                  }
                })

            })
            .then(() => {
              // refresh the page after sign out
              window.location.reload();
            })

        } catch (error) {
          toast.error('حدث خطأ ما.. حاول مرة اخرى');
        }
      }
      else {
        return null;
      }
    }
    return (
      <li
        style={{
          opacity: props.text === 'سجل المشاهدة' || props.text === 'Watched History'
            || props.text === 'المشاهدة لاحقاً' || props.text === 'Watch Later' ? '0.5' : null,
          // and cross the text by line if the text is 'Watched History' or 'المشاهدة لاحقاً'
          textDecoration: props.text === 'سجل المشاهدة' || props.text === 'Watched History'
            || props.text === 'المشاهدة لاحقاً' || props.text === 'Watch Later' ? 'line-through' : null,
        }}
        onClick={() => {
          if (props.text === 'تسجيل الخروج' || props.text === 'Logout') {
            handleSignOut();
          }
          else if (props.text === 'الإشعارات' || props.text === 'Notifications') {
            setActiveMenu('Profile_Notifications');
          }
          else {
            return null;
          }
        }}
        className={CurrentLanguage === 'EN' ? "ENProfile-Dropdown-items" : "Profile-Dropdown-items"}
      >
        <LazyLoadImage
          effect="blur"
          draggable={false}
          src={props.img}
          alt="icon"
          style={{ width: '100%', height: '100%', maxWidth: '20px', maxHeight: '20px' }}
          onContextMenu={(e) => e.preventDefault()}
        />
        <div> {props.text === 'الإشعارات' || props.text === 'Notifications'
          ?
          <div onClick={() => handleNotifications()}>
            <span>
              <span
                style={{
                  color: darkMode === 'light' ? '#000' : '#fff',
                  fontWeight: 'bold',
                  fontSize: '16px',
                }}
              >
                {props.text}
              </span>

              {userNotification.length > 0 ?
                <span style={{
                  color: '#FFFFFF', marginRight: CurrentLanguage === 'EN' ? null : '50px',
                  marginLeft: CurrentLanguage === 'EN' ? '5px' : null,
                  borderRadius: '20px', background: '#DB2A2A', padding: '0 7px'
                }}>
                  {userNotification.length > 0 && userNotification.length <= 99 ? checkNotifications_opendOrNot()
                    : userNotification.length > 99 ? '99+'
                      : '0'}
                </span>
                : null
              }
            </span>
          </div>
          :
          <span
            style={{
              color: darkMode === 'light' ? '#000' : '#fff',
              fontWeight: 'bold',
              fontSize: '16px',
            }}
          >
            {props.text}
          </span>
        }
        </div>
      </li>

    )
  }


  // LanguageDropDown Component
  function LanguageDropDown(props) {

    const handleLanguageChanger = () => {
      setLanguage_changed(true);
      try {
        if (props.text === 'العربية' || props.text === 'Arabic') {
          setOpenLanguageMenu(!openLanguageMenu);
          setLanguageMenu(false);
          if ((props.text === 'العربية' || props.text === 'Arabic') && localStorage.getItem('User_Language') !== 'AR') {
            setTimeout(() => {
              // update the local item to the selected language
              localStorage.setItem('User_Language', 'AR');
              window.location.reload()
            }, 200);
          }
        }
        else if (props.text === 'الإنجليزية' || props.text === 'English') {
          setOpenLanguageMenu(!openLanguageMenu);
          setLanguageMenu(false);
          if ((props.text === 'الإنجليزية' || props.text === 'English') && localStorage.getItem('User_Language') !== 'EN') {
            setTimeout(() => {
              // update the local item to the selected language
              localStorage.setItem('User_Language', 'EN');
              window.location.reload()
            }, 200);
          }
        }
      }
      catch (error) {
        console.log(error);
      }
    }

    return (
      <li
        onClick={() => {
          // if it is the same language that the user is using, then do nothing
          if (props.text === 'العربية' && CurrentLanguage === 'AR' || props.text === 'Arabic' && CurrentLanguage === 'AR'
            || props.text === 'الإنجليزية' && CurrentLanguage === 'EN' || props.text === 'English' && CurrentLanguage === 'EN') {
            return null;
          }
          else {
            handleLanguageChanger();
          }
        }}
        className={CurrentLanguage === 'EN' ? 'ENLanguageMenu-Dropdown-items' : "LanguageMenu-Dropdown-items"}
      >
        <LazyLoadImage
          effect="blur"
          draggable={false}
          src={props.img}
          alt="icon"
          style={{ width: '100%', height: '100%', maxWidth: '20px', maxHeight: '20px' }}
          onContextMenu={(e) => e.preventDefault()} />
        <div> {props.text === 'العربية' || props.text === 'Arabic' || props.text === 'الإنجليزية' || props.text === 'English' ?
          <div>
            <span style={{
              color: (props.text === 'العربية' || props.text === 'Arabic') && CurrentLanguage === 'AR'
                ? darkMode === 'light' ? '#32AEDF' : '#3993B6'
                : (props.text === 'الإنجليزية' || props.text === 'English') && CurrentLanguage === 'EN'
                  ? darkMode === 'light' ? '#32AEDF' : '#3993B6'
                  : darkMode === 'light' ? '#000' : '#fff',
              fontWeight: 'bold',
              fontSize: '16px',
            }}
            >
              {props.text}
            </span>
          </div>
          :
          <span
            style={{
              color: darkMode === 'light' ? '#000' : '#fff',
            }}
          >
            {props.text}
          </span>
        }
        </div>
      </li>

    )
  }


  return (
    <div style={{
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex'
    }}
    >
      <Navbar expanded={Expand} expand="md" className={scrolled ? "scrolled" : "notscrolled"}
        style={{ direction: isNonMobile ? CurrentLanguage === 'EN' ? 'ltr' : 'rtl' : 'ltr' }}>
        <Container style={{ width: '100%', }}>
          <Navbar.Brand href="/" style={{ width: '60px' }}>
            <LazyLoadImage
              effect="blur"
              draggable={false}
              src={RETO_defaultSettings[0].Website_Main_Logo}
              alt="Logo"
              onContextMenu={(e) => e.preventDefault()}
              style={{
                width: '54px',
                height: '54px',
                borderRadius: '40%',
              }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpand(!Expand)}>
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>

          <Navbar.Collapse ref={NavBarMenuRef} id="basic-navbar-nav">
            <Nav className={CurrentLanguage === 'EN' ? null : "ms-auto"} defaultActiveKey="#home">
              <Nav.Link
                href={'/'}
                className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'}
                onClick={() => onUpdateActiveLink('home')}
              >
                {CurrentLanguage === 'EN' ? 'Home' : 'صفحة الرئيسية'}
              </Nav.Link>
              <Nav.Link
                href={'/A2G_games'}
                className={activeLink === 'A2G_games' ? 'active navbar-link' : 'navbar-link'}
                onClick={() => onUpdateActiveLink('A2G_games')}>
                {CurrentLanguage === 'EN' ? 'Games' : 'الألعاب'}
              </Nav.Link>
              <Nav.Link
                href={'/A2G_apps'}
                className={activeLink === 'A2G_apps' ? 'active navbar-link' : 'navbar-link'}
                onClick={() => onUpdateActiveLink('A2G_apps')}>
                {CurrentLanguage === 'EN' ? 'Apps' : 'التطبيقات'}
              </Nav.Link>
              <Nav.Link
                href={'/RETO_servers'}
                className={activeLink === 'RETO_servers' ? 'active navbar-link' : 'navbar-link'}
                onClick={() => onUpdateActiveLink('RETO_servers')}>
                {CurrentLanguage === 'EN' ? 'RETO Servers' : 'سيرفرات RETO'}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Link to="/A2G_promo" style={{ textDecoration: 'none' }} >
            <div className='RETOpromo'>
              <i className="fas fa-star" style={{ textDecoration: 'none', fontSize: '1.05rem', }}>
                <FaStar style={{ fontSize: '1.1rem', marginTop: '-3.5px' }} />
              </i>
              <span style={{ fontSize: '1.093rem', marginRight: '5px' }}>
                {CurrentLanguage === 'EN' ? 'Donation' : 'Donation'}
              </span>
            </div>
          </Link>

          <div className="social-icon margin-" style={{ marginTop: '10px' }}>
            <a
              href="https://discord.gg/jwx23GMAXm"
              target="_blank"
              rel="noreferrer"
              className="discord"
            >
              <img
                loading="lazy"
                draggable={false}
                src={navIcon1}
                alt="navIcon1"
                style={{ width: '100%', height: '100%', maxWidth: '14px', maxHeight: '14px' }}
                onContextMenu={(e) => e.preventDefault()} />
            </a>
            <a
              href="https://www.instagram.com/arab2games/"
              target="_blank"
              rel="noreferrer"
              className="instagram"
            >
              <img
                loading="lazy"
                draggable={false}
                src={navIcon3}
                alt="navIcon3"
                style={{ width: '100%', height: '100%', maxWidth: '14px', maxHeight: '14px' }}
                onContextMenu={(e) => e.preventDefault()} />
            </a>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '10px', flexDirection: 'row' }}>

            <div
              className='Navbar-Language-Changer'
              style={{ direction: 'ltr', justifyContent: 'center', alignItems: 'center', display: 'flex', cursor: 'pointer' }}
              onClick={() => {
                setOpenLanguageMenu(!openLanguageMenu);
                setLanguageMenu('LanguageMenu')
              }}
            >
              <span style={{ fontSize: '14px', marginRight: '5px' }}>
                {CurrentLanguage === 'EN' ? 'EN' : 'AR'}
              </span>

              <i className="fas fa-star" style={{ textDecoration: 'none', fontSize: '1.05rem', }}>
                <LazyLoadImage
                  effect="blur"
                  draggable={false}
                  alt='languageFlag'
                  src={CurrentLanguage === 'EN' ? USA_Flag : KSA_Flag}
                  onContextMenu={(e) => e.preventDefault()}
                  style={{ fontSize: '1.1rem', marginTop: '-3.5px', width: '17px', height: '17px' }} />
              </i>
            </div>

            <div ref={LanguageMenuRef}>
              <div
                className={CurrentLanguage === 'EN' ? LanguageMenu === 'LanguageMenu'
                  ? `ENLanguageMenu-Dropdown${openLanguageMenu ? ' ENactive' : ' ENinactive'}`
                  : ' ENinactive'
                  : LanguageMenu === 'LanguageMenu' ? `LanguageMenu-Dropdown${openLanguageMenu ? ' active' : ' inactive'}` : ' inactive'
                }
                style={{
                  backgroundColor: language_changed === false ?
                    darkMode === 'light'
                      ? '#DFDFDF'
                      : '#212121ee'
                    : null,
                }}
              >
                <div style={{
                  direction: 'ltr', marginLeft: '10px', justifyContent: 'space-between', display: 'flex', alignItems: 'center',
                  flexDirection: 'row', textAlign: 'center'
                }}>

                </div>
                <div style={{ height: calcHeight }}>
                  <CSSTransition
                    in={LanguageMenu === 'LanguageMenu'}
                    unmountOnExit
                    timeout={500}
                    classNames={'LanguageMenu-primary'}
                    onEnter={calcHeight}
                  >
                    <ul>
                      <LanguageDropDown img={KSA_Flag} text={CurrentLanguage === 'EN' ? "Arabic" : "العربية"} />
                      <LanguageDropDown img={USA_Flag} text={CurrentLanguage === 'EN' ? "English" : "الإنجليزية"} />
                    </ul>
                  </CSSTransition>
                </div>
              </div>
            </div>

            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}
            >
              <div
                className="searchNavbar"
                style={{
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
              >
                <div
                  name="searchIcon"
                  className="SearchBtn-icon"
                  style={{ marginTop: '5px', }}>
                  <Link
                    to="/search"
                    style={{ textDecoration: 'none', color: 'white' }}
                    rel="noreferrer"
                    className="fas fa-search">
                    <FaSearch
                      className="img"
                      onContextMenu={(e) => e.preventDefault()}
                      style={{ transition: 'all 0.3s ease', fontSize: '1.1rem', color: '#aaa', cursor: 'pointer' }} />
                  </Link>
                </div>
              </div>

              <div
                name="searchIcon"
                style={{
                  transition: 'all 0.3s ease',
                  marginTop: '8px',
                  backgroundColor: darkMode_Btn_Hover ? '#333333' : 'rgba(217, 217, 217, 0.1)',
                  border: '1px solid rgba(255, 255, 255, 0.5)',
                  borderRadius: '50%',
                  width: '36px',
                  height: '36px',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '5px',
                  justifyContent: 'center',
                }}

              >
                <MdOutlineDarkMode
                  onContextMenu={(e) => e.preventDefault()}
                  onMouseEnter={() => setDarkMode_Btn_Hover(true)}
                  onMouseLeave={() => setDarkMode_Btn_Hover(false)}
                  style={{
                    transition: 'all 0.3s ease',
                    width: '25px',
                    height: '25px',
                    color: '#aaa',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                  }}
                  onClick={() => toggleDarkMode()}
                />
              </div>
            </div>

            {firebase?.auth()?.currentUser ?
              <div
                onClick={() => firebase?.auth()?.currentUser ? setOpenProfileMenu(!openProfileMenu) : null}
                className="Profile-icon margin-"
                style={{ marginTop: '10px' }}>

                <a style={{ display: 'flex', justifyContent: 'space-between', direction: 'rtl', alignItems: 'center', flexDirection: 'row' }}>
                  <div style={{
                    background: firebase?.auth()?.currentUser ? '#3F3F3F' : '#FF434C',
                  }}>
                    {firebase?.auth()?.currentUser && userProfilePicture !== null && userProfilePicture !== ''
                      ? <img
                        loading="lazy"
                        draggable={false}
                        src={userProfilePicture}
                        alt="userProfilePicture"
                        style={{ width: '38px', height: '38px', objectFit: 'cover', marginRight: '-2px', marginTop: '-5px', borderRadius: '50%' }} />
                      :
                      firebase?.auth()?.currentUser
                        ? <img
                          loading="lazy"
                          src={UserLoggedInIcon}
                          alt="UserLoggedInIcon"
                          onContextMenu={(e) => e.preventDefault()}
                          style={{ width: '38px', height: '38px', marginRight: '-2px', marginTop: '-5px', borderRadius: '45%' }} />

                        : <img
                          loading="lazy"
                          src={UserProffileIcon}
                          alt="UserProffileIcon"
                          onContextMenu={(e) => e.preventDefault()}
                          style={{ width: '25px', height: '25px', marginTop: '-5px', borderRadius: '25%' }} />
                    }
                  </div>
                  <span style={{ fontSize: '1rem', marginRight: '5px' }}
                  >
                    {firebase?.auth()?.currentUser ? displayName.length >= 11 ? '...' + displayName.slice(0, 11) : displayName
                      :
                      CurrentLanguage === 'EN' ? 'Login / Signup' : 'دخول / تسجيل'
                    }
                  </span>
                </a>
              </div>
              :
              <Link
                to="/login"
                style={{ textDecoration: 'none', color: 'white' }}
                as={Link}
              >
                <div
                  onClick={() => firebase?.auth()?.currentUser ? setOpenProfileMenu(!openProfileMenu) : null}
                  className="Profile-icon margin-"
                  style={{ marginTop: '10px' }}>
                  <Link
                    to="/login"
                    style={{
                      display: 'flex', justifyContent: 'space-between', direction: 'rtl', alignItems: 'center', flexDirection: 'row', color: 'white',
                      textDecoration: 'none'
                    }}>
                    <div style={{
                      background: firebase?.auth()?.currentUser ? '#3F3F3F' : '#FF434C',
                    }}>
                      {firebase?.auth()?.currentUser && userProfilePicture !== null && userProfilePicture !== ''
                        ? <img
                          loading="lazy"
                          src={userProfilePicture} alt="userProfilePicture"
                          style={{ width: '38px', height: '38px', objectFit: 'cover', marginRight: '-2px', marginTop: '-5px', borderRadius: '50%' }} />
                        :
                        firebase?.auth()?.currentUser
                          ? <img
                            src={UserLoggedInIcon}
                            alt="UserLoggedInIcon"
                            loading="lazy"
                            onContextMenu={(e) => e.preventDefault()}
                            style={{ width: '38px', height: '38px', marginRight: '-2px', marginTop: '-5px', borderRadius: '45%' }} />

                          : <img
                            src={UserProffileIcon}
                            alt="UserProffileIcon"
                            loading="lazy"
                            onContextMenu={(e) => e.preventDefault()}
                            style={{ width: '25px', height: '25px', marginTop: '-5px', borderRadius: '25%' }} />
                      }
                    </div>
                    <span style={{ fontSize: '1rem', marginRight: '5px' }}
                    >
                      {firebase?.auth()?.currentUser ? displayName.length >= 11 ? '...' + displayName.slice(0, 11) : displayName
                        :
                        CurrentLanguage === 'EN' ? 'Login / Signup' : 'دخول / تسجيل'
                      }
                    </span>
                  </Link>
                </div>
              </Link>
            }
          </div>
          <div ref={ProfileMenuRef}>
            <div className={activeMenu === 'MainProfile' ? CurrentLanguage === 'EN'
              ? `ENProfile-Dropdown${openProfileMenu ? ' ENactive' : ' ENinactive'}`
              : `Profile-Dropdown${openProfileMenu ? ' active' : ' inactive'}`
              : activeMenu === 'Profile_Notifications' ? CurrentLanguage === 'EN'
                ? `ENProfile-Notifications${openProfileMenu ? ' ENactive' : ' ENinactive'}`
                : `Profile-Notifications${openProfileMenu ? ' active' : ' inactive'}`
                : null
            }
              style={{
                backgroundColor: darkMode === 'light'
                  ? '#DFDFDF'
                  : '#212121ee'
              }}
            >
              <div
                style={{
                  direction: 'ltr', marginLeft: '10px', justifyContent: 'space-between',
                  display: 'flex', alignItems: 'center', flexDirection: 'row', textAlign: 'center'
                }}
              >
                <button
                  style={{
                    textAlign: 'center',
                    fontSize: '1.4rem',
                    fontWeight: 'bold',
                    color: activeMenu === 'MainProfile'
                      ? '#FF0000'
                      : darkMode === 'light' ? '#000' : '#D8D8D8'
                  }}
                  onClick={() => {
                    if (activeMenu === 'MainProfile') {
                      setOpenProfileMenu(false)
                    }
                    else if (activeMenu === 'Profile_Notifications') {
                      setActiveMenu('MainProfile')
                    }
                  }}
                >
                  {activeMenu === 'MainProfile' ? <MdClose /> : <MdKeyboardBackspace />}
                </button>
                <span
                  style={{
                    fontSize: '1.1rem', fontWeight: 'bold', marginRight: '10px',
                    color: darkMode === 'light' ? '#000' : '#fff', transition: 'all 0.3s ease-in-out'
                  }
                  }>
                  {activeMenu === 'MainProfile' ? '' : CurrentLanguage === 'EN' ? 'Notifications' : 'الإشعارات'}
                </span>
              </div>
              <div style={{ height: calcHeight, direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl' }}>
                <CSSTransition
                  in={activeMenu === 'MainProfile'}
                  unmountOnExit
                  timeout={500}
                  classNames={'ProfileMenu-primary'}
                  onEnter={calcHeight}
                >
                  <ul>
                    <Link to="/myProfile" style={{ textDecoration: 'none', color: 'white' }} onClick={() => {
                      setOpenProfileMenu(false);
                    }}>
                      <ProfileDropDown img={userIcon} text={CurrentLanguage === 'EN' ? "Profile" : "الحساب"} />
                    </Link>

                    <ProfileDropDown img={userNotification.length > 0 ? NotificationIcon_2 : NotificationIcon}
                      text={CurrentLanguage === 'EN' ? "Notifications" : "الإشعارات"}
                    />

                    <ProfileDropDown img={editIcon} text={
                      CurrentLanguage === 'EN' ? "Watch Later" : "المشاهدة لاحقاً"} />
                    <ProfileDropDown img={WatchedHistory} text={CurrentLanguage === 'EN' ? "Watched History" : "سجل المشاهدة"} />
                    <ProfileDropDown img={logoutIcon} text={CurrentLanguage === 'EN' ? "Logout" : "تسجيل الخروج"} />
                  </ul>
                </CSSTransition>

                <CSSTransition
                  in={activeMenu === 'Profile_Notifications'}
                  unmountOnExit
                  timeout={500}
                  classNames={CurrentLanguage === 'EN' ? 'ENProfileMenu-Noifications' : 'ProfileMenu-Noifications'}
                  onEnter={calcHeight}
                >
                  <ul>
                    <NotificationSection />
                  </ul>
                </CSSTransition>
              </div>
            </div>
          </div>
        </Container>
      </Navbar>

    </div>
  )
}
