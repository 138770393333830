import * as React from 'react'
import { hydrate, render } from "react-dom";
import './index.css';
import { AuthProvider } from './xFireBase/AuthContext';
import AuthNavigation from './AuthCheck';
import BackToTopButton from './hooks/Others/BackToTopButton';
import { HelmetProvider } from 'react-helmet-async';
import { SkeletonTheme } from 'react-loading-skeleton';

import './App.css';
import './components/RETOservers_info.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-loading-skeleton/dist/skeleton.css'

// ignore red box error
console.error = () => { };
//ignore red warning
console.warn = () => { };

const MemoizedAuthNavigation = React.memo(AuthNavigation);

const helmetContext = {};

const APP = () => {
  return (
    <React.StrictMode>
      <HelmetProvider context={helmetContext}>
        <AuthProvider>
        <SkeletonTheme baseColor="#313131" highlightColor="#242222">
          <MemoizedAuthNavigation />
        </SkeletonTheme>
          <BackToTopButton />
        </AuthProvider>
      </HelmetProvider>
    </React.StrictMode>
  );
};

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<APP />, rootElement);
} else {
  render(<APP />, rootElement);
}



