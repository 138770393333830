import React from "react";
function PreLoader(props) {
  return <div 
  style={{
    transition: "all 0.4s ease-out",
  }}
  id={props.load
    ? props.darkMode === 'light'
      ? 'preloader_light' : "preloader_dark"
    : "preloader-none"
  }></div>;
}

export default PreLoader;