import React, { useState } from 'react'
import useMediaQuery from "@mui/material/useMediaQuery";
import { Checkbox, Button } from '@mui/material';
import { Formik } from 'formik';
import { Check2Circle, Trash3 } from 'react-bootstrap-icons';
import { RETO_defaultSettings } from '../../../hooks/Others/RETO_data';
import { useAuth } from '../../../xFireBase/AuthContext';


const GameFilterSortMenu = () => {

    const { CurrentLanguage } = useAuth();
    const isNonMobile = useMediaQuery("(min-width:992px)");

    const [SelectedSort, setSelectedSort] = useState('');

    function handleFilterSubmit() {
        const selected = SelectedSort;
        setTimeout(() => {
            // update the local item to the selected language
            RETO_defaultSettings[0].Game_SelectedSort = selected;
        }, 1000);
    }



    return (
        <div style={{
            backgroundColor: '#FFFFFF0C', borderRadius: '15px', display: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: 'center', textAlign: 'center',
            width: '100%', padding: '0.5rem', marginTop: '-45px', marginBottom: '40px', direction: 'rtl', transition: 'all 0.3s ease-in-out'
        }}>
            <Formik
                onSubmit={handleFilterSubmit}
                initialValues={{
                    SelectedSort: '',
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit} style={{ width: '100%', flexDirection: 'column', }}>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            <Button
                                className='AccountEdit-Form-Button'
                                type='submit'
                                style={{ width: isNonMobile ? '15%' : '40%', color: 'white', borderRadius: '30px', marginTop: '10px', backgroundColor: '#2563ffd9' }}
                                disabled={SelectedSort === '' || SelectedSort === null ? true : false}
                            >
                                <Check2Circle style={{ marginLeft: '5px', color: '#FFFFFF', transition: 'all 0.2s ease-in-out' }} />
                                {CurrentLanguage === 'EN' ? 'Apply' : 'تطبيق'}
                            </Button>

                            <Button
                                variant="outlined"
                                style={{ width: isNonMobile ? '15%' : '40%', color: 'white', borderRadius: '30px', marginTop: '10px' }}
                                disabled={(SelectedSort === '' || SelectedSort === null)
                                    && (RETO_defaultSettings[0]?.Game_SelectedSort === '' || RETO_defaultSettings[0]?.Game_SelectedSort === null)
                                    ? true : false
                                }

                                onClick={() => {
                                    setSelectedSort('');
                                    RETO_defaultSettings[0].Game_SelectedSort = '';
                                }}
                            >
                                <Trash3 style={{
                                    marginLeft: '5px',
                                    color: SelectedSort !== '' || SelectedSort !== null || RETO_defaultSettings[0]?.Game_SelectedSort !== ''
                                        || RETO_defaultSettings[0]?.Game_SelectedSort !== null ? '#FF3943' : '#D62F379A',
                                    transition: 'all 0.2s ease-in-out'
                                }} />
                                {CurrentLanguage === 'EN' ? 'Clear' : 'مسح'}
                            </Button>
                        </div>

                        {/* 1st row */}
                        <div style={{ flexDirection: isNonMobile ? 'row' : 'column', display: 'flex', width: '100%', justifyContent: 'center' }}>

                            {/* 1st column */}
                            <div style={{ flexDirection: isNonMobile ? 'column' : 'row', display: 'flex', width: isNonMobile ? '25%' : '100%', justifyContent: 'space-between' }}>
                                <Button
                                    fullWidth
                                    name='TopDownloaded'
                                    value={'TopDownloaded'}
                                    onBlur={handleBlur('TopDownloaded')}
                                    onChange={handleChange('TopDownloaded')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%', color: 'white', borderRadius: '30px', marginTop: '10px', backgroundColor: '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        if (SelectedSort !== 'TopDownloaded') {
                                            setSelectedSort('TopDownloaded');
                                        }
                                        else {
                                            setSelectedSort('');
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'Top Downloaded' : 'الأكثر تحميلاً'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'TopDownloaded'}
                                        checked={SelectedSort === 'TopDownloaded' || RETO_defaultSettings[0]?.Game_SelectedSort === 'TopDownloaded' ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null, transition: 'all 0.2s ease-in-out',
                                            color: SelectedSort === 'TopDownloaded' ? '#2F5CD6' : '#FFFFFF7A'
                                        }}
                                    />
                                </Button>

                                <Button
                                    fullWidth
                                    name='TopRated'
                                    value={'TopRated'}
                                    onBlur={handleBlur('TopRated')}
                                    onChange={handleChange('TopRated')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%', color: 'white', borderRadius: '30px', marginTop: '10px', backgroundColor: '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        if (SelectedSort !== 'TopRated') {
                                            setSelectedSort('TopRated');
                                        }
                                        else {
                                            setSelectedSort('');
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'Top Rated' : 'الأعلى تقييماً'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'TopRated'}
                                        checked={SelectedSort === 'TopRated' || RETO_defaultSettings[0]?.Game_SelectedSort === 'TopRated' ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null, transition: 'all 0.2s ease-in-out',
                                            color: SelectedSort === 'TopRated' ? '#2F5CD6' : '#FFFFFF7A'
                                        }}
                                    />
                                </Button>
                            </div>

                            {/* 2nd column */}
                            <div style={{
                                flexDirection: isNonMobile ? 'column' : 'row', display: 'flex', width: isNonMobile ? '25%' : '100%',
                                marginTop: isNonMobile ? null : '20px', justifyContent: 'space-between'
                            }}>
                                <Button
                                    fullWidth
                                    name='Newest'
                                    value={'Newest'}
                                    onBlur={handleBlur('Newest')}
                                    onChange={handleChange('Newest')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%', color: 'white', borderRadius: '30px', marginTop: '10px', backgroundColor: '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        if (SelectedSort !== 'Newest') {
                                            setSelectedSort('Newest');
                                        }
                                        else {
                                            setSelectedSort('');
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'Newest' : 'الأحدث'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'Newest'}
                                        checked={SelectedSort === 'Newest' || RETO_defaultSettings[0]?.Game_SelectedSort === 'Newest' ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null, transition: 'all 0.2s ease-in-out',
                                            color: SelectedSort === 'Newest' ? '#2F5CD6' : '#FFFFFF7A'
                                        }}
                                    />
                                </Button>

                                <Button
                                    fullWidth
                                    name='Oldest'
                                    value={'Oldest'}
                                    onBlur={handleBlur('Oldest')}
                                    onChange={handleChange('Oldest')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%', color: 'white', borderRadius: '30px', marginTop: '10px', backgroundColor: '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        if (SelectedSort !== 'Oldest') {
                                            setSelectedSort('Oldest');
                                        }
                                        else {
                                            setSelectedSort('');
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'Oldest' : 'الأقدم'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'Oldest'}
                                        checked={SelectedSort === 'Oldest' || RETO_defaultSettings[0]?.Game_SelectedSort === 'Oldest' ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null, transition: 'all 0.2s ease-in-out',
                                            color: SelectedSort === 'Oldest' ? '#2F5CD6' : '#FFFFFF7A'
                                        }}
                                    />
                                </Button>
                            </div>

                            {/* 3rd column */}
                            <div style={{
                                flexDirection: isNonMobile ? 'column' : 'row', display: 'flex', width: isNonMobile ? '25%' : '100%',
                                marginTop: isNonMobile ? null : '20px', justifyContent: 'space-between'
                            }}>
                                <Button
                                    fullWidth
                                    name='A-Z'
                                    value={'A-Z'}
                                    onBlur={handleBlur('A-Z')}
                                    onChange={handleChange('A-Z')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%', color: 'white', borderRadius: '30px', marginTop: '10px', backgroundColor: '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        if (SelectedSort !== 'A-Z') {
                                            setSelectedSort('A-Z');
                                        }
                                        else {
                                            setSelectedSort('');
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'A-Z' : 'A-Z'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'A-Z'}
                                        checked={SelectedSort === 'A-Z' || RETO_defaultSettings[0]?.Game_SelectedSort === 'A-Z' ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null, transition: 'all 0.2s ease-in-out',
                                            color: SelectedSort === 'A-Z' ? '#2F5CD6' : '#FFFFFF7A'
                                        }}
                                    />
                                </Button>

                                <Button
                                    fullWidth
                                    name='Z-A'
                                    value={'Z-A'}
                                    onBlur={handleBlur('Z-A')}
                                    onChange={handleChange('Z-A')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%', color: 'white', borderRadius: '30px', marginTop: '10px', backgroundColor: '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        if (SelectedSort !== 'Z-A') {
                                            setSelectedSort('Z-A');
                                        }
                                        else {
                                            setSelectedSort('');
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'Z-A' : 'Z-A'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'Z-A'}
                                        checked={SelectedSort === 'Z-A' || RETO_defaultSettings[0]?.Game_SelectedSort === 'Z-A' ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null, transition: 'all 0.2s ease-in-out',
                                            color: SelectedSort === 'Z-A' ? '#2F5CD6' : '#FFFFFF7A'
                                        }}
                                    />
                                </Button>
                            </div>
                        </div>

                    </form>
                )}

            </Formik>
        </div>
    )
}

export default GameFilterSortMenu