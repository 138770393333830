import React, { useEffect, useState } from 'react'
import { Box, Button, useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import { motion } from 'framer-motion';
import './modalPopUpSignUp.css'
import { firebase, database } from '../../../xFireBase/Storage';
import { useAuth } from '../../../xFireBase/AuthContext';


const ModaPopUpSignUp = ({ onClose }) => {

  const { CurrentLanguage } = useAuth();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [emailVerified, setEmailVerified] = useState(false);
  const [text, setText] = useState('الرجاء التحقق من البريد الإلكتروني الخاص بك')



  useEffect(() => {
    const interval = setInterval(() => {
      firebase.auth().currentUser.reload()
        .then(() => {
          const user = firebase.auth().currentUser;
          if (user && user.emailVerified) {
            setEmailVerified(true);
            database.collection('users').doc(user.email).update({
              User_EmailVerified: true
            }).then(() => {
              window.location.reload();
            });
          }
        });
    }, 1200);
  
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user && user.emailVerified) {
        setEmailVerified(true);
        database.collection('users').doc(user.email).update({
          User_EmailVerified: true
        }).then(() => {
          window.location.reload();
        });
      }
    });
  
    return () => {
      clearInterval(interval);
      unsubscribe();
    };
  }, []);

  return (
    <div className='ModalContainer-Overlay' onClick={onClose}>
      <motion.div
        layout
        id='fadeIn'
        transition={{ layout: { duration: 1, type: 'spring' } }}
        className='ModalContainer-card'
        onClick={(event) => event.stopPropagation()}
        style={{ borderRadius: '2rem', boxShadow: '0px 10px 30px rgba(0,0,0,0.5)' }}
      >

<Box style={{ flexDirection: 'row', display: 'flex' }}>
            <Formik>
                {({
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between' }}>
                                <h5
                                    className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-black "
                                    style={{ direction: 'ltr', width: '100%', justifyContent: "center", alignItems: "center", textAlign: 'center' }}
                                >
                                    {text}
                                </h5>
                                <h4
                                    style={{ direction: 'ltr', width: isNonMobile ? '90%' : '100%', justifyContent: "center", alignItems: "center", textAlign: 'center' }}
                                >
                                    {firebase.auth().currentUser.email}
                                </h4>
                            </div>
                            <div style={{ flexDirection: 'row', justifyContent: 'center', width: '100%' }} onClick={() => {
                            }}>
                                <Box display="flex" alignItems={"center"} justifyContent="center" flexDirection="row" marginTop={"15px"} marginBottom={'15px'} >
                                <Button variant="contained" 
                                    onClick={() => {
                                        database.collection('users')
                                        .doc(firebase.auth().currentUser.email)
                                        .update({
                                            User_Status: 'غير متصل',
                                            User_Last_Login: new Date().toLocaleString('en-US', { timeZone: 'Asia/Riyadh' }),
                                        })
                                        .then(() => {
                                            firebase.auth().signOut()
                                        })
                                        .then(() => {
                                            window.location.reload();
                                        })
                                    }}
                                    >
                                       {CurrentLanguage === 'EN' ? 'Log Out' : 'تسجيل الخروج'}
                                </Button>
                                <Button variant="contained" style={{ marginLeft: '10px' }}
                                    onClick={() => {
                                        emailVerified === true
                                        ? 
                                        CurrentLanguage === 'EN'
                                        ? setText('Your email has been verified')
                                        :setText('تم التحقق من البريد الإلكتروني الخاص بك') 
                                        
                                        : firebase.auth().currentUser.sendEmailVerification()
                                        .then(() => {
                                            CurrentLanguage === 'EN'
                                            ? setText('Verification link has been sent to your email again')
                                            : setText('تم إرسال رابط التحقق إلى بريدك الإلكتروني مرة أخرى')
                                        })
                                    }}
                                    >
                                        {CurrentLanguage === 'EN' ? 'Verified ?' : 'تم التحقق ؟'} 
                                    </Button>
                                </Box>
                                <Box display="flex" justifyContent="center" mt={2} >
                                  <Button
                                    color="info"
                                    style={{ transform: "scale(1.5)", marginTop: '1.5rem' }}
                                    onClick={() => {
                                        firebase.auth().currentUser.sendEmailVerification()
                                    }}>
                                        {CurrentLanguage === 'EN' ? 'Resend' : 'إعادة إرسال'}
                                  </Button>
                              </Box>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </Box>
      </motion.div>
    </div>
  )
}

export default ModaPopUpSignUp;

