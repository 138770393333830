import React, { useEffect, useState } from 'react';
import Profile_Loading from '../../../assets/img/icons/Profile_Loading.svg'
import { firebase, database } from '../../../xFireBase/Storage';
import useMediaQuery from "@mui/material/useMediaQuery";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { TextField } from '@mui/material';
import TrackVisibility from 'react-on-screen';
import { FaSearch } from 'react-icons/fa';
import { useAuth } from '../../../xFireBase/AuthContext';


const NotificationPage = () => {

  const { CurrentLanguage, darkMode } = useAuth();

  const isNonMobile = useMediaQuery("(min-width:992px)");
  const isTablet = useMediaQuery("(min-width:525px)");

  const [gamesWrapper, setGamesWrapper] = useState(12);
  const [LoadMoreGames, setLoadMoreGames] = useState(false);
  const [Stop_LoadMoreGames, setStop_LoadMoreGames] = useState(false);
  const [SearchValue, setSearchValue] = useState("");
  const [start_search, setStart_search] = useState(false);
  const [error_search, setError_search] = useState('');
  const [User_Notification_games, setUser_Notification_games] = useState([]);

  const [box_isHovered, setBox_isHovered] = useState(false);


  const LoadingMoreGames = () => {
    setLoadMoreGames(true)

    setTimeout(() => {
      setStop_LoadMoreGames(true)
    }, 1100)
    setTimeout(() => {
      setLoadMoreGames(false)
      setStop_LoadMoreGames(false)
      setGamesWrapper(gamesWrapper + 12);
    }, 1200)
  }


  useEffect(() => {
    database
      .collection('users')
      .doc(firebase.auth().currentUser.email)
      .collection('more-details')
      .doc('User_History')
      .collection('User_Notification')
      .orderBy('Notify_CreatedAt', 'desc')
      .onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setUser_Notification_games(data);

        }
      })
  }, [])
  useEffect(() => {
    if (SearchValue.length > 0 && start_search === false) {
      setStart_search(true);
    }

  }, [SearchValue, start_search])

  useEffect(() => {
    if (User_Notification_games.length <= 0 && SearchValue?.length > 0) {
      setError_search('لا يوجد نتائج للبحث');
    }
    else if (User_Notification_games.length <= 0) {
      setError_search('حدث خطأ ما، يرجى المحاولة مرة أخرى او تحديث الصفحة');
    }
    else {
      setError_search('');
    }
  }, [User_Notification_games, SearchValue])

  useEffect(() => {
    if (SearchValue.length > 0 && User_Notification_games.length > 0 && SearchValue !== "" && start_search === true) {
      setTimeout(() => {
        setStart_search(false);
      }, 2000);
    } else if (SearchValue.length === 0 || SearchValue === "" || User_Notification_games.length === 0) {
      setStart_search(false);
    }
  }, [SearchValue, User_Notification_games, start_search])



  return (
    <div id='NotificationPage'>
      <div
        style={{
          textDecoration: 'none', color: 'white', direction: 'rtl', alignItems: 'flex-start', justifyContent: 'flex-end', textAlign: 'right', margin: '0.5rem',
          display: 'flex', flexDirection: isNonMobile ? 'row' : 'column', width: '100%',
        }}
      >
        {/** the box on the left */}
        <div className='container'>
          <div
            size={12}
            xs={6}
            sm={6}
            md={6}
            lg={4}
            xl={3}
            className={isNonMobile ? 'AccountEdit-bx' : 'AccountEdit-bx-Mobile'}
            style={{
              boxShadow: box_isHovered === true ? '0 0 20px 0 #42297c' 
              : darkMode === 'light' 
              ? '0 0 20px 0 #14141488' 
              : '0 0 20px 0 #e2dada1a'
            }}
            onMouseEnter={() => setBox_isHovered(true)}
            onMouseLeave={() => setBox_isHovered(false)}
          >

            <div className='AccountEdit-bx-Container-content'>
              <header style={{
                paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: isNonMobile || isTablet ? 'row' : 'column', justifyContent: 'space-between',
                alignItems: isNonMobile || isTablet ? 'center' : 'flex-start', textAlign: 'right', width: '100%', padding: '0.5rem',
                direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
              }}>
                <h2 style={{
                  fontSize: '1.25rem',
                  color: darkMode === 'light' ? '#000000' : 'white',
                  borderRadius: '0.2rem',
                }}
                >
                  <span style={{ color: '#ff434c' }}>|</span> {CurrentLanguage === 'EN' ? 'Notification History' : 'سجل الإشعارات'}
                  <span
                    style={{
                      color: '#ff434c', fontSize: '1.25rem', borderRadius: '0.2rem', padding: '5px', margin: '0 5px'
                    }}
                  >
                    {' '}{User_Notification_games.length.toLocaleString()}
                  </span>
                </h2>
                <div style={{
                  direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl', width: '100%', maxWidth: '330px', marginLeft: CurrentLanguage === 'EN' ? null : '20px',
                  height: '100%', justifyContent: "center", alignItems: "center", textAlign: 'center', display: 'flex', flexDirection: 'row', margin: '20px 0'
                }}>
                  <a
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      maxWidth: '330px',
                      height: '100%',
                      padding: '10px',
                      borderRadius: '25px',
                      border: 'none',
                      outline: 'none',
                      fontSize: '1.2rem',
                      textAlign: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: darkMode === 'light' ? '#EEECEC' : '#F5F5F52A',
                    }
                    }
                  >
                    <a

                      style={{ color: darkMode === 'light' ? '#000000' : '#ffffff', fontSize: '1.2rem',  }}
                    >
                      <FaSearch />
                    </a>

                    <input
                      variant="filled"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          // do nothing and don't refresh the page
                          e.preventDefault();
                        }
                      }}
                      name="SearchValue"
                      label={CurrentLanguage === 'EN' ? "search" : "البحث"}
                      placeholder={CurrentLanguage === 'EN'
                        ? 'Name / Date of Notification'
                        : 'اسم / تاريخ الإشعار'}
                      value={SearchValue}
                      autoFocus={false}
                      onChange={(e) => {
                        // the value should not start with space
                        if (e.target.value.startsWith(" ")) {
                          return;
                        }
                        else {
                          setSearchValue(e.target.value);
                        }
                      }}
                      type="text"
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        outline: 'none',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '90%',
                        fontSize: '1.05rem',
                        color: darkMode === 'light' ? '#000000' : '#ffffff',
                        direction: 'ltr',
                        fontFamily: 'Cairo',
                        fontWeight: 'bold',
                        padding: '0',
                        margin: '0',
                      }}
                    />
                  </a>
                </div>
              </header>


              <div style={{
                display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center',
                textAlign: 'center', width: '100%', padding: '0.5rem'
              }}>
                {
                  User_Notification_games?.filter((Result) => {
                    if (SearchValue.length > 0) {

                      // if the games_search is empty return all the data, else return the data that includes the SearchValue
                      if (
                        Result?.Notify_Name?.toLowerCase().includes(SearchValue.toLowerCase())
                        || Result?.Notify_Name_alias?.toLowerCase()?.includes(SearchValue?.toLowerCase())
                        || Result?.Notify_aType?.toLowerCase().includes(SearchValue.toLowerCase())
                        || Result?.Notify_At_Date?.toLowerCase().includes(SearchValue.toLowerCase())
                        || Result?.Notify_Size?.toLowerCase().includes(SearchValue.toLowerCase())
                        || Result?.Notify_Genres?.map((item) => item?.toLowerCase()).includes(SearchValue.toLowerCase())
                      ) {
                        return Result
                      }
                    }
                    else if (SearchValue.length <= 0) {
                      return Result
                    }
                  })
                    .slice(0, gamesWrapper)
                    .map((doc) => {
                      return (
                        <div
                          style={{
                            backgroundColor: darkMode === 'light' ? '#0000002F' : '#7272720C',
                            borderRadius: '15px',
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '30px',
                            alignItems: 'center',
                            textAlign: 'center',
                            width: isNonMobile || isTablet ? '47%' : '100%',
                            padding: '0.5rem',
                          }}
                        >

                          <div
                            style={{
                              width: '100%', display: 'flex', flexDirection: isNonMobile ? 'row' : 'column', justifyContent: 'flex-start', alignItems: 'flex-start',
                              textAlign: 'center', marginBottom: '30px',
                            }}>
                            {isNonMobile ?
                              <div style={{
                                width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end',
                                textAlign: 'center', direction: 'ltr'
                              }}>

                                <h2 style={{
                                  fontSize: '1rem',
                                  color: darkMode === 'light' ? '#000000' : '#3993B6',
                                  borderRadius: '0.2rem',
                                  direction: 'rtl',
                                  display: 'flex',
                                  flexDirection: 'row'
                                }}
                                >
                                  <span style={{ direction: 'ltr', display: 'flex', flexDirection: 'row', direction: 'rtl' }}>{doc.Notify_Message.length >= 35
                                    ? `${doc.Notify_Message.slice(0, 35)}...`
                                    : doc.Notify_Message}</span>
                                </h2>
                              </div>
                              : null}

                            <LazyLoadImage
                              src={doc.Notify_Image}
                              alt='Notify_Image'
                              effect="blur"
                              style={{ width: '100%', height: '100%', maxHeight: '220px', borderRadius: '15px', }}
                            />
                            {!isNonMobile ?
                              <div style={{
                                width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                                textAlign: 'center', marginTop: '10px'
                              }}>

                                <h2 style={{ 
                                  fontSize: '1rem', 
                                  color: darkMode === 'light' ? '#000000' : '#3993B6',
                                  borderRadius: '0.2rem' 
                                }}
                                >
                                  {doc.Notify_Message.length >= 35 ? `${doc.Notify_Message.slice(0, 35)}...` : doc.Notify_Message}
                                </h2>
                              </div>
                              : null}

                          </div>

                          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{
                              width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start',
                              direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                            }}>
                              {/*game_Description but let it a multiline text field*/}
                              <div style={{
                                fontSize: '1.2rem',
                                color: darkMode === 'light' ? '#000000' : '#FFFFFF', 
                                borderRadius: '0.2rem', 
                                display: 'flex',
                                justifyContent: 'right', 
                                textAlign: 'right',
                              }}>
                                {CurrentLanguage === 'EN'
                                  ? 'Description :'
                                  : 'وصف :'
                                }
                              </div>
                              <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={4}
                                defaultValue={doc.Notify_Description}
                                style={{
                                  width: '100%', 
                                  color: 'white', 
                                  borderRadius: '0.2rem', 
                                  backgroundColor: darkMode === 'light' ? '#F0F0F06C' : '#B8B8B80C',
                                  direction: 'rtl', 
                                  textAlign: 'right'
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                                sx={{ gridColumn: "span 4", }}
                                inputProps={{
                                  style: {
                                    textAlign: 'left',
                                    direction: 'rtl',
                                    color: darkMode === 'light' ? '#000000' :'#A5A5A5',
                                  }
                                }}
                              />

                            </div>
                          </div>
                        </div>
                      )
                    })
                }

                {gamesWrapper < User_Notification_games.length && Stop_LoadMoreGames === false ?
                  <div
                    style={{
                      textDecoration: 'none',
                      cursor: 'pointer',
                      width: '150%',
                      height: '100%',
                      padding: '10px',
                      borderRadius: '25px',
                      border: 'none',
                      outline: 'none',
                      fontSize: '1.2rem',
                      marginBottom: '0px',
                      textAlign: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#fff',
                    }}
                  >
                    <TrackVisibility once>
                      {({ isVisible }) =>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          {isVisible ? LoadingMoreGames() : null}
                          <span style={{ opacity: '0' }}>.</span>
                          {LoadMoreGames === true ?
                            <LazyLoadImage
                              effect="blur"
                              draggable={false}
                              src={Profile_Loading}
                              alt='Profile_Loading'
                              style={{ width: '50px', height: '50px', display: 'flex' }} />
                            :
                            null
                          }
                        </div>
                      }
                    </TrackVisibility>
                  </div>
                  :
                  null
                }
              </div>

            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default NotificationPage