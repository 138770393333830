import React, { useState } from 'react'

import useMediaQuery from "@mui/material/useMediaQuery";
import { Checkbox, Button } from '@mui/material';
import { Formik } from 'formik';
import { Check2Circle, Trash3 } from 'react-bootstrap-icons';
import { RETO_defaultSettings } from '../../../hooks/Others/RETO_data';
import { useAuth } from '../../../xFireBase/AuthContext';


const GmaeFilterGenresMenu = ({ closeFilter }) => {

    const { CurrentLanguage, darkMode } = useAuth();
    const isNonMobile = useMediaQuery("(min-width:992px)");

    const [SelectedGenres, setSelectedGenres] = useState([]);
    const [Filtering_started, setFiltering_started] = useState(false);


    function handleFilterSubmit() {
        setFiltering_started(true);
        try {
            const selectedGenres = SelectedGenres;
            setTimeout(() => {
                // update the local item to the selected language
                RETO_defaultSettings[0].Game_SelectedFilters = selectedGenres;

                // update the start_sorting to true
                RETO_defaultSettings[0].start_Filtring = true;

                closeFilter();
                setFiltering_started(false);
            }, 500);
        }
        catch (error) {
            console.log(error);
        }
    }



    return (
        <div style={{
            backgroundColor: darkMode === 'light' ? '#2B2B2B2A' : '#FFFFFF0C',
            borderRadius: '15px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'right',
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
            padding: '0.5rem',
            marginTop: '-45px',
            marginBottom: '40px',
            direction: 'rtl',
            transition: 'all 0.3s ease-in-out'
        }}
        >
            <Formik
                onSubmit={handleFilterSubmit}
                initialValues={{
                    Action: '',
                    Horror: '',
                    Adventure: '',
                    RPG: '',
                    Strategy: '',
                    Simulation: '',
                    Sports: '',
                    Racing: '',
                    Fighting: '',
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit} style={{ width: '100%', flexDirection: 'column', }}>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            <Button
                                className='AccountEdit-Form-Button'
                                type='submit'
                                style={{ width: isNonMobile ? '15%' : '40%', color: 'white', borderRadius: '30px', marginTop: '10px', backgroundColor: '#2563ffd9' }}
                                disabled={
                                    Filtering_started === true
                                        || SelectedGenres.length <= 0
                                        ? true : false
                                }
                            >
                                <Check2Circle style={{ marginLeft: '5px', color: '#FFFFFF', transition: 'all 0.2s ease-in-out' }} />
                                {CurrentLanguage === 'EN' ? 'Apply' : 'تطبيق'}
                            </Button>

                            <Button
                                variant="outlined"
                                style={{ width: isNonMobile ? '15%' : '40%', color: 'white', borderRadius: '30px', marginTop: '10px' }}
                                disabled={
                                    Filtering_started === true
                                        || SelectedGenres.length <= 0 && RETO_defaultSettings[0]?.Game_SelectedFilters?.length <= 0
                                        ? true : false
                                }
                                onClick={() => {
                                    setSelectedGenres([]);
                                    RETO_defaultSettings[0].Game_SelectedFilters = [];
                                    closeFilter();
                                }}
                            >
                                <Trash3 style={{
                                    marginLeft: '5px', color: SelectedGenres.length > 0 || RETO_defaultSettings[0]?.Game_SelectedFilters?.length > 0
                                        ? '#FF3943' : '#D62F379A', transition: 'all 0.2s ease-in-out'
                                }} />
                                {CurrentLanguage === 'EN' ? 'Clear' : 'مسح'}
                            </Button>
                        </div>

                        {/* 1st row */}
                        <div style={{ flexDirection: isNonMobile ? 'row' : 'column', display: 'flex', width: '100%', justifyContent: 'center' }}>

                            {/* 1st column */}
                            <div style={{ flexDirection: isNonMobile ? 'column' : 'row', display: 'flex', width: isNonMobile ? '25%' : '100%', justifyContent: 'space-between' }}>
                                <Button
                                    fullWidth
                                    name='Action'
                                    value={'Action'}
                                    onBlur={handleBlur('Action')}
                                    onChange={handleChange('Action')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%',
                                        color: 'white',
                                        borderRadius: '30px',
                                        marginTop: '10px',
                                        backgroundColor: darkMode === 'light' ? '#000000A4' : '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        // push the value of this button to the array and don't push it if it's already in the array, also remove it if it's already in the array
                                        // also don't remove the rest of the values in the array
                                        if (SelectedGenres.includes('Action')) {
                                            const index = SelectedGenres.indexOf('Action');
                                            if (index > -1) {
                                                SelectedGenres.splice(index, 1);
                                            }
                                            setSelectedGenres(SelectedGenres);
                                        }
                                        else {
                                            setSelectedGenres([...SelectedGenres, 'Action']);
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'Action' : 'أكشن'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'Action'}
                                        checked={SelectedGenres.includes('Action') || RETO_defaultSettings[0]?.Game_SelectedFilters?.includes('Action') ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null,
                                            color: SelectedGenres.includes('Action') ? '#2F5CD6' : '#FFFFFF7A', transition: 'all 0.2s ease-in-out'
                                        }}
                                    />
                                </Button>

                                <Button
                                    fullWidth
                                    name='Horror'
                                    value={'Horror'}
                                    onBlur={handleBlur('Horror')}
                                    onChange={handleChange('Horror')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%',
                                        color: 'white',
                                        borderRadius: '30px',
                                        marginTop: '10px',
                                        backgroundColor: darkMode === 'light' ? '#000000A4' : '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        // push the value of this button to the array and don't push it if it's already in the array, also remove it if it's already in the array
                                        // also don't remove the rest of the values in the array
                                        if (SelectedGenres.includes('Horror')) {
                                            const index = SelectedGenres.indexOf('Horror');
                                            if (index > -1) {
                                                SelectedGenres.splice(index, 1);
                                            }
                                            setSelectedGenres(SelectedGenres);
                                        }
                                        else {
                                            setSelectedGenres([...SelectedGenres, 'Horror']);
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'Horror' : 'رعب'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'Horror'}
                                        checked={SelectedGenres.includes('Horror') || RETO_defaultSettings[0]?.Game_SelectedFilters?.includes('Horror') ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null,
                                            color: SelectedGenres.includes('Horror') ? '#2F5CD6' : '#FFFFFF7A', transition: 'all 0.2s ease-in-out'
                                        }}
                                    />
                                </Button>
                            </div>

                            {/* 2nd column */}
                            <div style={{
                                flexDirection: isNonMobile ? 'column' : 'row', display: 'flex', width: isNonMobile ? '25%' : '100%',
                                marginTop: isNonMobile ? null : '20px', justifyContent: 'space-between'
                            }}>
                                <Button
                                    fullWidth
                                    name='Adventure'
                                    value={'Adventure'}
                                    onBlur={handleBlur('Adventure')}
                                    onChange={handleChange('Adventure')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%',
                                        color: 'white',
                                        borderRadius: '30px',
                                        marginTop: '10px',
                                        backgroundColor: darkMode === 'light' ? '#000000A4' : '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        // push the value of this button to the array and don't push it if it's already in the array, also remove it if it's already in the array
                                        // also don't remove the rest of the values in the array
                                        if (SelectedGenres.includes('Adventure')) {
                                            const index = SelectedGenres.indexOf('Adventure');
                                            if (index > -1) {
                                                SelectedGenres.splice(index, 1);
                                            }
                                            setSelectedGenres(SelectedGenres);
                                        }
                                        else {
                                            setSelectedGenres([...SelectedGenres, 'Adventure']);
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'Adventure' : 'مغامرة'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'Adventure'}
                                        checked={SelectedGenres.includes('Adventure') || RETO_defaultSettings[0]?.Game_SelectedFilters?.includes('Adventure') ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null,
                                            color: SelectedGenres.includes('Adventure') ? '#2F5CD6' : '#FFFFFF7A', transition: 'all 0.2s ease-in-out'
                                        }}
                                    />
                                </Button>

                                <Button
                                    fullWidth
                                    name='Open World'
                                    value={'Open World'}
                                    onBlur={handleBlur('Open World')}
                                    onChange={handleChange('Open World')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%',
                                        color: 'white',
                                        borderRadius: '30px',
                                        marginTop: '10px',
                                        backgroundColor: darkMode === 'light' ? '#000000A4' : '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        // push the value of this button to the array and don't push it if it's already in the array, also remove it if it's already in the array
                                        // also don't remove the rest of the values in the array
                                        if (SelectedGenres.includes('Open World')) {
                                            const index = SelectedGenres.indexOf('Open World');
                                            if (index > -1) {
                                                SelectedGenres.splice(index, 1);
                                            }
                                            setSelectedGenres(SelectedGenres);
                                        }
                                        else {
                                            setSelectedGenres([...SelectedGenres, 'Open World']);
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'Open World' : 'عالم مفتوح'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'Open World'}
                                        checked={SelectedGenres.includes('Open World') || RETO_defaultSettings[0]?.Game_SelectedFilters?.includes('Open World') ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null,
                                            color: SelectedGenres.includes('Open World') ? '#2F5CD6' : '#FFFFFF7A', transition: 'all 0.2s ease-in-out'
                                        }}
                                    />
                                </Button>
                            </div>

                            {/* 3rd column */}
                            <div style={{
                                flexDirection: isNonMobile ? 'column' : 'row', display: 'flex', width: isNonMobile ? '25%' : '100%',
                                marginTop: isNonMobile ? null : '20px', justifyContent: 'space-between'
                            }}>
                                <Button
                                    fullWidth
                                    name='Shooter'
                                    value={'Shooter'}
                                    onBlur={handleBlur('Shooter')}
                                    onChange={handleChange('Shooter')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%',
                                        color: 'white',
                                        borderRadius: '30px',
                                        marginTop: '10px',
                                        backgroundColor: darkMode === 'light' ? '#000000A4' : '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        // push the value of this button to the array and don't push it if it's already in the array, also remove it if it's already in the array
                                        // also don't remove the rest of the values in the array
                                        if (SelectedGenres.includes('Shooter')) {
                                            const index = SelectedGenres.indexOf('Shooter');
                                            if (index > -1) {
                                                SelectedGenres.splice(index, 1);
                                            }
                                            setSelectedGenres(SelectedGenres);
                                        }
                                        else {
                                            setSelectedGenres([...SelectedGenres, 'Shooter']);
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'Shooter' : 'شوتر'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'Shooter'}
                                        checked={SelectedGenres.includes('Shooter') || RETO_defaultSettings[0]?.Game_SelectedFilters?.includes('Shooter') ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null,
                                            color: SelectedGenres.includes('Shooter') ? '#2F5CD6' : '#FFFFFF7A', transition: 'all 0.2s ease-in-out'
                                        }}
                                    />
                                </Button>

                                <Button
                                    fullWidth
                                    name='Sports'
                                    value={'Sports'}
                                    onBlur={handleBlur('Sports')}
                                    onChange={handleChange('Sports')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%',
                                        color: 'white',
                                        borderRadius: '30px',
                                        marginTop: '10px',
                                        backgroundColor: darkMode === 'light' ? '#000000A4' : '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        // push the value of this button to the array and don't push it if it's already in the array, also remove it if it's already in the array
                                        // also don't remove the rest of the values in the array
                                        if (SelectedGenres.includes('Sports')) {
                                            const index = SelectedGenres.indexOf('Sports');
                                            if (index > -1) {
                                                SelectedGenres.splice(index, 1);
                                            }
                                            setSelectedGenres(SelectedGenres);
                                        }
                                        else {
                                            setSelectedGenres([...SelectedGenres, 'Sports']);
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'Sports' : 'رياضة'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'Sports'}
                                        checked={SelectedGenres.includes('Sports') || RETO_defaultSettings[0]?.Game_SelectedFilters?.includes('Sports') ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null,
                                            color: SelectedGenres.includes('Sports') ? '#2F5CD6' : '#FFFFFF7A', transition: 'all 0.2s ease-in-out'
                                        }}
                                    />
                                </Button>
                            </div>

                            {/* 4th column */}
                            <div style={{
                                flexDirection: isNonMobile ? 'column' : 'row', display: 'flex', width: isNonMobile ? '25%' : '100%',
                                marginTop: isNonMobile ? null : '20px', justifyContent: 'space-between'
                            }}>
                                <Button
                                    fullWidth
                                    name='Racing'
                                    value={'Racing'}
                                    onBlur={handleBlur('Racing')}
                                    onChange={handleChange('Racing')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%',
                                        color: 'white',
                                        borderRadius: '30px',
                                        marginTop: '10px',
                                        backgroundColor: darkMode === 'light' ? '#000000A4' : '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        // push the value of this button to the array and don't push it if it's already in the array, also remove it if it's already in the array
                                        // also don't remove the rest of the values in the array
                                        if (SelectedGenres.includes('Racing')) {
                                            const index = SelectedGenres.indexOf('Racing');
                                            if (index > -1) {
                                                SelectedGenres.splice(index, 1);
                                            }
                                            setSelectedGenres(SelectedGenres);
                                        }
                                        else {
                                            setSelectedGenres([...SelectedGenres, 'Racing']);
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'Racing' : 'سباق'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'Racing'}
                                        checked={SelectedGenres.includes('Racing') || RETO_defaultSettings[0]?.Game_SelectedFilters?.includes('Racing') ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null,
                                            color: SelectedGenres.includes('Racing') ? '#2F5CD6' : '#FFFFFF7A', transition: 'all 0.2s ease-in-out'
                                        }}
                                    />
                                </Button>

                                <Button
                                    fullWidth
                                    name='Strategy'
                                    value={'Strategy'}
                                    onBlur={handleBlur('Strategy')}
                                    onChange={handleChange('Strategy')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%',
                                        color: 'white',
                                        borderRadius: '30px',
                                        marginTop: '10px',
                                        backgroundColor: darkMode === 'light' ? '#000000A4' : '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        // push the value of this button to the array and don't push it if it's already in the array, also remove it if it's already in the array
                                        // also don't remove the rest of the values in the array
                                        if (SelectedGenres.includes('Strategy')) {
                                            const index = SelectedGenres.indexOf('Strategy');
                                            if (index > -1) {
                                                SelectedGenres.splice(index, 1);
                                            }
                                            setSelectedGenres(SelectedGenres);
                                        }
                                        else {
                                            setSelectedGenres([...SelectedGenres, 'Strategy']);
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'Strategy' : 'إستراتيجية'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'Strategy'}
                                        checked={SelectedGenres.includes('Strategy') || RETO_defaultSettings[0]?.Game_SelectedFilters?.includes('Strategy') ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null,
                                            color: SelectedGenres.includes('Strategy') ? '#2F5CD6' : '#FFFFFF7A', transition: 'all 0.2s ease-in-out'
                                        }}
                                    />
                                </Button>
                            </div>
                        </div>

                        {/* 2nd row */}
                        <div style={{ flexDirection: isNonMobile ? 'row' : 'column', display: 'flex', width: '100%', justifyContent: 'center' }}>

                            {/* 1st column */}
                            <div style={{
                                flexDirection: isNonMobile ? 'column' : 'row', display: 'flex', width: isNonMobile ? '25%' : '100%',
                                marginTop: isNonMobile ? null : '20px', justifyContent: 'space-between'
                            }}>
                                <Button
                                    fullWidth
                                    name='RPG'
                                    value={'RPG'}
                                    onBlur={handleBlur('RPG')}
                                    onChange={handleChange('RPG')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%',
                                        color: 'white',
                                        borderRadius: '30px',
                                        marginTop: '10px',
                                        backgroundColor: darkMode === 'light' ? '#000000A4' : '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        // push the value of this button to the array and don't push it if it's already in the array, also remove it if it's already in the array
                                        // also don't remove the rest of the values in the array
                                        if (SelectedGenres.includes('RPG')) {
                                            const index = SelectedGenres.indexOf('RPG');
                                            if (index > -1) {
                                                SelectedGenres.splice(index, 1);
                                            }
                                            setSelectedGenres(SelectedGenres);
                                        }
                                        else {
                                            setSelectedGenres([...SelectedGenres, 'RPG']);
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'RPG' : 'RPG'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'RPG'}
                                        checked={SelectedGenres.includes('RPG') || RETO_defaultSettings[0]?.Game_SelectedFilters?.includes('RPG') ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null,
                                            color: SelectedGenres.includes('RPG') ? '#2F5CD6' : '#FFFFFF7A', transition: 'all 0.2s ease-in-out'
                                        }}
                                    />
                                </Button>

                                <Button
                                    fullWidth
                                    name='Puzzle'
                                    value={'Puzzle'}
                                    onBlur={handleBlur('Puzzle')}
                                    onChange={handleChange('Puzzle')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%',
                                        color: 'white',
                                        borderRadius: '30px',
                                        marginTop: '10px',
                                        backgroundColor: darkMode === 'light' ? '#000000A4' : '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        // push the value of this button to the array and don't push it if it's already in the array, also remove it if it's already in the array
                                        // also don't remove the rest of the values in the array
                                        if (SelectedGenres.includes('Puzzle')) {
                                            const index = SelectedGenres.indexOf('Puzzle');
                                            if (index > -1) {
                                                SelectedGenres.splice(index, 1);
                                            }
                                            setSelectedGenres(SelectedGenres);
                                        }
                                        else {
                                            setSelectedGenres([...SelectedGenres, 'Puzzle']);
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'Puzzle' : 'ألغاز'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'Puzzle'}
                                        checked={SelectedGenres.includes('Puzzle') || RETO_defaultSettings[0]?.Game_SelectedFilters?.includes('Puzzle') ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null,
                                            color: SelectedGenres.includes('Puzzle') ? '#2F5CD6' : '#FFFFFF7A', transition: 'all 0.2s ease-in-out'
                                        }}
                                    />
                                </Button>
                            </div>

                            {/* 2nd column */}
                            <div style={{
                                flexDirection: isNonMobile ? 'column' : 'row', display: 'flex', width: isNonMobile ? '25%' : '100%',
                                marginTop: isNonMobile ? null : '20px', justifyContent: 'space-between'
                            }}>
                                <Button
                                    fullWidth
                                    name='Fighting'
                                    value={'Fighting'}
                                    onBlur={handleBlur('Fighting')}
                                    onChange={handleChange('Fighting')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%',
                                        color: 'white',
                                        borderRadius: '30px',
                                        marginTop: '10px',
                                        backgroundColor: darkMode === 'light' ? '#000000A4' : '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        // push the value of this button to the array and don't push it if it's already in the array, also remove it if it's already in the array
                                        // also don't remove the rest of the values in the array
                                        if (SelectedGenres.includes('Fighting')) {
                                            const index = SelectedGenres.indexOf('Fighting');
                                            if (index > -1) {
                                                SelectedGenres.splice(index, 1);
                                            }
                                            setSelectedGenres(SelectedGenres);
                                        }
                                        else {
                                            setSelectedGenres([...SelectedGenres, 'Fighting']);
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'Fighting' : 'قتال'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'Fighting'}
                                        checked={SelectedGenres.includes('Fighting') || RETO_defaultSettings[0]?.Game_SelectedFilters?.includes('Fighting') ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null,
                                            color: SelectedGenres.includes('Fighting') ? '#2F5CD6' : '#FFFFFF7A', transition: 'all 0.2s ease-in-out'
                                        }}
                                    />
                                </Button>

                                <Button
                                    fullWidth
                                    name='Role-playing'
                                    value={'Role-playing'}
                                    onBlur={handleBlur('Role-playing')}
                                    onChange={handleChange('Role-playing')}
                                    variant="outlined"
                                    style={{
                                        width: isNonMobile ? '65%' : '50%',
                                        color: 'white',
                                        borderRadius: '30px',
                                        marginTop: '10px',
                                        backgroundColor: darkMode === 'light' ? '#000000A4' : '#0000004D',
                                        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                    }}
                                    onClick={() => {
                                        // push the value of this button to the array and don't push it if it's already in the array, also remove it if it's already in the array
                                        // also don't remove the rest of the values in the array
                                        if (SelectedGenres.includes('Role-playing')) {
                                            const index = SelectedGenres.indexOf('Role-playing');
                                            if (index > -1) {
                                                SelectedGenres.splice(index, 1);
                                            }
                                            setSelectedGenres(SelectedGenres);
                                        }
                                        else {
                                            setSelectedGenres([...SelectedGenres, 'Role-playing']);
                                        }
                                    }}
                                >

                                    {CurrentLanguage === 'EN' ? 'Role-playing' : 'Role-playing'}

                                    <Checkbox
                                        type='checkbox'
                                        disabled onClick="return false"
                                        value={'Role-playing'}
                                        checked={SelectedGenres.includes('Role-playing') || RETO_defaultSettings[0]?.Game_SelectedFilters?.includes('Role-playing') ? true : false}
                                        style={{
                                            width: '15px', height: '15px', marginRight: CurrentLanguage === 'EN' ? null : 'auto',
                                            marginLeft: CurrentLanguage === 'EN' ? 'auto' : null,
                                            color: SelectedGenres.includes('Role-playing') ? '#2F5CD6' : '#FFFFFF7A', transition: 'all 0.2s ease-in-out'
                                        }}
                                    />
                                </Button>
                            </div>

                        </div>
                    </form>
                )}

            </Formik>
        </div>
    )
}

export default GmaeFilterGenresMenu