import React, { useState, useEffect } from 'react'
import UserIcon from '../../assets/img/icons/user-icon.png';
import { useAuth } from '../../xFireBase/AuthContext';


const Notification = ({ data }) => {

  const { CurrentLanguage } = useAuth();
  const date = new Date(data?.Notify_CreatedAt?.seconds && data?.Notify_CreatedAt?.seconds * 1000);
  const today = new Date();
  const [Notify_CreatedAt, setNotify_CreatedAt] = useState('');

  
  useEffect(() => {
    const timeDiff = today.getTime() - date.getTime(); // time difference in milliseconds
    const secondsDiff = Math.floor(timeDiff / 1000); // time difference in seconds
    const minutesDiff = Math.floor(secondsDiff / 60); // time difference in minutes
    const hoursDiff = Math.floor(minutesDiff / 60); // time difference in hours
    const daysDiff = Math.floor(hoursDiff / 24); // time difference in days
    const weeksDiff = Math.floor(daysDiff / 7); // time difference in weeks
    const yearsDiff = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear(); // time difference in years
  
    if (secondsDiff < 60) {
      setNotify_CreatedAt(`منذ ${secondsDiff.toLocaleString('ar')} ثانية`);
    } else if (minutesDiff < 60) {
      setNotify_CreatedAt(`منذ ${minutesDiff.toLocaleString('ar')} دقيقة`);
    } else if (hoursDiff < 24) {
      setNotify_CreatedAt(`منذ ${hoursDiff.toLocaleString('ar')} ساعة`);
    } else if (daysDiff === 1) {
      setNotify_CreatedAt('أمس');
    } else if (daysDiff < 7) {
      setNotify_CreatedAt(`منذ ${daysDiff.toLocaleString('ar')} يوم`);
    } else if (weeksDiff < 4) {
      setNotify_CreatedAt(`منذ ${weeksDiff.toLocaleString('ar')} أسبوع`);
    } else {
        setNotify_CreatedAt(`${yearsDiff.toLocaleString('ar')}`);
    }
  }, []);

  return (
    <div className='Profile-Notifications-inner' style={{marginLeft: "10px", direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr'}}>
      <div className='Profile-Notifications-image'>
        <img
        loading='lazy'
        draggable={false} 
        src={data?.Notify_Image ? data?.Notify_Image : UserIcon} 
        alt='user' 
        onContextMenu={(e) => e.preventDefault()} />
      </div>
      <div className='Profile-Notifications-right-part'>
        <div className='Profile-Notifications-content'>
          <div className='Profile-Notifications-content-username'>
            {data.userName}
          </div>
          <div style={{ }}>
            <span style={{ marginRight: '5px', color: 'rgb(163, 163, 163)', }}>
              {/* if the current language is arabic */}
              {CurrentLanguage === 'AR' ? Notify_CreatedAt 
              :
              // if the current language is english replace all the arabic words with english words
              Notify_CreatedAt.replace('منذ', 'ago')
              .replace('ثانية', 'second')
              .replace('دقيقة', 'minute')
              .replace('ساعة', 'hour')
              .replace('أمس', 'yesterday')
              .replace('يوم', 'day')
              .replace('أسبوع', 'week')
              .replace('أسابيع', 'weeks')
              .replace('شهر', 'month')
              .replace('أشهر', 'months')
              .replace('سنة', 'year')
              .replace('سنوات', 'years')
              } 
            </span>
          </div>
        </div>
        <div 
        className='Profile-Notifications-text' 
        style={{transform: CurrentLanguage === 'EN' ? 'scale(0.9)' : null, }}>
          { 
          // if the current language is arabic
          CurrentLanguage === 'AR' && data.Notify_Message  
          ?  // split the message into an array of words
          data.Notify_Message
          .replace('تم', 'تم')
          .replace('تحميل', 'تحميل')
          .replace('لعبة', 'لعبة:')
          .replace('برنامج', 'برنامج:')
          .split(' ').map((word, index) => {
            // if the word is 'تم' or 'تحميل' or 'لعبة' or 'برنامج' then change the color of the word to blue
            if (word === 'تم' || word === 'تحميل' || word === 'لعبة:' || word === 'برنامج:') {
              return <span key={index} style={{color: '#3993B6'}}>{word} </span>
            } else {
              return <span key={index}>{word} </span>
            }
          })
          :
          // if the current language is english
          CurrentLanguage === 'EN' && data.Notify_Message
          ? // replace the words 'تم' or 'تحميل' or 'لعبة' or 'برنامج' with their english words, then split the message into an array of words
          data.Notify_Message.replace('تم', '')
          .replace('تحميل', 'Downloaded')
          .replace('لعبة', 'Game:')
          .replace('برنامج', 'App:')
          .split(' ').map((word, index) => {
            // if the word is 'Downloaded' or 'Game' or 'App' then change the color of the word to blue
            if (word === 'Downloaded' || word === 'Game:' || word === 'App:') {
              return <span key={index} style={{color: '#3993B6'}}>{word} </span>
            } else {
              return <span key={index}>{word} </span>
            }
          }) 
          : null
          }
        </div>
        
      </div>
    </div>
  )
}

export default Notification