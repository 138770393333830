// Import the functions you need from the SDKs you need

import firebase from 'firebase/compat/app';

import 'firebase/compat/auth';

import 'firebase/compat/firestore';
import 'firebase/compat/storage'

// firebaseConfig for the storage database 1 
const firebaseConfig = {
  apiKey: "AIzaSyCfmm1iuTHAfBHCZjeDKIgZ-2z-u2_1b0A",
  authDomain: "retomainweb.firebaseapp.com",
  projectId: "retomainweb",
  storageBucket: "retomainweb.appspot.com",
  messagingSenderId: "552844289324",
  appId: "1:552844289324:web:e59a85b9288bf2aecfb889",
  measurementId: "G-HCKW201W5V"
};



firebase.initializeApp(firebaseConfig);


const database = firebase.firestore();
const storage = firebase.storage();



export { firebase, database, storage };
