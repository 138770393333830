import React, { useState, useEffect } from 'react';
import axios from "axios";
import CryptoJS from "crypto-js";
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import { CurrentGameCard } from "../../components/HomeMiddle/CurrentGameCard";
import 'animate.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Preloader from "../../hooks/Others/PreLoader";
import Profile_Loading from '../../assets/img/icons/Profile_Loading.svg';
import Page_Helmet from "../../hooks/SEO/react_Helmet";
import { useAuth } from '../../xFireBase/AuthContext';
import ScrollToTop from '../../ScrollToTop';

function CurrentGamePage() {

  const { ALL_Games, CurrentLanguage, darkMode, loading } = useAuth();

  const { game_Number, game_Page } = useParams();
  const [gameData, setGameData] = useState(null);

  const [something_happened, setSomething_happened] = useState(false);

  const [timer_animation, setTimer_animation] = useState(0);

  useEffect(() => {
    if (!gameData || gameData.length <= 0) {
      setTimeout(() => {
        setSomething_happened(true);
      }, 6000);
    }
  }, []);

  // Function to process the description and replace URLs with links
  const processDescription = (desc) => {
    return desc.split(' ').map((word, index) => {
      return word.startsWith('https://') ? (
        <a
          key={index}
          href={word}
          target='_self'
          rel="noopener noreferrer"
          style={{ color: '#2F8DF8' }}
        >
          <h4 style={{ display: 'inline', color: '#2F8DF8' }}>{"رابط " + ' \n'}</h4>
        </a>
      ) : (
        word + ' '
      );
    });
  };


  const axiosFetchData = async () => {
    try {
      // go through ALL_Games and get the game_Number && game_Page
      const gamedata = await ALL_Games?.filter(game => game.game_Page === game_Page);
      return gamedata;

    }
    catch (error) {
      console.log('error')
    }
  };

  useEffect(() => {
    async function fetchData() {
      const result = await axiosFetchData();
      if (result) {
        setGameData(result);
        setTimer_animation(1);
      }
    }
    fetchData();
  }, [game_Number, game_Page]);

  if (!gameData) {
    return <Preloader load={loading} darkMode={darkMode} />
  }

  return (
    <div className={timer_animation === 1 ? "animate__animated animate__fadeIn" : ''}>
      <Page_Helmet
        title={`Arab2Games | ${gameData[0]?.game_Name || ''}`}
        type="article"
        description={gameData[0]?.game_Description
          ? gameData[0]?.game_Description.length > 550
            ? processDescription(gameData[0]?.game_Description.slice(0, 550)).concat('...')
            : processDescription(gameData[0]?.game_Description) : ''
        }
        image={gameData[0]?.game_Picture[0] || ''}
        url={window.location.href}
        keywords={gameData[0]?.game_Categories || ''}
      />
      <ScrollToTop />
      <section className="GamePageSection">
        <Container>
          <Row>
            <Col size={12}>
              <Row
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                {gameData?.length > 0 ? (
                  gameData?.map((doc, index) => {
                    return (
                      <Col xs={6} sm={6} md={6} lg={5} xl={12} key={index} onClick={() => { }}>
                        <CurrentGameCard key={index} All_gameData={doc} game_Page={doc.game_Page} />
                      </Col>
                    );
                  })
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center' }}>
                    {something_happened === false ?
                      <LazyLoadImage
                        effect="blur"
                        alt="Profile_Loading"
                        draggable={false}
                        src={Profile_Loading}
                        onContextMenu={(e) => e.preventDefault()}
                        style={{ width: '30px', height: '30px', display: 'flex' }} />
                      :
                      null
                    }
                    {something_happened === false && gameData.length <= 0 ?
                      <h1>{CurrentLanguage === 'EN'
                        ? 'Loading Game Page'
                        : 'جاري تحميل صفحة اللعبة'}</h1>
                      :
                      something_happened === true && gameData.length <= 0 ?
                        <h3>{CurrentLanguage === 'EN'
                          ? 'Something went wrong ❌'
                          : '❌ حدث خطأ ما'}
                        </h3>
                        :
                        null
                    }
                  </div>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default CurrentGamePage;
