import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { RETO_defaultSettings } from "../../hooks/Others/RETO_data";
import Page_Helmet from "../../hooks/SEO/react_Helmet";
import { useAuth } from "../../xFireBase/AuthContext";
import ScrollToTop from "../../ScrollToTop";
import WarningMark from '../../assets/img/icons/WarningMark.gif';


const AgreementScreen = () => {


  const { CurrentLanguage, darkMode } = useAuth();
  const isNonMobile = useMediaQuery("(min-width:992px)");

  const [box_isHovered, setBox_isHovered] = useState(false);


  const Agreement_array_AR = [
    {
      id: 1,
      desc: 'يمنع السب و الشتم من خلال التعليقات أو الرسائل أو أي وسيلة للتواصل داخل الموقع',
    },
    {
      id: 2,
      desc: 'يمنع مشاركة أو الدخول في مواضيع سياسية او دينية أو طائفية او اي شيء ليس له علاقة بحاجة فالموقع منعاً باتاً '
    },
    {
      id: 3,
      desc: `لا يمكنك التسجيل بأكثر من حساب واحد بنفس الإيميل ..
      ولكن إن وُجد لديك اكثر من حساب بأي طريقة كانت .. فسوف يتم حذف الحسابات كلها`
    },
    {
      id: 4,
      desc: 'في حال واجهتك مشكلة في الموقع او في حسابك الشخصي او في اي شيء متعلق بالموقع ..تواصل معي عبر سيرفر الدسكورد https://discord.gg/TW553U9cpw'
    },
    {
      id: 5,
      desc: 'يمنع لمن هم دون عمر 16 سنة من استخدام الموقع'
    },
    {
      id: 6,
      desc: 'لا يحق لأي احد نشر موقع او رابط او اي شيء ليس متعلق بالموقع'
    },
    {
      id: 7,
      desc: 'يمنع الترويج / الإعلان عن أي شيء داخل الموقع او في سيرفر الدسكورد https://discord.gg/TW553U9cpw'
    },
    {
      id: 8,
      desc: 'سوف يتم حظرك نهائياً في حال الإساءة لأي شخص او لدين الإسلام بشكل خاص و للأديان الأخرى بشكل عام'
    },
    {
      id: 9,
      desc: 'نرجو منك ان تكون محترماً و مهذباً في تعاملك مع الجميع داخل الموقع و في سيرفر الدسكورد https://discord.gg/TW553U9cpw'
    },
    {
      id: 10,
      desc: ' احترم الجميع و احترم القوانين و الشروط و الأحكام'
    },
    {
      id: 11,
      desc: 'يمنع إنتحال شخصية أحد الأعضاء في الموقع و سيتم حذف الحسابات المخالفة و حظرها نهائياً'
    }
  ];

  // it will be exactly the same array as the AR one, but in english
  const Agreement_array_EN = [
    {
      id: 1,
      desc: 'Swearing or insulting through comments or messages or any means of communication within the site is prohibited',
    },
    {
      id: 2,
      desc: 'It is forbidden to share or enter political, religious, sectarian or anything that has nothing to do with the site at all'
    },
    {
      id: 3,
      desc: `You cannot register more than one account with the same email ..
      But if you have more than one account in any way .. all accounts will be deleted`
    },
    {
      id: 4,
      desc: 'In case you encounter a problem on the site or in your personal account or in anything related to the site .. contact us via the Discord server https://discord.gg/TW553U9cpw'
    },
    {
      id: 5,
      desc: 'Those under the age of 16 are not allowed to use the site'
    },
    {
      id: 6,
      desc: 'No one is allowed to publish a site, link, or anything not related to the site'
    },
    {
      id: 7,
      desc: 'Advertising / advertising anything within the site or on the Discord server https://discord.gg/TW553U9cpw is prohibited'
    },
    {
      id: 8,
      desc: 'You will be permanently banned if you abuse anyone or the religion of Islam in particular and other religions in general'
    },
    {
      id: 9,
      desc: 'Please be respectful and polite in dealing with everyone inside the site and on the Discord server https://discord.gg/TW553U9cpw'
    },
    {
      id: 10,
      desc: 'Respect everyone and respect the rules and conditions'
    },
    {
      id: 11,
      desc: 'Impersonating one of the members of the site is prohibited and the violating accounts will be deleted and permanently banned'
    }
  ];


  return (
    <section
      className="PolicyScreenSection" id="AgreementScreen"
      style={{
        transition: 'all 0.5s ease-in-out',
        height: 'auto', display: 'flex',
        justifyContent: 'center', alignItems: 'flex-start',
      }}
    >
      <Page_Helmet
        title={CurrentLanguage === 'EN' ? "Arab2Games | Terms & Conditions" : "Arab2Games | شروط و أحكام"}
        type="article"
        description={CurrentLanguage === 'EN' ? "Arab2Games | Terms & Conditions" : "Arab2Games | شروط و أحكام"}
        image={RETO_defaultSettings[0].Website_Main_Logo}
        url={window.location.href ? window.location.href : "https://Arab2Games.com/"}
        keywords={"apps, games, discord bots, arab2games, Arab2Games, Arab2Games.com, arab2games.com, arab2games.org, Arab2Games.org, arab2games.net, Arab2Games.net, arab2games.io, Arab2Games.io, arab2games.dev, Arab2Games.dev, crack, cracked, crack game, cracked game, crack games, cracked games, crack app, cracked app, crack apps, cracked apps, مكركة, كراك, العاب مكركة, مكركه, العاب مكركه, برامج مكركه, برامج مكركة, لعبة مكركة, لعبه مكركه, برنامج مكرك, العاب, لعبة, لعبه, برنامج, برامح, ريتو, لايك, لايك ريتو, arab2games, Arab2Games, ARAB2GAMES, Arab2Games, Arab2Games"}
      />
      <ScrollToTop />
      <Container>
        <Row>
          <Col size={12}>
            <div style={{ marginBottom: '50px' }}>
              <Box style={{ flexDirection: 'column', display: 'flex', width: '100%', position: 'relative' }}>
                <div style={{ width: '100%', }}>
                  <div
                    size={12}
                    xs={6}
                    sm={6}
                    md={6}
                    lg={4}
                    xl={3}
                    className={isNonMobile ? 'PolicyScreen-bx' : 'PolicyScreen-bx-Mobile'}
                    style={{
                      transition: 'all 0.5s ease-in-out',
                      direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                      boxShadow: darkMode === 'light'
                        ? box_isHovered === true ? '0 0 20px 0 #42297c' : '0 0 20px 0 #4644449D'
                        : box_isHovered === true ? '0 0 20px 0 #42297c' : '0 0 20px 0 #e2dada31'
                    }}
                    onMouseEnter={() => setBox_isHovered(true)}
                    onMouseLeave={() => setBox_isHovered(false)}
                  >

                    <div style={{
                      display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                      textAlign: CurrentLanguage === 'EN' ? 'left' : 'right',
                      width: '100%', padding: '0.5rem'
                    }}>
                      <header className="PolicyScreen-bx-Title">
                        <h2 style={{
                          transition: 'all 0.5s ease-in-out',
                          fontSize: '1.15rem',
                          color: darkMode === 'light' ? 'black' : 'white',
                          borderRadius: '0.2rem'
                        }}
                        >
                          {CurrentLanguage === 'EN'
                            ? 'Terms and Conditions of the site :'
                            : 'شروط و أحكام الموقع :'
                          }
                        </h2>

                      </header>

                      <span style={{ marginRight: '3px' }}>
                        {CurrentLanguage === 'EN'
                          ?
                          Agreement_array_EN.map((item) => {
                            return (
                              <div key={item.id} style={{ margin: '25px 0', display: 'flex', flexDirection: "row" }}>
                                <div>
                                  <LazyLoadImage
                                    effect="blur"
                                    draggable={false}
                                    src={WarningMark}
                                    alt='WarningMark'
                                    onContextMenu={(e) => e.preventDefault()}
                                    style={{
                                      width: '1.2rem', height: '1.2rem', marginLeft: '0.5rem'
                                    }} />
                                </div>
                                <span style={{
                                  transition: 'all 0.4s ease',
                                  fontSize: '1.1rem',
                                  color: darkMode === 'light' ? '#3A3939' : '#A7A7A7',
                                  marginLeft: '0.5rem'
                                }}
                                >
                                  {
                                    // check if the current item is the id 7, wrap the word that has the link with an actual link
                                    item.desc.split(' ').map((word) => {
                                      return word.startsWith('https')
                                        ? <a
                                          href={word}
                                          target='_blank'
                                          rel="noreferrer"
                                          style={{ color: '#3170C2' }}>
                                          {word}
                                        </a>
                                        :
                                        <span>{word} </span>
                                    })
                                  }
                                </span>
                              </div>
                            )
                          })
                          :
                          Agreement_array_AR.map((item) => {
                            return (
                              <div key={item.id} style={{ margin: '25px 0', display: 'flex', flexDirection: "row" }}>
                                <div>
                                  <LazyLoadImage
                                    effect="blur"
                                    draggable={false}
                                    src={WarningMark}
                                    alt='WarningMark'
                                    onContextMenu={(e) => e.preventDefault()}
                                    style={{
                                      width: '1.2rem', height: '1.2rem', marginLeft: '0.5rem'
                                    }} />
                                </div>
                                <span style={{
                                  transition: 'all 0.4s ease',
                                  fontSize: '1.1rem',
                                  color: darkMode === 'light' ? '#3A3939' : '#A7A7A7',
                                  marginLeft: '0.5rem'
                                }}
                                >
                                  {
                                    // check if the current item is the id 7, wrap the word that has the link with an actual link
                                    item.id === 4 || item.id === 7 || item.id === 9
                                      ? item.desc.split(' ').map((word) => {
                                        return word.startsWith('https')
                                          ? <a
                                            href={word}
                                            target='_blank'
                                            rel="noreferrer"
                                            style={{ color: '#3170C2' }}>
                                            {word}
                                          </a>
                                          :
                                          <span >{word} </span>
                                      })
                                      : item.desc
                                  }
                                </span>
                              </div>
                            )
                          })
                        }
                      </span>

                      <br />
                    </div>

                  </div>
                </div>
              </Box>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}


export default AgreementScreen;