import axios from "axios";

// game_Downloaded_Times_updater method
const game_Downloaded_Times_updater = async ({All_gameData, User_IP_Address}) => {
    try {
        const updateData = {
            game_Name: All_gameData.game_Name,
            game_Number: All_gameData.game_Number,
            game_Page: All_gameData.game_Page,
            User_IP_Address: User_IP_Address,
        }

        await axios.post("https://arab2games-games-backend.vercel.app/api/arab2games/game_watched_times_updater", updateData)
        .then(() => {
            return true
        })
        .catch((error) => {
            console.log("❌ game_DownloadedTimesUpdater ~ error : ", error.response.data.message)
            return false
        })
    }
    catch (error) {
        console.log("❌ game_DownloadedTimesUpdater ~ couldn't update the download times ~ error :   ", error)
        return false;
    }
};

export default game_Downloaded_Times_updater;