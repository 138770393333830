import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { RETO_defaultSettings } from "../../hooks/Others/RETO_data";
import Page_Helmet from "../../hooks/SEO/react_Helmet";
import { useAuth } from "../../xFireBase/AuthContext";
import ScrollToTop from "../../ScrollToTop";
import WarningMark from '../../assets/img/icons/WarningMark.gif';


const PolicyScreen = () => {


  const { CurrentLanguage, darkMode } = useAuth();
  const isNonMobile = useMediaQuery("(min-width:992px)");

  const [box_isHovered, setBox_isHovered] = useState(false);


  const Policy_array_AR = [
    {
      id: 1,
      desc: 'لا أحد سوى الإدارة يحق لها رؤية بريدك الإلكتروني و معلوماتك الشخصية الأخرى',
    },
    {
      id: 2,
      desc: `موقع Arab2Games لا يستضيف أي محتوى على السيرفر الخاص به,
       و إنما يقوم بوضع روابط و تضمين لمحتوى مرفوع و موجود على الإنترنت و على سيرفرات خارجية`,
    },
    {
      id: 3,
      desc: `موقع Arab2Games غير مسؤول عن أي محتوى تم تحميله على سيرفرات خارجية و مواقع خارجية " طرف ثالث ", 
       و بهذا فإن موقع Arab2Games لا ينتهك حقوق الطبع و النشر و قانون الألفية للملكية الرقمية DMCA \n 
       و في حال كان لديك شكوى خاصة بالروابط و التضامين الخارجية, الرجاء التواصل مع إدارة هذه السيرفرات و المواقع الخارجية.`
    },
    {
      id: 4,
      desc: 'كل الأعضاء يستطيعون رؤية تعليقاتك و تقييماتك '
    },
    {
      id: 5,
      desc: `فقط انت من يستطيع تعديل في حسابك الشخصي.
       إلا في حال طلبت من الإدارة في سيرفر الدسكورد بتعديل المعلومات التي طلبتها منها 
       او في حال تجاوزت الشروط و الأحكام`
    },
    {
      id: 6,
      desc: 'فقط انت و الإدارة الذين تستطيعون حذف تعليقاتك و تقييماتك'
    },
    {
      id: 7,
      desc: 'موقعك ( الدولة ) ظاهر للجميع في صفحتك الشخصية, و هذا لا يتضمن الـ ip الخاص بك. فقط الإدارة من تستطيع رؤيته لأسباب أمنية'
    },
    {
      id: 8,
      desc: `يتم جمع بعض المعلومات البسيطه عن جهازك و متصفحك لأغراض تحسين الموقع و تجربة المستخدم 
      كمثال : حجم الشاشة, نوع المتصفح, نظام التشغيل, اللغة, الدولة, و غيرها من المعلومات البسيطة.
       و هذه المعلومات لا يمكن لأي احد غيرك الإطلاع عليها في صفحتك الشخصية ( ما عدا انت و الإدارة )`
    },
    {
      id: 9,
      desc: `عند تجاوزك لشروط الإستخدام,
       في أول مرة سيتم تحذيرك او حظرك لمدة ما بين 5 الى 24 ساعة.
       و في حال تجاوزك للشروط مرة أخرى, سيتم حظرك نهائياً, و لن يتم رفع الحظر عنك إلا اذا تواصلت مع الإدارة في الدسكورد و توافق الإدارة على ذلك`
    },
    {
      id: 10,
      desc: 'لا يوجد شيء غير قابل للإختراق لذا في حال قام أحد بإختراق حسابك, يرجى التواصل مع الإدارة عبر سيرفر دسكورد بأسرع وقت : https://discord.gg/TW553U9cpw'
    }
  ];

  // it will be exactly the same array as the AR one, but in english
  const Policy_array_EN = [
    {
      id: 1,
      desc: 'No one but the administration has the right to see your email and other personal information',
    },
    {
      id: 2,
      desc: `RETO website does not host any content on its own server, 
      but rather linking to embedding content that was uploaded to popular Online Hosting / available on the Internet / external servers`,
    },
    {
      id: 3,
      desc: `RETO website is not responsible for any content uploaded to external servers and external websites "third party", 
      and thus RETO website does not violate copyright 
      and the Digital Millennium Copyright Act DMCA \n
      If you have a complaint about external links and embeds, please contact the administration of these external servers and websites.`
    },
    {
      id: 4,
      desc: 'All members can see your comments and ratings'
    },
    {
      id: 5,
      desc: `Only you can edit your personal account. 
      Unless you have requested from the administration in the Discord server to edit the information you requested from them 
      or in case you have exceeded the terms and conditions`
    },
    {
      id: 6,
      desc: 'Only you and the administration can delete your comments and ratings'
    },
    {
      id: 7,
      desc: 'Your location (country) is visible to everyone on your profile page, and this does not include your ip. Only the administration can see it for security reasons'
    },
    {
      id: 8,
      desc: `Some simple information about your device and browser is collected for the purpose of improving the site and the user experience, 
      for example: screen size, browser type, operating system, language, country, and other simple information. 
      This information cannot be viewed by anyone at all.`
    },
    {
      id: 9,
      desc: `When you exceed the terms of use,
       the first time you will be warned or banned for a period of 5 to 24 hours.
       And in case you exceed the terms again, you will be permanently banned, and the ban will not be lifted 
       unless you communicate with the administration in Discord and the administration agrees to that`
    },
    {
      id: 10,
      desc: `There is nothing that is not hackable, so if someone hacked your account, please contact the administration via 
      the Discord server as soon as possible : https://discord.gg/TW553U9cpw`
    }
  ];


  return (
    <section className="PolicyScreenSection" id="PolicyScreen"
      style={{
        transition: 'all 0.5s ease-in-out',
        height: 'auto', display: 'flex',
        justifyContent: 'center', alignItems: 'flex-start',
      }}
    >
      <Page_Helmet
        title={CurrentLanguage === 'EN' ? "Arab2Games | Policy & Agreement" : "Arab2Games | اتفاقية الاستخدام"}
        type="article"
        description={CurrentLanguage === 'EN' ? "Arab2Games | Policy & Agreement" : "Arab2Games | اتفاقية الاستخدام"}
        image={RETO_defaultSettings[0].Website_Main_Logo}
        url={window.location.href ? window.location.href : "https://Arab2Games.com/"}
        keywords="A2G, Arab2Games, Arab2Game, arab2games, arab2game, عرب تو قيمز, عرب تو قيمنق, عرب 2 قيمز, apps, games, discord bots, arab2games, Arab2Games, Arab2Games.com, arab2games.com, arab2games.org, Arab2Games.org, arab2games.net, Arab2Games.net, arab2games.io, Arab2Games.io, arab2games.dev, Arab2Games.dev, crack, cracked, crack game, cracked game, crack games, cracked games, crack app, cracked app, crack apps, cracked apps, مكركة, كراك, العاب مكركة, مكركه, العاب مكركه, برامج مكركه, برامج مكركة, لعبة مكركة, لعبه مكركه, برنامج مكرك, العاب, لعبة, لعبه, برنامج, برامح, ريتو, لايك, لايك ريتو, arab2games, Arab2Games, ARAB2GAMES, Arab2Games, Arab2Games"
      />
      <ScrollToTop />
      <Container>
        <Row>
          <Col size={12}>
            <div style={{ marginBottom: '50px' }}>
              <Box style={{ flexDirection: 'column', display: 'flex', width: '100%', position: 'relative' }}>
                <div style={{ width: '100%', }}>
                  <div
                    size={12}
                    xs={6}
                    sm={6}
                    md={6}
                    lg={4}
                    xl={3}
                    className={isNonMobile ? 'PolicyScreen-bx' : 'PolicyScreen-bx-Mobile'}
                    style={{
                      transition: 'all 0.5s ease-in-out',
                      direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
                      boxShadow: darkMode === 'light'
                        ? box_isHovered === true ? '0 0 20px 0 #42297c' : '0 0 20px 0 #4644449D'
                        : box_isHovered === true ? '0 0 20px 0 #42297c' : '0 0 20px 0 #e2dada31'
                    }}
                    onMouseEnter={() => setBox_isHovered(true)}
                    onMouseLeave={() => setBox_isHovered(false)}
                  >

                    <div style={{
                      display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
                      textAlign: CurrentLanguage === 'EN' ? 'left' : 'right',
                      width: '100%', padding: '0.5rem'
                    }}>
                      <header className="PolicyScreen-bx-Title">
                        <h2 style={{
                          transition: 'all 0.4s ease',
                          fontSize: '1.15rem',
                          color: darkMode === 'light' ? 'black' : 'white',
                          borderRadius: '0.2rem'
                        }}
                        >
                          {CurrentLanguage === 'EN'
                            ? 'Privacy Policy and User Agreement for Members :'
                            : 'سياسة الخصوصية و اتفاقية الاستخدام للأعضاء :'
                          }
                        </h2>

                      </header>

                      <span style={{ marginRight: '3px' }}>
                        {CurrentLanguage === 'EN'
                          ?
                          Policy_array_EN.map((item) => {
                            return (
                              <div key={item.id} style={{ margin: '25px 0', display: 'flex', flexDirection: "row" }}>
                                <div>
                                  <LazyLoadImage
                                    effect="blur"
                                    draggable={false}
                                    src={WarningMark}
                                    alt='WarningMark'
                                    onContextMenu={(e) => e.preventDefault()}
                                    style={{
                                      width: '1.2rem', height: '1.2rem', marginLeft: '0.5rem'
                                    }} />
                                </div>
                                <span style={{
                                  transition: 'all 0.4s ease',
                                  fontSize: '1.1rem',
                                  color: darkMode === 'light' ? '#3A3939' : '#A7A7A7',
                                  marginLeft: '0.5rem'
                                }}
                                >
                                  {
                                    // check if the current item and wrap the word that has the link with an actual link
                                    item.desc.split(' ').map((word) => {
                                      return word.startsWith('https')
                                        ? <a
                                          href={word}
                                          target='_blank'
                                          rel="noreferrer"
                                          style={{ color: '#3170C2' }}>
                                          {word}
                                        </a>
                                        :
                                        <span>{word} </span>
                                    })
                                  }
                                </span>
                              </div>
                            )
                          })
                          :
                          Policy_array_AR.map((item) => {
                            return (
                              <div key={item.id} style={{ margin: '25px 0', display: 'flex', flexDirection: "row" }}>
                                <div>
                                  <LazyLoadImage
                                    effect="blur"
                                    draggable={false}
                                    src={WarningMark}
                                    alt='WarningMark'
                                    onContextMenu={(e) => e.preventDefault()}
                                    style={{
                                      width: '1.2rem', height: '1.2rem', marginLeft: '0.5rem'
                                    }} />
                                </div>
                                <span style={{
                                  transition: 'all 0.4s ease',
                                  fontSize: '1.1rem',
                                  color: darkMode === 'light' ? '#3A3939' : '#A7A7A7',
                                  marginLeft: '0.5rem'
                                }}
                                >
                                  {
                                    // check if the current item and wrap the word that has the link with an actual link
                                    item.desc.split(' ').map((word) => {
                                      return word.startsWith('https')
                                        ? <a
                                          href={word}
                                          target='_blank'
                                          rel="noreferrer"
                                          style={{ color: '#3170C2' }}>
                                          {word}
                                        </a>
                                        :
                                        <span>{word} </span>
                                    })
                                  }
                                </span>
                              </div>
                            )
                          })
                        }
                      </span>

                      <br />
                    </div>

                  </div>
                </div>
              </Box>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}


export default PolicyScreen;