import React, { useState, useEffect, useRef } from "react";
import Carousel from 'react-slick';
import useMediaQuery from "@mui/material/useMediaQuery";
import "react-multi-carousel/lib/styles.css";
import arrow1 from "../../assets/img/Others/arrow1.svg";
import arrow2 from "../../assets/img/Others/arrow2.svg";

const axiosFetchData = async (docs, setFetchSliders) => {
  try {
    const response = await docs;
    const sortedData = response.sort((a, b) => new Date(b.game_Added_Date) - new Date(a.game_Added_Date));
    const slicedData = sortedData.slice(0, 5);
    setFetchSliders(slicedData);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const BannerSlider = ({ docs_Games, docs_Apps }) => {
  const isNonMobile = useMediaQuery("(min-width:1300px)");
  const isNonMobile_Small = useMediaQuery("(min-width:992px)");  
  const isTablet = useMediaQuery("(min-width:526px)");
  const [currentHref, setCurrentHref] = useState('');
  const [ShowArrows, setShowArrows] = useState(false);
  const [FetchSliders, setFetchSliders] = useState([]);
  const arrowRef = useRef();

  useEffect(() => {
    if (window.location.href.includes('/A2G_games') && docs_Games) {
      setCurrentHref('A2G_games');
      axiosFetchData(docs_Games, setFetchSliders);
    } else if (window.location.href.includes('/A2G_apps') && docs_Apps) {
      setCurrentHref('A2G_apps');
      axiosFetchData(docs_Apps, setFetchSliders);
    }
  }, [docs_Games, docs_Apps]);

  return (
    <div 
    className={isNonMobile || isNonMobile_Small ? "ALLGames-BannerSlider" : 'ALLGames-BannerSliderMobile'} 
    style={{ width: isNonMobile ? '800px' : isNonMobile_Small ? '710px' : isTablet ? '600px' : '100%' }}>
      <section>
        <div className={isNonMobile || isNonMobile_Small ? 'ALLGames-BannerSliderContainer' : 'container'}>
          {!isNonMobile && !isNonMobile_Small ?
            <div style={{ display: "flex", alignItems: "center", marginBottom: isTablet ? "40px" : '80px', marginTop: isTablet ? "-40px" : "-25px" }}>
              <button
                className={"BannerSlider-arrowLeftMobile wow fadeInLeft"}
                onClick={() => arrowRef.current.slickPrev()}
              >
                <img
                  draggable={false}
                  src={arrow1}
                  alt="arrow1"
                />
              </button>
              <button
                className={"BannerSlider-arrowRightMobile wow fadeInRight"}
                onClick={() => arrowRef.current.slickNext()}
              >
                <img
                  draggable={false}
                  src={arrow2}
                  alt="arrow2"
                />
              </button>
            </div>
            : null}
          <div className={isNonMobile || isNonMobile_Small || isTablet ? "ALLGames-BannerSlider-bx wow zoomIn" : "ALLGames-BannerSlider-bxMobile wow zoomIn"}
            onMouseEnter={() => setShowArrows(true)} onMouseLeave={() => setShowArrows(false)} style={{ transition: 'all 0.2s ease-in-out' }}
          >
            {(isNonMobile || isNonMobile_Small ) && ShowArrows ?
              <div style={{ display: "flex", alignItems: "center", position: 'absolute', zIndex: '1', marginBottom: '0px',
                marginTop: '190px', marginLeft: '25px', justifyContent: 'space-between', width: '95%' }}>
                <button
                  className={isNonMobile || isNonMobile_Small ? "BannerSlider-arrowLeft wow fadeInLeft" : "BannerSlider-arrowLeftMobile wow fadeInLeft"}
                  onClick={() => arrowRef.current.slickPrev()}
                >
                  <img
                    draggable={false}
                    src={arrow1}
                    alt="arrow1"
                  />
                </button>
                <button
                  className={isNonMobile || isNonMobile_Small ? "BannerSlider-arrowRight wow fadeInRight" : "BannerSlider-arrowRightMobile wow fadeInRight"}
                  onClick={() => arrowRef.current.slickNext()}
                >
                  <img
                    draggable={false}
                    src={arrow2}
                    alt="arrow2"
                  />
                </button>
              </div>
              : null}
            <Carousel
              dots={false}
              centerMode={true}
              draggable={true}
              swipe={true}
              slide='div'
              arrows={false}
              className="ALLGames-BannerSlider-Carousel"
              ref={arrowRef}
              centerPadding="0px"
              fade={true}
              focusOnSelect={false}
              infinite={true}
              autoplay={true}
              autoplaySpeed={3000}
              slidesToShow={1}
              slidesToScroll={1}
            >
              {FetchSliders.map((item, index) => (
                <div
                  className={isNonMobile || isNonMobile_Small ? "item" : "itemMobile"}
                  style={{ textDecoration: "none" }}
                  key={index}>
                  <div>
                    <div style={{ textDecoration: "none" }}>
                      <img
                        draggable={false}
                        src={currentHref === 'A2G_games' ? item.game_Picture[0] : item.app_Picture[0]}
                        alt={currentHref === 'A2G_games' ? item.game_Number : item.app_Number}
                        key={index}
                        style={{
                          width: isNonMobile ? "800px" : isNonMobile_Small ? '700px' : '100%',
                          height: isNonMobile ? "420px" : isNonMobile_Small ? '360px' : null,
                          maxHeight: isNonMobile || isNonMobile_Small ? null : '324.3px',
                          borderRadius: isNonMobile || isNonMobile_Small ? '45px' : '25px',
                        }}
                        onContextMenu={(e) => e.preventDefault()}
                        onDragStart={(e) => e.preventDefault()}
                      />
                    </div>
                  </div>
                  <span className='RETO_watermark' style={{
                    fontSize: isNonMobile || isNonMobile_Small ? '0.92rem' : '0.75rem',
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.35)',
                    opacity: '0.65',
                    padding: '0.2rem',
                    borderRadius: '0.9rem',
                    position: 'absolute',
                    bottom: isNonMobile || isNonMobile_Small ? '1.2rem' : (isNonMobile || isNonMobile_Small ) === false && 
                    currentHref === 'A2G_apps' ?
                    item.app_Picture[0].height > item.app_Picture[0].width ? '1rem' : '0.5rem' : 
                    item.game_Picture[0].height > item.game_Picture[0].width ? '1rem' : '0.5rem',
                    right: isNonMobile || isNonMobile_Small ? '1.55rem' : '1.3rem',
                  }}
                  >Arab2Games.com</span>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </section>
    </div>
  )
}

export default BannerSlider;
