import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { Row } from "react-bootstrap";
import { RETO_servers_Card } from "../../../../components/HomeMiddle/RETO_servers_Card";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrow1 from "../../../../assets/img/Others/arrow1.svg";
import arrow2 from "../../../../assets/img/Others/arrow2.svg";

const BO2_servers_screen = () => {

  const isNonMobile = useMediaQuery("(min-width:1200px)");
  const isTablet = useMediaQuery("(min-width:522px)");
  const isNon_TooSmall = useMediaQuery("(max-width:521px)");

  const [stop_server_data_fetching, setStop_server_data_fetching] = useState(false);
  const [stop_server_status_fetching, setStop_server_status_fetching] = useState(false);


  const arrowRef_bo2_1 = useRef();
  const arrowRef_bo2_2 = useRef();

  const [Bo2_mp_FFA_sniping, setBo2_mp_FFA_sniping] = useState([]);
  const [Bo2_mp_FFA_sniping_Status, setBo2_mp_FFA_sniping_Status] = useState([]);
  const [Bo2_mp_FFA_sniping_Names, setBo2_mp_FFA_sniping_Names] = useState([]);
  const [Bo2_mp_FFA_sniping_currentMap, setBo2_mp_FFA_sniping_currentMap] = useState([]);

  const [Bo2_mp_TDM_sniping, setBo2_mp_TDM_sniping] = useState([]);
  const [Bo2_mp_TDM_sniping_Status, setBo2_mp_TDM_sniping_Status] = useState([]);
  const [Bo2_mp_TDM_sniping_Names, setBo2_mp_TDM_sniping_Names] = useState([]);
  const [Bo2_mp_TDM_sniping_currentMap, setBo2_mp_TDM_sniping_currentMap] = useState([]);

  const [Bo2_mp_TDM_Everything, setBo2_mp_TDM_Everything] = useState([]);
  const [Bo2_mp_TDM_Everything_Status, setBo2_mp_TDM_Everything_Status] = useState([]);
  const [Bo2_mp_TDM_Everything_Names, setBo2_mp_TDM_Everything_Names] = useState([]);
  const [Bo2_mp_TDM_Everything_currentMap, setBo2_mp_TDM_Everything_currentMap] = useState([]);

  const [Bo2_zm_All_Maps_4_players, setBo2_zm_All_Maps_4_players] = useState([]);
  const [Bo2_zm_AllMaps_4_players_Status, setBo2_zm_all_maps_4players_Status] = useState([]);
  const [Bo2_zm_All_Maps_4_players_Names, setBo2_zm_All_Maps_4_players_Names] = useState([]);
  const [Bo2_zm_All_Maps_4_players_currentMap, setBo2_zm_All_Maps_4_players_currentMap] = useState([]);

  const [Bo2_zm_All_Maps_8_players, setBo2_zm_All_Maps_8_players] = useState([]);
  const [Bo2_zm_AllMaps_8_players_Status, setBo2_zm_all_maps_8players_Status] = useState([]);
  const [Bo2_zm_All_Maps_8_players_Names, setBo2_zm_All_Maps_8_players_Names] = useState([]);
  const [Bo2_zm_All_Maps_8_players_currentMap, setBo2_zm_All_Maps_8_players_currentMap] = useState([]);



  // Get all servers info from the API http://...../api/server
  const get_ALL_servers_Info = useCallback(async () => {
    try {
      if (stop_server_data_fetching === false) {
        const response = await axios.get("http://192.168.1.199:1624/api/server");
        const data = response.json();

        data.then((data) => {
          data?.map((server) => {
            if (server.id === 172310728969) {
              setBo2_mp_FFA_sniping(server ? server : []);
            }
            if (server.id === 172310728970) {
              setBo2_mp_TDM_sniping(server ? server : []);
            }
            if (server.id === 172310728971) {
              setBo2_mp_TDM_Everything(server ? server : []);
            }
            if (server.id === 172310728972) {
              setBo2_zm_All_Maps_4_players(server ? server : []);
            }
            if (server.id === 172310728973) {
              setBo2_zm_All_Maps_8_players(server ? server : []);
            }
          });
        })
          .then(() => {
            setStop_server_data_fetching(true);
          });
      }
    }
    catch (err) {
      console.log('error: ', 'Internal server error.');
    }
  }, []);

  // Get all servers info from the API http://....../api/status
  const get_ALL_servers_status = useCallback(async () => {
    try {
      if (stop_server_status_fetching === false) {
        const response = await axios.get("http://192.168.1.199:1624/api/status");
        const data = response.json();

        data.then((data) => {
          data?.map((server) => {
            if (server.id === 172310728969) {
              setBo2_mp_FFA_sniping_Status(server ? server : []);
              setBo2_mp_FFA_sniping_Names(server.players ? server.players : []);
              setBo2_mp_FFA_sniping_currentMap(server.map ? server.map : []);
            }
            if (server.id === 172310728970) {
              setBo2_mp_TDM_sniping_Status(server ? server : []);
              setBo2_mp_TDM_sniping_Names(server.players ? server.players : []);
              setBo2_mp_TDM_sniping_currentMap(server.map ? server.map : []);
            }
            if (server.id === 172310728971) {
              setBo2_mp_TDM_Everything_Status(server ? server : []);
              setBo2_mp_TDM_Everything_Names(server.players ? server.players : []);
              setBo2_mp_TDM_Everything_currentMap(server.map ? server.map : []);
            }
            if (server.id === 172310728972) {
              setBo2_zm_all_maps_4players_Status(server ? server : []);
              setBo2_zm_All_Maps_4_players_Names(server.players ? server.players : []);
              setBo2_zm_All_Maps_4_players_currentMap(server.map ? server.map : []);
            }
            if (server.id === 172310728973) {
              setBo2_zm_all_maps_8players_Status(server ? server : []);
              setBo2_zm_All_Maps_8_players_Names(server.players ? server.players : []);
              setBo2_zm_All_Maps_8_players_currentMap(server.map ? server.map : []);
            }
          });
        })
          .then(() => {
            setStop_server_status_fetching(true);
          });
      }
    }
    catch (err) {
      console.log('error: ', 'Internal server error.');
    }
  }, []);


  // get all servers info
  useEffect(() => {
    get_ALL_servers_Info()
  }, [get_ALL_servers_Info]);

  // get all servers status
  useEffect(() => {
    get_ALL_servers_status()
  }, [get_ALL_servers_status]);


  let PLAYERSNAMES_Bo2_mp_FFA_sniping = [];
  Bo2_mp_FFA_sniping_Names.map((player) => {
    PLAYERSNAMES_Bo2_mp_FFA_sniping.push({
      name: player.name,
      score: player.score,
      ping: player.ping,
      level: player.level,
    });
  });

  let CURRENTMAP_Bo2_mp_FFA_sniping = [{
    name: Bo2_mp_FFA_sniping_currentMap.name,
    alias: Bo2_mp_FFA_sniping_currentMap.alias
  }];

  let PLAYERSNAMES_Bo2_mp_TDM_sniping = [];
  Bo2_mp_TDM_sniping_Names.map((player) => {
    PLAYERSNAMES_Bo2_mp_TDM_sniping.push({
      name: player.name,
      score: player.score,
      ping: player.ping,
      level: player.level,
    });
  });

  let CURRENTMAP_Bo2_mp_TDM_sniping = [{
    name: Bo2_mp_TDM_sniping_currentMap.name,
    alias: Bo2_mp_TDM_sniping_currentMap.alias
  }];

  let PLAYERSNAMES_Bo2_mp_TDM_Everything = [];
  Bo2_mp_TDM_Everything_Names.map((player) => {
    PLAYERSNAMES_Bo2_mp_TDM_Everything.push({
      name: player.name,
      score: player.score,
      ping: player.ping,
      level: player.level,
    });
  });

  let CURRENTMAP_Bo2_mp_TDM_Everything = [{
    name: Bo2_mp_TDM_Everything_currentMap.name,
    alias: Bo2_mp_TDM_Everything_currentMap.alias
  }];

  let PLAYERSNAMES_Bo2_zm_AllMaps_4_players = [];
  Bo2_zm_All_Maps_4_players_Names.map((player) => {
    PLAYERSNAMES_Bo2_zm_AllMaps_4_players.push({
      name: player.name,
      score: player.score,
      ping: player.ping,
      level: player.level,
    });
  });

  let CURRENTMAP_Bo2_zm_AllMaps_4_players = [{
    name: Bo2_zm_All_Maps_4_players_currentMap.name,
    alias: Bo2_zm_All_Maps_4_players_currentMap.alias
  }];

  let PLAYERSNAMES_Bo2_zm_AllMaps_8_players = [];
  Bo2_zm_All_Maps_8_players_Names.map((player) => {
    PLAYERSNAMES_Bo2_zm_AllMaps_8_players.push({
      name: player.name,
      score: player.score,
      ping: player.ping,
      level: player.level,
    });
  });

  let CURRENTMAP_Bo2_zm_AllMaps_8_players = [{
    name: Bo2_zm_All_Maps_8_players_currentMap.name,
    alias: Bo2_zm_All_Maps_8_players_currentMap.alias
  }];


  const Servers_Info_Bo2 = [
    {
      serverType: 'BO2',
      serverRegion: 'KSA',
      serverDesc: 'mp',
      title: 'RETO | FFA Sniping  |KSA',
      description: "Design & Development",
      image: 'https://images5.alphacoders.com/447/447008.jpg',
      id: 172310728969,
      hostname: Bo2_mp_FFA_sniping.hostname,
      maxPlayers: Bo2_mp_FFA_sniping.maxClients,
      currentPlayers: Bo2_mp_FFA_sniping.clientNum,
      gameType: Bo2_mp_FFA_sniping.currentGameType,
      ip: Bo2_mp_FFA_sniping.listenAddress,
      port: Bo2_mp_FFA_sniping.listenPort,
      serverStatus: Bo2_mp_FFA_sniping_Status.isOnline,
      PlayersNow: PLAYERSNAMES_Bo2_mp_FFA_sniping,

      currentMap: `${CURRENTMAP_Bo2_mp_FFA_sniping.map((map) => {
        return map.alias + ' ';
      })}`,
    },

    {
      serverType: 'BO2',
      serverRegion: 'KSA',
      serverDesc: 'mp',
      title: 'RETO | TDM Sniping  |KSA',
      description: "Design & Development",
      image: 'https://wallpaperaccess.com/full/2204646.jpg',
      id: 172310728970,
      hostname: Bo2_mp_TDM_sniping.hostname,
      maxPlayers: Bo2_mp_TDM_sniping.maxClients,
      currentPlayers: Bo2_mp_TDM_sniping.clientNum,
      gameType: Bo2_mp_TDM_sniping.currentGameType,
      ip: Bo2_mp_TDM_sniping.listenAddress,
      port: Bo2_mp_TDM_sniping.listenPort,
      serverStatus: Bo2_mp_TDM_sniping_Status.isOnline,
      PlayersNow: PLAYERSNAMES_Bo2_mp_TDM_sniping,

      currentMap: `${CURRENTMAP_Bo2_mp_TDM_sniping.map((map) => {
        return map.alias + ' ';
      })}`,
    },

    {
      serverType: 'BO2',
      serverRegion: 'KSA',
      serverDesc: 'mp',
      title: 'RETO | Team Deathmatch |KSA',
      description: "Design & Development",
      image: 'https://getwallpapers.com/wallpaper/full/7/7/2/1105536-most-popular-cod-black-ops-2-wallpapers-1920x1080-windows-10.jpg',
      id: 172310728971,
      hostname: Bo2_mp_TDM_Everything.hostname,
      maxPlayers: Bo2_mp_TDM_Everything.maxClients,
      currentPlayers: Bo2_mp_TDM_Everything.clientNum,
      gameType: Bo2_mp_TDM_Everything.currentGameType,
      ip: Bo2_mp_TDM_Everything.listenAddress,
      port: Bo2_mp_TDM_Everything.listenPort,
      serverStatus: Bo2_mp_TDM_Everything_Status.isOnline,
      PlayersNow: PLAYERSNAMES_Bo2_mp_TDM_Everything,

      currentMap: `${CURRENTMAP_Bo2_mp_TDM_Everything.map((map) => {
        return map.alias + ' ';
      })}`,
    },

    {
      serverType: 'BO2',
      serverRegion: 'KSA',
      serverDesc: 'zm',
      title: 'RETO | All maps +MapVOTE | KSA',
      description: "Design & Development",
      image: 'https://cdn.discordapp.com/attachments/847105106298011668/1092130818702454925/ezgif.com-webp-to-jpg_1.jpg',
      id: 172310728972,
      hostname: Bo2_zm_All_Maps_4_players.hostname,

      maxPlayers: Bo2_zm_All_Maps_4_players.maxClients,
      currentPlayers: Bo2_zm_All_Maps_4_players.clientNum,
      gameType: Bo2_zm_AllMaps_4_players_Status.gameMode,
      ip: Bo2_zm_All_Maps_4_players.listenAddress,
      port: Bo2_zm_All_Maps_4_players.listenPort,
      serverStatus: Bo2_zm_AllMaps_4_players_Status.isOnline,
      PlayersNow: PLAYERSNAMES_Bo2_zm_AllMaps_4_players,

      currentMap: `${CURRENTMAP_Bo2_zm_AllMaps_4_players.map((map) => {
        return (
          // black ops 2 maps 
          map.name === 'zm_buried' ? 'Buried' :
            map.name === 'zm_tomb' ? 'Origins' :
              map.name === 'zm_nuked' ? 'Nuketown' :
                map.name === 'zm_prison' ? 'Mob of the dead' :
                  map.name === 'zm_transit' ? 'Tranzit' :
                    map.name === 'zm_highrise' ? 'Die Rise'
                      :
                      map.name + ' ' + map.alias
        );
      })}`,
    },

    {
      serverType: 'BO2',
      serverRegion: 'KSA',
      serverDesc: 'zm',
      title: 'RETO | All maps +Map VOTE | KSA. (8 Players)',
      image: 'https://cdn.discordapp.com/attachments/1120600422369988649/1134377276297977886/desktop-wallpaper-jeleena-on-cod-just-like-old-times-call-of-duty-zombies-black-ops-zombies-call-of-duty-black-zombies-chronicles-thumbnail.jpg',
      id: 172310728973,
      hostname: Bo2_zm_All_Maps_8_players.hostname,
      maxPlayers: Bo2_zm_All_Maps_8_players.maxClients,
      currentPlayers: Bo2_zm_All_Maps_8_players.clientNum,
      gameType: Bo2_zm_AllMaps_8_players_Status.gameMode,
      ip: Bo2_zm_All_Maps_8_players.listenAddress,
      port: Bo2_zm_All_Maps_8_players.listenPort,
      serverStatus: Bo2_zm_AllMaps_8_players_Status.isOnline,
      PlayersNow: PLAYERSNAMES_Bo2_zm_AllMaps_8_players,

      currentMap: `${CURRENTMAP_Bo2_zm_AllMaps_8_players.map((map) => {
        return (
          // black ops 2 maps 
          map.name === 'zm_buried' ? 'Buried' :
            map.name === 'zm_tomb' ? 'Origins' :
              map.name === 'zm_nuked' ? 'Nuketown' :
                map.name === 'zm_prison' ? 'Mob of the dead' :
                  map.name === 'zm_transit' ? 'Tranzit' :
                    map.name === 'zm_highrise' ? 'Die Rise'
                      :
                      map.name + ' ' + map.alias
        );
      })}`,
    },
  ];


  return (
    <section>

      {isNonMobile ? (
        <div>
          <div style={{ marginBottom: '50px' }}>
            <Slider
              ref={arrowRef_bo2_1}
              dots={false}
              infinite={false}
              draggable={true}
              swipe={true}
              arrows={false}
              className='Search-Result-Container-Slider'
              focusOnSelect={true}
              autoplay={true}
              autoplaySpeed={3000}
              slidesToShow={
                isNonMobile ? Servers_Info_Bo2?.length > 4 ? 4 : Servers_Info_Bo2?.length === 4 || Servers_Info_Bo2?.length >= 3 ? 3
                  : Servers_Info_Bo2?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
              slidesToScroll={
                isNonMobile ? Servers_Info_Bo2?.length > 4 ? 4 : Servers_Info_Bo2?.length === 4 || Servers_Info_Bo2?.length >= 3 ? 3
                  : Servers_Info_Bo2?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
            >
              {
                Servers_Info_Bo2.map((server, index) => {
                  return (
                    server.serverType === 'BO2' && server.serverDesc === 'mp' && server.currentPlayers >= 1 ?
                      <RETO_servers_Card
                        key={index}
                        {...server}
                      />
                      :
                      server.serverType === 'BO2' && server.serverDesc === 'mp' ?
                        <RETO_servers_Card
                          key={index}
                          {...server}
                        />
                        : null
                  )
                })
              }
            </Slider>
          </div>

          <div>
            <Slider
              ref={arrowRef_bo2_2}
              dots={false}
              infinite={false}
              draggable={true}
              swipe={true}
              arrows={false}
              className='Search-Result-Container-Slider'
              focusOnSelect={true}
              autoplay={true}
              autoplaySpeed={3000}
              slidesToShow={
                isNonMobile ? Servers_Info_Bo2?.length > 4 ? 4 : Servers_Info_Bo2?.length === 4 || Servers_Info_Bo2?.length >= 3 ? 3
                  : Servers_Info_Bo2?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
              slidesToScroll={
                isNonMobile ? Servers_Info_Bo2?.length > 4 ? 4 : Servers_Info_Bo2?.length === 4 || Servers_Info_Bo2?.length >= 3 ? 3
                  : Servers_Info_Bo2?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
            >
              {
                Servers_Info_Bo2.map((server, index) => {
                  return (
                    server.serverType === 'BO2' && server.serverDesc === 'zm' && server.currentPlayers >= 1 ?
                      <RETO_servers_Card
                        key={index}
                        {...server}
                      />
                      :
                      server.serverType === 'BO2' && server.serverDesc === 'zm' ?
                        <RETO_servers_Card
                          key={index}
                          {...server}
                        />
                        : null
                  )
                })
              }
            </Slider>
          </div>
        </div>
      ) : (
        <Row>
          <div style={{
            flexWrap: isNonMobile ? 'wrap' : isNon_TooSmall ? '' : 'wrap',
            flexDirection: isNonMobile ? '' : isNon_TooSmall ? 'column' : 'column', height: isNonMobile ? '100%' : '100%',
            padding: isNonMobile ? '' : isNon_TooSmall ? '' : '0 10px', overflow: 'hidden'

          }}>
            <div style={{ marginBottom: '50px', marginTop: '50px' }}>
              {!isNonMobile ?
                <div style={{
                  alignItems: "center", position: 'relative', zIndex: '1', marginBottom: '20px', flexDirection: 'row', display: 'flex',
                  marginLeft: '25px', justifyContent: '', width: '100%'
                }}>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowLeft wow fadeInLeft" : "BannerSlider-arrowLeftMobile wow fadeInLeft"}
                    onClick={() => arrowRef_bo2_1.current.slickPrev()}
                  >
                    <img src={arrow1} alt="arrow1" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowRight wow fadeInRight" : "BannerSlider-arrowRightMobile wow fadeInRight"}
                    onClick={() => arrowRef_bo2_1.current.slickNext()()}
                  >
                    <img src={arrow2} alt="arrow2" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                </div>
                : null}
              <Slider
                ref={arrowRef_bo2_1}
                dots={false}
                infinite={false}
                draggable={true}
                swipe={true}
                arrows={false}
                className='Search-Result-Container-Slider'
                focusOnSelect={true}
                autoplay={true}
                autoplaySpeed={3000}
                slidesToShow={
                  isNonMobile ? Servers_Info_Bo2?.length > 4 ? 4 : Servers_Info_Bo2?.length === 4 || Servers_Info_Bo2?.length >= 3 ? 3
                    : Servers_Info_Bo2?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
                slidesToScroll={
                  isNonMobile ? Servers_Info_Bo2?.length > 4 ? 4 : Servers_Info_Bo2?.length === 4 || Servers_Info_Bo2?.length >= 3 ? 3
                    : Servers_Info_Bo2?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
              >
                {
                  Servers_Info_Bo2.map((server, index) => {
                    return (
                      server.serverType === 'BO2' && server.serverDesc === 'mp' && server.currentPlayers >= 1 ?
                        <RETO_servers_Card
                          key={index}
                          {...server}
                        />
                        :
                        server.serverType === 'BO2' && server.serverDesc === 'mp' ?
                          <RETO_servers_Card
                            key={index}
                            {...server}
                          />
                          : null
                    )
                  })
                }
              </Slider>
            </div>

            <div style={{ marginBottom: '50px', marginTop: '50px' }}>
              {!isNonMobile ?
                <div style={{
                  alignItems: "center", position: 'relative', zIndex: '1', marginBottom: '20px', flexDirection: 'row', display: 'flex',
                  marginLeft: '25px', justifyContent: '', width: '100%'
                }}>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowLeft wow fadeInLeft" : "BannerSlider-arrowLeftMobile wow fadeInLeft"}
                    onClick={() => arrowRef_bo2_2.current.slickPrev()}
                  >
                    <img src={arrow1} alt="arrow1" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowRight wow fadeInRight" : "BannerSlider-arrowRightMobile wow fadeInRight"}
                    onClick={() => arrowRef_bo2_2.current.slickNext()()}
                  >
                    <img src={arrow2} alt="arrow2" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                </div>
                : null}
              <Slider
                ref={arrowRef_bo2_2}
                dots={false}
                infinite={false}
                draggable={true}
                swipe={true}
                arrows={false}
                className='Search-Result-Container-Slider'
                focusOnSelect={true}
                autoplay={true}
                autoplaySpeed={3000}
                slidesToShow={
                  isNonMobile ? Servers_Info_Bo2?.length > 4 ? 4 : Servers_Info_Bo2?.length === 4 || Servers_Info_Bo2?.length >= 3 ? 3
                    : Servers_Info_Bo2?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
                slidesToScroll={
                  isNonMobile ? Servers_Info_Bo2?.length > 4 ? 4 : Servers_Info_Bo2?.length === 4 || Servers_Info_Bo2?.length >= 3 ? 3
                    : Servers_Info_Bo2?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
              >
                {
                  Servers_Info_Bo2.map((server, index) => {
                    return (
                      server.serverType === 'BO2' && server.serverDesc === 'zm' && server.currentPlayers >= 1 ?
                        <RETO_servers_Card
                          key={index}
                          {...server}
                        />
                        :
                        server.serverType === 'BO2' && server.serverDesc === 'zm' ?
                          <RETO_servers_Card
                            key={index}
                            {...server}
                          />
                          : null
                    )
                  })
                }
              </Slider>
            </div>

          </div>
        </Row>
      )}
    </section>
  )
}


export default BO2_servers_screen;