import axios from "axios";
import { firebase, database } from '../../xFireBase/Storage';

// app_Downloaded_Times_updater method
const app_Downloaded_Times_updater = async (appData) => {
    try {
        const updateData = {
            app_Name: appData.app_Name,
            app_Number: appData.app_Number,
            app_Page: appData.app_Page,
        }

        const response = await axios.post("https://arab2games-games-backend.vercel.app/api/arab2games/app_Downloaded_Times_updater", updateData)
            .then(async () => {
                if (firebase.auth().currentUser) {
                    await database.collection('users').doc(firebase.auth().currentUser.email).collection('more-details').doc('Profile_Management').get().then(async (doc) => {
                        if (doc.exists && doc.data().Turn_On_Off_DownloadHistory === true) {
                            await database.collection('users').doc(firebase.auth().currentUser.email).collection('more-details').doc('User_History').get().then(async (doc) => {
                                if (doc.exists) {
                                    const randomID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                                    await database
                                        .collection('users')
                                        .doc(firebase.auth().currentUser.email)
                                        .collection('more-details')
                                        .doc('User_History')
                                        .collection('User_Downloads')
                                        .doc(randomID)
                                        .set({
                                            Download_ID: randomID,
                                            Download_aType: 'app',
                                            Download_CreatedAt: new Date(),
                                            Download_ReleaseDate: appData.app_ReleaseDate,
                                            Download_Name: appData.app_Name,
                                            Download_Name_alias: appData.app_Name_alias ? appData.app_Name_alias : null,
                                            Download_Picture: appData.app_Picture[0],
                                            Download_Genres: appData.app_Genres,
                                            Download_Size: appData.app_Size,
                                            Download_Description: appData.app_Description,
                                            Download_Page: appData.app_Page,
                                            Download_Number: appData.app_Number,
                                            Download_Downloaded_At_Date: new Date().getDate() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getFullYear(),
                                            Download_Downloaded_At_Time: new Date().toLocaleTimeString('en-US', { timeZone: 'Asia/Riyadh' }),
                                        })
                                }
                            })
                        }
                    })
                }
            })
            .then(async () => {
                if (firebase.auth().currentUser) {
                    await database.collection('users').doc(firebase.auth().currentUser.email).collection('more-details').doc('Profile_Management').get().then(async (doc) => {
                        if (doc.exists && doc.data().Turn_On_Off_NotificationsHistory === true) {
                            await database.collection('users').doc(firebase.auth().currentUser.email).collection('more-details').doc('User_History').get().then(async (doc) => {
                                if (doc.exists) {
                                    await database
                                        .collection('users')
                                        .doc(firebase.auth().currentUser.email)
                                        .collection('more-details')
                                        .doc('User_History')
                                        .collection('User_Notification')
                                        .add({
                                            Notify_Message: `تم تحميل لعبة ${appData.app_Name}`,
                                            Notify_Image: appData.app_Picture[0],
                                            Notify_Status: 'new',
                                            Notify_aType: 'app',
                                            Notify_CreatedAt: new Date(),
                                            Notify_Size: appData.app_Size,
                                            Notify_Name: appData.app_Name,
                                            Notify_Genres: appData.app_Genres,
                                            Notify_Name_alias: appData.app_Name_alias ? appData.app_Name_alias : null,
                                            Notify_Page: appData.app_Page,
                                            Notify_Number: appData.app_Number,
                                            Notify_At_Date: new Date().getDate() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getFullYear(),
                                            Notify_At_Time: new Date().toLocaleTimeString('en-US', { timeZone: 'Asia/Riyadh' }),
                                            Notify_Description: `تم تحميل لعبة ${appData.app_Name}
                     في تاريخ ${new Date().getDate() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getFullYear()}
                     في الساعة ${new Date().toLocaleTimeString('en-US', { timeZone: 'Asia/Riyadh' })}
                     و حجمها ${appData.app_Size}`,
                                        })
                                }
                            })
                        }
                    })
                }
            })
            .then(() => {
                return 'done';
            })

        return response;
    }
    catch (error) {
        console.log("❌ app_DownloadedTimesUpdater ~ couldn't update the download times ~ error :   ", error)
        return false;
    }
};

export default app_Downloaded_Times_updater;