import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { Row } from "react-bootstrap";
import { RETO_servers_Card } from "../../../../components/HomeMiddle/RETO_servers_Card";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrow1 from "../../../../assets/img/Others/arrow1.svg";
import arrow2 from "../../../../assets/img/Others/arrow2.svg";

const BO3_servers_screen = () => {

  const isNonMobile = useMediaQuery("(min-width:1200px)");
  const isTablet = useMediaQuery("(min-width:522px)");
  const isNon_TooSmall = useMediaQuery("(max-width:521px)");

  const [stop_server_data_fetching, setStop_server_data_fetching] = useState(false);
  const [stop_server_status_fetching, setStop_server_status_fetching] = useState(false);


  const arrowRef_bo3_1 = useRef();
  const arrowRef_bo3_2 = useRef();

  const [Bo3_mp_FFA_sniping, setBo3_mp_FFA_sniping] = useState([]);
  const [Bo3_mp_FFA_sniping_Status, setBo3_mp_FFA_sniping_Status] = useState([]);
  const [Bo3_mp_FFA_sniping_Names, setBo3_mp_FFA_sniping_Names] = useState([]);
  const [Bo3_mp_FFA_sniping_currentMap, setBo3_mp_FFA_sniping_currentMap] = useState([]);

  const [Bo3_mp_TDM_sniping, setBo3_mp_TDM_sniping] = useState([]);
  const [Bo3_mp_TDM_sniping_Status, setBo3_mp_TDM_sniping_Status] = useState([]);
  const [Bo3_mp_TDM_sniping_Names, setBo3_mp_TDM_sniping_Names] = useState([]);
  const [Bo3_mp_TDM_sniping_currentMap, setBo3_mp_TDM_sniping_currentMap] = useState([]);

  const [Bo3_mp_DOM, setBo3_mp_DOM] = useState([]);
  const [Bo3_mp_DOM_Status, setBo3_mp_DOM_Status] = useState([]);
  const [Bo3_mp_DOM_Names, setBo3_mp_DOM_Names] = useState([]);
  const [Bo3_mp_DOM_currentMap, setBo3_mp_DOM_currentMap] = useState([]);

  const [Bo3_zm_All_Maps_4_players, setBo3_zm_All_Maps_4_players] = useState([]);
  const [Bo3_zm_AllMaps_4_players_Status, setBo3_zm_all_maps_4players_Status] = useState([]);
  const [Bo3_zm_All_Maps_4_players_Names, setBo3_zm_All_Maps_4_players_Names] = useState([]);
  const [Bo3_zm_All_Maps_4_players_currentMap, setBo3_zm_All_Maps_4_players_currentMap] = useState([]);

  const [Bo3_zm_All_Maps_8_players, setBo3_zm_All_Maps_8_players] = useState([]);
  const [Bo3_zm_AllMaps_8_players_Status, setBo3_zm_all_maps_8players_Status] = useState([]);
  const [Bo3_zm_All_Maps_8_players_Names, setBo3_zm_All_Maps_8_players_Names] = useState([]);
  const [Bo3_zm_All_Maps_8_players_currentMap, setBo3_zm_All_Maps_8_players_currentMap] = useState([]);



  // Get all servers info from the API http://...../api/server
  const get_ALL_servers_Info = useCallback(async () => {
    try {
      if (stop_server_data_fetching === false) {
        const response = await axios.get("http://192.168.1.199:1624/api/server");
        const data = response.json();

        data.then((data) => {
          data?.map((server) => {
            if (server.id === 172310728974) {
              setBo3_mp_FFA_sniping(server ? server : []);
            }
            if (server.id === 172310728975) {
              setBo3_mp_TDM_sniping(server ? server : []);
            }
            if (server.id === 172310728976) {
              setBo3_zm_All_Maps_4_players(server ? server : []);
            }
            if (server.id === 172310728977) {
              setBo3_zm_All_Maps_8_players(server ? server : []);
            }
            if (server.id === 172310728978) {
              setBo3_mp_DOM(server ? server : []);
            }
          });
        })
          .then(() => {
            setStop_server_data_fetching(true);
          });
      }
    }
    catch (err) {
      console.log('error: ', 'Internal server error.');
    }
  }, []);

  // Get all servers info from the API http://....../api/status
  const get_ALL_servers_status = useCallback(async () => {
    try {
      if (stop_server_status_fetching === false) {
        const response = await axios.get("http://192.168.1.199:1624/api/status");
        const data = response.json();

        data.then((data) => {
          data?.map((server) => {
            if (server.id === 172310728974) {
              setBo3_mp_FFA_sniping_Status(server ? server : []);
              setBo3_mp_FFA_sniping_Names(server.players ? server.players : []);
              setBo3_mp_FFA_sniping_currentMap(server.map ? server.map : []);
            }
            if (server.id === 172310728975) {
              setBo3_mp_TDM_sniping_Status(server ? server : []);
              setBo3_mp_TDM_sniping_Names(server.players ? server.players : []);
              setBo3_mp_TDM_sniping_currentMap(server.map ? server.map : []);
            }
            if (server.id === 172310728976) {
              setBo3_zm_all_maps_4players_Status(server ? server : []);
              setBo3_zm_All_Maps_4_players_Names(server.players ? server.players : []);
              setBo3_zm_All_Maps_4_players_currentMap(server.map ? server.map : []);
            }
            if (server.id === 172310728977) {
              setBo3_zm_all_maps_8players_Status(server ? server : []);
              setBo3_zm_All_Maps_8_players_Names(server.players ? server.players : []);
              setBo3_zm_All_Maps_8_players_currentMap(server.map ? server.map : []);
            }
            if (server.id === 172310728978) {
              setBo3_mp_DOM_Status(server ? server : []);
              setBo3_mp_DOM_Names(server.players ? server.players : []);
              setBo3_mp_DOM_currentMap(server.map ? server.map : []);
            }
          });
        })
          .then(() => {
            setStop_server_status_fetching(true);
          });
      }
    }
    catch (err) {
      console.log('error: ', 'Internal server error.');
    }
  }, []);


  // get all servers info
  useEffect(() => {
    get_ALL_servers_Info()
  }, [get_ALL_servers_Info]);

  // get all servers status
  useEffect(() => {
    get_ALL_servers_status()
  }, [get_ALL_servers_status]);


  let PLAYERSNAMES_Bo3_mp_FFA_sniping = [];
  Bo3_mp_FFA_sniping_Names.map((player) => {
    PLAYERSNAMES_Bo3_mp_FFA_sniping.push({
      name: player.name,
      score: player.score,
      ping: player.ping,
      level: player.level,

    });
  });

  let CURRENTMAP_Bo3_mp_FFA_sniping = [{
    name: Bo3_mp_FFA_sniping_currentMap.name,
    alias: Bo3_mp_FFA_sniping_currentMap.alias
  }];


  let PLAYERSNAMES_Bo3_mp_TDM_sniping = [];
  Bo3_mp_TDM_sniping_Names.map((player) => {
    PLAYERSNAMES_Bo3_mp_TDM_sniping.push({
      name: player.name,
      score: player.score,
      ping: player.ping,
      level: player.level,

    });
  });

  let CURRENTMAP_Bo3_mp_TDM_sniping = [{
    name: Bo3_mp_TDM_sniping_currentMap.name,
    alias: Bo3_mp_TDM_sniping_currentMap.alias
  }];

  let PLAYERSNAMES_Bo3_mp_DOM = [];
  Bo3_mp_DOM_Names.map((player) => {
    PLAYERSNAMES_Bo3_mp_DOM.push({
      name: player.name,
      score: player.score,
      ping: player.ping,
      level: player.level,

    });
  });

  let CURRENTMAP_Bo3_mp_DOM = [{
    name: Bo3_mp_DOM_currentMap.name,
    alias: Bo3_mp_DOM_currentMap.alias
  }];

  let PLAYERSNAMES_Bo3_zm_AllMaps_4_players = [];
  Bo3_zm_All_Maps_4_players_Names.map((player) => {
    PLAYERSNAMES_Bo3_zm_AllMaps_4_players.push({
      name: player.name,
      score: player.score,
      ping: player.ping,
      level: player.level,
    });
  });

  let CURRENTMAP_Bo3_zm_AllMaps_4_players = [{
    name: Bo3_zm_All_Maps_4_players_currentMap.name,
    alias: Bo3_zm_All_Maps_4_players_currentMap.alias
  }];

  let PLAYERSNAMES_Bo3_zm_AllMaps_8_players = [];
  Bo3_zm_All_Maps_8_players_Names.map((player) => {
    PLAYERSNAMES_Bo3_zm_AllMaps_8_players.push({
      name: player.name,
      score: player.score,
      ping: player.ping,
      level: player.level,
    });
  });

  let CURRENTMAP_Bo3_zm_AllMaps_8_players = [{
    name: Bo3_zm_All_Maps_8_players_currentMap.name,
    alias: Bo3_zm_All_Maps_8_players_currentMap.alias
  }];


  const Servers_Info_Bo3 = [
    {
      serverType: 'BO3',
      serverRegion: 'KSA',
      serverDesc: 'mp',
      title: 'RETO | FFA Sniping | KSA',
      image: 'https://cdn.discordapp.com/attachments/847105106298011668/1104339785872592896/mqdefault.jpg',
      id: 172310728974,
      hostname: Bo3_mp_FFA_sniping.hostname,
      maxPlayers: Bo3_mp_FFA_sniping.maxClients,
      currentPlayers: Bo3_mp_FFA_sniping.clientNum,
      gameType: Bo3_mp_FFA_sniping_Status.gameMode,
      ip: Bo3_mp_FFA_sniping.listenAddress,
      port: Bo3_mp_FFA_sniping.listenPort,
      serverStatus: Bo3_mp_FFA_sniping_Status.isOnline,
      PlayersNow: PLAYERSNAMES_Bo3_mp_FFA_sniping,

      currentMap: `${CURRENTMAP_Bo3_mp_FFA_sniping.map((map) => {
        return map.alias + ' ';
      })}`,
    },

    {
      serverType: 'BO3',
      serverRegion: 'KSA',
      serverDesc: 'mp',
      title: 'RETO | Team Deathmatch | KSA',
      image: 'https://cdn.wallpapersafari.com/17/16/5lQ1XM.png',
      id: 172310728975,
      hostname: Bo3_mp_TDM_sniping.hostname,
      maxPlayers: Bo3_mp_TDM_sniping.maxClients,
      currentPlayers: Bo3_mp_TDM_sniping.clientNum,
      gameType: Bo3_mp_TDM_sniping_Status.gameMode,
      ip: Bo3_mp_TDM_sniping.listenAddress,
      port: Bo3_mp_TDM_sniping.listenPort,
      serverStatus: Bo3_mp_TDM_sniping_Status.isOnline,
      PlayersNow: PLAYERSNAMES_Bo3_mp_TDM_sniping,

      currentMap: `${CURRENTMAP_Bo3_mp_TDM_sniping.map((map) => {
        return map.alias + ' ';
      })}`,
    },

    {
      serverType: 'BO3',
      serverRegion: 'KSA',
      serverDesc: 'mp',
      title: 'RETO | Domination | KSA',
      image: 'https://cdn.wallpapersafari.com/53/54/Bpz7AW.jpg',
      id: 172310728978,
      hostname: Bo3_mp_DOM.hostname,
      maxPlayers: Bo3_mp_DOM.maxClients,
      currentPlayers: Bo3_mp_DOM.clientNum,
      gameType: Bo3_mp_DOM_Status.gameMode,
      ip: Bo3_mp_DOM.listenAddress,
      port: Bo3_mp_DOM.listenPort,
      serverStatus: Bo3_mp_DOM_Status.isOnline,
      PlayersNow: PLAYERSNAMES_Bo3_mp_DOM,

      currentMap: `${CURRENTMAP_Bo3_mp_DOM.map((map) => {
        return map.alias + ' ';
      })}`,
    },

    {
      serverType: 'BO3',
      serverRegion: 'KSA',
      serverDesc: 'zm',
      title: 'RETO | All maps +MapVOTE | KSA',
      description: "Design & Development",
      image: 'https://i.pinimg.com/originals/e1/68/f7/e168f7a6973b2cac158b3de904f1c3a7.jpg',
      id: 172310728976,
      hostname: Bo3_zm_All_Maps_4_players.hostname,

      maxPlayers: Bo3_zm_All_Maps_4_players.maxClients,
      currentPlayers: Bo3_zm_All_Maps_4_players.clientNum,
      gameType: Bo3_zm_AllMaps_4_players_Status.gameMode,
      ip: Bo3_zm_All_Maps_4_players.listenAddress,
      port: Bo3_zm_All_Maps_4_players.listenPort,
      serverStatus: Bo3_zm_AllMaps_4_players_Status.isOnline,
      PlayersNow: PLAYERSNAMES_Bo3_zm_AllMaps_4_players,

      currentMap: `${CURRENTMAP_Bo3_zm_AllMaps_4_players.map((map) => {
        return (
          // black ops 3 maps 
          map.name === 'zm_zod' ? 'Shadows of Evil' :
            map.name === 'zm_castle' ? 'Der Eisendrache' :
              map.name === 'zm_island' ? 'Zetsubou No Shima' :
                map.name === 'zm_stalingrad' ? 'Gorod Krovi' :
                  map.name === 'zm_genesis' ? 'Revelations' :
                    map.name === 'zm_cosmodrome' ? 'Ascension' :
                      map.name === 'zm_theater' ? 'Kino Der Toten' :
                        map.name === 'zm_moon' ? 'Moon' :
                          map.name === 'zm_prototype' ? 'Nacht der Untoten' :
                            map.name === 'zm_tomb' ? 'Origins' :
                              map.name === 'zm_temple' ? 'Shangri-La' :
                                map.name === 'zm_sumpf' ? 'Shi No Numa' :
                                  map.name === 'zm_factory' ? 'The Giant' :
                                    map.name === 'zm_asylum' ? 'Verrückt'
                                      :
                                      map.name + ' ' + map.alias
        );
      })}`,
    },

    {
      serverType: 'BO3',
      serverRegion: 'KSA',
      serverDesc: 'zm',
      title: 'RETO | All maps +Map VOTE | KSA. (8 Players)',
      image: 'https://www.teahub.io/photos/full/56-560485_black-ops-3-zombies-chronicles.jpg',
      id: 172310728977,
      hostname: Bo3_zm_All_Maps_8_players.hostname,
      maxPlayers: Bo3_zm_All_Maps_8_players.maxClients,
      currentPlayers: Bo3_zm_All_Maps_8_players.clientNum,
      gameType: Bo3_zm_AllMaps_8_players_Status.gameMode,
      ip: Bo3_zm_All_Maps_8_players.listenAddress,
      port: Bo3_zm_All_Maps_8_players.listenPort,
      serverStatus: Bo3_zm_AllMaps_8_players_Status.isOnline,
      PlayersNow: PLAYERSNAMES_Bo3_zm_AllMaps_8_players,

      currentMap: `${CURRENTMAP_Bo3_zm_AllMaps_8_players.map((map) => {
        return (
          // black ops 3 maps 
          map.name === 'zm_zod' ? 'Shadows of Evil' :
            map.name === 'zm_castle' ? 'Der Eisendrache' :
              map.name === 'zm_island' ? 'Zetsubou No Shima' :
                map.name === 'zm_stalingrad' ? 'Gorod Krovi' :
                  map.name === 'zm_genesis' ? 'Revelations' :
                    map.name === 'zm_cosmodrome' ? 'Ascension' :
                      map.name === 'zm_theater' ? 'Kino Der Toten' :
                        map.name === 'zm_moon' ? 'Moon' :
                          map.name === 'zm_prototype' ? 'Nacht der Untoten' :
                            map.name === 'zm_tomb' ? 'Origins' :
                              map.name === 'zm_temple' ? 'Shangri-La' :
                                map.name === 'zm_sumpf' ? 'Shi No Numa' :
                                  map.name === 'zm_factory' ? 'The Giant' :
                                    map.name === 'zm_asylum' ? 'Verrückt'
                                      :
                                      map.name + ' ' + map.alias
        );
      })}`,
    },
  ];


  return (
    <section>

      {isNonMobile ? (
        <div>
          <div style={{ marginBottom: '50px' }}>
            <Slider
              ref={arrowRef_bo3_1}
              dots={false}
              infinite={false}
              draggable={true}
              swipe={true}
              arrows={false}
              className='Search-Result-Container-Slider'
              focusOnSelect={true}
              autoplay={true}
              autoplaySpeed={3000}
              slidesToShow={
                isNonMobile ? Servers_Info_Bo3?.length > 4 ? 4 : Servers_Info_Bo3?.length === 4 || Servers_Info_Bo3?.length >= 3 ? 3
                  : Servers_Info_Bo3?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
              slidesToScroll={
                isNonMobile ? Servers_Info_Bo3?.length > 4 ? 4 : Servers_Info_Bo3?.length === 4 || Servers_Info_Bo3?.length >= 3 ? 3
                  : Servers_Info_Bo3?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
            >
              {
                Servers_Info_Bo3.map((server, index) => {
                  return (
                    server.serverType === 'BO3' && server.serverDesc === 'mp' && server.currentPlayers >= 1 ?
                      <RETO_servers_Card
                        key={index}
                        {...server}
                      />
                      :
                      server.serverType === 'BO3' && server.serverDesc === 'mp' ?
                        <RETO_servers_Card
                          key={index}
                          {...server}
                        />
                        : null
                  )
                })
              }
            </Slider>
          </div>

          <div>
            <Slider
              ref={arrowRef_bo3_2}
              dots={false}
              infinite={false}
              draggable={true}
              swipe={true}
              arrows={false}
              className='Search-Result-Container-Slider'
              focusOnSelect={true}
              autoplay={true}
              autoplaySpeed={3000}
              slidesToShow={
                isNonMobile ? Servers_Info_Bo3?.length > 4 ? 4 : Servers_Info_Bo3?.length === 4 || Servers_Info_Bo3?.length >= 3 ? 3
                  : Servers_Info_Bo3?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
              slidesToScroll={
                isNonMobile ? Servers_Info_Bo3?.length > 4 ? 4 : Servers_Info_Bo3?.length === 4 || Servers_Info_Bo3?.length >= 3 ? 3
                  : Servers_Info_Bo3?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
            >
              {
                Servers_Info_Bo3.map((server, index) => {
                  return (
                    server.serverType === 'BO3' && server.serverDesc === 'zm' && server.currentPlayers >= 1 ?
                      <RETO_servers_Card
                        key={index}
                        {...server}
                      />
                      :
                      server.serverType === 'BO3' && server.serverDesc === 'zm' ?
                        <RETO_servers_Card
                          key={index}
                          {...server}
                        />
                        : null
                  )
                })
              }
            </Slider>
          </div>
        </div>
      ) : selectedServer === 'BO3' && (
        <Row>
          <div style={{
            flexWrap: isNonMobile ? 'wrap' : isNon_TooSmall ? '' : 'wrap',
            flexDirection: isNonMobile ? '' : isNon_TooSmall ? 'column' : 'column', height: isNonMobile ? '100%' : '100%',
            padding: isNonMobile ? '' : isNon_TooSmall ? '' : '0 10px', overflow: 'hidden'

          }}>
            <div style={{ marginBottom: '50px', marginTop: '50px' }}>
              {!isNonMobile ?
                <div style={{
                  alignItems: "center", position: 'relative', zIndex: '1', marginBottom: '20px', flexDirection: 'row', display: 'flex',
                  marginLeft: '25px', justifyContent: '', width: '100%'
                }}>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowLeft wow fadeInLeft" : "BannerSlider-arrowLeftMobile wow fadeInLeft"}
                    onClick={() => arrowRef_bo3_2.current.slickPrev()}
                  >
                    <img src={arrow1} alt="arrow1" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowRight wow fadeInRight" : "BannerSlider-arrowRightMobile wow fadeInRight"}
                    onClick={() => arrowRef_bo3_2.current.slickNext()()}
                  >
                    <img src={arrow2} alt="arrow2" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                </div>
                : null}
              <Slider
                ref={arrowRef_bo3_1}
                dots={false}
                infinite={false}
                draggable={true}
                swipe={true}
                arrows={false}
                className='Search-Result-Container-Slider'
                focusOnSelect={true}
                autoplay={true}
                autoplaySpeed={3000}
                slidesToShow={
                  isNonMobile ? Servers_Info_Bo3?.length > 4 ? 4 : Servers_Info_Bo3?.length === 4 || Servers_Info_Bo3?.length >= 3 ? 3
                    : Servers_Info_Bo3?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
                slidesToScroll={
                  isNonMobile ? Servers_Info_Bo3?.length > 4 ? 4 : Servers_Info_Bo3?.length === 4 || Servers_Info_Bo3?.length >= 3 ? 3
                    : Servers_Info_Bo3?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
              >
                {
                  Servers_Info_Bo3.map((server, index) => {
                    return (
                      server.serverType === 'BO3' && server.serverDesc === 'mp' && server.currentPlayers >= 1 ?
                        <RETO_servers_Card
                          key={index}
                          {...server}
                        />
                        :
                        server.serverType === 'BO3' && server.serverDesc === 'mp' ?
                          <RETO_servers_Card
                            key={index}
                            {...server}
                          />
                          : null
                    )
                  })
                }
              </Slider>
            </div>

            <div style={{ marginBottom: '50px', marginTop: '50px' }}>
              {!isNonMobile ?
                <div style={{
                  alignItems: "center", position: 'relative', zIndex: '1', marginBottom: '20px', flexDirection: 'row', display: 'flex',
                  marginLeft: '25px', justifyContent: '', width: '100%'
                }}>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowLeft wow fadeInLeft" : "BannerSlider-arrowLeftMobile wow fadeInLeft"}
                    onClick={() => arrowRef_bo3_2.current.slickPrev()}
                  >
                    <img src={arrow1} alt="arrow1" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowRight wow fadeInRight" : "BannerSlider-arrowRightMobile wow fadeInRight"}
                    onClick={() => arrowRef_bo3_2.current.slickNext()()}
                  >
                    <img src={arrow2} alt="arrow2" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                </div>
                : null}
              <Slider
                ref={arrowRef_bo3_2}
                dots={false}
                infinite={false}
                draggable={true}
                swipe={true}
                arrows={false}
                className='Search-Result-Container-Slider'
                focusOnSelect={true}
                autoplay={true}
                autoplaySpeed={3000}
                slidesToShow={
                  isNonMobile ? Servers_Info_Bo3?.length > 4 ? 4 : Servers_Info_Bo3?.length === 4 || Servers_Info_Bo3?.length >= 3 ? 3
                    : Servers_Info_Bo3?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
                slidesToScroll={
                  isNonMobile ? Servers_Info_Bo3?.length > 4 ? 4 : Servers_Info_Bo3?.length === 4 || Servers_Info_Bo3?.length >= 3 ? 3
                    : Servers_Info_Bo3?.length >= 2 ? 2 : 1 : isTablet ? 2 : 1}
              >
                {
                  Servers_Info_Bo3.map((server, index) => {
                    return (
                      server.serverType === 'BO3' && server.serverDesc === 'zm' && server.currentPlayers >= 1 ?
                        <RETO_servers_Card
                          key={index}
                          {...server}
                        />
                        :
                        server.serverType === 'BO3' && server.serverDesc === 'zm' ?
                          <RETO_servers_Card
                            key={index}
                            {...server}
                          />
                          : null
                    )
                  })
                }
              </Slider>
            </div>

          </div>
        </Row>
      )}
    </section>
  )
}


export default BO3_servers_screen;