import axios from "axios";

// game_like_dislike_Comment method
const game_like_dislike_Comment = async ({gameData, specific_comment, user}) => {
    try {
        const current_game_Data = {
            _id: gameData._id,
            game_Name: gameData.game_Name,
            game_Number: gameData.game_Number,
            game_Page: gameData.game_Page,
        }


        const response = await axios.post("https://arab2games-games-backend.vercel.app/api/arab2games/game_like_dislike_Comment", {
            current_game_Data: current_game_Data,
            specific_comment: specific_comment,
            user: user
        })
            .then((response) => {
                if (response.status === 200) {


                   return 'done';
                }
            })

        return response;
    }
    catch (error) {
        console.log("❌ game_like_dislike_Comments ~ couldn't add the like to the comment ~ error :   ", error)
        return false;
    }
};

export default game_like_dislike_Comment;