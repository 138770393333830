import React from 'react';
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  FaDownload,
  FaEye,
  FaStar,
} from 'react-icons/fa';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useAuth } from '../../xFireBase/AuthContext';

export const GamesCard = ({ All_gameData }) => {

  const { CurrentLanguage, darkMode } = useAuth();

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isTooSmall = useMediaQuery("(max-width:359px)");

  const formatNumber = (number) => {
    if (number > 99999) {
      return (number / 1000).toFixed(1) + 'K';
    }
    return number.toLocaleString();
  };

  return (
    <div
      className={darkMode === 'light' ? 'games-card light' : 'games-card dark'}
      style={{
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out',
        minHeight: isNonMobile ? '244px' : '230px',
      }}>
      <div className="games-imgbx">
        <span className='RETO_watermark' style={{
          fontSize: isNonMobile ? '0.8rem' : '0.53rem',
          color: 'white',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          opacity: '0.65',
          padding: '0.2rem',
          borderRadius: '0.2rem',
          position: 'absolute',
          top: isNonMobile ? '0.2rem' : (isNonMobile === false && All_gameData.game_Picture[0].height > All_gameData.game_Picture[0].width) ? '1rem' : '0.1rem',
          left: isNonMobile ? '0.15rem' : '0.4rem',
        }}>Arab2Games.com</span>
        <LazyLoadImage
          src={All_gameData.game_Picture[0]}
          effect="blur"
          alt='game_Picture'
          draggable={false}
          style={{ width: '100%', height: '100%' }}
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>

      <div>
        <h4 style={{
          fontSize: '0.95rem',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          textAlign: 'left',
          marginLeft: '0.5rem',
          marginRight: '0.5rem',
          direction: CurrentLanguage === 'EN' ? null : 'ltr',
          color: darkMode === 'light' ? '#000000' : '#ffffff',
        }}>
          {All_gameData.game_Name.length >= 35 ? `${All_gameData.game_Name.slice(0, 35)}...` : All_gameData.game_Name}
        </h4>

        {!isNonMobile && (
          <div style={{
            flexDirection: 'row',
            display: 'flex',
            color: darkMode === 'light' ? '#2C2C2C' : '#969595',
            fontSize: '0.75rem',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '10px',
            textAlign: 'center',
            marginLeft: '0.8rem',
            marginRight: '0.8rem',
            direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr',
            width: 'auto',
            height: 'auto'
          }}>
            <div style={{
              fontSize: '0.79rem',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr',
              display: 'flex',
              flexDirection: (All_gameData.game_Downloaded_Times > 9999 && All_gameData?.game_watched_times?.watched_By?.length > 999) ||
                (All_gameData?.game_watched_times?.watched_By?.length > 9999 && All_gameData.game_Downloaded_Times > 999)
                ? 'column'
                : isTooSmall ? 'column' : 'row'
            }}>
              {formatNumber(All_gameData.game_Downloaded_Times)}
              <FaDownload style={{
                color: darkMode === 'light' ? '#9642F7' : '#B67CF8AF',
                marginLeft: CurrentLanguage === 'EN' ? '' : '3px',
                marginRight: CurrentLanguage === 'EN' ? '3px' : ''
              }}
              />
            </div>

            <div style={{
              fontSize: '0.79rem',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              marginLeft: '0.5rem',
              marginRight: '0.5rem',
              direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr',
              display: 'flex',
              flexDirection: (All_gameData.game_Downloaded_Times > 9999 && All_gameData?.game_watched_times?.watched_By?.length > 999) ||
                (All_gameData?.game_watched_times?.watched_By?.length > 9999 && All_gameData.game_Downloaded_Times > 999)
                ? 'column'
                : isTooSmall ? 'column' : 'row'
            }}>
              {formatNumber(All_gameData?.game_watched_times?.watched_By?.length)}
              <FaEye
                style={{
                  color: darkMode === 'light' ? '#D82C2CEF' : '#DF6868D5',
                  marginLeft: CurrentLanguage === 'EN' ? '' : '3px',
                  marginRight: CurrentLanguage === 'EN' ? '3px' : ''
                }}
              />
            </div>

            <div style={{
              fontSize: '0.79rem',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr',
              display: 'flex',
              flexDirection: (All_gameData.game_Downloaded_Times > 9999 && All_gameData?.game_watched_times?.watched_By?.length > 999) ||
                (All_gameData?.game_watched_times?.watched_By?.length > 9999 && All_gameData.game_Downloaded_Times > 999)
                ? 'column'
                : isTooSmall ? 'column' : 'row'
            }}>
              {formatNumber(All_gameData.game_Reviews > 0 ? All_gameData.game_Reviews : 0)}
              <FaStar
                style={{
                  color: darkMode === 'light' ? '#F7A52BFA' : '#FFB547EA',
                  marginLeft: CurrentLanguage === 'EN' ? '' : '3px',
                  marginRight: CurrentLanguage === 'EN' ? '3px' : ''
                }}
              />
            </div>
          </div>
        )}

        {isNonMobile && (
          <div style={{
            flexDirection: 'row',
            display: 'flex',
            color: '#969595',
            fontSize: '0.79rem',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr',
            marginTop: '5px',
            marginBottom: '15px',
            width: 'auto',
            color: darkMode === 'light' ? '#2C2C2C' : '#969595',
            height: 'auto',
            marginLeft: isNonMobile ? (CurrentLanguage === 'EN' ? '' : '0.5rem') : (CurrentLanguage === 'EN' ? '' : ''),
            marginRight: isNonMobile ? (CurrentLanguage === 'EN' ? '0.5rem' : '') : (CurrentLanguage === 'EN' ? '' : ''),
          }}>
            <div style={{
              fontSize: '0.89rem',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr'
            }}>
              {formatNumber(All_gameData.game_Downloaded_Times)}
              <FaDownload style={{
                color: darkMode === 'light' ? '#9642F7' : '#B67CF8AF',
                marginLeft: CurrentLanguage === 'EN' ? '' : '3px',
                marginRight: CurrentLanguage === 'EN' ? '3px' : ''
              }}
              />
            </div>

            <div style={{
              fontSize: '0.89rem',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              marginLeft: '1.5rem',
              marginRight: '1.5rem',
              direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr'
            }}>
              {formatNumber(All_gameData?.game_watched_times?.watched_By?.length)}
              <FaEye
                style={{
                  color: darkMode === 'light' ? '#D82C2CEF' : '#DF6868D5',
                  marginLeft: CurrentLanguage === 'EN' ? '' : '3px',
                  marginRight: CurrentLanguage === 'EN' ? '3px' : ''
                }}
              />
            </div>

            <div style={{
              fontSize: '0.89rem',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              direction: CurrentLanguage === 'EN' ? 'rtl' : 'ltr'
            }}>
              {formatNumber(All_gameData.game_Reviews > 0 ? All_gameData.game_Reviews : 0)}
              <FaStar
                style={{
                  color: darkMode === 'light' ? '#F7A52BFA' : '#FFB547EA',
                  marginLeft: CurrentLanguage === 'EN' ? '' : '3px',
                  marginRight: CurrentLanguage === 'EN' ? '3px' : ''
                }}
              />
            </div>
          </div>
        )}

        <div style={{
          flexDirection: 'row',
          display: 'flex',
          color: '#969595',
          fontSize: '0.75rem',
          justifyContent: 'space-between',
          alignItems: 'center',
          textAlign: 'center',
          marginLeft: '0.8rem',
          marginRight: '0.8rem',
          direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
          width: 'auto',
          height: 'auto'
        }}>
          <div style={{
            flexDirection: 'row',
            display: 'flex',
            color: darkMode === 'light' ? '#2C2C2C' : '#969595',
            direction: isNonMobile ? (CurrentLanguage === 'EN' ? 'rtl' : 'ltr') : (CurrentLanguage === 'EN' ? 'ltr' : 'rtl')
          }}>
            <h6 style={{
              fontSize: '0.79rem',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              marginLeft: isNonMobile ? (CurrentLanguage === 'EN' ? '' : '0.5rem') : '',
              marginTop: isNonMobile ? (CurrentLanguage === 'EN' ? '1.5px' : '2.5px') : '1.5px',
              direction: 'ltr'
            }}>
              {isNonMobile ? All_gameData.game_Size : (CurrentLanguage === 'EN' ? 'Size :' : ': بحجم')}
            </h6>
            <h6 style={{
              fontSize: isNonMobile ? '0.95rem' : '0.75rem',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              marginLeft: isNonMobile ? (CurrentLanguage === 'EN' ? '' : '0.35rem') : (CurrentLanguage === 'EN' ? '0.35rem' : '0.35rem'),
              marginRight: '0.5rem',
              direction: !isNonMobile ? (CurrentLanguage === 'EN' ? 'ltr' : 'ltr') : '',
              marginTop: isNonMobile ? null : (CurrentLanguage === 'EN' ? '1.5px' : '2.5px')
            }}>
              {!isNonMobile ? All_gameData.game_Size : (CurrentLanguage === 'EN' ? ': Size' : ': بحجم')}
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
}