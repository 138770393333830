import React, { useState, useEffect } from 'react';
import axios from "axios";
import CryptoJS from "crypto-js";
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Tab, } from "react-bootstrap";
import { CurrentAppCard } from "../../components/HomeMiddle/CurrentAppCard";
import 'animate.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Preloader from "../../hooks/Others/PreLoader";
import Profile_Loading from '../../assets/img/icons/Profile_Loading.svg'
import Page_Helmet from "../../hooks/SEO/react_Helmet";
import { useAuth } from '../../xFireBase/AuthContext';
import ScrollToTop from '../../ScrollToTop';

const CurrentAppPage = () => {

  const { ALL_Apps, darkMode } = useAuth();

  const { app_Number, app_Page } = useParams();
  const [appData, setAppData] = useState(null);
  const [load, upadateLoad] = useState(true);
  const [timer_animation, setTimer_animation] = useState(0);


  // loading
  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [])


  useEffect(() => {
    const axiosFetchData = async () => {
      try {
        // go through ALL_Apps and get the app_Number && app_Page
        const appData = await ALL_Apps.filter(app => app.app_Page === app_Page);
        setAppData(appData);

      }
      catch (error) {
      }
    };

    axiosFetchData();

  }, [app_Number, app_Page]);

  if (!appData) {
    return <Preloader load={load} darkMode={darkMode} />
  }


  return (
    <div className={timer_animation === 1 ? "animate__animated animate__fadeIn" : ''}>
      <Page_Helmet
        title={`Arab2Games | ${appData[0]?.app_Name || ''}`}
        type="article"
        description={appData[0]?.app_Description ? appData[0]?.app_Description?.length > 550
          ? appData[0]?.app_Description?.slice(0, 550) + '...' : appData[0]?.app_Description : ''}
        image={appData[0]?.app_Picture[0] || ''}
        url={window.location.href}
        keywords={appData[0]?.app_Categories || ''}
      />
      <ScrollToTop />
      <section className="GamePageSection" >
        <Container>
          <Row>
            <Col size={12}>

              <Tab.Container defaultActiveKey="first">

                <Tab.Content id="slideInUp">
                  <Tab.Pane eventKey="first">
                    <Row
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        alignContent: 'center',
                      }}
                    >
                      {
                        appData.length > 0 ?
                          appData
                            .map((doc, index) => {
                              if (doc.app_Page !== app_Page) return null;

                              return (
                                <Col xs={6} sm={6} md={6} lg={5} xl={12} key={index} onClick={() => {

                                }}>
                                  <CurrentAppCard
                                    key={index}
                                    All_appData={doc}
                                    app_Page={doc.app_Page}
                                  />
                                </Col>
                              )
                            })
                          :
                          <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center' }}>
                            <LazyLoadImage
                              effect="blur"
                              draggable={false}
                              src={Profile_Loading}
                              alt='Profile_Loading'
                              onContextMenu={(e) => e.preventDefault()}
                              style={{ width: '30px', height: '30px', display: 'flex' }} />
                            <h1>جاري تحميل صفحة البرنامج</h1>
                          </div>
                      }
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        </Container>

      </section>
    </div>
  );
}

export default CurrentAppPage;
